import React from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Popup } from 'semantic-ui-react';

const PopupButton = ({ onClick, title, id, icon }) => (
  <Popup
    content={title}
    position="top right"
    trigger={
      <Button icon basic color="teal" id={id} onClick={onClick}>
        <Icon name={icon} size="large" />
      </Button>
    }
  />
);

PopupButton.propTypes = {
  onClick: PropTypes.func.isRequired,
  title: PropTypes.string.isRequired,
  icon: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
};
export default PopupButton;
