import Papa from 'papaparse';

// Custom hook for importing assessments from CSV files.
const useImportAssessments = (handleAssessments, failure) => {
  const _makeLowerCase = (string) =>
    string.charAt(0).toLowerCase() + string.slice(1);

  const _sortAssessments = (toSort) =>
    toSort.sort((a, b) => (a.coach > b.coach ? 1 : -1));

  const _importFile = async (file) => {
    console.log('importFile.File: ', file);
    if (!file) {
      failure('File not found');
    }

    Papa.parse(file, {
      header: true,
      complete(event) {
        console.log('Parsed assessments', event);
        const sorted = _sortAssessments(event.data);
        handleAssessments(sorted);
      },
      transformHeader(value) {
        return _makeLowerCase(value);
      },
      transform(value, name) {
        if (name === 'exemption') {
          return value !== 'f';
        }
        if (name === 'coach') {
          return parseInt(value);
        }
        if (name === 'landValue') {
          return parseInt(value);
        }
        if (name === 'improvements') {
          return parseInt(value);
        }
        if (name === 'hoeAmount') {
          return parseInt(value);
        }
        return value;
      },
    });
  };

  const importAssessmentsFile = async (e) => {
    _importFile(e.target.files[0]);
  };

  const importReassessmentsFile = async (e) => {
    _importFile(e.target.files[0]);
  };

  return {
    importAssessmentsFile,
    importReassessmentsFile,
  };
};

export default useImportAssessments;
