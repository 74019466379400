import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Modal } from 'semantic-ui-react';
import AwesomeSlider from 'react-awesome-slider';
import 'react-awesome-slider/dist/styles.css';
// imsport SwiftSlider from "react-swift-slider";

const ModalGallery = ({ imageUrls, header }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const images = imageUrls.map((url) => ({
    source: url,
  }));

  // const simages = props.imageUrls.map((url, index) => {
  //   return {
  //     id: index + 1,
  //     src: url
  //   };
  // });

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic color="teal">
          View Images
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
      size="fullscreen"
    >
      <Header>{header}</Header>
      <Modal.Content>
        <AwesomeSlider
          media={images}
          bullets={false}
          // organicArrows={false}
          // buttonContentLeft={"<"}
          // buttonContentRight={">"}
        />
        {/* <SwiftSlider data={simages} height={700} /> */}
      </Modal.Content>
      <Header>
        <Button basic color="teal" onClick={handleClose}>
          Close
        </Button>
      </Header>
    </Modal>
  );
};

ModalGallery.propTypes = {
  imageUrls: PropTypes.array.isRequired,
};

export default ModalGallery;
