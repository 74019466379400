import React from 'react';
import { Menu, Divider } from 'semantic-ui-react';
import Can from '../../../components/Can/Can';
import { UserContext } from '../../../services/context/UserContext';
import SideBarItem from '../../SideBar/SideBarItem/SideBarItem';
import SideBarHeader from '../../SideBar/SideBarHeader/SideBarHeader';
import SideBarFooter from '../../SideBar/SideBarFooter/SideBarFooter';
import './SideBar.scss';

const renderOffice = () => (
  <Menu borderless vertical stackable fixed="left" className="side-nav">
    <SideBarItem path="/" label="Home" icon="home" />
    <Divider fitted />
    <SideBarHeader title="Office" />
    <SideBarItem path="reimburse" label="Print Reimburse Form" icon="dollar" />
    <SideBarItem path="birthdays" label="Resident Birthdays" icon="birthday" />
    <SideBarItem path="frequent" label="Business Directory" icon="phone" />
    <SideBarItem path="ballot" label="Ballot" icon="table" />
    <Divider />
    <SideBarItem path="archived" label="Archived" icon="archive" />
    <Divider />
    <SideBarFooter />
  </Menu>
);

const renderResidents = () => (
  <Menu borderless vertical stackable fixed="left" className="side-nav">
    <SideBarItem path="/" label="Home" icon="home" />
    <Divider fitted />
    <SideBarHeader title="Office" />
    <SideBarItem path="birthdays" label="Resident Birthdays" icon="birthday" />
    <SideBarItem path="frequent" label="Business Directory" icon="phone" />
    <Divider />
    <SideBarFooter />
  </Menu>
);

const SideBar = () => {
  const { user } = React.useContext(UserContext);

  if (!user) {
    return null;
  }

  return (
    <Can
      role={user.role}
      perform="office:admin"
      yes={() => renderOffice()}
      no={() => renderResidents()}
    />
  );
};

export default SideBar;
