import React from 'react';
import { withRouter } from 'react-router-dom';
import { UserContext } from '../../../services/context/UserContext';
import Can from '../../../components/Can/Can';
import LoansTable from './LoansTable';
import useLoans from './useLoans';

const ArchivedLoans = () => {
  const { user } = React.useContext(UserContext);
  const { error, loans } = useLoans(true);

  if (!user) {
    return <div>Loading..</div>;
  }

  if (error) {
    return <div>Error loading loans: {error}</div>;
  }

  return (
    <>
      <Can
        role={user.role}
        perform="finance/budgets:visit"
        yes={() => <LoansTable loans={loans} user={user} isArchive />}
        // This causes us to reset to home even when logged in
        // no={() => <Redirect to="/" />}
      />
    </>
  );
};

export default withRouter(ArchivedLoans);
