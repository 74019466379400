import React from 'react';
import EnsureUser from '../../components/EnsureUser/EnsureUser';
import OfficeContent from './OfficeContent/OfficeContent';
import SideBar from './SideBar/SideBar';

const Office = () => (
  <EnsureUser>
    <SideBar />
    <OfficeContent />
  </EnsureUser>
);

export default Office;
