import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useComponentSet from './useComponentSet';
import EditForm from './EditForm';

const EditComponentSet = ({ set }) => {
  const [open, setOpen] = useState(false);
  const { canUpdate, doUpdate, vars, handleChange } = useComponentSet(
    set,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Error updating component set', error);
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  const disabled = () => !canUpdate();

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name="edit outline" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Component Set" />
      <Modal.Content>
        <EditForm vars={vars} fieldUpdated={handleChange} />
        {/* {mutationLoading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {mutationError && <p>Error: {mutationError.message}</p>} */}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

EditComponentSet.propTypes = {
  set: PropTypes.object.isRequired,
};

export default EditComponentSet;
