import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import { db } from '../../../../services/firebase/firebase';
import EditForm from './EditForm';

const EditStudyComp = ({ study, editComp, index }) => {
  const [open, setOpen] = useState(false);
  const [studyComp, setStudyComp] = useState({
    name: editComp.name || '',
    pid: editComp.pid || '',
    ul: editComp.usefulLife || '',
    rul: editComp.remainingUsefulLife || '',
    rc: editComp.replacementCost || '',
  });

  const makeVariables = () => ({
    pid: studyComp.pid,
    name: studyComp.name,
    remainingUsefulLife: parseInt(studyComp.rul),
    usefulLife: parseInt(studyComp.ul),
    replacementCost: parseInt(studyComp.rc),
    category: editComp.category,
  });

  const update = async (variables) => {
    console.log('variables ', variables);

    // Replace the updated component
    study.components[index] = variables;
    // Updating a component requires updating the
    // array of components, best I can tell.
    const studyRef = db.collection('studies').doc(study.id);
    studyRef
      .update({
        components: study.components,
      })
      .then(function () {
        console.log('Document successfully updated!');
        setOpen(false);
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });
  };

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    update(makeVariables());
  };

  const disabled = () => false;

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name="edit outline" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Study Component" />
      <Modal.Content>
        <EditForm
          studyComp={studyComp}
          fieldUpdated={(name, value) => {
            setStudyComp({ ...studyComp, [name]: value });
          }}
        />
        {/* {mutationLoading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {mutationError && <p>Error: {mutationError.message}</p>} */}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

EditStudyComp.propTypes = {
  editComp: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  index: PropTypes.number.isRequired,
};

export default EditStudyComp;
