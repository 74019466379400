import React from 'react';
import firebase from 'firebase/app';
import LoginForm from '../Login/LoginForm';
import Logout from '../Logout/Logout';

const Account = ({ error }) => {
  const user = firebase.auth().currentUser;

  return (
    <div>
      {user && <Logout error={error} />}
      {!user && <LoginForm />}
    </div>
  );
};

export default Account;
