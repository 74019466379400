import React, { useState } from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
// import { db } from "../../../../../services/firebase/firebase";
import ModalActions from '../../../../components/CreateModal/ModalActions';
// import { createVars, createEditState } from "./common";
import useSupplemental from './useSupplemental';
import EditForm from './EditForm';
// import firebase from "firebase/app";

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const EditSupplemental = ({ edit, taxBill, index }) => {
  // console.log("EditSupplementalTaxBill.props", props);
  const [open, setOpen] = useState(false);
  const { supplemental, doUpdate, canUpdate, handleChange } = useSupplemental(
    taxBill,
    edit,
    index,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );
  // const update = (variables) => {
  //   console.log("edit supp vars", variables);
  //   const vars = [variables];
  //   const original = [props.supplemental];
  //   const docRef = db.collection("taxBills").doc(props.taxBill.id);

  //   let writeBatch = db.batch();
  //   writeBatch.update(docRef, {
  //     supplementals: firebase.firestore.FieldValue.arrayRemove(...original),
  //   });
  //   writeBatch.update(docRef, {
  //     supplementals: firebase.firestore.FieldValue.arrayUnion(...vars),
  //   });
  //   writeBatch.commit().then((res) => {
  //     console.log("Successfully executed batch.");
  //   });
  // };

  // const makeVariables = () => {
  //   return createVars(taxBill);
  // };

  const disabled = () => !canUpdate();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    // update(makeVariables());
    doUpdate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <LightTooltip title="Edit Supplemental Tax Bill" placement="top-start">
          <Button basic icon color="teal">
            <Icon name="edit outline" size="large" />
          </Button>
        </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header
        content={`Edit Supplemental Tax Bill for Space:  ${edit.space}`}
      />
      <Modal.Content>
        <EditForm supplemental={supplemental} fieldUpdated={handleChange} />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditSupplemental;
