import React from 'react';
import { UserContext } from '../../../services/context/UserContext';
import { ParkContext } from '../../../services/context/ParkContext';
import ReconcileTable from './ReconcileTable';

const TaxBills = ({ taxBills }) => {
  const { user } = React.useContext(UserContext);
  const { park } = React.useContext(ParkContext);

  if (!park || !user) {
    return null;
  }

  return <ReconcileTable park={park} taxBills={taxBills} />;
};

export default TaxBills;
