import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Dimmer, Loader } from 'semantic-ui-react';
import { ParkContext } from '../../../services/context/ParkContext';
import Privacy from '../../../components/Privacy/Privacy';
import useSpaces from '../../Spaces/useSpaces';
import useArchives from '../TaxBills/useArchives';
import useTaxBills from '../TaxBills/useTaxBills';
import Supplementals from '../Supplementals/Supplementals';
import Reassessments from '../Reassessments/Reassessments';
import Reconcile from '../Reconcile/Reconcile';
import TaxBills from '../TaxBills/TaxBills';
import Park from '../../Parks/Park';
import Help from '../Help/Help';
import './TaxesContent.scss';

const TaxesContent = () => {
  const { taxBillArchives } = useArchives();
  const { taxBills } = useTaxBills(false);
  const { spaces } = useSpaces();

  if (!spaces || !taxBills || !taxBillArchives) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  const park = new Park(spaces, taxBills);
  // if (parkError) {
  //   return (
  //     <div>
  //       <Segment textAlign="center" size="small" color="red">
  //         {`Error loading tax info: ${parkError}`}
  //       </Segment>
  //     </div>
  //   );
  // }

  return (
    <div className="taxes-content">
      <ParkContext.Provider value={{ park }}>
        <Switch>
          <Route
            path="/taxes/taxbills"
            render={() => <TaxBills taxBills={taxBills} showArchive={false} />}
          />
          <Route
            path="/taxes/supplementals"
            render={() => (
              <Supplementals taxBills={taxBills} showArchive={false} />
            )}
          />
          <Route
            path="/taxes/reassessments"
            render={() => <Reassessments taxBills={taxBills} />}
          />
          <Route
            path="/taxes/reconcile"
            render={() => <Reconcile taxBills={taxBills} />}
          />
          <Route
            path="/taxes/archives"
            render={() => <TaxBills taxBills={taxBillArchives} showArchive />}
          />
          <Route
            path="/taxes/supparchives"
            render={() => (
              <Supplementals taxBills={taxBillArchives} showArchive />
            )}
          />
          <Route path="/taxes/help" component={Help} />
          <Route path="/taxes/privacy" component={Privacy} />
        </Switch>
      </ParkContext.Provider>
    </div>
  );
};

export default TaxesContent;
