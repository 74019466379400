import React, { useState } from 'react';
import { Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import PopupButton from '../../../../components/PopupButton/PopupButton';
import useImportSupplementals from './useImportSupplementals';
import useTaxBill from './useTaxBill';
import EditForm from './EditForm';

const CreateProjected = ({ current }) => {
  const [open, setOpen] = useState(false);

  const {
    taxBill,
    handleChange,
    createProjected,
    canCreateProjected,
    handleSupplementals,
  } = useTaxBill(
    current,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const { importSupplementalsFile } = useImportSupplementals(
    () => {},
    (parsedSupplementals) => {
      console.log('Imported supplementals');
      handleSupplementals(parsedSupplementals);
    },
    (error) => {
      console.log('Error parsing import file', error);
    }
  );

  const disabled = () => !canCreateProjected();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    createProjected();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <PopupButton
          id="create-projected"
          basic
          color="teal"
          title="Create New Copy"
          icon="copy outline"
          onClick={() => {}}
        />
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Tax Bill" />
      <Modal.Content>
        <EditForm
          taxBill={taxBill}
          fieldUpdated={handleChange}
          importSupplementalsFile={importSupplementalsFile}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default CreateProjected;
