import React, { useState } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../../components/CreateModal/ModalActions';
import useExpense from './useExpense';
import ExpenseForm from './ExpenseForm';

const CreateExpense = ({ study }) => {
  const [open, setOpen] = useState(false);
  const {
    expense,
    doCreate,
    canCreate,
    handleChange,
    handleOption,
    makeOptions,
  } = useExpense(
    {},
    study,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doCreate();
  };

  const disabled = () => !canCreate();

  return (
    <Modal
      dimmer="inverted"
      trigger={
        // <LightTooltip title="Create Tax Bill" placement="top-start">
        <Button
          // floated="left"
          color="teal"
          basic
          icon
          size="small"
          // style={{ margin: "6px 20px 10px" }}
        >
          <Icon name="plus" size="large" color="teal" />
        </Button>
        // </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create Current Year Expenditure" />
      <Modal.Content>
        <ExpenseForm
          expense={expense}
          fieldUpdated={handleChange}
          handleOption={handleOption}
          options={makeOptions()}
        />
        {/* {mutationLoading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {mutationError && <p>Error: {mutationError.message}</p>} */}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default CreateExpense;
