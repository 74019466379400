import React, { useState } from 'react';
import { Button, Icon, Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../../components/CreateModal/ModalActions';
import usePayment from './usePayment';
import EditForm from './EditForm';

const EditPayment = ({ project, editing }) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setErrorMessage(null);
    setOpen(false);
  };

  const { payment, doUpdate, canUpdate, handleChange } = usePayment(
    project.id,
    editing,
    () => {
      handleClose();
    },
    (error) => {
      setErrorMessage(error);
    }
  );

  const disabled = () => !canUpdate();

  // Save and close modal on succes
  const save = () => {
    doUpdate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name="edit outline" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Payment" />
      <Modal.Content>
        <EditForm payment={payment} fieldUpdated={handleChange} />
        {errorMessage && <p style={{ color: 'red' }}>Error: {errorMessage}</p>}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditPayment;
