// A place for the colors

const colors = [
  'green',
  'red',
  'blue',
  'orange',
  'purple',
  'yellow',
  'olive',
  'green',
  'teal',
  'violet',
  'pink',
  'brown',
  'grey',
  'black',
];

export default colors;
