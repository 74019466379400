import { db } from '../../../../../services/firebase/firebase';

const useModify = (projectId, success, failure) => {
  const deletePayment = (paymentId) => {
    console.log('deletePayment', paymentId);

    db.collection('projects')
      .doc(projectId)
      .collection('payments')
      .doc(paymentId)
      .delete()
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error deleting payment', error);
        failure(error.message);
      });
  };

  return {
    deletePayment,
  };
};

export default useModify;
