import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Button, Dimmer, Header, Loader, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useLoan from './useLoan';
import EditForm from './EditForm';

const CreateLoan = () => {
  const [open, setOpen] = useState(false);

  const {
    loan,
    loading,
    doCreate,
    doCleanup,
    canCreate,
    handleChange,
    errorMessage,
  } = useLoan(
    {},
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    doCleanup();
    setOpen(false);
  };

  const disabled = () => !canCreate();

  // Modal Actions

  const save = () => {
    doCreate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button color="teal" basic icon size="small">
          <Icon name="plus" size="large" color="teal" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create New Loan" />
      <Modal.Content>
        <EditForm loan={loan} fieldUpdated={handleChange} />
        {loading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {errorMessage && <p style={{ color: 'red' }}>Error: {errorMessage}</p>}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

// CreateBudget.propTypes = {
//   user: PropTypes.object.isRequired,
// };

export default CreateLoan;
