import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';

// Returns array of line items sorted into categories
const sortCategories = (lineItems) => {
  if (!lineItems) return [];

  const cats = lineItems.reduce((acc, lineItem) => {
    const cat = lineItem.category;
    if (!acc[cat]) {
      acc[cat] = [];
    }
    acc[cat].push(lineItem);
    return acc;
  }, {});
  const values = Object.values(cats);
  const ordered = values.sort((a, b) => {
    const aCat = a[0].category;
    const bCat = b[0].category;
    if (aCat === 'Income') return -1;
    if (bCat === 'Income') return 1;
    return aCat > bCat ? 1 : -1;
  });
  return ordered;
};

const useLineItems = (budgetId) => {
  const [categories, setCategories] = useState([]);
  const [lineItems, setLineItems] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setLoading(false);

    try {
      const unsubscribe = db
        .collection('budgets')
        .doc(budgetId)
        .collection('lineItems')
        .onSnapshot((snapshot) => {
          const all = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setLineItems(all);
          setCategories(sortCategories(all));
        });

      return () => {
        console.log('cleanup  lineItems');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [budgetId]);

  return {
    fetchError,
    loading,
    lineItems,
    categories,
  };
};

export default useLineItems;
