import React from 'react';
// import styled from 'styled-components';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { digits } from '../../../../services/number/formatting';
import { monthName, monthNames } from '../../../../services/dates/formatting';

// const StyledInput = styled.input`
//   height: 36px;
//   border-radius: 5px;
//   border: 1px solid teal;
//   padding: 10px;
//   margin: 15px;
// `;

const EditForm = ({ fieldUpdated, person, importBirthdaysFile }) => {
  // const { register, handleSubmit, watch, errors } = useForm();

  const birthDate = (timestamp) => {
    fieldUpdated('date', timestamp);
  };

  const getMonth = (date) => date.getMonth();

  const windowDigits = (value) => {
    const digitsOnly = digits(value);
    return digitsOnly <= 106 && digitsOnly > 0 ? digitsOnly : '';
  };

  // console.log(watch('value'));
  // console.log(watch('name'));
  // console.log(watch('email'));

  // const onSubmit = (data) => {
  //   console.log('submit', data);
  // };

  //   return (
  //     <>
  //       <div>
  //         <form onSubmit={handleSubmit(onSubmit)}>
  //           {errors.name && <span>Name is required</span>}
  //           <StyledInput
  //             name="name"
  //             defaultValue="test"
  //             ref={register({ required: true })}
  //           />
  //           {errors.email && <span>Email is required</span>}
  //           <StyledInput
  //             name="email"
  //             defaultValue="a@b.com"
  //             ref={register({ required: true })}
  //           />
  //           <StyledInput name="value" ref={register({ required: true })} />
  //           {errors.value && <span>Value is required</span>}
  //           <StyledInput type="submit" />
  //         </form>
  //       </div>
  //     </>
  //   );
  // };

  return (
    <Form>
      <Form.Group>
        <Form.Input
          width={3}
          label="Name"
          id="form-input-name"
          value={person.name}
          onChange={(_, data) => {
            fieldUpdated('name', data.value);
          }}
        />
        <Form.Input
          width={5}
          label="Space"
          id="form-input-space"
          value={person.space}
          onChange={(_, data) => {
            fieldUpdated('space', windowDigits(data.value));
          }}
        />{' '}
        <Form.Input label="Date">
          <DatePicker
            renderCustomHeader={({
              date,
              changeMonth,
              decreaseMonth,
              increaseMonth,
              prevMonthButtonDisabled,
              nextMonthButtonDisabled,
            }) => (
              <div
                style={{
                  margin: 10,
                  display: 'flex',
                  justifyContent: 'center',
                }}
              >
                <button
                  type="button"
                  onClick={decreaseMonth}
                  disabled={prevMonthButtonDisabled}
                >
                  {'<'}
                </button>
                <select
                  value={monthName[getMonth(date)]}
                  onChange={({ target: { value } }) =>
                    changeMonth(monthNames.indexOf(value))
                  }
                >
                  {monthNames.map((option) => (
                    <option key={option} value={option}>
                      {option}
                    </option>
                  ))}
                </select>

                <button
                  type="button"
                  onClick={increaseMonth}
                  disabled={nextMonthButtonDisabled}
                >
                  {'>'}
                </button>
              </div>
            )}
            selected={person.date}
            onChange={birthDate}
          />
        </Form.Input>
      </Form.Group>
      <Form.Group>
        {importBirthdaysFile && (
          <Form.Input
            type="file"
            label="Import Birthdays"
            id="form-input-ibd"
            onChange={importBirthdaysFile}
          />
        )}
      </Form.Group>
    </Form>
  );
};

export default EditForm;
