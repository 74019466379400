import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import Can from '../../../components/Can/Can';
import EditAssessment from './EditAssessment/EditAssessment';
import AssessmentsFooter from './AssessmentsFooter';

const AssessmentsTable = ({ park, taxBill, user }) => {
  const { model } = taxBill;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const formatter0 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const currency = (value) => formatter.format(value);
  const currency0 = (value) => formatter0.format(value);

  const projectedString = () => `${model.projectedIncrease}%`;

  const hasSupplemental = (spaceNumber, supplementals) =>
    supplementals.find((supplemental) => supplemental.space === spaceNumber);

  const assessmentHoeAmount = (coachNumber) => {
    const amount = model.assessmentHoeAmountFor(coachNumber);
    return amount === 0 ? '--' : formatter0.format(amount);
  };

  const renderButtons = (assessment, index) => (
    <Button.Group size="mini">
      <EditAssessment taxBill={taxBill} editAssess={assessment} index={index} />
    </Button.Group>
  );

  const renderButtonsCan = (assessment, index) => (
    <Can
      role={user.role}
      perform="park/taxes:edit"
      yes={() => renderButtons(assessment, index)}
      no={() => null}
    />
  );

  const renderNormalRow = (assessment, index) => {
    const space = park.spaceFor(assessment.coach);
    const assessmentTaxes = model.assessmentTaxesFor(assessment.coach);
    const isNewOwner =
      hasSupplemental(space.number, model.supplementals) != null;

    return (
      <Table.Row key={assessment.coach}>
        <Table.Cell textAlign="center">{space.number}</Table.Cell>
        <Table.Cell textAlign="left">{space.name}</Table.Cell>
        <Table.Cell textAlign="center">{isNewOwner ? 'Y' : ''}</Table.Cell>
        <Table.Cell textAlign="center">
          {assessmentHoeAmount(assessment.coach)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency0(assessment.landValue)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency0(assessment.improvements)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency0(assessment.landValue + assessment.improvements)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {formatter.format(assessmentTaxes.annualTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(assessmentTaxes.monthlyTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(assessmentTaxes.projectedMonthlyTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {renderButtonsCan(assessment, index)}
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="left">Space</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width="1">
              New Owner
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">HOE</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Land Value</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Improvements</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Total Assessed Value
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Annual Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Monthly Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Projected ({projectedString()})
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {model.assessments.map((assessment, index) =>
            renderNormalRow(assessment, index)
          )}
        </Table.Body>
        <AssessmentsFooter taxes={model.allAssessmentTaxesMap} />
      </Table>
    </>
  );
};

AssessmentsTable.propTypes = {
  user: PropTypes.object.isRequired,
  park: PropTypes.object.isRequired,
  taxBill: PropTypes.object.isRequired,
};

export default AssessmentsTable;
