import React from 'react';
import { Table, Button, Radio, Segment } from 'semantic-ui-react';
import Can from '../../../components/Can/Can';
import colors from '../../../components/Styles/Colors';
import PopupButton from '../../../components/PopupButton/PopupButton';
import CreateProjected from './EditTaxBill/CreateProjected';
import CreateTaxBill from './EditTaxBill/CreateTaxBill';
import EditTaxBill from './EditTaxBill/EditTaxBill';
import Assessments from '../Assessments/Assessments';
import useTaxBillSelector from './useTaxBillSelector';
import useModify from './EditTaxBill/useModify';

const TaxBillsTable = ({ park, taxBills, showArchive, user }) => {
  const { selectedIndex, handleSelectionChange } = useTaxBillSelector(
    taxBills,
    showArchive
  );

  const { archiveItem } = useModify(
    () => {},
    (error) => {
      console.log('Failure', error);
    }
  );

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  // const percent = (value) => (value ? `${value}%` : null);

  const currency = (value) => (value ? formatter.format(value) : null);

  const handleArchive = async (_, data) => {
    archiveItem(data.id);
  };

  const selectedChanged = (_, data) => {
    handleSelectionChange(data.id);
  };

  const renderAddTaxBill = () => (
    <Can
      role={user.role}
      perform="park/taxes:edit"
      yes={() => <CreateTaxBill />}
      no={() => null}
    />
  );

  const renderButtons = (model) => (
    <Button.Group size="mini">
      <EditTaxBill editing={model} />
      <CreateProjected current={model} />
      <PopupButton
        title="Archive Tax Bill"
        icon="file archive outline"
        id={model.id}
        onClick={handleArchive}
      />
    </Button.Group>
  );

  const renderButtonsCan = (model, index) => (
    <Can
      role={user.role}
      perform="park/taxes:edit"
      yes={() => renderButtons(model, index)}
      no={() => null}
    />
  );

  const renderNormalRow = (taxBill, index) => {
    const { model } = taxBill;

    return (
      <Table.Row key={taxBill.id}>
        <Table.Cell textAlign="center">
          <Radio
            id={model.id}
            checked={selectedIndex === index}
            disabled={false}
            onChange={selectedChanged}
          />
        </Table.Cell>
        <Table.Cell textAlign="center">{model.year}</Table.Cell>
        <Table.Cell textAlign="center">{model.assessedValueRate}</Table.Cell>
        <Table.Cell textAlign="center">{model.landImprovementsRate}</Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.totalAssessedValue)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.perPropertyTotalTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.specialATax)}
        </Table.Cell>
        <Table.Cell textAlign="left">{model.notes}</Table.Cell>
        <Table.Cell textAlign="center">
          {renderButtonsCan(model, index)}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderTaxBillHeader = () => (
    <>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell textAlign="center" width={1}>
            {renderAddTaxBill()}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Tax Year</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Assessed Value Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Land & Impr Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Total Assessed Value
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Per Prop Value</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Special A Tax</Table.HeaderCell>
          <Table.HeaderCell width={5} textAlign="center">
            Notes
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </>
  );

  const renderTaxBillBody = (taxBill, index) => (
    <React.Fragment key={taxBill.id}>
      <Table.Body>{renderNormalRow(taxBill, index)}</Table.Body>
    </React.Fragment>
  );

  const renderSelectedAssessments = () => {
    const color = colors[selectedIndex % colors.length];
    const index = selectedIndex < taxBills.length ? selectedIndex : 0;
    const taxBill = taxBills[index];

    if (!taxBill) {
      console.log('No tax bill', selectedIndex);
      return null;
    }

    if (!taxBill.assessments || !taxBill.supplementals) {
      return null;
    }

    return (
      <Segment color={color}>
        <Assessments taxBill={taxBill} showArchive={false} park={park} />
      </Segment>
    );
  };

  return (
    <>
      <Table celled striped>
        {renderTaxBillHeader()}
        {taxBills.map((taxBill, index) => renderTaxBillBody(taxBill, index))}
      </Table>
      {renderSelectedAssessments()}
    </>
  );
};

export default TaxBillsTable;
