import Papa from 'papaparse';

// Custom hook for importing birthdays from CSV files.
// Provide callbacks for success and failure notification.
const useImport = (handleBirthdays, failure) => {
  const _makeLowerCase = (string) =>
    string.charAt(0).toLowerCase() + string.slice(1);

  const _sortBirthdays = (toSort) =>
    toSort.sort((a, b) => (a.name > b.name ? 1 : -1));

  const importBirthdaysFile = async (e) => {
    const file = e.target.files[0];
    console.log('importBirthdaysFile.File: ', file);
    if (!file) {
      failure('File not found');
    }

    Papa.parse(file, {
      skipEmptyLines: 'greedy',
      header: true,
      complete(event) {
        console.log('Parsed birthdays', event);
        const sorted = _sortBirthdays(event.data);
        handleBirthdays(sorted);
      },
      transformHeader(value) {
        return _makeLowerCase(value);
      },
      transform(value, name) {
        if (name === 'space') {
          return parseInt(value);
        }
        if (name === 'date') {
          return new Date(value);
        }
        return value;
      },
    });
  };

  return {
    importBirthdaysFile,
  };
};

export default useImport;
