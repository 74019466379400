import React from 'react';
import { Segment } from 'semantic-ui-react';
import { UserContext } from '../../../services/context/UserContext';
import ListingsTable from './ListingsTable';
import useArchives from './useArchives';

// Function component to query for archived listings. Update state
// and render the table with the listings.
const CoachArchives = () => {
  const { archives, fetchError } = useArchives();

  if (fetchError) {
    return (
      <div>
        <Segment textAlign="center" size="small" color="red">
          {`Error getting archived listings: ${fetchError.message}`}
        </Segment>
      </div>
    );
  }

  return (
    <div>
      <UserContext.Consumer>
        {({ user }) => (
          <ListingsTable listings={archives} showArchive user={user} />
        )}
      </UserContext.Consumer>
    </div>
  );
};

export default CoachArchives;
