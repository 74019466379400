import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';

const useBudgets = (kind, archive) => {
  const [budgets, setBudgets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setLoading(false);

    try {
      const unsubscribe = db
        .collection('budgets')
        .where('archive', '==', archive)
        .where('kind', '==', kind)
        .onSnapshot((snapshot) => {
          const all = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          // .sort((a, b) => {
          //   if (a.current) {
          //     return 1;
          //   }
          //   if (b.current) {
          //     return -1;
          //   }

          //   // return a.current
          //   //   ? 1
          //   //   : b.current
          //   //   ? -1
          //   //   : a.budgetYear > b.budgetYear
          //   //   ? -1
          //   //   : 1;
          //   return a.number > b.number ? 1 : -1;
          // });

          setBudgets(all);
          console.log('budgets', all);
        });

      return () => {
        console.log('cleanup budgets');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [kind, archive]);

  return {
    fetchError,
    loading,
    budgets,
  };
};

export default useBudgets;
