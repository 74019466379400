import { useState } from 'react';
import firebase from 'firebase/app';
import { parseLocaleNumber } from '../../../../../services/number/formatting';
import { db } from '../../../../../services/firebase/firebase';

// Custom hook for CRUD Reserve Expense Activity. Provide optional callbacks for
// success and failure notification.
const useExpense = (itemOrNull, study, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();

  const [expense, setExpense] = useState({
    pid: itemOrNull.pid || '',
    name: itemOrNull.name || '',
    amount: itemOrNull.amount || '',
    date: itemOrNull.date ? itemOrNull.date : new Date(),
  });

  // Returns variables for edit
  const makeVariables = () => ({
    ...expense,
    amount: parseLocaleNumber(expense.amount),
  });

  const docRef = db
    .collection('studies')
    .doc(study.id)
    .collection('activities')
    .doc('expenses');

  const canCreate = () => expense.name && expense.date && expense.amount;

  const canUpdate = () => true;

  const _writeBatch = async (batch) => {
    batch
      .commit()
      .then(() => {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        failure(errorMessage);
      });
  };

  const _create = async (variables) => {
    const vars = [variables];

    docRef
      .set(
        { expenses: firebase.firestore.FieldValue.arrayUnion(...vars) },
        { merge: true }
      )
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('error', error);
        setErrorMessage(error.message);
        failure(errorMessage);
      });
  };

  const doCreate = async () => {
    _create(makeVariables());
  };

  const _update = async (variables) => {
    console.log('update expenses vars ', variables);

    const writeBatch = db.batch();

    writeBatch.update(docRef, {
      expenses: firebase.firestore.FieldValue.arrayRemove({ ...itemOrNull }),
    });
    writeBatch.update(docRef, {
      expenses: firebase.firestore.FieldValue.arrayUnion({ ...variables }),
    });
    _writeBatch(writeBatch);
  };

  const doUpdate = async () => {
    _update(makeVariables());
  };

  const doDelete = async (entry) => {
    console.log('doDelete', entry);

    const writeBatch = db.batch();

    writeBatch.update(docRef, {
      expenses: firebase.firestore.FieldValue.arrayRemove({ ...entry }),
    });
    _writeBatch(writeBatch);
  };

  const handleChange = (name, value) => {
    setExpense({ ...expense, [name]: value });
  };

  const handleOption = (name, value, options) => {
    // console.log("options", options);
    const option = options.find((item) => item.key === value);
    setExpense({ ...expense, [name]: value, pid: option.pid });
  };

  const makeOptions = () => {
    const comps = study.yearlyExpenses.componentsByYear;
    return comps[0].map((comp) => ({
      text: comp.name,
      value: comp.name,
      key: comp.name,
      pid: comp.pid,
    }));
  };

  return {
    errorMessage,
    handleChange,
    handleOption,
    makeOptions,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doDelete,
    expense,
  };
};

export default useExpense;
