import { useState, useEffect } from 'react';
import TypeOptions from '../LineItems/TypeOptions';

const useSummary = (categories) => {
  const [income, setIncome] = useState(0);
  const [dmfIncome, setDmfIncome] = useState(0);
  const [budgeted, setBudgeted] = useState(0);
  const [dmfBudgeted, setDmfBudgeted] = useState(0);

  const computeItemsTotal = (items, type) =>
    items.reduce(
      (acc, item) => (item.type === type ? acc + item.amount : acc),
      0
    );

  useEffect(() => {
    const computeIncome = (cats, type) => {
      if (!cats) return;

      return cats.reduce((acc, items) => {
        if (items.length > 0) {
          if (items[0].category === 'Income') {
            const itemsTotal = computeItemsTotal(items, type);
            return acc + itemsTotal;
          }
        }
        return acc;
      }, 0);
    };

    const computeBudgeted = (cats, type) => {
      if (!cats) return;
      return cats.reduce((acc, items) => {
        if (items.length > 0) {
          if (items[0].category !== 'Income') {
            const total = computeItemsTotal(items, type);
            return acc + total;
          }
        }
        return acc;
      }, 0);
    };

    setIncome(computeIncome(categories, TypeOptions[0].value));
    setDmfIncome(computeIncome(categories, TypeOptions[1].value));
    setBudgeted(computeBudgeted(categories, TypeOptions[2].value));
    setDmfBudgeted(computeBudgeted(categories, TypeOptions[3].value));
  }, [categories]);

  return {
    income,
    dmfIncome,
    budgeted,
    dmfBudgeted,
  };
};

export default useSummary;
