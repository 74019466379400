import React, { useState } from 'react';
import Papa from 'papaparse';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dimmer, Header, Loader, Modal, Form } from 'semantic-ui-react';
import { db } from '../../../../services/firebase/firebase';
import ModalActions from '../../../../components/CreateModal/ModalActions';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const makeLowerCase = (string) =>
  string.charAt(0).toLowerCase() + string.slice(1);

const ImportListings = () => {
  // console.log("props", props);
  const [loading] = useState(false);
  const [error] = useState(null);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);

  const importFile = async (e) => {
    const file = e.target.files[0];
    console.log('File: ', file);
    Papa.parse(file, {
      header: true,
      complete(event) {
        console.log('complete', event);
        // const filtered = applyFilters(event.data);
        // console.log("Filtered", filtered);
        setData(event.data);
      },
      transformHeader(value) {
        return makeLowerCase(value.replace(' ', ''));
      },
      transform(value, name) {
        if (name === 'isArchived') {
          return value === 't';
        }
        return value;
        // return value.startsWith("$") ? value.replace(/\D/g, "") / 100 : value;
      },
    });
  };

  const makeVars = (parsed) => {
    const vars = {
      number: parseInt(parsed.coach),
      owner: parsed.owner,
      agent: parsed.agent,
      asking: parseFloat(parsed.asking),
      selling: parseFloat(parsed.selling),
      isArchive: parsed.isArchived,
      status: parsed.status,
      listedAt: parsed.listedAt ? new Date(parsed.listedAt) : null,
      closedAt: parsed.closedAt ? new Date(parsed.closedAt) : null,
    };
    return vars;
  };

  const createFromImport = () => {
    const batch = db.batch();
    let index;
    for (index = 0; index < data.length; index += 1) {
      const parsed = data[index];
      const vars = makeVars(parsed);
      console.log(vars);
      const setRef = db.collection('listings').doc();
      batch.set(setRef, vars);
    }
    batch
      .commit()
      .then(() => {
        console.log('batch done');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    createFromImport();
  };

  const disabled = () => false;

  const renderForm = () => (
    <div>
      <Form>
        <Form.Group>
          <Form.Input
            type="file"
            label="Import File"
            id="form-input-file"
            onChange={importFile}
          />
        </Form.Group>
      </Form>
    </div>
  );

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <LightTooltip title="Import Listings" placement="top-start">
          <Button
            floated="left"
            color="teal"
            basic
            size="small"
            style={{ margin: '6px 20px 10px' }}
          >
            Import Listings
          </Button>
        </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Import Listings" />
      <Modal.Content>
        {loading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {error && <p>Error: {error.message}</p>}
        {renderForm()}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

ImportListings.propTypes = {
  // park: PropTypes.object.isRequired,
};

export default ImportListings;
