import React, { useContext } from 'react';
import { Segment } from 'semantic-ui-react';
import Can from '../../../components/Can/Can';
import { UserContext } from '../../../services/context/UserContext';
import CreateFrequent from './EditFrequents/CreateFrequent';
import DirectoryTable from './DirectoryTable';
import useFrequents from './useFrequents';

const Frequents = () => {
  const { categories, fetchError } = useFrequents();
  const { user } = useContext(UserContext);

  if (fetchError) {
    return (
      <div>
        <Segment textAlign="center" size="small" color="red">
          {`An error occurred: ${fetchError}`}
        </Segment>
      </div>
    );
  }

  if (!user) {
    return null;
  }

  return (
    <div>
      <Can
        role={user.role}
        perform="office:admin"
        yes={() => <CreateFrequent />}
        no={() => null}
      />
      {categories && <DirectoryTable categories={categories} />}
    </div>
  );
};

export default Frequents;
