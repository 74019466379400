// Firebase App (the core Firebase SDK) is always required and must be listed first
import firebase from 'firebase/app';
// import "firebase/performance";
import 'firebase/firestore';
import 'firebase/storage';
import 'firebase/auth';

const firebaseConfig = {
  apiKey: 'AIzaSyCeNTXnUIeQ34Nrg8ZgRMdfo2k_-NmvRo4',
  authDomain: 'paseo-de-palomas.firebaseapp.com',
  databaseURL: 'https://paseo-de-palomas.firebaseio.com',
  projectId: 'paseo-de-palomas',
  storageBucket: 'paseo-de-palomas.appspot.com',
  messagingSenderId: '283972570591',
  appId: '1:283972570591:web:6b7ac608b8fe82b699c86a',
  measurementId: 'G-2DNC1CW8QN',
};

// Initialize Firebase
firebase.initializeApp(firebaseConfig);

export const db = firebase.firestore();

// export const perf = firebase.performance();

export const storage = firebase.storage();
