import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useStudy from './useStudy';
import EditForm from './EditForm';

const EditStudy = ({ editStudy }) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  const { study, doUpdate, canUpdate, handleChange } = useStudy(
    editStudy,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
      setErrorMessage(error);
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setErrorMessage(null);
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  const disabled = () => !canUpdate();

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name="edit outline" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Study" />
      <Modal.Content>
        <EditForm
          annualAmounts={study.annualAmounts}
          study={study}
          fieldUpdated={handleChange}
        />
        {errorMessage && (
          <>
            <br />
            <p style={{ color: 'red' }}>Error: {errorMessage}</p>
          </>
        )}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

EditStudy.propTypes = {
  editStudy: PropTypes.object.isRequired,
};

export default EditStudy;
