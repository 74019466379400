import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Budgets from '../Budgets/Budgets';
import Archived from '../Budgets/Archived/Archived';
// import Expenses from "../Expenses/Expenses";
// import Tracker from "../Expenses/Tracking/Tracker";
import DMF from '../DMF/DMF';
import Loans from '../Loans/Loans';
import Projects from '../Projects/Projects';
import ArchivedLoans from '../Loans/ArchivedLoans';
import ArchivedProjects from '../Projects/Archived';
import BudgetCharts from '../Budgets/Charts/BudgetCharts';
import Privacy from '../../../components/Privacy/Privacy';
import './BudgetContent.scss';

class BudgetContent extends Component {
  render() {
    return (
      <div className="finance-content">
        <Switch>
          <Route path="/finance/dmf" component={DMF} />
          <Route path="/finance/active" component={Budgets} />
          <Route path="/finance/archived" component={Archived} />
          {/* <Route path="/finance/tracking" component={Tracker} /> */}
          {/* <Route path="/finance/expenses" component={Expenses} /> */}
          <Route path="/finance/charts" component={BudgetCharts} />
          <Route path="/finance/activeprojects" component={Projects} />
          <Route
            path="/finance/archivedprojects"
            component={ArchivedProjects}
          />
          <Route path="/finance/loans" component={Loans} />
          <Route path="/finance/archivedloans" component={ArchivedLoans} />
          <Route path="/finance/privacy" component={Privacy} />
          <Route
            path="/finance/help"
            render={() => <p>Help: under construction</p>}
          />
          <Route
            path="/finance/feedback"
            render={() => <p>Feedback: under construction</p>}
          />
        </Switch>
      </div>
    );
  }
}

export default BudgetContent;
