import React, { useState } from 'react';
import { Button, Icon, Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import { RoleOptions, StatusOptions } from './StatusOptions';
import useUser, { COMPLETION_CODE } from './useUser';
import EditForm from './EditForm';

const CreateUser = () => {
  // console.log("CreateUser.props", props);
  const [open, setOpen] = useState(false);
  const { handleChange, doCreate, user } = useUser({}, (status, result) => {
    console.log('completed', status, result);
    if (status === COMPLETION_CODE.SUCCESS) {
      setOpen(false);
    }
  });

  // The required fields to update record
  const disabled = () => !user.email || !user.role || !user.space;

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doCreate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button color="teal" basic icon>
          <Icon name="add" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Add Role" />
      <Modal.Content>
        <EditForm
          roleOptions={RoleOptions}
          statusOptions={StatusOptions}
          user={user}
          fieldUpdated={handleChange}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default CreateUser;
