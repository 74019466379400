import {
  useUserContext,
  UserContext,
} from '../../services/context/UserContext';

export const useGetRole = () => {
  const { user } = useUserContext(UserContext);

  return {
    role: user.role,
  };
};
