import React, { useState } from 'react';
import { Button, Icon, Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useImportSupplementals from './useImportSupplementals';
import useImportAssessments from './useImportAssessments';
import useTaxBill from './useTaxBill';
import EditForm from './EditForm';

// Creates a new tax bill. Import the assessments and supplementals.
// Look at projected where a 2% increase is added to assessments to
// project what next year's bill may look like.

const CreateTaxBill = () => {
  const [open, setOpen] = useState(false);
  const {
    taxBill,
    doCreate,
    canCreate,
    setAssessments,
    setReassessments,
    setSupplementals,
    handleChange,
  } = useTaxBill(
    {},
    () => {
      console.log('success');
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const { importAssessmentsFile } = useImportAssessments(
    (parsedAssessments) => {
      console.log('Imported assessments', parsedAssessments);
      setAssessments(parsedAssessments);
    },
    (error) => {
      console.log('Error parsing import file', error);
    }
  );

  const { importReassessmentsFile } = useImportAssessments(
    (parsedReassessments) => {
      console.log('Imported reassessments', parsedReassessments);
      setReassessments(parsedReassessments);
    },
    (error) => {
      console.log('Error parsing import file', error);
    }
  );

  const { importSupplementalsFile } = useImportSupplementals(
    (parsedSupplementals) => {
      console.log('Imported supplementals', parsedSupplementals);
      setSupplementals(parsedSupplementals);
    },
    (error) => {
      console.log('Error parsing import file', error);
    }
  );

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doCreate();
  };

  const disabled = () => !canCreate();

  return (
    <Modal
      dimmer="inverted"
      trigger={
        // <LightTooltip title="Create Tax Bill" placement="top-start">
        <Button
          // floated="left"
          color="teal"
          basic
          icon
          size="small"
          // style={{ margin: "6px 20px 10px" }}
        >
          <Icon name="plus" size="large" color="teal" />
        </Button>
        // </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create New Tax Bill" />
      <Modal.Content>
        <EditForm
          taxBill={taxBill}
          fieldUpdated={handleChange}
          importSupplementalsFile={importSupplementalsFile}
          importAssessmentsFile={importAssessmentsFile}
          importReassessmentsFile={importReassessmentsFile}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default CreateTaxBill;
