import React, { useState, useEffect } from 'react';
import { Table } from 'semantic-ui-react';

const ExpenseItems = (props) => {
  const [items, setItems] = useState();

  useEffect(() => {
    setItems(props.study.yearlyExpenses.componentsByYear[props.index]);
  }, [props]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  // const handleExclude = (data, event) => {
  //   // console.log("items", items);
  //   const item = items[event.id];
  //   item.isExcluded = !item.isExcluded;
  //   // props.study.recomputeYearlyExpenses();
  //   // const flag = item.isExcluded;
  //   // console.log("toggleExclude", item, flag);
  //   // item.isExcluded = !flag;
  //   // console.log("toggleExclude", item, !flag);
  //   // item.toggleExclude();
  //   console.log("handleExclude", item);
  // };

  // const renderExcluded = (item, index) => {
  //   return (
  //     <Checkbox
  //       disabled={true}
  //       id={index}
  //       defaultChecked={item.isExcluded}
  //       onChange={handleExclude}
  //     />
  //   );
  // };

  const renderNormalRow = (item) => (
    <Table.Row key={item.name}>
      <Table.Cell>{item.category}</Table.Cell>
      <Table.Cell textAlign="center">{item.pid}</Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(item.replacementCost)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(item.inflatedCost)}
      </Table.Cell>
    </Table.Row>
  );

  return (
    <>
      <Table celled compact striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={1}>Category</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              ID
            </Table.HeaderCell>
            <Table.HeaderCell width={3}>Name</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Replacement Cost
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Inflated Cost
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {items && items.map((item, index) => renderNormalRow(item, index))}
        </Table.Body>
      </Table>
    </>
  );
};

export default ExpenseItems;
