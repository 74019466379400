import React, { useState, useEffect } from 'react';
import { Button, Table, Radio, Segment } from 'semantic-ui-react';
import Moment from 'moment';
import PopupButton from '../../../components/PopupButton/PopupButton';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import Can from '../../../components/Can/Can';
import CreateProject from './EditProject/CreateProject';
import EditProject from './EditProject/EditProject';
import useModify from './EditProject/useModify';
import Payments from './Payments/Payments';
import Bids from './Bids/Bids';
import './Projects.scss';

const ProjectsTable = ({ projects, archive, user }) => {
  const [selected, setSelected] = useState();
  const { deleteProject, activateProject, archiveProject } = useModify(
    () => {},
    (error) => {
      console.log('Modify project failed', error);
    }
  );

  useEffect(() => {
    const found = projects.find((project) => project.id);
    setSelected(found ? found.id : null);
  }, [projects]);

  const handleArchive = (event) => {
    console.log('handleArchive ', event);
    archiveProject(event.id);
  };

  const handleActivate = (event) => {
    console.log('handleActivate ', event);
    activateProject(event.id);
  };

  const deleteConfirmed = (project) => {
    console.log('deleteConfirmed', project.id);
    deleteProject(project.id);
  };

  const renderEditButtons = (project) => (
    <>
      <Button.Group size="mini">
        <EditProject editing={project} />
        {!archive && (
          <>
            <ConfirmDelete
              deleteId={project}
              name={`Project ${project.name}`}
              deleteConfirmed={deleteConfirmed}
            />
            <PopupButton
              title="Archive Project"
              icon="file archive outline"
              id={project.id}
              onClick={handleArchive}
            />
          </>
        )}
        {archive && (
          <PopupButton
            title="Activate Project"
            icon="file archive outline"
            id={project.id}
            onClick={handleActivate}
          />
        )}
      </Button.Group>
    </>
  );

  const renderButtons = (project, index) => (
    <Can
      role={user.role}
      perform="job:edit"
      yes={() => renderEditButtons(project, index)}
    />
  );

  const handleCheckbox = (event) => {
    setSelected(event.id);
  };

  const renderSelected = (project) => (
    <Radio
      id={project.id}
      disabled={false}
      checked={selected === project.id}
      onChange={(_, data) => {
        handleCheckbox(data);
      }}
    />
  );

  const renderAddProjectCan = () => (
    <Can
      role={user.role}
      perform="job:create"
      yes={() => (
        <div>
          <div>
            <CreateProject user={user} />
          </div>
        </div>
      )}
    />
  );

  const renderProjectRow = (project, index) => (
    <Table.Row key={project.id} className="job-record">
      <Table.Cell width={1} />
      <Table.Cell width={3} textAlign="left">
        {project.name}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {Moment(project.date).format('l')}
      </Table.Cell>
      <Table.Cell width={1} textAlign="center">
        {project.funding}
      </Table.Cell>
      <Table.Cell textAlign="left">{project.notes}</Table.Cell>
      <Table.Cell textAlign="center" width={1}>
        {renderSelected(project)}
      </Table.Cell>
      <Table.Cell textAlign="center" width={2}>
        {renderButtons(project, index)}
      </Table.Cell>
    </Table.Row>
  );

  const renderHeader = () => (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell textAlign="center" width={1}>
          {renderAddProjectCan()}
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="left" width={4}>
          Name
        </Table.HeaderCell>
        <Table.HeaderCell>Date</Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={1}>
          Funding
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Notes</Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={1}>
          View Details
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  const renderSelectedProject = (project) => (
    <React.Fragment key={project.id}>
      <Bids project={project} user={user} />
      <Payments project={project} user={user} />
    </React.Fragment>
  );

  return (
    <>
      <Segment color="teal">
        <Table celled striped>
          {renderHeader()}
          <Table.Body>
            {projects.map((project, index) => renderProjectRow(project, index))}
          </Table.Body>
        </Table>

        {projects.map((project) => {
          if (project.id === selected) {
            return renderSelectedProject(project);
          }
          return null;
        })}
      </Segment>
    </>
  );
};

export default ProjectsTable;
