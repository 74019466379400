import React from 'react';
import { Cell, PieChart, Pie, Tooltip } from 'recharts';
// import colors from "../../../../components/Styles/Colors";

// const data01 = [
//   { name: "Group A", value: 400 },
//   { name: "Group B", value: 300 },
//   { name: "Group C", value: 300 },
//   { name: "Group D", value: 200 },
//   { name: "Group E", value: 278 },
//   { name: "Group F", value: 189 },
// ];

// const data = [
//   { name: "Group A", value: 2400 },
//   { name: "Group B", value: 4567 },
//   { name: "Group C", value: 1398 },
//   { name: "Group D", value: 9800 },
//   { name: "Group E", value: 3908 },
//   { name: "Group F", value: 4800 },
// ];

// const COLORS = [
//   "#0088FE",
//   "#00C49F",
//   "#FFBB28",
//   "#FF8042",
//   "#CC5042",
//   "#99FF30",
//   "#AA4455",
// ];

const colors = [
  '#00008C',
  '#C38C21',
  '#8970FF',
  '#B65535',
  '#576600',
  '#FF36AD',
  '#C71442',
  '#4AA05A',
  '#0FFF62',
  '#56BADA',
];
// const RADIAN = Math.PI / 180;

const computeName = (category) => category[0].category;

const computeTotal = (category) => {
  console.log('Reduce on', category);
  return category.reduce(
    (acc, item) =>
      // acc += item.amount;
      acc + item.amount,
    0
  );
};

// const makeNamesAxisX = (categories) => {
//   return categories
//     .filter((category) => category[0].type !== "INCOME")
//     .map((category, index) => {
//       const name = computeName(category);
//       return name;
//     });
// };

const filterCategory = (category) =>
  category.filter((item) => item.cid !== '5610');
// return computeTotal(category) > 3000;
const prepareData = (categories) =>
  categories
    .filter(
      (category) =>
        category[0].category !== 'Income' && computeTotal(category) > 2000
    )
    .map((category) => filterCategory(category))
    .sort((a, b) => (computeTotal(a) > computeTotal(b) ? 1 : -1));

const BudgetPie = ({ categories }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const currency = (value) => {
    if (value) {
      return formatter.format(value);
    }
    return null;
  };

  const formatData = (cats) =>
    cats.map((category) => ({
      name: computeName(category),
      value: computeTotal(category),
    }));

  // const renderCustomizedLabel = ({
  //   cx,
  //   cy,
  //   midAngle,
  //   innerRadius,
  //   outerRadius,
  //   percent,
  //   index,
  // }) => {
  //   const radius = innerRadius + (outerRadius - innerRadius) * 0.8;
  //   const x = cx + radius * Math.cos(-midAngle * RADIAN);
  //   const y = cy + radius * Math.sin(-midAngle * RADIAN);

  //   return (
  //     <text
  //       x={x}
  //       y={y}
  //       fill="white"
  //       textAnchor={x > cx ? "start" : "end"}
  //       dominantBaseline="central"
  //     >
  //       {`${(percent * 100).toFixed(0)}%`}
  //     </text>
  //   );
  // };

  console.log('Incoming', categories);
  const ordered = prepareData(categories);
  console.log('ordered', ordered);
  const formatted = formatData(ordered);
  console.log('Formatted', formatted);

  return (
    <PieChart
      width={800}
      height={600}
      margin={{ top: 50, right: 60, left: 60, bottom: 30 }}
    >
      <Pie
        data={formatted}
        cx={400}
        cy={200}
        // labelLine={false}
        // label={renderCustomizedLabel}
        label
        outerRadius={200}
        fill="#8884d8"
        dataKey="value"
      >
        {formatted.map((entry, index) => (
          <Cell key={`cell-${index}`} fill={colors[index % colors.length]} />
        ))}
      </Pie>
      <Tooltip
        formatter={(value, name) =>
          // console.log(props);
          [currency(value), name]
        }
      />
    </PieChart>
  );
};

export default BudgetPie;
