import { useState, useEffect } from 'react';
import { db } from '../../../../services/firebase/firebase';

const usePayments = (projectId) => {
  const [payments, setPayments] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setLoading(false);

    try {
      const unsubscribe = db
        .collection('projects')
        .doc(projectId)
        .collection('payments')
        .onSnapshot((snapshot) => {
          const all = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setPayments(all);
          console.log('payments', all);
        });

      return () => {
        console.log('cleanup  payments');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [projectId]);

  return {
    fetchError,
    loading,
    payments,
  };
};

export default usePayments;
