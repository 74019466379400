import React, { useContext } from 'react';
import moment from 'moment';
import { Button, Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import Can from '../../../components/Can/Can';
import { UserContext } from '../../../services/context/UserContext';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import { monthName } from '../../../services/dates/formatting';
import CreateBirthday from './EditBirthdays/CreateBirthday';
import PrintBirthdays from './EditBirthdays/PrintBirthdays';
import EditBirthday from './EditBirthdays/EditBirthday';
import useBirthday from './EditBirthdays/useBirthday';
import useBirthdays from './useBirthdays';

const BirthdaysTable = (props) => {
  console.log('BirthdaysTable.props ', props);
  const { doDelete } = useBirthday(
    {},
    () => {
      console.log('Deleted');
    },
    (error) => {
      console.log('Delete error', error);
    }
  );

  const renderDate = (date) => moment(date).format('Do');

  const deleteConfirmed = async (entry) => {
    console.log('deleteConfirmed', entry);
    doDelete(entry);
  };

  const renderButtons = (person) => (
    <>
      <Button.Group>
        <EditBirthday current={person} />
        <ConfirmDelete
          deleteId={person}
          name={person.name}
          deleteConfirmed={deleteConfirmed}
        />
      </Button.Group>
    </>
  );

  const renderButtonsCan = (person) => (
    <Can
      role={props.user.role}
      perform="office:admin"
      yes={() => renderButtons(person)}
      no={() => null}
    />
  );

  const renderNormalRow = (person, index) => (
    <Table.Row key={`${person.name}-${index}`}>
      <Table.Cell />
      <Table.Cell textAlign="center">{renderDate(person.date)}</Table.Cell>
      <Table.Cell textAlign="left">{person.name}</Table.Cell>
      <Table.Cell textAlign="center">{person.space}</Table.Cell>
      <Table.Cell textAlign="left" />
      <Table.Cell textAlign="center">{renderButtonsCan(person)}</Table.Cell>
    </Table.Row>
  );

  const renderMonthTable = (month, index) => (
    <React.Fragment key={index}>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign="center">
              <PrintBirthdays monthName={monthName(index)} birthdays={month} />
            </Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="center">
              {monthName(index)}
            </Table.HeaderCell>
            <Table.HeaderCell width={3} textAlign="left">
              Name
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              Space
            </Table.HeaderCell>
            <Table.HeaderCell />
            <Table.HeaderCell textAlign="center" width={2}>
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {month.map((person, i) => renderNormalRow(person, i))}
        </Table.Body>
      </Table>
    </React.Fragment>
  );

  return props.months.map((month, index) => renderMonthTable(month, index));
};

const convertToMonths = (birthdays) => {
  const months = [[], [], [], [], [], [], [], [], [], [], [], []];
  const built = birthdays.reduce((acc, item) => {
    const monthIndex = item.date.getMonth();
    acc[monthIndex].push(item);
    return acc;
  }, months);

  return built.map((month) =>
    month.sort((a, b) => (a.date.getDate() > b.date.getDate() ? 1 : -1))
  );
};

const Birthdays = () => {
  const { user } = useContext(UserContext);
  const { birthdays, fetchError } = useBirthdays();

  if (fetchError) {
    return (
      <div>
        <Segment textAlign="center" size="small" color="red">
          {`An error occurred: ${fetchError}`}
        </Segment>
      </div>
    );
  }

  if (!birthdays) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  if (!user) {
    return null;
  }

  // Convert array of persons to array of months
  const months = convertToMonths(birthdays);

  return (
    <div>
      <Can
        role={user.role}
        perform="office:admin"
        yes={() => <CreateBirthday />}
        no={() => null}
      />
      <BirthdaysTable months={months} user={user} />
    </div>
  );
};

export default Birthdays;
