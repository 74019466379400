import React from 'react';
import { Button, Checkbox, Table } from 'semantic-ui-react';
import { computeComponentIdealBalance } from '../../../../services/calculators/studies/balances';
import Can from '../../../../components/Can/Can';
import EditStudyComp from '../EditStudyComp/EditStudyComp';
import { db } from '../../../../services/firebase/firebase';

const StudyCompsTable = ({ study, user }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const toggleExclude = (index) => {
    const comp = study.components[index];
    comp.isExcluded = !comp.isExcluded;
    // console.log("toggleExclude", comp);

    // Updating a component requires updating the
    // array of components, best I can tell.
    const studyRef = db.collection('studies').doc(study.id);
    studyRef
      .update({
        components: [...study.components],
      })
      .then(function () {
        console.log('Document successfully updated!');
      })
      .catch((error) => {
        console.error('Error updating document: ', error);
      });
  };

  const handleExclude = (_, data) => {
    const comp = study.components[data.id];
    if (comp) {
      toggleExclude(data.id);
    }
  };

  // Renders checkbox enabled or disabled depending on RBAC
  const renderCheckbox = (index, disabled) => (
    <Checkbox
      disabled={disabled}
      id={index}
      defaultChecked={study.components[index].isExcluded}
      onChange={handleExclude}
    />
  );

  // Tests role authorization, returning checkbox
  const renderCheckboxCan = (index) => (
    <Can
      role={user.role}
      perform="studyComp:exclude"
      yes={() => renderCheckbox(index, false)}
      no={() => renderCheckbox(index, true)}
    />
  );

  const renderButtons = (index) => {
    const comp = study.components[index];
    return (
      <Button.Group basic size="mini">
        <EditStudyComp editComp={comp} study={study} index={index} />
      </Button.Group>
    );
  };

  // Tests role authorization, returning checkbox
  const renderButtonsCan = (index) => (
    <Can
      role={user.role}
      perform="studyComp:edit"
      yes={() => renderButtons(index)}
      no={() => null}
    />
  );

  const idealBalance = (studyComp) =>
    formatter.format(computeComponentIdealBalance(studyComp));

  const renderTableRow = (studyComp, index) => (
    <Table.Row key={studyComp.name}>
      <Table.Cell>{studyComp.category}</Table.Cell>
      <Table.Cell>{studyComp.pid}</Table.Cell>
      <Table.Cell>{studyComp.name}</Table.Cell>
      <Table.Cell textAlign="center">{studyComp.usefulLife}</Table.Cell>
      <Table.Cell textAlign="center">
        {studyComp.remainingUsefulLife}
      </Table.Cell>
      <Table.Cell>{formatter.format(studyComp.replacementCost)}</Table.Cell>
      <Table.Cell>{idealBalance(studyComp)}</Table.Cell>
      <Table.Cell textAlign="center">{renderCheckboxCan(index)}</Table.Cell>
      <Table.Cell textAlign="center">{renderButtonsCan(index)}</Table.Cell>
    </Table.Row>
  );

  return (
    <Table celled compact striped>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell width={3}>Category</Table.HeaderCell>
          <Table.HeaderCell width={1}>ID</Table.HeaderCell>
          <Table.HeaderCell width={4}>Name</Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            UL
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            RUL
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Repl Cost
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Ideal Balance
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Exclude
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>

      <Table.Body>
        {study.components.map((studyComp, index) =>
          renderTableRow(studyComp, index)
        )}
      </Table.Body>
    </Table>
  );
};

export default StudyCompsTable;
