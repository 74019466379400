import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';
import Study from './Study';

const useStudies = (archive) => {
  const [studies, setStudies] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    try {
      const unsub = db
        .collection('studies')
        .where('isArchived', '==', archive)
        .onSnapshot((snapshot) => {
          const allStudies = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            // .filter((study) => {
            //   return study.isArchived === archive;
            // })
            .sort((a, b) => {
              const aName = a.name;
              const bName = b.name;
              return aName > bName ? 1 : -1;
            })
            .map((obj) => {
              if (obj.balance && obj.balance.date) {
                obj.balance.date = obj.balance.date.toDate();
              }
              return obj;
            })
            .map((obj) => new Study(obj));

          setLoading(false);
          console.log('All Studies', allStudies);
          setStudies(allStudies);
        });
      return () => {
        console.log('cleanup  studies');
        unsub();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [archive]);

  return {
    fetchError,
    loading,
    studies,
  };
};

export default useStudies;
