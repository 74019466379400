import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const AllocationsFooter = ({ allocations }) => {
  const [itemTotal, setItemTotal] = useState();

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const computeItemTotal = (items) => {
    const total = items.reduce((acc, item) => acc + item.amount, 0);
    // console.log("item total", total);
    return total;
  };

  useEffect(() => {
    setItemTotal(computeItemTotal(allocations.allocations));
  }, [allocations]);

  const currency = (value) => {
    if (value) {
      return formatter.format(value);
    }
    return null;
  };

  // style={{ fontWeight: "600", fontSize: "1.1rem" }}
  //  style={{ fontSize: "1.1rem" }}
  return (
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell colSpan="2" />
        <Table.HeaderCell
          textAlign="center"
          style={{ fontWeight: '600', fontSize: '1.1rem' }}
        >
          Total
        </Table.HeaderCell>
        <Table.HeaderCell
          textAlign="center"
          style={{ fontWeight: '600', fontSize: '1.1rem' }}
        >
          {currency(itemTotal)}
        </Table.HeaderCell>
        <Table.HeaderCell colSpan="3" />
      </Table.Row>
    </Table.Footer>
  );
};

AllocationsFooter.propTypes = {
  allocations: PropTypes.object.isRequired,
};

export default AllocationsFooter;
