import React, { useState } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useFrequent from './useFrequent';
import useImport from './useImport';
import EditForm from './EditForm';

const CreateFrequent = () => {
  const [open, setOpen] = useState(false);
  const {
    frequent,
    doCreate,
    canCreate,
    setFrequents,
    handleChange,
  } = useFrequent(
    {},
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const { importFrequentsFile } = useImport(
    (parsedFrequents) => {
      console.log('Imported frequents');
      setFrequents(parsedFrequents);
    },
    (error) => {
      console.log('Error parsing import file', error);
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doCreate();
  };

  const disabled = () => !canCreate();

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button
          floated="left"
          color="teal"
          basic
          size="small"
          style={{ margin: '6px 20px 10px' }}
        >
          Create New Entry
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create New Entry" />
      <Modal.Content>
        <EditForm
          frequent={frequent}
          fieldUpdated={handleChange}
          importFrequentsFile={importFrequentsFile}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default CreateFrequent;
