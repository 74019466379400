import React, { useState } from 'react';
import { Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../../components/CreateModal/ModalActions';
import PopupButton from '../../../../../components/PopupButton/PopupButton';
import useExpense from './useExpense';
import ExpenseForm from './ExpenseForm';

const EditExpense = ({ study, editExpense }) => {
  const [open, setOpen] = useState(false);
  const {
    expense,
    doUpdate,
    canUpdate,
    handleChange,
    handleOption,
    makeOptions,
  } = useExpense(
    editExpense,
    study,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const disabled = () => !canUpdate();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <PopupButton
          id="edit-expense"
          basic
          icon="edit outline"
          color="teal"
          title="Edit Expense"
          onClick={() => {}}
        />
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Expense" />
      <Modal.Content>
        <ExpenseForm
          expense={expense}
          fieldUpdated={handleChange}
          handleOption={handleOption}
          options={makeOptions()}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditExpense;
