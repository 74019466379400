import React from 'react';
import { Table, Button, Radio, Segment } from 'semantic-ui-react';
import Can from '../../../components/Can/Can';
import colors from '../../../components/Styles/Colors';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import PopupButton from '../../../components/PopupButton/PopupButton';
import Assessments from '../Assessments/Assessments';
import useTaxBillSelector from './useTaxBillSelector';
import useModify from './EditTaxBill/useModify';

const Archives = ({ park, taxBills, showArchive, user }) => {
  const { selectedIndex, handleSelectionChange } = useTaxBillSelector(
    taxBills,
    showArchive
  );

  const { activateItem, deleteItem } = useModify(
    () => {},
    (error) => {
      console.log('Failure', error);
    }
  );

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const percent = (value) => (value ? `${value}%` : null);

  const currency = (value) => (value ? formatter.format(value) : null);

  const deleteConfirmed = async (id) => {
    deleteItem(id);
  };

  const handleActivate = (_, data) => {
    activateItem(data.id);
  };

  const selectedChanged = (_, data) => {
    handleSelectionChange(data.id);
  };

  const renderButtons = (taxBill) => (
    <Button.Group size="mini">
      <PopupButton
        title="Activate Tax Bill"
        icon="file archive outline"
        id={taxBill.id}
        onClick={handleActivate}
      />
      <ConfirmDelete
        deleteId={taxBill.id}
        name={`Tax Bill '${taxBill.year}'`}
        deleteConfirmed={deleteConfirmed}
      />
    </Button.Group>
  );

  const renderButtonsCan = (taxBill, index) => (
    <Can
      role={user.role}
      perform="park/taxes:edit"
      yes={() => renderButtons(taxBill, index)}
      no={() => null}
    />
  );

  const renderNormalRow = (taxBill, index) => (
    <Table.Row key={taxBill.id}>
      <Table.Cell textAlign="center">
        <Radio
          id={index}
          checked={selectedIndex === index}
          disabled={false}
          onChange={selectedChanged}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">{taxBill.year}</Table.Cell>
      <Table.Cell textAlign="center">
        {percent(taxBill.assessedValueRate)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {percent(taxBill.landImprovementsRate)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {currency(taxBill.totalAssessedValue)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {currency(taxBill.perPropertyTotalTax)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {currency(taxBill.specialATax)}
      </Table.Cell>
      <Table.Cell textAlign="left">{taxBill.notes}</Table.Cell>
      <Table.Cell textAlign="center">
        {renderButtonsCan(taxBill, index)}
      </Table.Cell>
    </Table.Row>
  );

  const renderTaxBillHeader = () => (
    <>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell textAlign="center" width={1} />
          <Table.HeaderCell textAlign="center">Tax Year</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Assessed Value Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Land & Impr Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Total Assessed Value
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Per Prop Value</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Special A Tax</Table.HeaderCell>
          <Table.HeaderCell width={5} textAlign="center">
            Notes
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </>
  );

  const renderTaxBillBody = (taxBill, index) => (
    <React.Fragment key={taxBill.id}>
      <Table.Body>{renderNormalRow(taxBill, index)}</Table.Body>
    </React.Fragment>
  );

  const renderSelectedAssessments = () => {
    const color = colors[selectedIndex % colors.length];
    const index = selectedIndex < taxBills.length ? selectedIndex : 0;
    const taxBill = taxBills[index];

    if (!taxBill) {
      console.log('No tax bill', selectedIndex);
      return null;
    }

    if (!taxBill.assessments || !taxBill.supplementals) {
      return null;
    }

    return (
      <Segment color={color}>
        <Assessments taxBill={taxBill} showArchive park={park} />
      </Segment>
    );
  };

  return (
    <>
      <Table celled striped>
        {renderTaxBillHeader()}
        {taxBills.map((taxBill, index) => renderTaxBillBody(taxBill, index))}
      </Table>
      {renderSelectedAssessments()}
    </>
  );
};

export default Archives;
