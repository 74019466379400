import React, { Component } from 'react';
import HomeContent from './HomeContent/HomeContent';

class Home extends Component {
  render() {
    return (
      <>
        <HomeContent />
      </>
    );
  }
}

export default Home;
