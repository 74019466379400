import React from 'react';
import { Form } from 'semantic-ui-react';
import { digits } from '../../../../services/number/formatting';

const EditForm = ({ fieldUpdated, importFrequentsFile, frequent }) => (
  <Form>
    <Form.Group>
      <Form.Input
        width={3}
        label="Category"
        id="form-input-category"
        value={frequent.category}
        onChange={(_, data) => {
          fieldUpdated('category', data.value);
        }}
      />
      <Form.Input
        width={3}
        label="Name"
        id="form-input-name"
        value={frequent.name}
        onChange={(_, data) => {
          fieldUpdated('name', data.value);
        }}
      />
      <Form.Input
        width={3}
        label="Phone"
        id="form-input-phone"
        value={frequent.phone}
        onChange={(_, data) => {
          fieldUpdated('phone', digits(data.value));
        }}
      />
      <Form.Input
        width={7}
        label="Notes"
        id="form-input-notes"
        value={frequent.notes}
        onChange={(_, data) => {
          fieldUpdated('notes', data.value);
        }}
      />
    </Form.Group>
    <Form.Group>
      {importFrequentsFile && (
        <Form.Input
          type="file"
          label="Import Frequent Numbers"
          id="form-input-ibd"
          onChange={importFrequentsFile}
        />
      )}
    </Form.Group>
  </Form>
);

export default EditForm;
