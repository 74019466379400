import React from 'react';
import { Form } from 'semantic-ui-react';
import ExportAssessments from './ExportAssessments';
import ExportSupplementals from './ExportSupplementals';

const EditForm = ({
  editing,
  fieldUpdated,
  createProjected,
  importImpoundsFile,
  importAssessmentsFile,
  importSupplementalsFile,
  importReassessmentsFile,
  taxBill,
}) => (
  <Form>
    <Form.Group>
      <Form.Input
        required
        width={2}
        label="Year"
        id="form-input-year"
        value={taxBill.year}
        onChange={(_, data) => {
          fieldUpdated('year', data.value);
        }}
      />
      <Form.Input
        width={2}
        label="AV Rate"
        id="form-input-assessedValueRate"
        value={taxBill.assessedValueRate}
        onChange={(_, data) => {
          fieldUpdated('assessedValueRate', data.value);
        }}
      />
      <Form.Input
        width={2}
        label="L & I Rate"
        id="form-input-landImprovementsRate"
        value={taxBill.landImprovementsRate}
        onChange={(_, data) => {
          fieldUpdated('landImprovementsRate', data.value);
        }}
      />
      <Form.Input
        width={2}
        label="Assessed Value"
        id="form-input-totalAssessedValue"
        value={taxBill.totalAssessedValue}
        onChange={(_, data) => {
          fieldUpdated('totalAssessedValue', data.value);
        }}
      />
      <Form.Input
        width={2}
        label="Per Prop Total"
        id="form-input-perPropertyTotalTax"
        value={taxBill.perPropertyTotalTax}
        onChange={(_, data) => {
          fieldUpdated('perPropertyTotalTax', data.value);
        }}
      />
      <Form.Input
        width={2}
        label="Special A Tax"
        id="form-input-specialATax"
        value={taxBill.specialATax}
        onChange={(_, data) => {
          fieldUpdated('specialATax', data.value);
        }}
      />
      <Form.Input
        width={2}
        label="Projected %"
        id="form-input-pi"
        value={taxBill.projectedIncrease}
        onChange={(_, data) => {
          fieldUpdated('projectedIncrease', data.value);
        }}
      />
    </Form.Group>
    {/* <Form.Group>
      <Form.Input
        // width={3}
        label="Previous Tax (to compute delta)"
        id="form-input-pt"
        value={taxBill.previous}
        onChange={(_, data) => {
          fieldUpdated('previous', data.value);
        }}
      />
    </Form.Group> */}
    <Form.Group>
      <Form.Input
        width={16}
        label="Notes"
        id="form-input-notes"
        value={taxBill.notes}
        onChange={(_, data) => {
          fieldUpdated('notes', data.value);
        }}
      />
    </Form.Group>
    <Form.Group>
      {importAssessmentsFile && (
        <Form.Input
          type="file"
          label="Import Assessments"
          id="form-input-ass"
          onChange={importAssessmentsFile}
        />
      )}
      {importSupplementalsFile && (
        <Form.Input
          type="file"
          label="Import Supplementals"
          id="form-input-supp"
          onChange={importSupplementalsFile}
        />
      )}
    </Form.Group>
    <Form.Group>
      {importReassessmentsFile && (
        <>
          <Form.Input
            type="file"
            label="Import Reassessments"
            id="form-input-reassess"
            onChange={importReassessmentsFile}
          />
          <Form.Input
            // width={3}
            label="Total Reassessed Value"
            id="form-input-trv"
            value={taxBill.totalReassessedValue}
            onChange={(_, data) => {
              fieldUpdated('totalReassessedValue', data.value);
            }}
          />
        </>
      )}
      <ExportAssessments
        taxRollYear={`${editing.year}`}
        assessments={editing.assessments}
      />
      <ExportSupplementals
        taxRollYear={`${editing.year}`}
        supplementals={editing.supplementals}
      />
    </Form.Group>
    <Form.Group>
      {importImpoundsFile && (
        <Form.Input
          type="file"
          label="Import Impounds"
          id="form-input-impounds"
          onChange={importImpoundsFile}
        />
      )}
    </Form.Group>
    {createProjected && (
      <Form.Group>
        <Form.Button basic color="teal" onClick={createProjected}>
          Create Projected Tax Bill
        </Form.Button>
      </Form.Group>
    )}
  </Form>
);

export default EditForm;
