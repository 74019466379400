import { useState } from 'react';
import { db } from '../../../../services/firebase/firebase';

// Custom hook for CRUD Loan. Provide optional callbacks for
// success and failure notification.
const useLoan = (loanOrNull, success = () => {}, failure = () => {}) => {
  const [errorMessage, setErrorMessage] = useState();

  const [loan, setLoan] = useState({
    name: loanOrNull.name || '',
    note: loanOrNull.note || '',
    term: loanOrNull.term || '',
    rate: loanOrNull.rate * 100 || '',
    amount: loanOrNull.amount || '',
    archive: loanOrNull.archive || false,
  });

  const doCleanup = () => {
    setErrorMessage(null);
    setLoan({});
  };

  const makeVariables = () => ({
    term: parseInt(loan.term),
    rate: parseFloat(loan.rate) / 100,
    amount: parseInt(loan.amount),
    name: loan.name,
    note: loan.note,
    archive: false,
  });

  const canCreate = () => loan.name && loan.term && loan.rate && loan.amount;

  const canUpdate = () => true;

  const doCreate = async () => {
    const vars = makeVariables();
    console.log('create loan vars ', vars);

    db.collection('loans')
      .add(vars)
      .then((value) => {
        console.log('value', value.id);
        success();
      })
      .catch((error) => {
        console.log('Error creating Loan', error);
        failure(error.message);
      });
  };

  const update = async (variables) => {
    console.log('update Loan vars ', variables);

    db.collection('loans')
      .doc(loanOrNull.id)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating Loan', error);
        setErrorMessage(error.message);
        failure(error.message);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const handleChange = (name, value) => {
    setLoan({ ...loan, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doCleanup,
    loan,
  };
};

export default useLoan;
