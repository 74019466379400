import React from 'react';
import { Table } from 'semantic-ui-react';

const TaxTotals = ({ taxBill }) => {
  const formatter0 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const formatter2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const {
    model: { perPropAssessments, allAssessmentTaxesMap },
  } = taxBill;

  return (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Land Value</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Improvements</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Exemptions</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Bus Equip</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Special A Tax
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Pers Property
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total Tax</Table.HeaderCell>
          </Table.Row>
          <Table.Row>
            <Table.Cell textAlign="center">
              {formatter0.format(perPropAssessments.landTotal)}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {formatter0.format(perPropAssessments.improveTotal)}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {formatter0.format(perPropAssessments.assessTotal)}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {formatter0.format(perPropAssessments.exemptTotal)}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {formatter2.format(perPropAssessments.busEquipEach)}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {formatter2.format(perPropAssessments.specialEach)}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {formatter2.format(perPropAssessments.perPropEach)}
            </Table.Cell>
            <Table.Cell textAlign="center">
              {formatter2.format(allAssessmentTaxesMap.att)}
            </Table.Cell>
          </Table.Row>
        </Table.Header>
      </Table>
    </>
  );
};

export default TaxTotals;
