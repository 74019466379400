import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import TypeOptions from '../../LineItems/TypeOptions';

// Type 0: income
// Type 1: DMF income
// Type 2: Outflow
// Type 3: DMF outflow

const computeItemTotal = (items) => {
  const total = items.reduce(
    (acc, item) =>
      item.type === TypeOptions[0].value || item.type === TypeOptions[2].value
        ? acc + item.amount
        : acc,
    0
  );
  // console.log("item total", total);
  return total;
};

const computeDmfTotal = (items) => {
  const total = items.reduce(
    (acc, item) =>
      item.type === TypeOptions[1].value || item.type === TypeOptions[3].value
        ? acc + item.amount
        : acc,
    0
  );
  // console.log("dmf total", total);
  return total;
};

const computePrevYearItemTotal = (items) => {
  const total = items.reduce(
    (acc, item) =>
      item.type === TypeOptions[0].value || item.type === TypeOptions[2].value
        ? acc + item.prevYear
        : acc,
    0
  );
  // console.log("item total", total);
  return total;
};

const computePrevYearDmfTotal = (items) => {
  const total = items.reduce(
    (acc, item) =>
      item.type === TypeOptions[1].value || item.type === TypeOptions[3].value
        ? acc + item.prevYear
        : acc,
    0
  );
  // console.log("dmf total", total);
  return total;
};

const CategoryFooter = ({ lineItems, showDMF = false }) => {
  const [itemTotal, setItemTotal] = useState();
  const [dmfTotal, setDmfTotal] = useState();
  const [prevTotal, setPrevTotal] = useState();
  const [prevDmf, setPrevDmf] = useState();

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    setItemTotal(computeItemTotal(lineItems));
    setDmfTotal(computeDmfTotal(lineItems));
    setPrevTotal(computePrevYearItemTotal(lineItems));
    setPrevDmf(computePrevYearDmfTotal(lineItems));
  }, [lineItems]);

  const currency = (value) => {
    if (value) {
      return formatter.format(value);
    }
    return null;
  };

  // style={{ fontWeight: "600", fontSize: "1.1rem" }}
  //  style={{ fontSize: "1.1rem" }}
  return (
    <Table.Footer fullWidth>
      <Table.Row>
        <Table.HeaderCell colSpan="2" />
        <Table.HeaderCell>Total</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">
          {currency(itemTotal)}
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" style={{ color: '#999' }}>
          {currency(prevTotal)}
        </Table.HeaderCell>
        <Table.HeaderCell colSpan="2" />
      </Table.Row>
      {showDMF && dmfTotal > 0 && (
        <Table.Row>
          <Table.HeaderCell colSpan="2" />
          <Table.HeaderCell>DMF</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            {currency(dmfTotal)}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" style={{ color: '#999' }}>
            {currency(prevDmf)}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="2" />
        </Table.Row>
      )}
      {showDMF && dmfTotal > 0 && (
        <Table.Row>
          <Table.HeaderCell colSpan="2" />
          <Table.HeaderCell>Total (incl DMF)</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            {currency(itemTotal + dmfTotal)}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" style={{ color: '#999' }}>
            {currency(prevTotal + prevDmf)}
          </Table.HeaderCell>
          <Table.HeaderCell colSpan="2" />
        </Table.Row>
      )}
    </Table.Footer>
  );
};

CategoryFooter.propTypes = {
  lineItems: PropTypes.array.isRequired,
};

export default CategoryFooter;
