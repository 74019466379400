import React from 'react';
import { withRouter, Redirect } from 'react-router-dom';
import {
  useUserContext,
  UserContext,
} from '../../services/context/UserContext';

const EnsureUser = ({ children }) => {
  const { user } = useUserContext(UserContext);

  return user ? children : <Redirect to="/" />;
};

export default withRouter(EnsureUser);
