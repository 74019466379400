import React from 'react';
import { UserContext } from '../../../services/context/UserContext';
import ProjectsTable from './ProjectsTable';
import useProjects from './useProjects';

const Archived = () => {
  const { user } = React.useContext(UserContext);
  const { error, projects } = useProjects(true);

  if (!user) {
    return <div>Loading..</div>;
  }

  if (error) {
    return <div>Error loading projects: {error}</div>;
  }

  return (
    <>
      <ProjectsTable projects={projects} user={user} archive />
    </>
  );
};

export default Archived;
