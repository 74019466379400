import React, { useState, useEffect } from 'react';
import { Button, Radio, Table } from 'semantic-ui-react';
import Can from '../../../../components/Can/Can';
import SummaryTable from './SummaryTable';
import '../Studies.scss';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const reserveAllocation = (study) => {
  // If no annual allocation amounts in array, return
  if (study.annualAllocationAmounts.length === 0) {
    return formatter.format(study.monthlyReserveAllocation);
  }
  // If all years the same return
  const total = study.annualAllocationAmounts.reduce(
    (acc, amount) => acc + amount
  );
  const avg = total / study.annualAllocationAmounts.length;
  if (avg === study.monthlyReserveAllocation) {
    return formatter.format(study.monthlyReserveAllocation);
  }
  // If increasing by a percentage, return
  if (study.annualAllocationIncrease > 0) {
    return `${formatter.format(study.monthlyReserveAllocation)} + ${
      study.annualAllocationIncrease
    }%`;
  }
  // Else it's a custom allocation schedule
  return `${formatter.format(study.monthlyReserveAllocation)} + `;
};

const ActivitiesTable = ({ studies, user }) => {
  const [selected, setSelected] = useState();

  useEffect(() => {
    setSelected(studies[0]);
  }, [studies]);

  const handleCheckbox = (event) => {
    const s = studies.find((study) => study.id === event.id);
    if (s) {
      setSelected(s);
    }
  };

  const renderCheckbox = (study) => (
    <Radio
      id={study.id}
      checked={selected && study.id === selected.id}
      onChange={(_, data) => {
        handleCheckbox(data);
      }}
    />
  );

  const renderButtons = () => (
    <>
      {/* <ConfirmDelete
          deleteId={study.id}
          name={study.name}
          deleteConfirmed={deleteConfirmed}
        /> */}
      {/* <PopupButton
          title="Archive Study"
          icon="file archive outline"
          id={study.id}
          onClick={handleArchive}
        /> */}
    </>
  );

  const renderButtonsCan = (study) => (
    <Can
      role={user.role}
      perform="study:edit"
      yes={() => renderButtons(study)}
    />
  );

  // const renderSelectStudyCan = (study) => {
  //   return (
  //     <Can
  //       role={props.user.role}
  //       perform="study:select"
  //       yes={() => renderCheckboxCanEdit(study)}
  //       no={() => renderCheckbox(study)}
  //     />
  //   );
  // };

  const renderNormalRow = (study) => (
    <Table.Row key={study.id}>
      <Table.Cell>{study.name}</Table.Cell>
      <Table.Cell textAlign="center">{study.studyYear}</Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(study.startingReserveBalance)}
      </Table.Cell>
      <Table.Cell textAlign="center">{reserveAllocation(study)}</Table.Cell>
      <Table.Cell textAlign="center">{100 * study.inflationRate}%</Table.Cell>
      <Table.Cell textAlign="center">{100 * study.interestRate}%</Table.Cell>
      <Table.Cell textAlign="center">{renderCheckbox(study)}</Table.Cell>
      <Table.Cell textAlign="center">
        <Button.Group basic size="tiny">
          {renderButtonsCan(study)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  const renderHeader = () => (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Study Year
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Starting Balance
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Monthly Allocation
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Inflation %
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Interest %
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Show Details
        </Table.HeaderCell>
        <Table.HeaderCell width={2} textAlign="center">
          Actions
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  console.log('selected', selected);
  return (
    <>
      <Table celled compact striped color="blue">
        {renderHeader()}
        <Table.Body>
          {studies.map((study, index) => renderNormalRow(study, index))}
        </Table.Body>
      </Table>
      {selected && <SummaryTable study={selected} user={user} />}
    </>
  );
};

export default ActivitiesTable;
