import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import LineItem from '../../LineItems/LineItem';
import LineItemsTable from '../../LineItems/LineItems';
import CategoryFooter from './CategoryFooter';
import BudgetSummary from '../../BudgetSummary/BudgetSummary';
import useLineItems from '../../LineItems/useLineItems';

const CategoryTable = ({ budget, user }) => {
  const { categories } = useLineItems(budget.id);

  const renderLineItemsInCategory = (category, index) => {
    const items = category.sort((a, b) => (a.cid > b.cid ? 1 : -1));

    return (
      <LineItemsTable key={200 + index}>
        <Table.Body>
          {items.map((lineItem) => (
            <LineItem
              key={lineItem.id}
              budget={budget}
              lineItem={lineItem}
              user={user}
            />
          ))}
        </Table.Body>
        <CategoryFooter lineItems={category} />
      </LineItemsTable>
    );
  };

  const renderCategories = (cats) => (
    <>
      {cats.map((category, index) =>
        renderLineItemsInCategory(category, index)
      )}
      <BudgetSummary categories={cats} />
    </>
  );

  return <>{categories && renderCategories(categories)}</>;
};

CategoryTable.propTypes = {
  budget: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default CategoryTable;
