import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';

const useComponentSets = () => {
  const [sets, setSets] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  const sortedComponents = (set) =>
    set.components.sort((a, b) => {
      const aPid = parseInt(a.pid, 10);
      const bPid = parseInt(b.pid, 10);
      const aCat = a.category;
      const bCat = b.category;
      const aName = a.name;
      const bName = b.name;
      if (aCat === bCat) {
        if (aPid === bPid) {
          return aName > bName ? 1 : -1;
        }
        return aPid - bPid;
      }
      return aCat > bCat ? 1 : -1;
    });

  useEffect(() => {
    try {
      const unsub = db.collection('componentSets').onSnapshot((snapshot) => {
        const all = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        const allSorted = all.map((set) => {
          set.components = sortedComponents(set);
          return set;
        });
        setSets(allSorted);
        setLoading(false);
      });
      return () => {
        console.log('Cleanup componentSets');
        unsub();
      };
    } catch (error) {
      console.log('Error', error);
      setLoading(false);
      setFetchError(error);
    }
  }, []);

  return {
    fetchError,
    loading,
    sets,
  };
};

export default useComponentSets;
