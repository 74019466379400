import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useComponent from './useComponent';
import EditForm from './EditForm';

const CreateComponent = ({ set }) => {
  const [open, setOpen] = useState(false);
  const { canCreate, doCreate, component, handleChange } = useComponent(
    set,
    {},
    0,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Error updating component', error);
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doCreate();
  };

  const disabled = () => !canCreate;

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          Create Component
          {/* <Icon name="plus" size="large" /> */}
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create Component" />
      <Modal.Content>
        <EditForm component={component} fieldUpdated={handleChange} />
        {/* {mutationLoading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {mutationError && <p>Error: {mutationError.message}</p>} */}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

CreateComponent.propTypes = {
  set: PropTypes.object.isRequired,
};

export default CreateComponent;
