import React, { useState } from 'react';
import { Button, Icon, Header, Modal } from 'semantic-ui-react';
import firebase from 'firebase/app';
import ModalActions from '../../../../../components/CreateModal/ModalActions';
import EditForm from './EditForm';
import useBid from './useBid';
import 'firebase/storage';

const { uuid } = require('uuidv4');

const EditBid = ({ project, editing }) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();
  const { bid, doAddImageUrl, doUpdate, canUpdate, handleChange } = useBid(
    project.id,
    editing,
    () => {},
    (error) => {
      setErrorMessage(error);
    }
  );

  const uploadToFirebaseStorage = async (file) => {
    const fileName = `${uuid()}.jpeg`;
    const storage = firebase.storage();
    console.log('storageRef', storage);
    const storageRef = storage.ref();
    console.log('ref', storageRef);
    const imagesRef = storageRef.child(`images/${fileName}`);
    const uploadTask = imagesRef.put(file);
    // .then((snapshot) => {
    //   console.log("uploaded", snapshot, imagesRef.fullPath);
    //   imagesRef.getDownloadURL().then((value) => {
    //     console.log("downloadURL", value);
    //     noAutoClose = true;
    //     doAddImageUrl(value);
    //   });
    // });
    uploadTask.on(
      'state_changed',
      function (snapshot) {
        // Observe state change events such as progress, pause, and resume
        // Get task progress, including the number of bytes uploaded and the total number of bytes to be uploaded
        const progress =
          (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
        console.log(`Upload is ${progress}% done`);
        switch (snapshot.state) {
          case firebase.storage.TaskState.PAUSED: // or 'paused'
            console.log('Upload is paused');
            break;
          case firebase.storage.TaskState.RUNNING: // or 'running'
            console.log('Upload is running');
            break;
          default:
            console.log('Unknown task state: ', snapshot.state);
        }
      },
      function (error) {
        // Handle unsuccessful uploads
        console.log('Error uploading image ', error);
      },
      function () {
        // Get the download URL: https://firebasestorage.googleapis.com/...
        uploadTask.snapshot.ref.getDownloadURL().then(function (downloadURL) {
          console.log('File available at', downloadURL);
          // noAutoClose = true;
          doAddImageUrl(downloadURL);
        });
      }
    );
  };

  // const handleImageUpload = async (file) => {
  //   const formData = new FormData();
  //   formData.append("file", file);
  //   formData.append("upload_preset", "sickfits");
  //   // setUploading(true);
  //   await fetch("https://api.cloudinary.com/v1_1/wishinone/image/upload", {
  //     method: "POST",
  //     body: formData,
  //   })
  //     .then((response) => response.json())
  //     .then((data) => {
  //       console.log("Upload complete", data);
  //       if (data.secure_url !== "") {
  //         noAutoClose = true;
  //         doAddImageUrl(data.secure_url);
  //       }
  //     })
  //     .catch((err) => {
  //       console.error(err);
  //       // setUploading(false);
  //     });
  // };

  // Upload a single file TODO! multiple files
  const uploadFile = (acceptedFiles) => {
    console.log('acceptedFiles', acceptedFiles);
    // handleImageUpload(acceptedFiles[0]);
    uploadToFirebaseStorage(acceptedFiles[0]);
  };

  const disabled = () => !canUpdate();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setErrorMessage(null);
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name="edit outline" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Bid" />
      <Modal.Content>
        <EditForm
          bid={bid}
          fieldUpdated={handleChange}
          uploadFile={uploadFile}
          imageUrls={editing.imageUrls}
        />
        {/* {(uploading || mutationLoading) && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )} */}
        {errorMessage && <p style={{ color: 'red' }}>Error: {errorMessage}</p>}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditBid;
