import React from 'react';
import { Table, Divider, Header, Radio, Segment } from 'semantic-ui-react';
import colors from '../../../components/Styles/Colors';
import useTaxBillSelector from '../TaxBills/useTaxBillSelector';
import ReconcileSellers from './ReconcileSellers';
import ReconcileBuyers from './ReconcileBuyers';
import Reconciliations from './Reconcilations';

const ReconcileTable = ({ park, taxBills }) => {
  const { selectedIndex, handleSelectionChange } = useTaxBillSelector(
    taxBills,
    false
  );

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const currency = (value) => (value ? formatter.format(value) : null);

  const selectedChanged = (_, data) => {
    handleSelectionChange(data.id);
  };

  const renderNormalRow = (taxBill, index) => {
    const { model } = taxBill;

    return (
      <Table.Row key={taxBill.id}>
        <Table.Cell textAlign="center">
          <Radio
            id={model.id}
            checked={selectedIndex === index}
            disabled={false}
            onChange={selectedChanged}
          />
        </Table.Cell>
        <Table.Cell textAlign="center">{model.year}</Table.Cell>
        <Table.Cell textAlign="center">{model.assessedValueRate}</Table.Cell>
        <Table.Cell textAlign="center">{model.landImprovementsRate}</Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.totalAssessedValue)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.perPropertyTotalTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.specialATax)}
        </Table.Cell>
        <Table.Cell textAlign="left">{model.notes}</Table.Cell>
        <Table.Cell textAlign="center">
          {/* {renderButtons(model, index)} */}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderTaxBillHeader = () => (
    <>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell textAlign="center" width={1}>
            {/* {renderAddTaxBill()} */}
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Tax Year</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Assessed Value Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Land & Impr Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Total Assessed Value
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Per Prop Value</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Special A Tax</Table.HeaderCell>
          <Table.HeaderCell width={5} textAlign="center">
            Notes
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </>
  );

  const renderTaxBillBody = (taxBill, index) => (
    <React.Fragment key={taxBill.id}>
      <Table.Body>{renderNormalRow(taxBill, index)}</Table.Body>
    </React.Fragment>
  );

  const renderSelectedAssessments = () => {
    const color = colors[selectedIndex % colors.length];
    const index = selectedIndex < taxBills.length ? selectedIndex : 0;
    const taxBill = taxBills[index];

    if (!taxBill) {
      console.log('No tax bill', selectedIndex);
      return null;
    }

    if (!taxBill.assessments || !taxBill.supplementals) {
      return null;
    }

    return (
      <Segment color={color}>
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>Pre-2020 Existing Spaces</Divider>
        </Header>
        <Reconciliations taxBill={taxBill} park={park} bought={2019} />
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>2020 Buyers</Divider>
        </Header>
        <Reconciliations taxBill={taxBill} park={park} bought={2020} />
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>2021 Sellers</Divider>
        </Header>
        <ReconcileSellers taxBill={taxBill} park={park} bought={2021} />
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>2021 Buyers</Divider>
        </Header>
        <ReconcileBuyers taxBill={taxBill} park={park} bought={2021} />
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>2022 Buyers</Divider>
        </Header>
        <ReconcileBuyers taxBill={taxBill} park={park} bought={2022} />
      </Segment>
    );
  };

  return (
    <>
      <Table celled striped>
        {renderTaxBillHeader()}
        {taxBills.map((taxBill, index) => renderTaxBillBody(taxBill, index))}
      </Table>
      {renderSelectedAssessments()}
    </>
  );
};

export default ReconcileTable;
