import React from 'react';
import DatePicker from 'react-datepicker';
import { Button, Form } from 'semantic-ui-react';
import { digits } from '../../../../../services/number/formatting';

const AllocationForm = ({ fieldUpdated, addAll, allocation }) => {
  const handleClick = (event, data) => {
    console.log('date', data);
    addAll();
  };

  const expenseDate = (timestamp) => {
    fieldUpdated('date', timestamp);
  };

  return (
    <Form>
      {addAll && (
        <Form.Group>
          <Form.Input label="Add All to Date">
            <Button color="teal" size="medium" onClick={handleClick}>
              Add All
            </Button>
          </Form.Input>
        </Form.Group>
      )}
      <Form.Group>
        {/* <Form.Input
          width={2}
          fluid
          label="Month"
          id="form-input-month"
          value={props.allocation.month}
          onChange={(_, data) => {
            fieldUpdated("month", data.value);
          }}
        /> */}
        <Form.Input label="Date">
          <DatePicker
            selected={allocation.date}
            onChange={expenseDate}
            popperPlacement="top-end"
          />
        </Form.Input>
        <Form.Input
          width={2}
          fluid
          label="Amount"
          id="form-input-amount"
          value={allocation.amount}
          onChange={(_, data) => {
            fieldUpdated('amount', digits(data.value));
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default AllocationForm;
