import React from 'react';
import { Icon, Menu } from 'semantic-ui-react';
import { Link, withRouter } from 'react-router-dom';
import './SideBarItem.scss';

const SideBarItem = ({ location, path, label, icon }) => {
  const shouldBeHighlighted = () => {
    if (!location) return false;
    const { pathname } = location;
    if (path === '/') {
      return pathname === path;
    }
    const items = pathname.split('/');
    return items.find((item) => item === path);
  };

  // React will ignore custom boolean attributes, therefore we pass a string
  // we use this attribute in our SCSS for styling
  const highlight = shouldBeHighlighted() ? 'highlight-item' : null;

  return (
    <Link to={{ pathname: path }}>
      <Menu.Item className={['sidebar-item', highlight].join(' ')}>
        <div className="sidebar-item-alignment-container">
          <span>
            <Icon size="large" name={icon} color="teal" />
          </span>
          <span>{label}</span>
        </div>
      </Menu.Item>
    </Link>
  );
};

export default withRouter(SideBarItem);
