import React from 'react';
import EnsureUser from '../../components/EnsureUser/EnsureUser';
import ParksContent from './ParksContent/ParksContent';
import SideBar from './SideBar/SideBar';

const Parks = () => (
  <EnsureUser>
    <SideBar />
    <ParksContent />
  </EnsureUser>
);

export default Parks;
