import React, { useState } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import { statusOptions } from '../StatusOptions';
import useProject from './useProject';
import EditForm from './EditForm';

const CreateProject = () => {
  const [open, setOpen] = useState(false);
  const { project, doCreate, canCreate, handleChange } = useProject(
    {},
    () => {
      console.log('success');
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const disabled = () => !canCreate();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doCreate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button color="teal" basic icon size="small">
          <Icon name="plus" size="large" color="teal" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create New Project" />
      <Modal.Content>
        <EditForm
          options={statusOptions}
          project={project}
          fieldUpdated={handleChange}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default CreateProject;
