import React from 'react';
import PropTypes from 'prop-types';
import { Divider, Header } from 'semantic-ui-react';
import { UserContext } from '../../../services/context/UserContext';
import Can from '../../../components/Can/Can';
import ReassessmentsTable from './ReassessmentsTable';
import TaxTotals from './TaxTotals';

const Assessments = ({ park, taxBill }) => {
  const { user } = React.useContext(UserContext);

  if (!user) {
    return <div>Loading...</div>;
  }

  return (
    <>
      <div>
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>Tax Totals & Computed Taxes</Divider>
        </Header>
        <TaxTotals taxBill={taxBill} />
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>County Assessed Values</Divider>
        </Header>
      </div>
      <Can
        role={user.role}
        perform="park/taxes:visit"
        yes={() => (
          <ReassessmentsTable park={park} taxBill={taxBill} user={user} />
        )}
        no={() => null}
      />
    </>
  );
};

Assessments.propTypes = {
  park: PropTypes.object.isRequired,
  taxBill: PropTypes.object.isRequired,
};

export default Assessments;
