import React, { useState } from 'react';
import { Button, Icon, Header, Modal } from 'semantic-ui-react';
import { db } from '../../../services/firebase/firebase';
import { parseLocaleFloat } from '../../../services/number/formatting';
import ModalActions from '../../../components/CreateModal/ModalActions';
import EditForm from './EditForm';

const EditSpace = ({ editSpace }) => {
  const [open, setOpen] = useState(false);
  const [space, setSpace] = useState({
    number: editSpace.number || '',
    address: editSpace.address || '',
    name: editSpace.name || '',
    name2: editSpace.name2 || '',
    notes: editSpace.notes || '',
    phone: editSpace.phone || '',
    size: editSpace.size || '',
    shareNumber: editSpace.shareNumber || '',
    decal: editSpace.decal || '',
    isNewHome: editSpace.isNewHome || false,
    isForSale: editSpace.isForSale || false,
    isVacant: editSpace.isVacant || false,
    purchasePrice: editSpace.purchasePrice || '',
    purchaseDate: editSpace.purchaseDate ? editSpace.purchaseDate : null,
  });

  const update = async (variables) => {
    console.log('Update Space ', variables);

    db.collection('spaces')
      .doc(editSpace.id)
      .update(variables)
      .then(() => {
        console.log('Updated space');
        setOpen(false);
      })
      .catch((error) => {
        console.log('Error', error);
      });
  };

  // Need to prevent a comma in a possible CSV output
  const fixSize = (size) => (size ? size.replace(',', ';') : size);

  const makeVariables = () => ({
    number: space.number,
    address: space.address,
    name: space.name,
    name2: space.name2,
    notes: space.notes,
    phone: space.phone,
    size: fixSize(space.size),
    shareNumber: space.shareNumber,
    decal: space.decal,
    isNewHome: space.isNewHome,
    isForSale: space.isForSale,
    isVacant: space.isVacant,
    purchaseDate: space.purchaseDate,
    purchasePrice: parseLocaleFloat(space.purchasePrice),
  });

  // var myFirebaseFirestoreTimestampFromDate = firebase.firestore.Timestamp.fromDate(new Date());

  const disabled = () => false;

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    update(makeVariables());
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name="edit outline" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Space Owner and Coach" />
      <Modal.Content>
        <EditForm
          space={space}
          fieldUpdated={(name, value) => {
            setSpace({ ...space, [name]: value });
          }}
        />
        {/* {loading() && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {error() && <p>Error: {errorMessage()}</p>} */}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditSpace;
