import React, { useCallback } from 'react';
import DatePicker from 'react-datepicker';
import { Dropdown, Form } from 'semantic-ui-react';
import ModalGallery from '../../../../components/Image/ModalGallery';
import EditGallery from '../../../../components/Image/EditGallery';

const EditForm = ({
  fieldUpdated,
  uploadFile,
  project,
  imageUrls,
  options,
}) => {
  const maxSize = 1048576 * 3;

  // const fieldUpdated = (name, value) => {
  //   props.fieldUpdated && props.fieldUpdated(name, value);
  // };

  const date = (timestamp) => {
    fieldUpdated('date', timestamp);
  };

  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadFile(acceptedFiles);
    },
    [uploadFile]
  );

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Input
            width={4}
            label="Name"
            id="form-input-name"
            value={project.name}
            onChange={(_, data) => {
              fieldUpdated('name', data.value);
            }}
          />
          <Form.Input
            width={4}
            label="Funding"
            id="form-input-fuding"
            value={project.funding}
            onChange={(_, data) => {
              fieldUpdated('funding', data.value);
            }}
          />
          <Form.Input label="Status">
            <Dropdown
              basic
              selection
              options={options}
              value={project.status}
              onChange={(_, data) => fieldUpdated('status', data.value)}
            />
          </Form.Input>
          <Form.Input label="Project Date">
            <DatePicker
              selected={project.date}
              onChange={date}
              popperPlacement="top-end"
            />
          </Form.Input>
        </Form.Group>
        <Form.Group>
          <Form.TextArea
            width={16}
            label="Notes"
            id="form-input-notes"
            value={project.notes}
            onChange={(_, data) => {
              fieldUpdated('notes', data.value);
            }}
          />
        </Form.Group>
        {imageUrls && (
          <EditGallery
            imageUrls={imageUrls}
            maxSize={maxSize}
            onDrop={onDrop}
          />
        )}
        {imageUrls && imageUrls.length > 0 ? (
          <ModalGallery imageUrls={imageUrls} header="Project Images" />
        ) : null}
        <br />
      </Form>
    </div>
  );
};

export default EditForm;
