import React from 'react';
import DatePicker from 'react-datepicker';
import { Form } from 'semantic-ui-react';
import { digits } from '../../../../../services/number/formatting';
import 'react-datepicker/dist/react-datepicker.css';

const BalanceForm = ({ fieldUpdated, balance }) => {
  const updatedDate = (timestamp) => {
    fieldUpdated('date', timestamp);
  };

  return (
    <Form>
      <Form.Group>
        <Form.Input label="Date">
          <DatePicker
            selected={balance.date}
            onChange={updatedDate}
            popperPlacement="top-end"
          />
        </Form.Input>
        <Form.Input
          width={2}
          fluid
          label="Amount"
          id="form-input-amount"
          value={balance.amount}
          onChange={(_, data) => {
            fieldUpdated('amount', digits(data.value));
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default BalanceForm;
