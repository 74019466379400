import React from 'react';
import { Table } from 'semantic-ui-react';

function sortNames(data) {
  const sorted = data.sort((a, b) => {
    const aName = a.name.split(' ').pop();
    const bName = b.name.split(' ').pop();
    return aName.localeCompare(bName);
  });
  return sorted;
}

function sortNames2(data) {
  const sorted = data.sort((a, b) => {
    const aName = a.name2.split(' ').pop();
    const bName = b.name2.split(' ').pop();
    return aName.localeCompare(bName);
  });
  return sorted;
}

function sortSpaces(data) {
  return data.sort((a, b) => a.number - b.number);
}

function exampleReducer(state, action) {
  switch (action.type) {
    case 'CHANGE_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        column: action.column,
        data: sortSpaces(state.data),
        direction: 'ascending',
      };

    case 'ALPHA_SORT':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        column: action.column,
        data: sortNames(state.data),
        direction: 'ascending',
      };

    case 'ALPHA_SORT_2':
      if (state.column === action.column) {
        return {
          ...state,
          data: state.data.reverse(),
          direction:
            state.direction === 'ascending' ? 'descending' : 'ascending',
        };
      }

      return {
        column: action.column,
        data: sortNames2(state.data),
        direction: 'ascending',
      };

    default:
      throw new Error();
  }
}

const ResidentsTable = ({ spaces }) => {
  const [state, dispatch] = React.useReducer(exampleReducer, {
    column: null,
    data: spaces,
    direction: null,
  });

  const { column, data, direction } = state;

  const makeName = (space) => {
    if (state.column === 'name') {
      const parts = space.name.split(' ');
      const last = parts.pop();
      const whole = `${last}, ${parts.join(' ')}`;
      return whole;
    }
    return space.name;
  };

  const makeName2 = (space) => {
    if (state.column === 'name2') {
      const parts = space.name2.split(' ');
      const last = parts.pop();
      const whole = `${last}, ${parts.join(' ')}`;
      return whole;
    }
    return space.name2 ? space.name2 : ' ';
  };

  const renderSpaceRow = (space) => (
    <Table.Row key={space.id}>
      <Table.Cell textAlign="center">{space.number}</Table.Cell>
      <Table.Cell textAlign="center">{space.address}</Table.Cell>
      <Table.Cell textAlign="left">{makeName(space)}</Table.Cell>
      <Table.Cell textAlign="center">{space.phone}</Table.Cell>
      <Table.Cell textAlign="left">{makeName2(space)}</Table.Cell>
      <Table.Cell textAlign="left">{space.notes}</Table.Cell>
    </Table.Row>
  );

  const renderSpaces = () => {
    if (!data) {
      return null;
    }
    return data.map((space) => renderSpaceRow(space));
  };

  const renderSpacesTable = () => (
    <Table celled striped sortable>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === 'space' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'space' })}
          >
            Space
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="center"
            sorted={column === 'address' ? direction : null}
            onClick={() => dispatch({ type: 'CHANGE_SORT', column: 'address' })}
          >
            Address
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="left"
            sorted={column === 'name' ? direction : null}
            onClick={() => dispatch({ type: 'ALPHA_SORT', column: 'name' })}
          >
            Name
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="2">
            Phone
          </Table.HeaderCell>
          <Table.HeaderCell
            textAlign="left"
            sorted={column === 'name2' ? direction : null}
            onClick={() => dispatch({ type: 'ALPHA_SORT_2', column: 'name2' })}
          >
            Name 2
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Notes</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderSpaces()}</Table.Body>
    </Table>
  );

  return <>{renderSpacesTable()}</>;
};

export default ResidentsTable;
