import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const TaxTotals = ({ taxBill }) => {
  const { model } = taxBill;
  const { perPropReassessments, allReassessmentTaxesMap } = model;
  const { perPropAssessments, allAssessmentTaxesMap } = model;

  const formatter0 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });
  const formatter2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const renderTableRow = (prefix, perProp, taxes) => (
    <Table.Row>
      <Table.Cell textAlign="center">{`${model.year} (${prefix} )`}</Table.Cell>
      <Table.Cell textAlign="center">
        {formatter0.format(perProp.landTotal)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter0.format(perProp.improveTotal)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter0.format(perProp.assessTotal)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter0.format(perProp.exemptTotal)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter2.format(perProp.busEquipEach)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter2.format(perProp.specialEach)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter2.format(perProp.perPropEach)}
      </Table.Cell>
      <Table.Cell textAlign="center">{formatter2.format(taxes.att)}</Table.Cell>
    </Table.Row>
  );

  return (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Year</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Land Value</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Improvements</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Exemptions</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Bus Equip</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Special A Tax
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Personal Property
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Total Tax</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {renderTableRow('Prev', perPropAssessments, allAssessmentTaxesMap)}
          {renderTableRow('New', perPropReassessments, allReassessmentTaxesMap)}
        </Table.Body>
      </Table>
    </>
  );
};

TaxTotals.propTypes = {
  taxBill: PropTypes.object.isRequired,
};

export default TaxTotals;
