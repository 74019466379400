const TypeOptions = [
  {
    key: 'INCOME',
    text: 'INCOME',
    value: 'INCOME',
  },
  {
    key: 'DMF_INCOME',
    text: 'DMF INCOME',
    value: 'DMF INCOME',
  },
  {
    key: 'EXPENSE',
    text: 'EXPENSE',
    value: 'EXPENSE',
  },
  {
    key: 'DMF_EXPENSE',
    text: 'DMF EXPENSE',
    value: 'DMF EXPENSE',
  },
];

export default TypeOptions;
