import React, { useState } from 'react';
// import PropTypes from 'prop-types';
import { Button, Dimmer, Header, Loader, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useImports from '../../LineItems/EditLineItem/useImports';
import useBudget from './useBudget';
import EditForm from './EditForm';

const CreateBudget = () => {
  const [open, setOpen] = useState(false);

  const {
    budget,
    loading,
    doCreate,
    doCleanup,
    canCreate,
    setLineItems,
    handleChange,
    errorMessage,
  } = useBudget(
    {},
    () => {
      console.log('success');
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    doCleanup();
    setOpen(false);
  };

  const { importLineItemsFile } = useImports(
    (parsedLineItems) => {
      console.log('Imported line items');
      setLineItems(parsedLineItems);
    },
    (error) => {
      console.log('Error parsing import file', error);
    }
  );

  const disabled = () => !canCreate();

  // Modal Actions

  const save = () => {
    doCreate();
  };

  // const copyCurrentLineItems = () => {
  //   copyLineItems(currentLineItems.id);
  // };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button color="teal" basic icon size="small">
          <Icon name="plus" size="large" color="teal" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create New Budget" />
      <Modal.Content>
        <EditForm
          budget={budget}
          fieldUpdated={handleChange}
          importFile={importLineItemsFile}
        />
        {loading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {errorMessage && <p style={{ color: 'red' }}>Error: {errorMessage}</p>}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

// CreateBudget.propTypes = {
//   user: PropTypes.object.isRequired,
// };

export default CreateBudget;
