import React, { useState } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import PrintForm from './PrintForm';
import useGenPdf from './useGenPdf';

const PrintBirthday = ({ monthName, birthdays }) => {
  const [open, setOpen] = useState(false);
  const { generateFromHtml, handleChange, options } = useGenPdf(
    monthName,
    birthdays,
    () => {
      console.log('success');
      // setOpen(false);
    },
    (error) => {
      console.log('Error', error);
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const disabled = () => false;

  const save = () => {
    // generatePDF();
    generateFromHtml();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button color="teal" icon basic size="large">
          <Icon name="file pdf outline" size="large" color="teal" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content={`Print ${monthName} Birthdays`} />
      <Modal.Content>
        <PrintForm
          birthdays={birthdays}
          monthName={monthName}
          fieldUpdated={handleChange}
          options={options}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default PrintBirthday;
