import React, { Component } from 'react';
import { Menu, Divider } from 'semantic-ui-react';
import SideBarItem from '../SideBar/SideBarItem/SideBarItem';
import SideBarHeader from '../SideBar/SideBarHeader/SideBarHeader';
import SideBarFooter from '../SideBar/SideBarFooter/SideBarFooter';
import './SideBar.scss';

export class Sidebar extends Component {
  render() {
    return (
      <Menu borderless vertical stackable fixed="left" className="side-nav">
        <SideBarItem path="/" label="Home" icon="home" />
        <Divider fitted />
        <SideBarHeader title="Active" />
        <SideBarItem path="taxbills" label="Tax Bills" icon="table" />
        <SideBarItem path="supplementals" label="Supplementals" icon="table" />
        <SideBarItem path="reassessments" label="Reassessments" icon="table" />
        <SideBarItem path="reconcile" label="Reconciliation" icon="table" />
        <Divider />
        <SideBarHeader title="Archives" />
        <SideBarItem path="archives" label="Tax Bills" icon="archive" />
        <SideBarItem path="supparchives" label="Supplementals" icon="archive" />
        <Divider />
        <SideBarItem path="help" label="Help" icon="question circle outline" />
        <SideBarItem label="Send feedback" icon="comment outline" />
        <Divider />
        <SideBarFooter />
      </Menu>
    );
  }
}
