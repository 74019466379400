export const ROLES = {
  resident: 'RESIDENT',
  office: 'OFFICE',
  board: 'BOARD',
  admin: 'ADMIN',
};

export const SCOPES = {
  canCreate: 'can-create',
  canEdit: 'can-edit',
  canDelete: 'can-delete',
  canView: 'can-view',
};

export const PERMISSIONS = {
  [ROLES.resident]: [SCOPES.canView],
  [ROLES.office]: [SCOPES.canView, SCOPES.canEdit, SCOPES.canCreate],
  [ROLES.board]: [SCOPES.canView, SCOPES.canEdit, SCOPES.canCreate],
  [ROLES.admin]: [
    SCOPES.canCreate,
    SCOPES.canDelete,
    SCOPES.canEdit,
    SCOPES.canView,
  ],
};
