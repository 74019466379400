import { useState, useEffect } from 'react';
import Moment from 'moment';
import { db } from '../../../services/firebase/firebase';

const useProjects = (archive) => {
  const [projects, setProjects] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setLoading(false);

    try {
      const unsubscribe = db
        .collection('projects')
        .where('archive', '==', archive)
        .onSnapshot((snapshot) => {
          const all = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .map((project) => ({ ...project, date: project.date.toDate() }))
            .sort((a, b) => new Moment(b.date) - new Moment(a.date));

          setProjects(all);
          console.log('projects', all);
        });

      return () => {
        console.log('cleanup  projects');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [archive]);

  return {
    fetchError,
    loading,
    projects,
  };
};

export default useProjects;
