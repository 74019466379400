// Helper functions to reconcile payments vs impounds

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const currency = (value) => (value ? formatter.format(value) : null);

// Returns First1 & First2 Last1
const makeNames = (space) => {
  if (space.name && space.name2) {
    const names1 = space.name.split(' ');
    const names2 = space.name2.split(' ');
    if (names1[1] === names2[1]) {
      return `${names1[0]} & ${names2[0]} ${names1[1]}`;
    }
    return `${space.name} & ${space.name2}`;
  }
  return space.name;
};

const makeExportVars = ({ space, taxes }) => {
  const vars = {
    number: space.number,
    address: space.address,
    name: makeNames(space),
    assessment: currency(taxes.assessment),
    impounds: currency(taxes.totalImpounds),
    parkPaid: currency(taxes.parkPaid),
    difference: currency(taxes.difference),
    projected: currency(taxes.projected),
  };
  // console.log('vars', taxes);
  return vars;
};

const makeSellerVars = ({ archive, space, taxes }) => {
  const {
    parkPaid,
    impounds,
    difference,
    monthlyImpounds,
    monthlyTaxes,
  } = taxes;

  const vars = {
    number: space.number,
    address: space.address,
    name: makeNames(archive),
    impounds: currency(impounds),
    parkPaid: currency(parkPaid),
    difference: currency(difference),
    monthlyTaxes: currency(monthlyTaxes),
    monthlyImpounds: currency(monthlyImpounds),
  };
  return vars;
};

export const makeExportFileData = (values) =>
  values.map((value) => makeExportVars(value));

export const makeSellerFileData = (values) =>
  values.map((value) => makeSellerVars(value));

const taxesAt = (park, coachNumber, index) => {
  const taxBill = park.taxBillFor(index);
  const { model } = taxBill;
  return model.assessmentTaxesFor(coachNumber).monthlyTax;
};

export const computeSellerTaxes = (park, space) => {
  const closeMonth = space.purchaseDate ? space.purchaseDate.getMonth() : 0;
  const closeDate = space.purchaseDate ? space.purchaseDate.getDate() : 0;
  const monthCount = closeDate > 11 ? closeMonth + 1 : closeMonth;
  const taxBill1 = park.taxBillFor(1);
  const taxBill2 = park.taxBillFor(2);
  const model1 = taxBill1.model;
  const tax1 = model1.assessmentTaxesFor(space.number);
  const model2 = taxBill2.model;
  const tax2 = model2.assessmentTaxesFor(space.number);
  const parkPaid = tax2.monthlyTax * monthCount;
  const impounds = tax1.projectedMonthlyTax * monthCount;
  const difference = impounds - parkPaid;
  const taxes = model2.assessmentTaxesFor(space.number);

  return {
    assessment: taxes.landImprovementsTotal,
    monthlyImpounds: tax1.projectedMonthlyTax,
    monthlyTaxes: tax2.monthlyTax,
    impounds,
    parkPaid,
    difference,
    tax1,
    tax2,
    months: monthCount,
    closeMonth,
    closeDate,
  };
};

export const computeTaxes = (park, space, model) => {
  const coachNumber = space.number;
  const closeMonth = space.purchaseDate ? space.purchaseDate.getMonth() + 1 : 0;

  const supps = model.supplementals.reduce(
    (acc, supp) => (supp.space === coachNumber ? [...acc, supp] : acc),
    []
  );

  const suppTotal = model.supplementals.reduce(
    (acc, supp) =>
      supp.space === coachNumber ? acc + supp.proratedTaxBill : acc,
    0
  );

  // An array of the number of months of taxes paid at the rate
  // for 2019, 2020, 2021 for 2 supplemental bills and
  // for 2020, 2021 for 1 supplemental bill

  let bills = [];
  if (supps.length === 2) {
    bills = [
      {
        taxes: taxesAt(park, coachNumber, 0),
        months: 6 - closeMonth,
      },
      {
        taxes: taxesAt(park, coachNumber, 1),
        months: 6,
      },
      {
        taxes: taxesAt(park, coachNumber, 2),
        months: 12,
      },
    ];
  }
  if (supps.length === 1) {
    bills = [
      {
        taxes: taxesAt(park, coachNumber, 1),
        months: 12 - closeMonth,
      },
      {
        taxes: taxesAt(park, coachNumber, 2),
        months: 12,
      },
    ];
  }
  if (supps.length === 0) {
    bills = [
      {
        taxes: taxesAt(park, coachNumber, 2),
        months: 12,
      },
    ];
  }

  const pp = bills.reduce((acc, bill) => acc + bill.months * bill.taxes, 0);

  const tax = model.supplementals.reduce(
    (acc, supp) =>
      supp.space === coachNumber ? acc + supp.proratedTaxBill : acc,
    0
  );

  const parkPaid = pp + tax;

  // Resident Paid
  const impoundAmount = model.impoundsFor(coachNumber);
  const totalImpounds = bills.reduce(
    (acc, bill) => acc + bill.months * impoundAmount,
    0
  );

  const difference = totalImpounds - parkPaid;

  const taxes = model.assessmentTaxesFor(coachNumber);
  const projected = taxes.projectedMonthlyTax;

  const vals = {
    coachNumber,
    closeMonth,
    difference,
    projected,
    assessment: taxes.landImprovementsTotal,
    impoundAmount,
    totalImpounds,
    suppTotal,
    parkPaid,
    supps,
    bills,
    tax,
    pp,
  };
  return vals;
};

const postponeCompute = (park, model, coachNumber) => {
  const { purchaseDate } = park.spaceFor(coachNumber);
  if (purchaseDate) {
    const year = purchaseDate.getFullYear();
    if (year === model.year) return true;
  }
  return false;
};

export const computeDifference = (park, model, values) => {
  const impoundDifference = model.difference(values.coach);
  const specialDifference = values.difference;
  return postponeCompute(park, model, values.coach)
    ? 0
    : specialDifference || impoundDifference;
};

export const computeSupplementalTaxes = (model, coachNumber) =>
  model.supplementals.reduce(
    (acc, supp) =>
      supp.space === coachNumber ? acc + supp.proratedTaxBill : acc,
    0
  );

export const computeSummary = (allValues) => {
  const impounds = allValues.reduce(
    (acc, values) => acc + values.taxes.totalImpounds,
    0
  );

  const parkPaid = allValues.reduce(
    (acc, values) => acc + values.taxes.parkPaid,
    0
  );

  const refunds = allValues.reduce((acc, values) => acc + values.difference, 0);

  const supplementals = allValues.reduce(
    (acc, values) => acc + values.taxes.suppTotal,
    0
  );

  return {
    supplementals,
    impounds,
    parkPaid,
    refunds,
  };
};
