import Papa from 'papaparse';

// Custom hook for importing supplementals from CSV files.
const useImportSupplementals = (handleSupplementals, failure) => {
  const _makeLowerCase = (string) =>
    string.charAt(0).toLowerCase() + string.slice(1);

  const importSupplementalsFile = async (e) => {
    const file = e.target.files[0];
    console.log('importSupplementals.File: ', file);
    if (!file) {
      failure('File not found');
    }

    Papa.parse(file, {
      header: true,
      complete(event) {
        console.log('Completed supplementals', event);
        handleSupplementals(event.data);
      },
      transformHeader(value) {
        return _makeLowerCase(value);
      },
      transform(value, name) {
        if (name === 'space') {
          return parseInt(value);
        }
        if (name === 'date') {
          return value;
        }
        if (name === 'rollYear') {
          return value;
        }
        if (name === 'taxRate') {
          return parseFloat(value);
        }
        if (name === 'prevLandValue') {
          return parseInt(value);
        }
        if (name === 'prevImprovements') {
          return parseInt(value);
        }
        if (name === 'newLandValue') {
          return parseInt(value);
        }
        if (name === 'newImprovements') {
          return parseInt(value);
        }
        if (name === 'prorationFactor') {
          return parseFloat(value);
        }
        if (name === 'proratedTaxBill') {
          return parseFloat(value);
        }
        console.log('Got unknown', name, value);
        return value;
      },
    });
  };

  return {
    importSupplementalsFile,
  };
};

export default useImportSupplementals;
