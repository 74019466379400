import React from 'react';
import { useUserContext } from '../../../services/context/UserContext';
import ComponentSetsTable from './ComponentSetsTable';
import useComponentSets from './useComponentSets';

const ComponentSets = () => {
  const { user } = useUserContext();
  const { sets, fetchError } = useComponentSets();

  if (fetchError) {
    return <div>{fetchError.message}</div>;
  }

  if (sets) {
    console.log('cs', sets);
  }

  return (
    <div>
      <ComponentSetsTable sets={sets} user={user} />
    </div>
  );
};

export default ComponentSets;
