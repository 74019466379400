import React from 'react';
import EnsureUser from '../../components/EnsureUser/EnsureUser';
import TaxesContent from './TaxesContent/TaxesContent';
import { Sidebar } from './Sidebar';

const Taxes = () => (
  <EnsureUser>
    <Sidebar />
    <TaxesContent />
  </EnsureUser>
);

export default Taxes;
