import React, { useState, useEffect } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Header,
  Segment,
  Table,
} from 'semantic-ui-react';
import {
  getSelectedStudies,
  saveSelectedStudies,
} from '../../../services/storage/Storage';
import PopupButton from '../../../components/PopupButton/PopupButton';
import ShowModal from '../../../components/ShowModal/ShowModal';
import FundingChart from '../Graphs/FundingChart';
import CreateStudy from './EditStudy/CreateStudy';
import EditStudy from './EditStudy/EditStudy';
import StudyComps from './StudyComps/StudyComps';
import YearlySummary from '../Tables/YearlySummary';
import Can from '../../../components/Can/Can';
import useModify from './EditStudy/useModify';
import './Studies.scss';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const reserveAllocation = (study) => {
  // If no annual allocation amounts in array, return
  if (study.annualAllocationAmounts.length === 0) {
    return formatter.format(study.monthlyReserveAllocation);
  }
  // If all years the same return
  const total = study.annualAllocationAmounts.reduce(
    (acc, amount) => acc + amount
  );
  const avg = total / study.annualAllocationAmounts.length;
  if (avg === study.monthlyReserveAllocation) {
    return formatter.format(study.monthlyReserveAllocation);
  }
  // If increasing by a percentage, return
  if (study.annualAllocationIncrease > 0) {
    return `${formatter.format(study.monthlyReserveAllocation)} + ${
      study.annualAllocationIncrease
    }%`;
  }
  // Else it's a custom allocation schedule
  return `${formatter.format(study.monthlyReserveAllocation)} + `;
};

const StudiesTable = ({ studies, user }) => {
  const [selected, setSelected] = useState([]);
  const { archiveStudy } = useModify(
    () => {},
    (error) => {
      console.log('Failure', error);
    }
  );

  const updateSavedIds = (updatedStudies) => {
    const updatedIds = updatedStudies.map((item) => item.id);
    saveSelectedStudies(updatedIds);
  };

  useEffect(() => {
    const ssids = getSelectedStudies();
    if (ssids) {
      // Use saved IDs
      setSelected(studies.filter((study) => ssids.includes(study.id)));
    } else {
      // Use database IDs
      setSelected(studies.filter((study) => study.isSelected));
    }
  }, [studies]);

  const findStudy = (id) => studies.find((study) => study.id === id);

  const toggleStudySelected = (id) => {
    const study = findStudy(id);
    if (study) {
      if (selected.includes(study)) {
        // Remove this study selection
        const updated = selected.filter((item) => item.id !== id);
        updateSavedIds(updated);
        setSelected(updated);
      } else {
        // Append this study selection
        const updated = [...selected, study];
        updateSavedIds(updated);
        setSelected(updated);
      }
    }
  };

  const studyIsInSelectedList = (study) => selected.includes(study);

  const handleCheckboxCanEdit = (event) => {
    toggleStudySelected(event.id);
  };

  const handleCheckbox = (event) => {
    toggleStudySelected(event.id);
  };

  const handleArchive = async (event, data) => {
    archiveStudy(data.id);
  };

  const renderCheckboxCanEdit = (study) => (
    <Checkbox
      id={study.id}
      checked={studyIsInSelectedList(study)}
      onChange={(_, data) => {
        handleCheckboxCanEdit(data);
      }}
    />
  );

  const renderCheckbox = (study) => (
    <Checkbox
      id={study.id}
      checked={studyIsInSelectedList(study)}
      onChange={(_, data) => {
        handleCheckbox(data);
      }}
    />
  );

  const renderButtons = (study) => (
    <>
      <EditStudy editStudy={study} user={user} />
      <PopupButton
        title="Archive Study"
        icon="file archive outline"
        id={study.id}
        onClick={handleArchive}
      />
    </>
  );

  const renderButtonsCan = (study) => (
    <Can
      role={user.role}
      perform="study:edit"
      yes={() => renderButtons(study)}
    />
  );

  const renderCreateStudyCan = () => (
    <Can
      role={user.role}
      perform="study:create"
      yes={() => <CreateStudy user={user} />}
      no={() => null}
    />
  );

  const renderSelectStudyCan = (study) => (
    <Can
      role={user.role}
      perform="study:select"
      yes={() => renderCheckboxCanEdit(study)}
      no={() => renderCheckbox(study)}
    />
  );

  const renderNormalRow = (study) => (
    <Table.Row key={study.id}>
      <Table.Cell width={1} />
      <Table.Cell>{study.name}</Table.Cell>
      <Table.Cell textAlign="center">{study.studyYear}</Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(study.startingReserveBalance)}
      </Table.Cell>
      <Table.Cell textAlign="center">{reserveAllocation(study)}</Table.Cell>
      <Table.Cell textAlign="left">{study.notes}</Table.Cell>
      {/* <Table.Cell textAlign="center">
        {formatter.format(study.assessment)}
      </Table.Cell>
      <Table.Cell textAlign="center">{100 * study.inflationRate}%</Table.Cell>
      <Table.Cell textAlign="center">{100 * study.interestRate}%</Table.Cell> */}
      <Table.Cell textAlign="center">{renderSelectStudyCan(study)}</Table.Cell>
      <Table.Cell textAlign="center">
        <Button.Group basic size="tiny">
          <ShowModal
            modalTitle={`${study.name} Components`}
            buttonTitle="Components"
          >
            <StudyComps study={study} user={user} />
          </ShowModal>
          {renderButtonsCan(study)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  const renderHeader = () => (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell width={1} textAlign="center">
          {renderCreateStudyCan()}
        </Table.HeaderCell>
        <Table.HeaderCell width={2}>Name</Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Study Year
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Starting Balance
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Monthly Allocation
        </Table.HeaderCell>
        <Table.HeaderCell>Notes</Table.HeaderCell>
        {/* <Table.HeaderCell width={1} textAlign="center">
          Assessment
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Inflation %
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Interest %
        </Table.HeaderCell> */}
        <Table.HeaderCell width={1} textAlign="center">
          Details & Graphs
        </Table.HeaderCell>
        <Table.HeaderCell width={2} textAlign="center">
          Actions
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  return (
    <>
      <Segment color="teal">
        <Table celled compact striped>
          {renderHeader()}
          <Table.Body>
            {studies.map((study, index) => renderNormalRow(study, index))}
          </Table.Body>
        </Table>
      </Segment>
      <Segment color="teal">
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>Balances and Funding</Divider>
        </Header>
        <FundingChart studies={selected} />
      </Segment>
      {selected.length > 0 && <YearlySummary study={selected[0]} />}
      {selected.length > 1 && <YearlySummary study={selected[1]} />}
    </>
  );
};

export default StudiesTable;
