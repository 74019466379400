import React from 'react';
import { Button, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const BallotForm = ({ fieldUpdated, content, randomize, alphabetize }) => {
  const dateUpdated = (timestamp) => {
    fieldUpdated('date', timestamp);
  };
  const date3Updated = (timestamp) => {
    fieldUpdated('date3', timestamp);
  };

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Input required label="Election Date">
            <DatePicker
              selected={content.date}
              onChange={dateUpdated}
              popperPlacement="top-end"
            />
          </Form.Input>
          <Form.Input required label="Ballots Due Date">
            <DatePicker
              selected={content.date3}
              onChange={date3Updated}
              popperPlacement="top-end"
            />
          </Form.Input>
          <Form.Input>
            <Button type="button" onClick={randomize}>
              Randomize Names
            </Button>
          </Form.Input>
          <Form.Input>
            <Button type="button" onClick={alphabetize}>
              Alphabetize Names
            </Button>
          </Form.Input>
        </Form.Group>
        <Form.Group>
          <Form.Input
            width={3}
            fluid
            // required
            label="Line 1"
            id="form-input-1"
            value={content.line1}
            onChange={(_, data) => {
              fieldUpdated('line1', data.value);
            }}
          />
          <Form.Input
            width={3}
            fluid
            // required
            label="Line 2"
            id="form-input-2"
            value={content.line2}
            onChange={(_, data) => {
              fieldUpdated('line2', data.value);
            }}
          />
          <Form.Input
            width={3}
            fluid
            // required
            label="Line 3"
            id="form-input-3"
            value={content.line3}
            onChange={(_, data) => {
              fieldUpdated('line3', data.value);
            }}
          />
          <Form.Input
            width={3}
            fluid
            // required
            label="Line 4"
            id="form-input-4"
            value={content.line4}
            onChange={(_, data) => {
              fieldUpdated('line4', data.value);
            }}
          />
          <Form.Input
            width={3}
            fluid
            // required
            label="Line 5"
            id="form-input-5"
            value={content.line5}
            onChange={(_, data) => {
              fieldUpdated('line5', data.value);
            }}
          />
          <Form.Input
            width={3}
            fluid
            // required
            label="Line 6"
            id="form-input-6"
            value={content.line6}
            onChange={(_, data) => {
              fieldUpdated('line6', data.value);
            }}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default BallotForm;
