import React from 'react';
import { Button, Radio, Segment, Table } from 'semantic-ui-react';
import Can from '../../../components/Can/Can';
import colors from '../../../components/Styles/Colors';
import EditSupplemental from './EditSupplemental/EditSupplemental';
import useTaxBillSelector from '../TaxBills/useTaxBillSelector';

const SupplementalsTable = ({ park, taxBills, showArchive, user }) => {
  const { selectedIndex, handleSelectionChange } = useTaxBillSelector(
    taxBills,
    showArchive
  );
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });
  const formatter0 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const percent = (value) => (value ? `${value}%` : null);

  const currency = (value) => formatter0.format(value);

  const selectedChanged = (_, event) => {
    handleSelectionChange(event.id);
  };

  const renderButtons = (taxBill, supplemental, index) => (
    <Button.Group size="mini">
      <EditSupplemental taxBill={taxBill} edit={supplemental} index={index} />
    </Button.Group>
  );

  const renderButtonsCan = (taxBill, supplemental, index) => (
    <Can
      role={user.role}
      perform="park/taxes:edit"
      yes={() => renderButtons(taxBill, supplemental, index)}
      no={() => null}
    />
  );

  const renderTotalRow = (taxBill) => {
    const total = taxBill.supplementals.reduce(
      (acc, supplemental) => acc + supplemental.proratedTaxBill,
      0
    );

    return (
      <Table.Row key="total">
        <Table.Cell textAlign="right" colSpan={9}>
          {formatter.format(total)}
        </Table.Cell>
        <Table.Cell />
      </Table.Row>
    );
  };

  const renderSupplmentalRow = (taxBill, supplemental, index) => {
    const space = park.spaceFor(supplemental.space);

    return (
      <Table.Row key={index}>
        <Table.Cell textAlign="center">{space.number}</Table.Cell>
        <Table.Cell textAlign="left">{space.name}</Table.Cell>
        <Table.Cell textAlign="center">{supplemental.date}</Table.Cell>
        <Table.Cell textAlign="center">{supplemental.rollYear}</Table.Cell>
        <Table.Cell textAlign="center">
          {currency(supplemental.newLandValue + supplemental.newImprovements)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(supplemental.prevLandValue + supplemental.prevImprovements)}
        </Table.Cell>
        <Table.Cell textAlign="center">{supplemental.taxRate}</Table.Cell>
        <Table.Cell textAlign="center">
          {supplemental.prorationFactor}
        </Table.Cell>
        <Table.Cell
          textAlign="right"
          negative={supplemental.proratedTaxBill < 0}
        >
          {formatter.format(supplemental.proratedTaxBill)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {renderButtonsCan(taxBill, supplemental, index)}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderSupplementals = (taxBill) => {
    const sorted = taxBill.supplementals.sort((a, b) =>
      a.space > b.space ? 1 : -1
    );

    return (
      <>
        <Table celled striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell textAlign="left">Space</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Year</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                New Assessment
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Previous Assessment
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Tax Rate</Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Proration Factor
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">
                Prorated Tax Bill
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {sorted.map((supplemental, index) =>
              renderSupplmentalRow(taxBill, supplemental, index)
            )}
            {renderTotalRow(taxBill)}
          </Table.Body>
        </Table>
      </>
    );
  };

  const renderNormalRow = (taxBill, index) => (
    <Table.Row key={taxBill.id}>
      <Table.Cell textAlign="center">
        <Radio
          id={taxBill.id}
          checked={selectedIndex === index}
          disabled={false}
          onChange={selectedChanged}
        />
      </Table.Cell>
      <Table.Cell textAlign="center">{taxBill.year}</Table.Cell>
      <Table.Cell textAlign="center">
        {percent(taxBill.assessedValueRate)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {percent(taxBill.landImprovementsRate)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {currency(taxBill.totalAssessedValue)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {currency(taxBill.perPropertyTotalTax)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {currency(taxBill.specialATax)}
      </Table.Cell>
      <Table.Cell textAlign="left">{taxBill.notes}</Table.Cell>
      <Table.Cell textAlign="center">
        {/* {renderButtonsCan(taxBill, index)} */}
      </Table.Cell>
    </Table.Row>
  );

  const renderTaxBillHeader = () => (
    <>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell textAlign="center" width={1} />
          <Table.HeaderCell textAlign="center">Tax Year</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Assessed Value Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Land & Impr Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Total Assessed Value
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Per Prop Value</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Special A Tax</Table.HeaderCell>
          <Table.HeaderCell width={5} textAlign="center">
            Notes
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </>
  );

  const renderTaxBillBody = (taxBill, index) => (
    <React.Fragment key={taxBill.id}>
      <Table.Body>{renderNormalRow(taxBill, index)}</Table.Body>
    </React.Fragment>
  );

  const renderSelectedAssessments = () => {
    const color = colors[selectedIndex % colors.length];
    const index = selectedIndex < taxBills.length ? selectedIndex : 0;
    const taxBill = taxBills[index];

    if (!taxBill) {
      return null;
    }
    if (!taxBill.supplementals) {
      return null;
    }

    return <Segment color={color}>{renderSupplementals(taxBill)}</Segment>;
  };

  return (
    <>
      <Table celled striped>
        {renderTaxBillHeader()}
        {taxBills.map((taxBill, index) => renderTaxBillBody(taxBill, index))}
      </Table>
      {renderSelectedAssessments()}
    </>
  );
};

export default SupplementalsTable;
