import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';
import TaxBill from '../TaxBill';

const useArchives = () => {
  const [taxBillArchives, setTaxBillArchives] = useState();
  const [archivesError, setArchivesError] = useState(null);

  useEffect(() => {
    try {
      const unsubscribe = db
        .collection('taxBills')
        .where('isArchive', '==', true)
        .onSnapshot(
          (snapshot) => {
            const all = snapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              .sort((a, b) => (a.year > b.year ? 1 : -1))
              .map((obj) => new TaxBill(obj));

            setTaxBillArchives(all);
          },
          (error) => {
            setArchivesError(error);
          }
        );

      return () => {
        console.log('cleanup archive taxbills');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setArchivesError(error.message);
    }
  }, []);

  return {
    archivesError,
    taxBillArchives,
  };
};

export default useArchives;
