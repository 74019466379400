import { useState } from 'react';
import { db } from '../../../../services/firebase/firebase';

const useComponentSet = (set, success, failure) => {
  const [vars, setVars] = useState({
    name: set.name || '',
    notes: set.notes || '',
    year: set.year || '',
  });

  const makeVariables = () => ({
    name: vars.name,
    notes: vars.notes,
    year: parseInt(vars.year),
  });

  // Updates a componentSet
  const _doUpdate = async (variables) => {
    console.log('Update ComponentSet variables', variables, set);

    const docRef = db.collection('componentSets').doc(set.id);
    docRef
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        failure(error.message);
      });
  };

  const doUpdate = async () => {
    _doUpdate(makeVariables());
  };

  const canUpdate = () => !vars.name.isEmpty;

  const canCreate = () => !vars.name.isEmpty;

  const makeCreateVariables = (components) => ({
    ...makeVariables(),
    components,
  });

  // Used for create all components when imported from CSV file.
  // Create the array of components in the doc user provided ID string.
  const doCreate = async (components) => {
    const variables = makeCreateVariables(components);
    console.log('Create componentSet', variables);

    const ref = db.collection('componentSets').doc();

    ref
      .set({ ...variables })
      .then(() => {
        success();
      })
      .catch((error) => {
        failure(error);
      });
  };

  const handleChange = (name, value) => {
    setVars({ ...vars, [name]: value });
  };

  return {
    vars,
    doUpdate,
    canUpdate,
    doCreate,
    canCreate,
    handleChange,
  };
};

export default useComponentSet;
