import { useState } from 'react';
import { db } from '../../../../services/firebase/firebase';

// Custom hook for CRUD Budget. Provide optional callbacks for
// success and failure notification.
const useBudget = (budgetOrNull, success = () => {}, failure = () => {}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [loading, setLoading] = useState(false);

  const [budget, setBudget] = useState({
    kind: budgetOrNull.kind || 'ops',
    name: budgetOrNull.name || '',
    note: budgetOrNull.note || '',
    budgetYear: budgetOrNull.budgetYear || '',
    archive: budgetOrNull.archive || false,
  });

  const doCleanup = () => {
    setErrorMessage(null);
    setBudget({});
  };

  // Imported from file, or copied from current budget.
  // NOTE: only set line items at CREATE TIME.
  const [lineItems, setLineItems] = useState([]);

  const makeVariables = () => ({
    budgetYear: parseInt(budget.budgetYear),
    kind: budget.kind,
    name: budget.name,
    note: budget.note,
    archive: false,
  });

  const addLineItems = (items, budgetId = budgetOrNull.id) => {
    console.log('addLineItems', items, budgetId);

    setLoading(true);

    const itemsRef = db
      .collection('budgets')
      .doc(budgetId)
      .collection('lineItems');

    const writeBatch = db.batch();
    items.forEach((item) => {
      console.log('wb', item);
      writeBatch.set(itemsRef.doc(), { ...item });
    });

    writeBatch
      .commit()
      .then((res) => {
        success();
      })
      .catch((error) => {
        failure(error);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const copyLineItems = (currentId) => {
    console.log('copy from ', currentId);

    db.collection('budgets')
      .doc(currentId)
      .collection('lineItems')
      .get()
      .then((snapshot) => {
        const all = snapshot.docs.map((doc) => ({
          id: doc.id,
          ...doc.data(),
        }));
        setLineItems(all);
      });
  };

  const _haveLineItems = () => Array.isArray(lineItems) && lineItems.length > 0;

  const canCreate = () => budget.name && budget.budgetYear && _haveLineItems();

  const canUpdate = () => true;

  const doCreate = async () => {
    const vars = makeVariables();
    console.log('create Budget vars ', vars);

    db.collection('budgets')
      // .doc()
      .add(vars)
      .then((value) => {
        console.log('value', value.id);
        addLineItems(lineItems, value.id);
      })
      .catch((error) => {
        console.log('Error creating Budget', error);
        failure(error.message);
      });
  };

  const update = async (variables) => {
    console.log('update Budget vars ', variables);

    db.collection('budgets')
      .doc(budgetOrNull.id)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating Budget', error);
        setErrorMessage(error.message);
        failure(error.message);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const handleChange = (name, value) => {
    setBudget({ ...budget, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    copyLineItems,
    setLineItems,
    lineItems,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doCleanup,
    loading,
    budget,
  };
};

export default useBudget;
