import React, { useState } from 'react';
import Papa from 'papaparse';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dimmer, Header, Loader, Modal, Form } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useComponentSet from './useComponentSet';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const makeLowerCase = (string) =>
  string.charAt(0).toLowerCase() + string.slice(1);

const parseBoolean = (string) => string.charAt(0).toLowerCase() === 't';

const NamesWithIntegerValues = [
  'remainingUsefulLife',
  'usefulLife',
  'replacementCost',
  'year',
];
const NamesWithBooleanVaues = ['isExcluded'];

const ImportComponentSet = () => {
  const [loading] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);
  const [parsed, setParsed] = useState(null);
  const [open, setOpen] = useState(false);

  const { handleChange, canCreate, doCreate, vars } = useComponentSet(
    {},
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Error creating componentSet', error);
      setErrorMessage(error.message);
    }
  );

  const importFile = async (e) => {
    const file = e.target.files[0];
    console.log('File: ', file);
    Papa.parse(file, {
      header: true,
      complete(event) {
        console.log('complete', event);
        setParsed(event.data);
      },
      transformHeader(value) {
        return makeLowerCase(value.replace(' ', ''));
      },
      transform(value, name) {
        console.log('name, value', name, value);
        if (NamesWithIntegerValues.includes(name)) {
          return parseInt(value);
        }
        if (NamesWithBooleanVaues.includes(name)) {
          return parseBoolean(value);
        }
        return value;
      },
    });
  };

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = async () => {
    await doCreate(parsed);
  };

  const disabled = () => !canCreate();

  const renderForm = () => (
    <div>
      <Form>
        <Form.Group>
          <Form.Input
            width={6}
            fluid
            label="Name"
            id="form-input-name"
            placeholder="Set Name"
            value={vars.name}
            onChange={(_, data) => {
              handleChange('name', data.value);
            }}
          />
          <Form.Input
            width={2}
            fluid
            label="Year"
            id="form-input-year"
            placeholder="Set Year"
            value={vars.year}
            onChange={(_, data) => {
              handleChange('year', data.value);
            }}
          />
          <Form.Input
            type="file"
            label="Import File"
            id="form-input-file"
            onChange={importFile}
          />
        </Form.Group>
      </Form>
    </div>
  );

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <LightTooltip title="Import Components" placement="top-start">
          <Button
            floated="left"
            color="teal"
            basic
            size="small"
            style={{ margin: '6px 20px 10px' }}
          >
            Import Component Set
          </Button>
        </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Import Component Set" />
      <Modal.Content>
        {loading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {errorMessage && <p>Error: {errorMessage}</p>}
        {renderForm()}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

ImportComponentSet.propTypes = {};

export default ImportComponentSet;
