import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';
import TaxBill from '../TaxBill';

const useTaxBills = (archive) => {
  const [taxBills, setTaxBills] = useState();
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    try {
      const unsubscribe = db
        .collection('taxBills')
        .where('isArchive', '==', archive)
        .onSnapshot(
          (snapshot) => {
            const all = snapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              .sort((a, b) => (a.year > b.year ? 1 : -1))
              .map((obj) => new TaxBill(obj));
            console.log('taxbills', all);
            setTaxBills(all);
          },
          (error) => {
            setFetchError(error);
          }
        );

      return () => {
        console.log('cleanup taxbills');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [archive]);

  return {
    fetchError,
    taxBills,
  };
};

export default useTaxBills;
