export const statusOptions = [
  {
    key: 'ACTIVE',
    text: 'ACTIVE',
    value: 'ACTIVE',
  },
  {
    key: 'ARCHIVED',
    text: 'ARCHIVED',
    value: 'ARCHIVED',
  },
];
