import React, { useState, useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Card,
  Container,
  Dimmer,
  Loader,
  Grid,
  Image,
  Header,
} from 'semantic-ui-react';
import { db } from '../../../services/firebase/firebase';
import { ReactComponent as AppStoreBadge } from './AppStoreBadge.svg';
import CreateEntity from '../CreateEntity/CreateEntity';
import './HomeContent.scss';

const HomeContent = (props) => {
  const [entity, setEntity] = useState(null);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);

  useEffect(() => {
    setLoading(true);
    db.collection('paseo-de-palomas')
      .doc('entity')
      .get()
      .then((doc) => {
        console.log(doc.data());
        setEntity(doc.data());
        setLoading(false);
      })
      .catch((error) => {
        console.log('HomeContent', error);
        setError(error.message);
        setLoading(false);
      });
  }, []);

  if (error) {
    // return <Redirect to="/account" />;
    console.log('props', props);
    return <div>error</div>;
  }

  if (!loading && !entity) {
    return <CreateEntity />;
  }

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }
  // if (error) return <div>Error: {error}</div>;

  // if (!entity) {
  //   return <div>Loading...</div>;
  // }

  return (
    <Container>
      <Grid centered columns={3}>
        <Grid.Column />
        <Grid.Column>
          <Card>
            <Image src={entity.imageUrl} wrapped ui={false} />
            <Card.Content>
              <Card.Header>{entity.name}</Card.Header>
              <Card.Meta>{entity.address}</Card.Meta>
              <Card.Description>{entity.description}</Card.Description>
            </Card.Content>
          </Card>
        </Grid.Column>
        <Grid.Column>
          <Header size="huge" />
          <h4>Check out the iPhone app...</h4>
          <AppStoreBadge />
          <br />
          <a href={entity.mobileApp.iPhoneStoreUrl}>{entity.mobileApp.name}</a>
        </Grid.Column>
      </Grid>
    </Container>
  );
};

export default withRouter(HomeContent);
