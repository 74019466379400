import React from 'react';
import { Route, Switch } from 'react-router-dom';
import Privacy from '../../../components/Privacy/Privacy';
import TestPrint from '../Reimburse/Reimburse';
import Birthdays from '../Birthdays/Birthdays';
import Frequents from '../Frequent/Frequents';
import Ballot from '../Ballot/Ballot';
import './OfficeContent.scss';

const OfficeContent = () => (
  <div className="office-content">
    <Switch>
      <Route path="/office/reimburse" component={TestPrint} />
      <Route path="/office/birthdays" component={Birthdays} />
      <Route path="/office/ballot" component={Ballot} />
      <Route path="/office/privacy" component={Privacy} />
      <Route path="/office/frequent" component={Frequents} />
    </Switch>
  </div>
);

export default OfficeContent;
