import React from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Table,
  Button,
  Divider,
  Header,
  Radio,
  Segment,
} from 'semantic-ui-react';
import { UserContext } from '../../../../services/context/UserContext';
import ConfirmDelete from '../../../../components/Confirm/ConfirmDelete';
import Can from '../../../../components/Can/Can';
import CreateBid from './EditBid/CreateBid';
import EditBid from './EditBid/EditBid';
import useModify from './EditBid/useModify';
import useBids from './useBids';

const isArchived = (project) => project && project.status === 'ARCHIVED';

const BidsTable = ({ project, bids, user }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const { deleteBid, selectBid, unselectBid } = useModify(
    project.id,
    () => {},
    (error) => {
      console.log('Error modifying bid', error);
    }
  );

  const currency = (value) => (value ? formatter.format(value) : null);

  const deleteConfirmed = (bidId) => {
    console.log('deleteConfirmed', bidId);
    deleteBid(bidId);
  };

  const updateSelectedBid = (data, event) => {
    console.log('Update selected bid id', event.id);

    const selectedBids = bids.filter((bid) => bid.isSelected);
    if (selectedBids && selectedBids.length > 0) {
      let i;
      for (i = 0; i < selectedBids.length; i += 1) {
        unselectBid(selectedBids[i].id);
      }
    }

    const toSelect = bids.find((bid) => bid.id === event.id);
    if (toSelect) {
      selectBid(toSelect.id);
    }
  };

  const renderSelectedBidEnabled = (bid) => (
    <Radio
      id={bid.id}
      checked={bid.isSelected}
      disabled={isArchived(project)}
      onChange={updateSelectedBid}
    />
  );

  const renderSelectedBidDisabled = (bid) => (
    <Radio id={bid.id} checked={bid.isSelected} disabled onChange={() => {}} />
  );

  const renderSelectedBidCan = (bid) => (
    <Can
      role={user.role}
      perform="job:edit"
      yes={() => renderSelectedBidEnabled(bid)}
      no={() => renderSelectedBidDisabled(bid)}
    />
  );

  const renderAddButton = (proj) => <CreateBid project={proj} />;

  const renderAddBidCan = (proj) =>
    isArchived(proj) ? null : (
      <Can
        role={user.role}
        perform="job:edit"
        yes={() => renderAddButton(proj)}
      />
    );

  const renderButtons = (bid) =>
    isArchived(project) ? null : (
      <Button.Group floated="right" size="mini">
        <EditBid project={project} editing={bid} />
        <ConfirmDelete
          deleteId={bid.id}
          name={`Bid from ${bid.name}`}
          deleteConfirmed={deleteConfirmed}
        />
      </Button.Group>
    );

  const renderButtonsCan = (bid) => (
    <Can role={user.role} perform="job:edit" yes={() => renderButtons(bid)} />
  );

  const renderNormalRow = (bid, index) => (
    <Table.Row key={index}>
      <Table.Cell />
      <Table.Cell textAlign="left">{bid.name}</Table.Cell>
      <Table.Cell textAlign="center">{currency(bid.amount)}</Table.Cell>
      <Table.Cell textAlign="left">{bid.notes}</Table.Cell>
      <Table.Cell textAlign="center">{renderSelectedBidCan(bid)}</Table.Cell>
      <Table.Cell>{renderButtonsCan(bid)}</Table.Cell>
    </Table.Row>
  );

  return (
    <React.Fragment key={project.id}>
      <Segment color="blue">
        <Header as="h4" textAlign="center" block>
          <Divider horizontal>{project.name} Bids</Divider>
        </Header>
        <Table celled striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell textAlign="center" width={1}>
                {renderAddBidCan(project)}
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left" width={3}>
                From
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={2}>
                Amount
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="left">Notes</Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={1}>
                Selected
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center" width={1}>
                Actions
              </Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {project &&
              bids &&
              bids.map((bid, index) => renderNormalRow(bid, index))}
          </Table.Body>
        </Table>
      </Segment>
    </React.Fragment>
  );
};

const Bids = ({ project }) => {
  const { bids, error } = useBids(project.id);

  if (error) {
    return <p>Error: ${error.message}</p>;
  }

  return (
    <UserContext.Consumer>
      {({ user }) => <BidsTable bids={bids} project={project} user={user} />}
    </UserContext.Consumer>
  );
};

BidsTable.propTypes = {
  project: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

Bids.propTypes = {
  project: PropTypes.object.isRequired,
};

export default withRouter(Bids);
