import React from 'react';
import Charts from './Charts';
import useBudgets from '../useBudgets';

const BudgetCharts = () => {
  const { error, budgets } = useBudgets('ops', false);

  if (error) {
    return <div>Error loading budgets: {error}</div>;
  }

  if (!budgets) {
    return <div>Loading..</div>;
  }

  console.log('BudgetCharts.budgets', budgets);

  return (
    <>
      <Charts budgets={budgets} />
    </>
  );
};

export default BudgetCharts;
