import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';

const useBirthdays = () => {
  const [birthdays, setBirthdays] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    try {
      const unsubscribe = db
        .collection('paseo-de-palomas')
        .doc('birthdays')
        .onSnapshot((snapshot) => {
          if (snapshot.data()) {
            const all = snapshot.data().persons.map((person) => ({
              ...person,
              date: person.date.toDate(),
            }));
            console.log('all', all);
            setBirthdays(all);
          }
        });

      return () => {
        console.log('cleanup birthdays');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, []);

  return {
    fetchError,
    birthdays,
  };
};

export default useBirthdays;
