import React from 'react';
import PropTypes from 'prop-types';
import { CSVLink } from 'react-csv';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Header, Modal, Form } from 'semantic-ui-react';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const ExportDialer = ({ exportFile, buttonTitle }) => {
  console.log('file, title', exportFile, buttonTitle);
  const [open, setOpen] = React.useState(false);
  const [fileName, setFileName] = React.useState();

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileName = (e) => {
    setFileName(e.target.value);
  };

  const renderForm = () => (
    <div>
      <Form>
        <Form.Group>
          <Form.Input
            type="text"
            label="Export File Name"
            id="form-input-file"
            onChange={handleFileName}
          />
        </Form.Group>
      </Form>
      {fileName && (
        <CSVLink data={exportFile} filename={fileName}>
          Download DialMyCalls Contacts File
        </CSVLink>
      )}
    </div>
  );

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <LightTooltip
          title="Export DialMyCalls Contacts File"
          placement="top-start"
        >
          <Button>{buttonTitle}</Button>
        </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Export DialMyCalls Contacts File" />
      <Modal.Content>{renderForm()}</Modal.Content>
      <Modal.Actions>
        <Button basic color="teal" onClick={handleClose}>
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

ExportDialer.propTypes = {
  exportFile: PropTypes.array.isRequired,
  buttonTitle: PropTypes.string.isRequired,
};

export default ExportDialer;
