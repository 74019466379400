import React from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Header, Modal, Form } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import Downloader from '../../../../components/Downloader/Downloader';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const columns = [
  'space',
  'date',
  'rollYear',
  'taxRate',
  'prevLandValue',
  'prevImprovements',
  'newLandValue',
  'newImprovements',
  'prorationFactor',
  'proratedTaxBill',
];

const ExportSupplementals = ({ supplementals, taxRollYear }) => {
  const [open, setOpen] = React.useState(false);
  const [fileName, setFileName] = React.useState();

  const makeVars = (supplemental) => {
    const vars = {
      space: supplemental.space,
      date: supplemental.date,
      rollYear: supplemental.rollYear,
      taxRate: supplemental.taxRate,
      prevLandValue: supplemental.prevLandValue,
      prevImprovements: supplemental.prevImprovements,
      newLandValue: supplemental.newLandValue,
      newImprovements: supplemental.newImprovements,
      prorationFactor: supplemental.prorationFactor,
      proratedTaxBill: supplemental.proratedTaxBill,
    };
    return vars;
  };

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileName = (e) => {
    setFileName(e.target.value);
  };

  const exportFile = () =>
    supplementals.map((supplemental) => makeVars(supplemental));

  const disabled = () => false;

  const renderForm = () => (
    <div>
      <Form>
        <Form.Group>
          <Form.Input
            type="text"
            label={`Export file name for ${taxRollYear}`}
            id="form-input-file"
            onChange={handleFileName}
          />
        </Form.Group>
      </Form>
      {fileName && (
        <Downloader
          title="Download Supplementals"
          filename={fileName}
          columns={columns}
          datas={exportFile()}
        />
      )}
    </div>
  );

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <LightTooltip title="Export Supplementals" placement="top-start">
          <Button color="teal" basic style={{ margin: '6px 20px 10px' }}>
            Export Supplementals
          </Button>
        </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Export Supplementals" />
      <Modal.Content>{renderForm()}</Modal.Content>
      <ModalActions
        onClose={handleClose}
        onSave={exportFile}
        disabled={disabled}
      />
    </Modal>
  );
};

export default ExportSupplementals;
