import React from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';
import firebase from 'firebase/app';
import { handleLogout } from '../../../services/storage/Storage';

const Logout = ({ error, history }) => {
  const _handleSignout = () => {
    firebase
      .auth()
      .signOut()
      .then(() => {
        handleLogout();
        history.push('/account');
      })
      .catch((err) => {
        console.log('Signout error: ', err);
      });
  };

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src="Pigeon.png" avatar />
          Sign out of your account
        </Header>
        <Form size="large">
          <Segment stacked>
            {error ? (
              <Message visible error header="Error" content={error} />
            ) : null}
            <Button
              type="button"
              color="teal"
              fluid
              size="large"
              onClick={() => {
                _handleSignout();
              }}
            >
              Sign Out
            </Button>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default withRouter(Logout);
