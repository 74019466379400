import React, { useState, useEffect } from 'react';
import { Header, Segment, Table } from 'semantic-ui-react';

const ExclusionsTable = (props) => {
  const [items, setItems] = useState();

  useEffect(() => {
    setItems(props.study.excludedComponents());
  }, [props]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const renderNormalRow = (item) => (
    <Table.Row key={item.name}>
      <Table.Cell>{item.category}</Table.Cell>
      <Table.Cell textAlign="center">{item.pid}</Table.Cell>
      <Table.Cell>{item.name}</Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(item.replacementCost)}
      </Table.Cell>
    </Table.Row>
  );

  return (
    <>
      <Segment.Group>
        <Segment color="red">
          <Header as="h4" textAlign="center" block>
            Excluded Components
          </Header>
        </Segment>
        <Segment.Group color="blue">
          <Table celled compact striped>
            <Table.Header fullWidth>
              <Table.Row>
                <Table.HeaderCell width={1}>Category</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  ID
                </Table.HeaderCell>
                <Table.HeaderCell width={3}>Name</Table.HeaderCell>
                <Table.HeaderCell width={1} textAlign="center">
                  Replacement Cost
                </Table.HeaderCell>
              </Table.Row>
            </Table.Header>
            <Table.Body>
              {items &&
                items.map((item, index) => renderNormalRow(item, index))}
            </Table.Body>
          </Table>
        </Segment.Group>
      </Segment.Group>
    </>
  );
};

export default ExclusionsTable;
