// Defines rules for role based access control

const rules = {
  RESIDENT: {
    static: ['finance/archived:visit', 'finance/budgets:visit'],
  },
  BOARD: {
    static: [
      'coaches/listings:visit',
      'finance/archived:visit',
      'finance/budgets:visit',
      'job:create',
      'job:edit',
      'listing:edit',
      'office/menu:view',
      'office:admin',
      'park/taxes:visit',
      'study:select',
    ],
    dynamic: {},
  },
  OFFICE: {
    static: [
      'coaches/listings:visit',
      'finance/archived:visit',
      'finance/budgets:visit',
      'job:create',
      'job:edit',
      'listing:edit',
      'office/menu:view',
      'office:admin',
      'park:edit',
      'park/taxes:visit',
      'study:select',
      'studyComp:edit',
      'studyComp:exclude',
    ],
  },
  ADMIN: {
    static: [
      'admin/users:visit',
      'budget:create',
      'budget:edit',
      'component:create',
      'component:edit',
      'finance/archived:visit',
      'finance/budgets:visit',
      'job:create',
      'job:edit',
      'listing:edit',
      'office/menu:view',
      'office:admin',
      'park:edit',
      'park/taxes:edit',
      'park/taxes:visit',
      'study:create',
      'study:edit',
      'study:select',
      'studyComp:edit',
      'studyComp:exclude',
    ],
  },
};

export default rules;
