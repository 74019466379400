// Create all data for charts, tables

function computeCompsData(studiesWithYearlyExpenses) {
  return studiesWithYearlyExpenses.map(
    (study) => (study.expenses ? study.expenses.componentsByYear : []),
    []
  );
}

function mergeChartData(source1, source2, source3, dataArray) {
  for (let i = 0; i < 31; i += 1) {
    const data1 = source1 && source1.length > i ? source1[i] : null;
    const data2 = source2 && source2.length > i ? source2[i] : null;
    const data3 = source3 && source3.length > i ? source3[i] : null;
    const data = {};

    if (data1) {
      data.name = data1.name;
      data.ra1 = data1.ra;
      data.pf1 = data1.pf;
      data.int1 = data1.int;
      data.tib1 = data1.tib;
    }
    if (data2) {
      data.ra2 = data2.ra;
      data.pf2 = data2.pf;
      data.int2 = data2.int;
      data.tib2 = data2.tib;
    }
    if (data3) {
      data.ra3 = data3.ra;
      data.pf3 = data3.pf;
      data.int3 = data3.int;
      data.tib3 = data3.tib;
    }
    dataArray[i] = data;
  }
}

export function combineChartData(studies) {
  const dataArray = [];
  const data1 = studies.length > 0 ? studies[0].graphData : null;
  const data2 = studies.length > 1 ? studies[1].graphData : null;
  const data3 = studies.length > 2 ? studies[2].graphData : null;
  mergeChartData(data1, data2, data3, dataArray);
  return dataArray;
}

// const fudgeFactors = [
//   0.9495535,
//   0.935472,
//   0.9405,
//   1.0,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95,
//   0.95
// ];

// Returns the annual reserve allocation computed by taking the monthly
// amount from the array times 12. Since our array has only 30 slots and
// the year index ends at 30, we need to figure out what the number should
// be using some other logic.
function annualReserveAllocation(study, yearIndex) {
  if (yearIndex < study.annualAllocationAmounts.length) {
    const allocations = study.annualAllocationAmounts[yearIndex] * 12;
    return yearIndex === 0 ? study.assessment + allocations : allocations;
  }
  // Protect the array access just in case things are messed up.
  if (yearIndex < 2) {
    return 0;
  }
  // Is the monthly the same for all year indexes, like [29] & [30] ?
  // This is normally the case when the amount is a constant
  if (
    study.annualAllocationAmounts[yearIndex - 1] ===
    study.annualAllocationAmounts[yearIndex - 2]
  ) {
    // Then it's safe to use -1
    return study.annualAllocationAmounts[yearIndex - 1] * 12;
  }
  // Else we should have a % increase
  if (study.annualAllocationIncrease > 0) {
    return Math.round(
      study.annualAllocationAmounts[yearIndex - 1] *
        (1 + study.annualAllocationIncrease / 100.0) *
        12
    );
  }
  // Finally we don't know what to do so punt
  return 0;
}

function computeInterest(study, startBalance, expenses, yearIndex) {
  const ara = annualReserveAllocation(study, yearIndex);
  const eb = startBalance + ara - expenses;
  // return (startBalance + eb) / 2;
  return Math.round(eb * study.interestRate); // * fudgeFactors[yearIndex]);
}

function computeSummaryData(study) {
  if (!study) {
    return [];
  }
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const dataArray = [];
  let previousBalance = study.startingReserveBalance;

  for (let i = 0; i < 31; i += 1) {
    const expense = study.expenses.expensesByYear[i];

    const interest = computeInterest(study, previousBalance, expense, i);

    // const interest = Math.round(averageBalance * study.interestRate * 0.95);
    // const fudgedInterest = Math.round(interest);
    // const interest = computeInterest(averageBalance, study.interestRate, i);
    const balance =
      annualReserveAllocation(study, i) + previousBalance - expense + interest;
    // const tib = computeStudyIdealBalance(study, i);
    const tib = study.idealBalancesByYear[i];

    const bpf = `${Math.round((previousBalance / tib) * 100)}%`;
    const ra = formatter.format(annualReserveAllocation(study, i));
    const int = formatter.format(interest);
    const eb = formatter.format(balance);
    const exp = formatter.format(expense);
    const bffb = formatter.format(tib);
    const brb = formatter.format(previousBalance);

    dataArray.push({
      name: String(study.studyYear + i),
      ra,
      int,
      eb,
      exp,
      bffb,
      brb,
      bpf,
    });
    previousBalance = balance;
  }
  // console.log("Summary dataArray ", dataArray);
  return dataArray;
}

// function computeComponentIdealBalance(comp) {
//   const age = comp.usefulLife - comp.remainingUsefulLife;
//   if (age >= 0) {
//     const balance = Math.round(
//       (comp.replacementCost * age) / comp.usefulLife
//     );
//     return balance;
//   }
//   // useful life < remaining useful life => repl cost / remaining life + 1
//   const bal = comp.replacementCost / (comp.remainingUsefulLife + 1);
//   return Math.round(bal);
// }

// function computeTotalReplacementCost(comps) {
//   const filtered = comps.filter(comp => !comp.isExcluded);
//   const total = filtered.reduce((acc, obj) => {
//     return acc + obj.replacementCost;
//   }, 0);
//   console.log("Total Repl Cost ", Math.round(total));
//   return Math.round(total);
// }

// function computeTotalIdealBalance(comps) {
//   const filtered = comps.filter(comp => !comp.isExcluded);
//   const total = Math.round(
//     filtered.reduce((acc, val) => {
//       return acc + computeComponentIdealBalance(val);
//     }, 0)
//   );
//   console.log("Total Ideal Balance ", total);
//   return total;
// }

// function makeIdealBalanceArray(comps) {
//   const filtered = comps.filter(comp => !comp.isExcluded);
//   const total = computeTotalIdealBalance(comps);
//   const ibs = filtered.map(comp => {
//     const ib = computeComponentIdealBalance(comp);
//     const pib = (ib / total) * 100.0;
//     const pibs = pib.toFixed(2);
//     var sig;
//     if (comp.usefulLife > comp.remainingUsefulLife) {
//       sig = Math.round(comp.replacementCost / comp.usefulLife);
//     } else {
//       sig = Math.round(comp.replacementCost / (comp.remainingUsefulLife + 1));
//     }
//     return { id: comp.id, ib, pibs, sig };
//   });
//   console.log("Ideal balances ", ibs);
//   return ibs;
// }

// function printCompsInfo(comps, categoryName) {
//   const filtered = comps.filter(comp => {
//     return comp.category === categoryName;
//   });
//   console.log("For ", categoryName);
//   computeTotalIdealBalance(filtered);
//   computeTotalReplacementCost(filtered);
// }

function computeStudyIdealBalance(study, yearIndex) {
  // console.log("worker.computeStudyIdealBalance");
  const match1 = 0;
  const match2 = 1;

  if (!study.components) {
    return 0;
  }
  // const filtered = study.components.filter(comp => !comp.isExcluded);
  const rc = study.components.reduce((acc, comp) => {
    if (comp.isExcluded) {
      return acc;
    }
    const age = Math.abs(comp.usefulLife - comp.remainingUsefulLife);

    // if (comp.usefulLife === 0) {
    //   // Special case 1 time only expense
    //   if (yearIndex > age) {
    //     return acc; // Done
    //   }
    //   if (yearIndex === 0) {
    //     return acc + 150000;
    //   } else if (yearIndex === 1) {
    //     return acc + 200000;
    //   } else if (yearIndex === 2) {
    //     return acc + 300000;
    //   } else {
    //     return acc + 600000;
    //   }
    //   // const rul = comp.rul + 1;
    //   // const ib = (yearIndex * comp.rc) / rul + comp.rc / rul;
    //   // const total = acc + ib;
    //   // console.log("One Time ", ib, total);
    //   // return total;
    // } else if (age === 0) {
    if (age === 0) {
      // See #216 10/10/0/14700
      const ib =
        ((yearIndex % comp.usefulLife) / comp.usefulLife) *
        comp.replacementCost;
      const t = acc + ib;
      // if (yearIndex == match1 || yearIndex == match2) {
      //   console.log("ib ", comp, yearIndex, ib, total);
      // }
      return t;
    }
    // Typical case
    // debugger;
    const m = (age + yearIndex) % comp.usefulLife;
    // const ib = (comp.rc * m) / comp.ul;
    const tib =
      m === 0
        ? comp.replacementCost
        : (comp.replacementCost * m) / comp.usefulLife;
    const total = acc + tib;
    if (yearIndex === match1 || yearIndex === match2) {
      // console.log("m, tib ", comp, m, tib, total);
    }
    return total;
  }, 0);
  const fudge = rc + (yearIndex >= 4 ? 60 : 0);
  const withInflation = fudge * (1 + study.inflationRate) ** yearIndex;
  // if (yearIndex === match1 || yearIndex === match2) {
  //   console.log("cIB ", Math.round(rc), Math.round(withInflation));
  // }
  return Math.round(withInflation);
}

// Make array of studies, each a list of yearly expenses
// e.g. [[Study 1 expenses by year], [Study 2 expenses by year]...]
// function makeStudiesExpensesArray(studies) {
//   const studiesExpenses = [];
//   for (const study of studies) {
//     const expenses = [];
//     for (var year = 0; year < 31; year++) {
//       // const expense = computeStudyYearExpense(study, year);
//       const components = computeStudyYearExpenseComponents(study, year);
//       // console.log("Components for year ", year, components);
//       // Total for all components this year
//       const total = components.reduce((acc, comp) => {
//         return acc + comp.replacementCost;
//       }, 0);
//       // Adjust for inflation
//       const withInflation = total * Math.pow(1 + study.inflationRate, year);
//       const expense = Math.round(withInflation);
//       expenses.push(expense);
//     }
//     studiesExpenses.push(expenses);
//   }
//   return studiesExpenses;
// }

// Returns the list of Study with an array of balances added
function addStudyBalances(studies) {
  return studies.map((study) => {
    const idealBalancesByYear = [];
    for (let year = 0; year < 31; year += 1) {
      const balance = computeStudyIdealBalance(study, year);
      idealBalancesByYear.push(balance);
    }
    return { ...study, idealBalancesByYear };
  });
}

// Make array of studies, each a list of ideal balances
// e.g. [[Study 1 balances], [Study 2 balances]...]
// function makeStudiesIdealBalancesArray(studies) {
//   const studiesBalances = [];
//   for (const study of studies) {
//     const balances = [];
//     for (var year = 0; year < 31; year++) {
//       const balance = computeStudyIdealBalance(study, year);
//       balances.push(balance);
//     }
//     studiesBalances.push(balances);
//   }
//   return studiesBalances;
// }

// Compute the expenses for components for given year index
// function computeStudyYearExpense(study, yearIndex) {
//   if (!study.components) {
//     return 0;
//   }
//   // const filtered = study.components.filter(comp => !comp.isExcluded);
//   const exp = study.components.reduce((acc, comp) => {
//     if (comp.isExcluded) {
//       return acc;
//     }
//     const age = Math.abs(comp.usefulLife - comp.remainingUsefulLife);
//     if (comp.usefulLife === 0) {
//       // Special case for one time upgrade
//       if (age === yearIndex) {
//         return acc + comp.replacementCost;
//       }
//       return acc + 0;
//     } else if (
//       comp.usefulLife === comp.remainingUsefulLife &&
//       comp.usefulLife > 0
//     ) {
//       // debugger;
//       // Case where AGE == 0
//       const ul = Math.floor(comp.usefulLife);
//       const year = Math.floor(yearIndex % ul);
//       const sum =
//         acc + (yearIndex > 0 && year === 0 ? comp.replacementCost : 0);
//       // if (yearIndex > 0 && year === 30) {
//       //   console.log("A Comp repl in year ", yearIndex, comp);
//       // }
//       return sum;
//     } else {
//       // Normal case with repeating RUL. Add the RC if
//       // the comp is up for replacement this index year.
//       const year = (age + yearIndex) % comp.usefulLife;
//       const sum = acc + (year === 0 ? comp.replacementCost : 0);
//       // if (year === 0 && yearIndex === 30) {
//       //   // This component is replaced this year
//       //   console.log(" B Comp repl in year ", yearIndex, comp);
//       // }
//       return sum;
//     }
//   }, 0);

//   const withInflation = exp * Math.pow(1 + study.inflationRate, yearIndex);
//   // console.log("expense withInflation ", withInflation, yearIndex);
//   return Math.round(withInflation);
// }

function expenseComp(comp, inflationRate, year) {
  const copy = { ...comp };
  const withInflation = comp.replacementCost * (1 + inflationRate) ** year;
  copy.inflatedCost = Math.round(withInflation);
  return copy;
}

function computeStudyYearExpenseComponents(study, yearIndex) {
  if (!study.components) {
    return [];
  }
  const { inflationRate } = study;
  const components = study.components.reduce((acc, comp) => {
    if (comp.isExcluded) {
      return acc;
    }
    const age = Math.abs(comp.usefulLife - comp.remainingUsefulLife);
    if (comp.usefulLife === 0) {
      // Special case for one time upgrade
      if (age === yearIndex) {
        acc.push(expenseComp(comp, inflationRate, yearIndex));
      }
      return acc;
    }
    if (comp.usefulLife === comp.remainingUsefulLife && comp.usefulLife > 0) {
      // debugger;
      // Case where AGE == 0
      const ul = Math.floor(comp.usefulLife);
      const yr = Math.floor(yearIndex % ul);
      if (yearIndex > 0 && yr === 0) {
        acc.push(expenseComp(comp, inflationRate, yearIndex));
      }
      return acc;
    }
    // Normal case with repeating RUL. Add the RC if
    // the comp is up for replacement this index year.
    const year = (age + yearIndex) % comp.usefulLife;
    if (year === 0) {
      acc.push(expenseComp(comp, inflationRate, yearIndex));
    }
    return acc;
  }, []);

  return components;
}

// Returns {components: [comps by year], expenses: [expensesByYear] }
// Returns study.expenses := {componentsByYear, expensesByYear}
function makeStudyYearlyExpenses(study) {
  const componentsByYear = [];
  const expensesByYear = [];
  for (let year = 0; year < 31; year += 1) {
    // const expense = computeStudyYearExpense(study, year);
    const comps = computeStudyYearExpenseComponents(study, year);
    componentsByYear.push(comps);
    // console.log("Components for year ", year, comps);
    // Total for all components this year
    const total = comps.reduce((acc, comp) => acc + comp.replacementCost, 0);
    // Adjust for inflation
    const withInflation = total * (1 + study.inflationRate) ** year;
    const expense = Math.round(withInflation);
    expensesByYear.push(expense);
  }
  return { ...study, expenses: { componentsByYear, expensesByYear } };
}

function addGraphData(study, dataArray) {
  const expenses = study.expenses.expensesByYear;
  const balances = study.idealBalancesByYear;

  let previousBalance = study.startingReserveBalance;

  for (let i = 0; i < 31; i += 1) {
    const expense = expenses[i];

    const interest = computeInterest(study, previousBalance, expense, i);
    const balance =
      annualReserveAllocation(study, i) + previousBalance - expense;
    // const interest = computeInterest(averageBalance, study.interestRate, i);
    // const interest = Math.round(averageBalance * study.interestRate);
    // const fudgedInterest = i === 0 ? interest * 0.93 : interest;

    const tib = balances[i];
    const pf = Math.round((previousBalance / tib) * 100);

    const data = dataArray[i] || {};
    data.name = String(study.studyYear + i);
    // RA
    if (!data.ra1) {
      data.ra1 = previousBalance;
    } else if (!data.ra2) {
      data.ra2 = previousBalance;
    } else {
      data.ra3 = previousBalance;
    }
    // INT
    if (!data.int1) {
      data.int1 = interest;
    } else if (!data.int2) {
      data.int2 = interest;
    } else {
      data.int3 = interest;
    }
    // PF
    if (!data.pf1) {
      data.pf1 = pf;
    } else if (!data.pf2) {
      data.pf2 = pf;
    } else {
      data.pf3 = pf;
    }
    // TIB
    if (!data.tib1) {
      data.tib1 = tib;
    } else if (!data.tib2) {
      data.tib2 = tib;
    } else {
      data.tib3 = tib;
    }
    dataArray[i] = data;
    previousBalance = balance;
  }
  // console.log("graphworker.addStudyData.dataArray ", dataArray);
}
// Returns [study1 {components, expenses},
//          study2 {compoments, expenses}]
// Returns [study with added expenses object]
// expenses object is expenses: {components, expenses}
function makeStudiesYearlyExpenses(studies) {
  return studies.map((study) => makeStudyYearlyExpenses(study));
}

function computeGraphData(studies) {
  const dataArray = [];
  for (let i = 0; i < studies.length; i += 1) {
    addGraphData(studies[i], dataArray);
  }
  return dataArray;
}

function computeTableData(studiesWithBalances) {
  return studiesWithBalances.map((study) => computeSummaryData(study), []);
}

function createChartData(studies) {
  const studiesWithYearlyExpenses = makeStudiesYearlyExpenses(studies);
  // console.log("Studies With Yearly Expenses ", studiesWithYearlyExpenses);
  const studiesWithIdealBalances = addStudyBalances(studiesWithYearlyExpenses);
  // console.log("Studies With Balances ", studiesWithIdealBalances);
  const chartData = computeGraphData(studiesWithIdealBalances);
  const tableData = computeTableData(studiesWithIdealBalances);
  const compsData = computeCompsData(studiesWithYearlyExpenses);

  const data = {
    chartData,
    tableData,
    compsData,
  };
  console.log('chart data ', data);
  return data;
}

export { createChartData };
