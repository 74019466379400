import { useState } from 'react';
import { db } from '../../../../services/firebase/firebase';

// Custom hook for updating impounds. Provide callbacks for
// success and failure notification.
const useImpounds = (space, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();
  const [impounds, setImpounds] = useState({
    coach: space.impounds?.coach || 0,
    amount: space.impounds?.amount || 0,
    date: space.impounds?.date || '',
  });

  // Returns variables for edit
  const makeVariables = () => ({
    coach: impounds.coach,
    amount: parseFloat(impounds.amount),
    date: impounds.date,
  });

  const canUpdate = () => true;

  const update = async (variables) => {
    console.log('update Impound vars ', variables);

    const ref = db.collection('spaces').doc(space.id);
    ref
      .update({
        impounds: variables,
      })
      .then(function () {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.error('Error updating impounds: ', error);
        failure(error);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const handleChange = (name, value) => {
    setImpounds({ ...impounds, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    canUpdate,
    doUpdate,
    impounds,
  };
};

export default useImpounds;
