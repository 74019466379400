import React, { useState } from 'react';
import {
  Form,
  Button,
  Container,
  Divider,
  Dropdown,
  Grid,
  Header,
  Segment,
} from 'semantic-ui-react';
import {
  parseLocaleNumber,
  digits,
} from '../../../../services/number/formatting';

const EditForm = ({ study, annualAmounts, fieldUpdated, statusOptions }) => {
  const [lastIndex, setLastIndex] = useState(0);

  const monthlyDmfAllocation = () =>
    study.assessment ? Math.floor(study.assessment / 12) : 0;

  const applyDmfAllocation = () => {
    const monthlyDmf = monthlyDmfAllocation();
    const updated = study.annualAmounts.map((value) => value + monthlyDmf);
    fieldUpdated('annualAmounts', updated);
  };

  // Tracks data input to year fields
  const handleAnnualAmountsChange = (value, index) => {
    const current = [...annualAmounts];
    current[index] = parseLocaleNumber(digits(value));
    setLastIndex(index);

    console.log('handleAnnualAmountsChange', index, current[index]);
    fieldUpdated('annualAmounts', current);
  };

  const handleCopyAll = () => {
    const lastValue = annualAmounts[lastIndex];
    console.log('handleCopyAll', lastIndex, lastValue);
    const current = [...annualAmounts];
    // eslint-disable-next-line no-plusplus
    for (let i = lastIndex; i < current.length; i++) {
      current[i] = lastValue;
    }
    fieldUpdated('annualAmounts', current);
  };

  // Must have percent increase and start amount
  const fillPercentIncrease = () => {
    if (study.annualIncrease && annualAmounts[0]) {
      const percentIncrease = 1 + study.annualIncrease / 100;
      const newValues = [];
      let previous = annualAmounts[0];
      newValues.push(previous);
      for (let i = 1; i < 30; i += 1) {
        const newValue = Math.floor(previous * percentIncrease);
        newValues.push(newValue);
        previous = newValue;
      }
      fieldUpdated('annualAmounts', newValues);
    }
  };

  const fillDisabled = () => false;

  const copyDisabled = () => false;

  const renderYearInput = (year) => (
    <Form.Input
      width={2}
      value={annualAmounts[year]}
      label={`Year ${year + 1}`}
      id={`year-${year}`}
      onChange={(_, data) => {
        const index = data.id.split('-').pop();
        handleAnnualAmountsChange(data.value, index);
      }}
    />
  );

  const renderApplyButton = () => (
    <Button
      disabled={!study.assessment}
      color="teal"
      onClick={() => {
        applyDmfAllocation();
      }}
    >
      Apply
    </Button>
  );
  const renderCopyButton = () => (
    <Button
      // basic
      disabled={fillDisabled()}
      color="teal"
      onClick={(_, data) => {
        fillPercentIncrease(data);
      }}
    >
      Fill with % increase
    </Button>
  );

  const renderFillButton = () => (
    <Button
      // basic
      disabled={copyDisabled()}
      color="teal"
      onClick={(_, data) => {
        handleCopyAll(data);
      }}
    >
      Copy to remaining years
    </Button>
  );

  const renderAllocationButtons = () => (
    <Button.Group>
      {renderCopyButton()}
      <Button.Or />
      {renderFillButton()}
    </Button.Group>
  );

  return (
    <Container>
      <Grid>
        <Grid.Row>
          <Grid.Column width={16}>
            <Form>
              <Form.Group>
                <Form.Input
                  width={4}
                  fluid
                  label="Name"
                  id="form-input-name"
                  value={study.name}
                  onChange={(_, data) => {
                    fieldUpdated('name', data.value);
                  }}
                />
                <Form.Input
                  width={2}
                  fluid
                  label="Study Year"
                  id="form-input-year"
                  value={study.studyYear}
                  onChange={(_, data) => {
                    fieldUpdated('studyYear', digits(data.value));
                  }}
                />
                {statusOptions && (
                  <Form.Input label="Components Set">
                    <Dropdown
                      placeholder="Select Components"
                      basic
                      selection
                      name="comps"
                      options={statusOptions}
                      value={study.comps}
                      onChange={(_, data) =>
                        fieldUpdated(data.name, data.value)
                      }
                    />
                  </Form.Input>
                )}
                {/* </Form.Group>
              <Form.Group> */}
                <Form.Input
                  width={2}
                  fluid
                  label="Start Balance"
                  id="form-input-sb"
                  value={study.startBalance}
                  onChange={(_, data) => {
                    fieldUpdated('startBalance', digits(data.value));
                  }}
                />
                <Form.Input
                  width={2}
                  fluid
                  label="Inflation Rate"
                  id="form-input-ir"
                  value={study.inflationRate}
                  onChange={(_, data) => {
                    fieldUpdated('inflationRate', data.value);
                  }}
                />
                <Form.Input
                  width={2}
                  fluid
                  label="Interest Rate"
                  id="form-input-intr"
                  value={study.interestRate}
                  onChange={(_, data) => {
                    fieldUpdated('interestRate', data.value);
                  }}
                />
                {/* <Form.Input
                  width={2}
                  value={study.assessment}
                  label="Assessment"
                  id="assessment"
                  onChange={(_, data) => {
                    fieldUpdated('assessment', data.value);
                  }}
                /> */}
              </Form.Group>
              <Form.Group>
                <Form.Input
                  width={16}
                  fluid
                  label="Notes"
                  id="form-input-notes"
                  value={study.notes}
                  onChange={(_, data) => {
                    fieldUpdated('notes', data.value);
                  }}
                />
              </Form.Group>
              <Divider />
              <Header as="h3" style={{ textAlign: 'center' }}>
                Annual Reserve Allocations
              </Header>
              <Form.Group widths="equal">
                {renderAllocationButtons()}
                <Form.Input
                  width={3}
                  value={study.annualIncrease}
                  label="Annual % Increase"
                  id="percent-increase"
                  onChange={(_, data) => {
                    fieldUpdated('annualIncrease', data.value);
                  }}
                />
                <Form.Input
                  width={3}
                  value={study.assessment}
                  label="Annual DMF Allocation"
                  id="dmf-allocation"
                  onChange={(_, data) => {
                    fieldUpdated('assessment', data.value);
                  }}
                />
                {renderApplyButton()}
              </Form.Group>
              <Segment>
                <Form.Group widths="equal">
                  {renderYearInput(0)}
                  {renderYearInput(1)}
                  {renderYearInput(2)}
                  {renderYearInput(3)}
                  {renderYearInput(4)}
                  {renderYearInput(5)}
                </Form.Group>
                <Form.Group widths="equal">
                  {renderYearInput(6)}
                  {renderYearInput(7)}
                  {renderYearInput(8)}
                  {renderYearInput(9)}
                  {renderYearInput(10)}
                  {renderYearInput(11)}
                </Form.Group>
                <Form.Group widths="equal">
                  {renderYearInput(12)}
                  {renderYearInput(13)}
                  {renderYearInput(14)}
                  {renderYearInput(15)}
                  {renderYearInput(16)}
                  {renderYearInput(17)}
                </Form.Group>
                <Form.Group widths="equal">
                  {renderYearInput(18)}
                  {renderYearInput(19)}
                  {renderYearInput(20)}
                  {renderYearInput(21)}
                  {renderYearInput(22)}
                  {renderYearInput(23)}
                </Form.Group>
                <Form.Group widths="equal">
                  {renderYearInput(24)}
                  {renderYearInput(25)}
                  {renderYearInput(26)}
                  {renderYearInput(27)}
                  {renderYearInput(28)}
                  {renderYearInput(29)}
                </Form.Group>
              </Segment>
            </Form>
          </Grid.Column>
        </Grid.Row>
      </Grid>
    </Container>
  );
};

export default EditForm;
