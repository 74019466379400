import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import {
  Button,
  Divider,
  Header,
  Radio,
  Segment,
  Table,
} from 'semantic-ui-react';
import PopupButton from '../../../components/PopupButton/PopupButton';
import { db } from '../../../services/firebase/firebase';
import EditBudget from './EditBudget/EditBudget';
import CreateBudget from './EditBudget/CreateBudget';
import CategoryTable from './Category/CategoryTable';

const BudgetsTable = ({ user, budgets, canCreate = true, canEdit = true }) => {
  const [current, setCurrent] = useState();
  const [selected, setSelected] = useState();

  useEffect(() => {
    const cur = budgets.find((budget) => budget.current);
    setCurrent(cur);
    setSelected(cur ? cur.id : null);
  }, [budgets]);

  const handleArchive = async (event, data) => {
    console.log('archive', data.id);
    db.collection('budgets')
      .doc(data.id)
      .update({ archive: true })
      .then(() => {
        console.log(`Archived ${data.id}`);
      });
  };

  const handleCheckbox = (event) => {
    setSelected(event.id);
  };

  const renderSelectedEnabled = (budget) => (
    <Radio
      id={budget.id}
      disabled={false}
      checked={selected === budget.id}
      onChange={(_, data) => {
        handleCheckbox(data);
      }}
    />
  );

  const renderButtons = (budget) => (
    <>
      <EditBudget current={current} editBudget={budget} />
      <PopupButton
        title="Archive Budget"
        icon="file archive outline"
        id={budget.id}
        onClick={handleArchive}
      />
    </>
  );

  const renderNormalRow = (budget) => (
    <Table.Row key={budget.id}>
      <Table.Cell />
      <Table.Cell textAlign="left">{budget.name}</Table.Cell>
      <Table.Cell textAlign="center">{budget.budgetYear}</Table.Cell>
      <Table.Cell textAlign="left">{budget.note}</Table.Cell>
      <Table.Cell textAlign="center">
        {renderSelectedEnabled(budget)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Button.Group basic size="mini">
          {canEdit && renderButtons(budget)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  const renderLineItems = (budget) => (
    <React.Fragment key={budget.name}>
      {selected === budget.id && (
        <>
          <Segment color="teal">
            <Header as="h3" textAlign="center" block>
              <Divider horizontal>Budget Categories</Divider>
            </Header>
            <CategoryTable budget={budget} user={user} />
          </Segment>
        </>
      )}
    </React.Fragment>
  );

  const renderTable = () => (
    <>
      <Table celled striped stackable>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="center" width={1}>
              {canCreate && <CreateBudget current={current} user={user} />}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left" width={3}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={1}>
              Year
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={6}>
              Notes
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={1}>
              Line Items
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={1}>
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {budgets &&
            budgets.map((budget, index) => renderNormalRow(budget, index))}
        </Table.Body>
      </Table>
      {budgets &&
        budgets.map((budget, index) => renderLineItems(budget, index))}
    </>
  );

  return (
    <>
      <Segment color="teal">{renderTable()}</Segment>
    </>
  );
};

BudgetsTable.propTypes = {
  budgets: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default BudgetsTable;
