import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Segment, Table } from 'semantic-ui-react';
import { formatDateString } from '../../../../services/dates/formatting';
import ConfirmDelete from '../../../../components/Confirm/ConfirmDelete';
import Can from '../../../../components/Can/Can';
import useAllocation from './EditActivity/useAllocation';
import CreateAllocation from './EditActivity/CreateAllocation';
import EditAllocation from './EditActivity/EditAllocation';
import AllocationsFooter from './AllocationsFooter';
import '../Studies.scss';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const AllocationsTable = ({ study, activities, user }) => {
  const { doDelete } = useAllocation(
    {},
    study,
    () => {},
    (error) => {
      console.log('Failure', error);
    }
  );

  const deleteConfirmed = async (allocation) => {
    console.log('deleteConfirmed', allocation);
    doDelete(allocation);
  };

  const renderButtons = (allocation) => (
    <>
      <EditAllocation editAlloc={allocation} study={study} />
      <ConfirmDelete
        deleteId={allocation}
        name={`Allocation item ${formatDateString(allocation.date)}`}
        deleteConfirmed={deleteConfirmed}
      />
    </>
  );

  const renderButtonsCan = (allocation) => (
    <Can
      role={user.role}
      perform="study:edit"
      yes={() => renderButtons(allocation)}
    />
  );

  const renderAddAllocation = () => <CreateAllocation study={study} />;

  const renderActivityRow = (allocation, index) => (
    <Table.Row key={allocation.date.toString() + index}>
      <Table.Cell textAlign="center" />
      <Table.Cell textAlign="center" />
      <Table.Cell textAlign="center" />
      <Table.Cell textAlign="center">
        {formatDateString(allocation.date)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(allocation.amount)}
      </Table.Cell>
      <Table.Cell textAlign="center" />
      <Table.Cell textAlign="center">
        <Button.Group basic size="tiny">
          {renderButtonsCan(allocation)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  const renderActivityHeader = () => (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell textAlign="center" width={1}>
          {renderAddAllocation()}
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={4} />
        <Table.HeaderCell textAlign="center" width={1} />
        <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Amount</Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={4} />
        <Table.HeaderCell textAlign="center" width={1}>
          Actions
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  const allocations = activities.find((act) => act.allocations);
  // console.log("AT", allocations);

  return (
    <>
      <Segment color="green">
        <Divider horizontal>Allocations</Divider>
        <Table celled compact striped>
          {renderActivityHeader()}
          <Table.Body>
            {allocations &&
              allocations.allocations.map((allocation, index) =>
                renderActivityRow(allocation, index)
              )}
          </Table.Body>
          {allocations && (
            <AllocationsFooter allocations={allocations} study={study} />
          )}
        </Table>
      </Segment>
    </>
  );
};

AllocationsTable.propTypes = {
  user: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
};

export default AllocationsTable;
