import React, { useState } from 'react';
import { Checkbox, Container, Segment } from 'semantic-ui-react';
import {
  ResponsiveContainer,
  LineChart,
  Line,
  // Label,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  Legend,
} from 'recharts';
import { formatDateString } from '../../../services/dates/formatting';
import { combineChartData } from '../../../services/calculators/studies/funding';
import colors from '../../../components/Styles/Colors';

const FundingChart = (props) => {
  const [ibState, setIbState] = useState(true);
  const [sbState, setSbState] = useState(true);
  const [pfState, setPfState] = useState(true);

  const formatter = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 6,
    style: 'currency',
    currency: 'USD',
  });

  const renderCheckboxes = (name) => (
    <Container textAlign="center">
      <Segment.Group horizontal>
        <Segment color={colors[0]}>OPTIONS: {name}</Segment>
        <Segment color={colors[1]}>
          <Checkbox
            id="IB"
            label="Ideal Balance (IB)"
            checked={ibState}
            onChange={() => {
              setIbState(!ibState);
            }}
          />
        </Segment>
        <Segment color={colors[2]}>
          <Checkbox
            id="SB"
            label="Starting Balance (SB)"
            checked={sbState}
            onChange={() => {
              setSbState(!sbState);
            }}
          />
        </Segment>
        <Segment color={colors[3]}>
          <Checkbox
            id="PF"
            label="Percent Funded (PF)"
            checked={pfState}
            onChange={() => {
              setPfState(!pfState);
            }}
          />
        </Segment>
      </Segment.Group>
    </Container>
  );

  const formatValue = (value) => {
    if (typeof value === 'string') {
      return formatDateString(value);
    }
    // Hack for % funded, right Y axis
    if (value < 1000) {
      return `${value}%`;
    }
    return formatter.format(value);
  };

  const formatPercent = (value) => `${value}%`;

  const formatMoney = (value) => formatter.format(value);

  const { studies } = props;
  if (!studies || studies.length === 0) {
    return null;
  }

  const chartData = combineChartData(studies);
  // const chartData = props.data.chartData;
  console.log('FundingChart chartData ', chartData);

  if (!chartData || chartData.length === 0) {
    return null;
  }
  console.log('About to render chart');
  // Flag for available data
  const first = chartData[0];
  const name0 = studies[0] ? studies[0].name : null;
  const name1 = studies[1] ? studies[1].name : null;
  // const name2 = studies[2] ? studies[2].name : null;
  const names = name0 + (name1 ? `, ${name1}` : '');
  console.log('names', names);

  return (
    <div>
      {renderCheckboxes(names)}
      <ResponsiveContainer height={600}>
        <LineChart
          data={chartData}
          margin={{ top: 10, right: 40, left: 40, bottom: 30 }}
        >
          <XAxis dataKey="name" />
          <YAxis type="number" tickFormatter={(value) => formatMoney(value)} />
          <YAxis
            yAxisId="right"
            orientation="right"
            tickFormatter={(value) => formatPercent(value)}
          />
          <CartesianGrid strokeDasharray="3 3" />
          <Tooltip formatter={(value) => formatValue(value)} />
          <Legend verticalAlign="top" height={36} />

          {first.ra1 && sbState && (
            <Line
              name={`${name0}(Starting Balance)`}
              type="monotone"
              dataKey="ra1"
              stroke="purple"
              activeDot={{ r: 8 }}
            />
          )}

          {first.ra2 && sbState && (
            <Line
              name={`${name1}(Starting Balance)`}
              type="monotone"
              dataKey="ra2"
              stroke="blue"
            />
          )}

          {/* PF1 = Percent Funded */}
          {first.pf1 && pfState && (
            <Line
              name={`${name0}(Percent Funded)`}
              yAxisId="right"
              type="monotone"
              dataKey="pf1"
              stroke="red"
              activeDot={{ r: 8 }}
            />
          )}

          {/* PF2 = Percent Funded */}
          {first.pf2 && pfState && (
            <Line
              name={`${name1}(Percent Funded)`}
              yAxisId="right"
              type="monotone"
              dataKey="pf2"
              stroke="orange"
            />
          )}

          {/* TIB1 = Total Ideal Balance */}
          {first.tib1 && ibState && (
            <Line
              name={`${name0}(Ideal Balance)`}
              type="monotone"
              dataKey="tib1"
              stroke="green"
              activeDot={{ r: 8 }}
            />
          )}

          {/* TIB2 = Total Ideal Balance */}
          {first.tib2 && ibState && (
            <Line
              name={`${name1}(Ideal Balance)`}
              type="monotone"
              dataKey="tib2"
              stroke="olive"
            />
          )}
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default FundingChart;
