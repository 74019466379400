import React, { useState } from 'react';
import { Button, Divider, Header, Segment, Table } from 'semantic-ui-react';
import Tooltip from '@material-ui/core/Tooltip';
import Radio from '@material-ui/core/Radio';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import ComponentsTable from './ComponentsTable';
import ImportComponentSet from './EditComponentSet/ImportComponentSet';
import EditComponentSet from './EditComponentSet/EditComponentSet';
import Can from '../../../components/Can/Can';
import useModify from './useModify';

const ComponentSetsTable = ({ sets, user }) => {
  const [showing, setShowing] = useState(0);

  const { deleteComponentSet } = useModify((error) => {
    console.log('Failed to modify component set', error);
  });

  const handleChange = (event) => {
    setShowing(parseInt(event.target.id));
  };

  const renderShowDetailsButton = (index) => (
    <Tooltip title="Show Details" placement="top-start">
      <Radio
        checked={showing === index}
        onChange={handleChange}
        id={`${index}`}
        name="showing"
        inputProps={{ 'aria-label': index }}
      />
    </Tooltip>
  );

  const deleteConfirmed = async (set) => {
    deleteComponentSet(set.id);
  };

  const renderButtons = (set) => (
    <>
      <EditComponentSet set={set} />
      <ConfirmDelete
        deleteId={set}
        name={set.name}
        deleteConfirmed={deleteConfirmed}
      />
    </>
  );

  // Tests role authorization, returning buttons
  const renderButtonsCan = (set) => (
    <Can
      role={user.role}
      perform="component:edit"
      yes={() => renderButtons(set)}
      no={() => null}
    />
  );

  // Tests role authorization, returning buttons
  const renderImportCan = () => (
    <Can
      role={user.role}
      perform="component:edit"
      yes={() => <ImportComponentSet />}
      no={() => null}
    />
  );

  const renderTableRow = (set, index) => (
    <Table.Row key={set.id}>
      <Table.Cell />
      <Table.Cell textAlign="left">{set.name}</Table.Cell>
      <Table.Cell textAlign="left">{set.notes}</Table.Cell>
      <Table.Cell textAlign="center">
        {renderShowDetailsButton(index)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        <Button.Group basic size="mini" color="teal">
          {renderButtonsCan(set)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  const showingSet = () => (sets.length > showing ? sets[showing] : null);

  const showingTitle = () => {
    const show = showingSet();
    return show ? show.name : '';
  };

  const currentSetTitle = () => `Current Component Set - ${showingTitle()}`;

  return (
    <>
      <Table celled compact striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={3} textAlign="center">
              {renderImportCan()}
            </Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="left">
              Component Set Name
            </Table.HeaderCell>
            <Table.HeaderCell width={6} textAlign="left">
              Notes
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Show
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {sets.map((set, index) => renderTableRow(set, index))}
        </Table.Body>
      </Table>
      <Segment color="teal">
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>{currentSetTitle()}</Divider>
        </Header>
      </Segment>
      {showingSet() && <ComponentsTable set={showingSet()} user={user} />}
      {/* {sets.map((set, i) =>
        shouldShowSetDetails(set.id) ? (
          <ComponentsTable key={`ct-${i}`} set={set} user={user} />
        ) : null
      )} */}
    </>
  );
};

export default ComponentSetsTable;
