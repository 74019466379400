import { useState } from 'react';
import { parseLocaleFloat } from '../../../../services/number/formatting';
import { db } from '../../../../services/firebase/firebase';

// Custom hook for CRUD TaxBill. Provide optional callbacks for
// success and failure notification.
const useTaxBill = (editable, success = () => {}, failure = () => {}) => {
  const [errorMessage, setErrorMessage] = useState();
  const [projectedAssessments, setProjectedAssessments] = useState(0);
  const [totalImprovements, setTotalImprovements] = useState(0);
  const [totalLandValue, setTotalLandValue] = useState(0);

  const [taxBill, setTaxBill] = useState({
    year: editable.year || '',
    previous: editable.previous || '',
    specialATax: editable.specialATax || '',
    assessedValueRate: editable.assessedValueRate || '',
    landImprovementsRate: editable.landImprovementsRate || '',
    totalAssessedValue: editable.totalAssessedValue || '',
    totalReassessedValue: editable.totalReassessedValue || '',
    perPropertyTotalTax: editable.perPropertyTotalTax || '',
    exemptionAmount: editable.exemptionAmount || '7000',
    projectedIncrease: editable.projectedIncrease || '',
    isArchive: editable.isArchive || false,
    notes: editable.notes || '',
  });
  const [assessments, setAssessments] = useState(
    editable.assessments ? editable.assessments : []
  );
  const [supplementals, setSupplementals] = useState(
    editable.supplementals ? editable.supplementals : []
  );
  const [reassessments, setReassessments] = useState(
    editable.reassessments ? editable.reassessments : []
  );
  const [impounds, setImpounds] = useState(
    editable.impounds ? editable.impounds : []
  );

  const handleChange = (name, value) => {
    setTaxBill({ ...taxBill, [name]: value });
  };

  const canCreateProjected = () => projectedAssessments;

  const _create = async (variables) => {
    console.log('createTaxBill vars ', variables);
    const ref = db.collection('taxBills');
    ref
      .add(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        failure(error.message);
      });
  };

  const _makeVariables = () => ({
    year: taxBill.year,
    previous: parseLocaleFloat(taxBill.previous),
    specialATax: parseLocaleFloat(taxBill.specialATax),
    assessedValueRate: parseLocaleFloat(taxBill.assessedValueRate),
    landImprovementsRate: parseLocaleFloat(taxBill.landImprovementsRate),
    totalAssessedValue: parseLocaleFloat(taxBill.totalAssessedValue),
    totalReassessedValue: parseLocaleFloat(taxBill.totalReassessedValue),
    perPropertyTotalTax: parseLocaleFloat(taxBill.perPropertyTotalTax),
    exemptionAmount: parseLocaleFloat(taxBill.exemptionAmount),
    projectedIncrease: parseLocaleFloat(taxBill.projectedIncrease),
    isArchive: taxBill.isArchive,
    notes: taxBill.notes,
  });

  // Copy current tax bill assessments, bumping 2%
  const createProjected = () => {
    console.log('create projected tax bill');

    const vars = {
      ..._makeVariables(),
      assessments: projectedAssessments,
      supplementals,
      totalLandValue,
      totalImprovements,
    };

    _create(vars);
  };

  const _findSupplemental = (supps, coach) => {
    if (!supps) return null;
    // eslint-disable-next-line
  return supps.find((supp) => supp.space === coach);
  };

  const _projectedAssessment = (assessment, supps) => {
    const supplemental = _findSupplemental(supps, assessment.coach);

    const landValue = supplemental
      ? supplemental.newLandValue
      : Math.trunc(assessment.landValue * 1.02);
    const improvements = supplemental
      ? supplemental.newImprovements
      : Math.trunc(assessment.improvements * 1.02);
    return {
      coach: assessment.coach,
      exemption: assessment.exemption,
      landValue,
      improvements,
    };
  };

  const makeUpdateVariables = () => ({
    ..._makeVariables(),
    assessments,
    supplementals,
    reassessments,
    impounds,
  });
  const _makeCreateVariables = () => ({
    ..._makeVariables(),
    assessments,
    supplementals,
    reassessments,
    impounds,
  });

  const handleSupplementals = (importedSupplementals) => {
    console.log('Prepare projected tax bill', taxBill.year);

    const { model } = editable;
    const { perPropAssessments } = model;

    setSupplementals(importedSupplementals);

    const pa = model.assessments.map((assessment) =>
      _projectedAssessment(assessment, importedSupplementals)
    );
    setProjectedAssessments(pa);

    // Sum the land values and improvements
    const tlv = pa.reduce((acc, assessment) => acc + assessment.landValue, 0);
    const ti = pa.reduce((acc, assessment) => acc + assessment.improvements, 0);
    setTotalImprovements(ti);
    setTotalLandValue(tlv);

    // Back into TAV by taking current BE * 106 + tlv + ti
    const bepp = perPropAssessments.busEquipEach;
    const tav = bepp * model.assessments.length + tlv + ti;
    handleChange('totalAssessedValue', tav);
  };

  const canCreate = () =>
    taxBill.year &&
    taxBill.specialATax &&
    taxBill.assessedValueRate &&
    taxBill.landImprovementsRate &&
    taxBill.totalAssessedValue &&
    taxBill.perPropertyTotalTax &&
    taxBill.exemptionAmount;

  const canUpdate = () => assessments && supplementals;

  const doCreate = async () => {
    _create(_makeCreateVariables());
  };

  const doUpdate = async () => {
    const variables = makeUpdateVariables();
    console.log('updateTaxBill vars ', variables);

    db.collection('taxBills')
      .doc(editable.id)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        failure(error.message);
      });
  };

  return {
    handleSupplementals,
    errorMessage,
    handleChange,
    setImpounds,
    setAssessments,
    setReassessments,
    setSupplementals,
    canCreateProjected,
    createProjected,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    taxBill,
  };
};

export default useTaxBill;
