import React, { useState } from 'react';
import { Confirm } from 'semantic-ui-react';
import PopupButton from '../PopupButton/PopupButton';

const ConfirmDelete = ({ name, deleteId, deleteConfirmed = () => {} }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleDelete = () => {
    deleteConfirmed(deleteId);
    setOpen(false);
  };

  const handleCancel = () => {
    setOpen(false);
  };

  return (
    <div>
      <PopupButton
        id="confirm"
        basic
        icon="trash alternate outline"
        color="teal"
        onClick={handleOpen}
        title="Delete"
      />
      <Confirm
        content={`Delete ${name}?`}
        open={open}
        onCancel={handleCancel}
        onConfirm={handleDelete}
      />
    </div>
  );
};

export default ConfirmDelete;
