import React from 'react';
import { Button, Grid, Segment, Table } from 'semantic-ui-react';
import useDMFs from './useDMFs';

const DMFTable = ({ dmfs }) => {
  console.log('DMF', dmfs);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const currency = (value) => {
    if (value) {
      return formatter.format(value);
    }
    return null;
  };

  const renderNormalRow = (dmf) => (
    <Table.Row key={dmf.id}>
      <Table.Cell textAlign="center">{dmf.spaceNumber}</Table.Cell>
      <Table.Cell textAlign="center">{currency(dmf.amount)}</Table.Cell>
      <Table.Cell textAlign="left">{dmf.notes}</Table.Cell>
      <Table.Cell>
        <Button.Group basic size="mini" />
      </Table.Cell>
    </Table.Row>
  );

  return (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={2} textAlign="center">
              Space
            </Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="center">
              Amount
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Notes</Table.HeaderCell>
            <Table.HeaderCell width={2} textAlign="center">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>{dmfs.map((dmf) => renderNormalRow(dmf))}</Table.Body>
      </Table>
    </>
  );
};

const ResponsiveTable = ({ dmfs }) => (
  <Grid textAlign="center" columns={3}>
    <Grid.Row>
      <Grid.Column width={3}>
        <Segment>Category</Segment>
      </Grid.Column>
      <Grid.Column width={13}>
        <DMFTable dmfs={dmfs} />
      </Grid.Column>
    </Grid.Row>
  </Grid>
);

const DMF = ({ showArchive }) => {
  const { dmfs, loading } = useDMFs(showArchive);

  if (loading) {
    return <p>Loading...</p>;
  }

  if (!dmfs) {
    return null;
  }

  return (
    <div>
      <ResponsiveTable dmfs={dmfs} showArchive={showArchive} />
    </div>
  );
};

export default DMF;
