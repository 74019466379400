import React from 'react';
import { Divider } from 'semantic-ui-react';
import SideBarItem from '../SideBarItem/SideBarItem';
import './SideBarFooter.scss';

export default function SideBarFooter() {
  return (
    <>
      <SideBarItem path="privacy" label="Privacy Policy" icon="privacy" />
      <Divider fitted />
      <div className="footer-block">
        <div>© paseodepalomas.org</div>
      </div>
      <div className="footer-block">
        <div>Paseo de Palomas internal tools</div>
      </div>
      <div className="footer-block">
        <div>Usage restricted to residents only.</div>
      </div>
      <div className="footer-block">
        <div>Attributions:</div>
        <div>Pigeon by AFY Studio from the Noun Project</div>
      </div>
    </>
  );
}
