import React, { Component } from 'react';
import { Menu, Divider } from 'semantic-ui-react';
import SideBarItem from '../../SideBar/SideBarItem/SideBarItem';
import SideBarHeader from '../../SideBar/SideBarHeader/SideBarHeader';
import SideBarFooter from '../../SideBar/SideBarFooter/SideBarFooter';
import './SideBar.scss';

class SideBar extends Component {
  render() {
    return (
      <Menu borderless vertical stackable fixed="left" className="side-nav">
        <SideBarItem path="/" label="Home" icon="home" />
        <Divider fitted />
        <SideBarHeader title="Admin" />
        <SideBarItem path="users" label="Users" icon="table" />
        <SideBarItem path="importspaces" label="Import Spaces" icon="table" />
        <SideBarItem path="exportspaces" label="Export Spaces" icon="table" />
        <SideBarItem path="exportdmc" label="Export DialMyCalls" icon="table" />
        <SideBarItem path="impounds" label="Import Impounds" icon="table" />
        {/* <SideBarItem
          path="importlineitems"
          label="Import Line Items"
          icon="table"
        /> */}
        <Divider fitted />
        <SideBarItem path="help" label="Help" icon="question circle outline" />
        {/* <SideBarItem
          path="feedback"
          label="Send feedback"
          icon="comment outline"
        /> */}
        <Divider />
        <SideBarFooter />
      </Menu>
    );
  }
}

export default SideBar;
