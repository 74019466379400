import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Header, Radio, Table } from 'semantic-ui-react';
import BudgetChart from './BudgetChart';

const Charts = ({ budgets }) => {
  const [selected, setSelected] = useState(Array(budgets.length).fill(false));

  useEffect(() => {
    const temp = Array(budgets.length).fill(false);
    setSelected(temp.map((flag, index) => budgets[index].current));
  }, [budgets]);

  const updateSelected = (index) => {
    setSelected(selected.map((flag, theIndex) => index === theIndex));
  };

  const handleCheckbox = (event) => {
    updateSelected(event.id);
  };

  const renderSelected = (index) => (
    <Radio
      id={index}
      disabled={false}
      checked={selected[index]}
      onChange={(_, data) => {
        handleCheckbox(data);
      }}
    />
  );

  const renderNormalRow = (budget, index) => (
    <Table.Row key={budget.id}>
      <Table.Cell textAlign="center">{budget.name}</Table.Cell>
      <Table.Cell textAlign="center">{budget.budgetYear}</Table.Cell>
      <Table.Cell textAlign="left">{budget.note}</Table.Cell>
      <Table.Cell textAlign="center">{renderSelected(index)}</Table.Cell>
      <Table.Cell textAlign="center" width={2}>
        <Button.Group basic size="mini" floated="right" />
      </Table.Cell>
    </Table.Row>
  );

  const renderLineItems = (budget, index) => (
    <React.Fragment key={budget.name}>
      {selected[index] && (
        <>
          <Header as="h3" content={budget.name} textAlign="center" />
          {/* <Segment color={color}></Segment> */}
          <BudgetChart budget={budget} />
          {/* <CategoryTable budget={budget} user={props.user} /> */}
          {/* <Segment color={color}></Segment> */}
        </>
      )}
    </React.Fragment>
  );

  const renderTable = () => (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="center" width={4}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={1}>
              Year
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Notes</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              Show Charts
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {budgets &&
            budgets.map((budget, index) => renderNormalRow(budget, index))}
        </Table.Body>
      </Table>
      {budgets &&
        budgets.map((budget, index) => renderLineItems(budget, index))}
    </>
  );

  return <>{renderTable()}</>;
};

Charts.propTypes = {
  budgets: PropTypes.array.isRequired,
};

export default Charts;
