import React from 'react';
import { CartesianGrid, XAxis, YAxis, Tooltip, Bar, BarChart } from 'recharts';
import moment from 'moment';
import {
  formatDateString,
  compareDates,
} from '../../../../services/dates/formatting';
import useArchives from '../../Listings/useArchives';

const monthYearDateString = (date) => {
  const newDate = new Date(date);
  // console.log('dates ', date, newDate);
  return `${newDate.getMonth()}/${newDate.getFullYear().toString().slice(-2)}`;
};

const listingDate = (listing) => {
  if (listing.closedAt) {
    return listing.closedAt;
  }
  if (listing.listedAt) {
    return listing.listedAt;
  }
  return null;
};

const daysOnMarket = (listing) => {
  if (listing.listedAt && listing.closedAt) {
    const start = moment(listing.listedAt);
    const end = moment(listing.closedAt);
    const days = moment.duration(end.diff(start)).asDays();
    return days.toFixed(0);
  }
  return 0;
};

const makeData = (listings) =>
  listings
    .map((listing) => {
      const dom = daysOnMarket(listing);
      const date = listingDate(listing);
      const dateString = monthYearDateString(date);
      return {
        dateObj: new Date(date),
        dates: date.toString(),
        date: dateString,
        dom,
      };
    })
    .sort((a, b) => compareDates(a.dateObj, b.dateObj));

const DaysOnMarket = () => {
  const { archives } = useArchives();

  const formatValue = (value) => {
    if (typeof value === 'string') {
      return formatDateString(value);
    }
    return `${value}`;
  };

  const formatShortDate = (value) => monthYearDateString(value);
  if (!archives) {
    return null;
  }

  const scatterData = makeData(archives);

  return (
    <BarChart
      data={scatterData}
      width={1100}
      height={500}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid strokeDasharray="3 3" />
      <XAxis
        // type="category"
        dataKey="dates"
        name="date"
        tickFormatter={(value) => formatShortDate(value)}
      />
      <YAxis
        // type="number"
        // dataKey="dom"
        // name="dom"
        tickFormatter={(value) => formatValue(value)}
        domain={[0, 600]}
        ticks={[100, 200, 300, 400, 500, 600]}
      />
      <Tooltip
        cursor={{ strokeDasharray: '4 4' }}
        // formatter={(value) => formatValue(value)}
      />
      <Bar
        name="Days on Market"
        dataKey="dom"
        data={scatterData}
        fill="#8884d8"
      />
    </BarChart>
  );
};

export default DaysOnMarket;
