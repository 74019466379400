import React from 'react';
import { UserContext } from '../../services/context/UserContext';
import { ParkContext } from '../../services/context/ParkContext';
import SpacesTable from './SpacesTable';

const Archives = () => {
  const { user } = React.useContext(UserContext);
  const { park } = React.useContext(ParkContext);

  if (!user || !park) {
    return null;
  }

  return <SpacesTable spaces={park.archives} user={user} showArchive />;
};

export default Archives;
