import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import ConfirmDelete from '../../components/Confirm/ConfirmDelete';
import { formatDateString } from '../../services/dates/formatting';
import { db } from '../../services/firebase/firebase';
import PopupButton from '../../components/PopupButton/PopupButton';
import Can from '../../components/Can/Can';
import EditSpace from './EditSpace/EditSpace';

const SpacesTable = ({ spaces, user, showArchive }) => {
  // console.log('spaes', spaces);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const currency = (price) => (price ? formatter.format(price) : null);

  const makeName = (space) =>
    space.name2.length > 0 ? `${space.name}, ${space.name2}` : space.name;

  const makeStatus = (space) => {
    const status = [];
    if (space.isForSale) {
      status.push('FS');
    }
    if (space.isVacant) {
      status.push('V');
    }
    if (space.isNewHome) {
      status.push('NH');
    }
    return status.join(',');
  };

  const findSpace = (id) => spaces.find((space) => space.id === id);

  const deleteConfirmed = async (id) => {
    console.log('deleteConfirmed', id);
    db.collection('spaces')
      .doc(id)
      .delete()
      .then(() => {
        console.log('Deleted Space');
      })
      .catch((error) => {
        console.log('Error deleting Space', error);
      });
  };

  const handleArchive = (event, data) => {
    console.log(event);
    const space = findSpace(data.id);
    const vars = space.makeArchiveVars();
    db.collection('spaces').add(vars);
  };

  const renderEditButtons = (space) => (
    <>
      <Button.Group size="mini">
        <EditSpace editSpace={space} />
        {showArchive && (
          <ConfirmDelete
            deleteId={space.id}
            name={space.address}
            deleteConfirmed={deleteConfirmed}
          />
        )}
        {!showArchive && (
          <PopupButton
            title="Archive Space"
            icon="file archive outline"
            id={space.id}
            onClick={handleArchive}
          />
        )}
      </Button.Group>
    </>
  );

  const renderButtons = (space) => (
    <Can
      role={user.role}
      perform="park:edit"
      yes={() => renderEditButtons(space)}
    />
  );

  // const renderPurchasePrice = (space) => (
  //   <Can
  //     role={user.role}
  //     perform="park:edit"
  //     yes={() => currency(space.purchasePrice)}
  //   />
  // );

  const renderDetails = (space, showDetails) => (
    <>
      <Table.Cell textAlign="center">{showDetails && space.decal}</Table.Cell>
      <Table.Cell textAlign="center">{showDetails && space.size}</Table.Cell>
      <Table.Cell textAlign="center">
        {showDetails &&
          space.purchaseDate &&
          formatDateString(space.purchaseDate)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {showDetails && currency(space.purchasePrice)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {showDetails && space.shareNumber}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {showDetails && makeStatus(space)}
      </Table.Cell>
      <Table.Cell textAlign="left">{showDetails && space.notes}</Table.Cell>
    </>
  );

  const renderDetailsCan = (space) => (
    <Can
      role={user.role}
      perform="park:edit"
      yes={() => renderDetails(space, true)}
      no={() => renderDetails(space, false)}
    />
  );

  const renderSpaceRow = (space) => (
    <Table.Row key={space.id}>
      <Table.Cell textAlign="center">{space.number}</Table.Cell>
      <Table.Cell textAlign="center">{space.address}</Table.Cell>
      <Table.Cell textAlign="left">{makeName(space)}</Table.Cell>
      <Table.Cell textAlign="center">{space.phone}</Table.Cell>
      {renderDetailsCan(space)}
      {/* <Table.Cell textAlign="center">{space.decal}</Table.Cell>
      <Table.Cell textAlign="center">{space.size}</Table.Cell>
      <Table.Cell textAlign="center">
        {space.purchaseDate && formatDateString(space.purchaseDate)}
      </Table.Cell>
      <Table.Cell textAlign="center">{renderPurchasePrice(space)}</Table.Cell>
      <Table.Cell textAlign="center">{space.shareNumber}</Table.Cell>
      <Table.Cell textAlign="center">{makeStatus(space)}</Table.Cell>
      <Table.Cell textAlign="left">{space.notes}</Table.Cell> */}
      <Table.Cell textAlign="center">{renderButtons(space)}</Table.Cell>
    </Table.Row>
  );

  const renderSpaces = () => {
    if (!spaces) {
      return null;
    }
    return spaces.map((space) => renderSpaceRow(space));
  };

  const renderSpacesTable = () => (
    <Table celled striped>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell textAlign="center">Space</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Address</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
          <Table.HeaderCell textAlign="center" width="2">
            Phone
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Decal</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Size</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Purchase Date</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Purchase Price</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Share #</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Notes</Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderSpaces()}</Table.Body>
    </Table>
  );

  return <>{renderSpacesTable()}</>;
};

SpacesTable.propTypes = {
  showArchive: PropTypes.bool.isRequired,
  spaces: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default SpacesTable;
