import React, { useState, useEffect } from 'react';
import Papa from 'papaparse';
import { ArrowUpward } from '@material-ui/icons';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Header, Modal, Form } from 'semantic-ui-react';
import { db } from '../../../services/firebase/firebase';
import ModalActions from '../../../components/CreateModal/ModalActions';
import useSpaces from '../../Spaces/useSpaces';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const makeLowerCase = (string) =>
  string.charAt(0).toLowerCase() + string.slice(1);

const ImportImpounds = () => {
  const { spaces } = useSpaces();
  const [activeSpaces, setActiveSpaces] = useState();
  const [open, setOpen] = useState(false);
  const [data, setData] = useState(null);

  useEffect(() => {
    if (spaces) setActiveSpaces(spaces.filter((space) => !space.isArchive));
  }, [spaces]);

  const importFile = async (e) => {
    const file = e.target.files[0];
    console.log('File: ', file);
    Papa.parse(file, {
      header: true,
      complete(event) {
        console.log('complete', event);
        setData(event.data);
      },
      transformHeader(value) {
        return makeLowerCase(value);
      },
      transform(value, name) {
        if (name === 'amount') {
          return value.startsWith('-$')
            ? parseFloat(value.replace(['-$'], ''))
            : value;
        }
        if (name === 'coach') {
          return parseInt(value);
        }
        if (name === 'date') {
          return value;
        }
        return value.startsWith('-') ? value.replace(/\D/g, '') : value;
      },
    });
  };

  const findSpace = (theData) =>
    activeSpaces.find((space) => space.number === theData.coach);

  const makeUpdateVars = (theData) => ({
    impounds: {
      date: theData.date,
      amount: theData.amount,
      coach: theData.coach,
    },
  });

  // const makeVariables = () =>
  //   data.map((item) => ({
  //     date: item.date,
  //     amount: item.amount,
  //     coach: item.coach,
  //   }));

  const update = (parsedData) => {
    const batch = db.batch();
    parsedData.forEach((item) => {
      const space = findSpace(item);
      if (space) {
        const theVars = makeUpdateVars(item);
        const updateRef = db.collection('spaces').doc(space.id);
        batch.update(updateRef, theVars);
      }
    });
    batch
      .commit()
      .then(() => {
        console.log('batch done');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  const createFromImport = () => {
    update(data);
  };

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    createFromImport(data);
  };

  const disabled = () => !data || !spaces;

  const renderForm = () => (
    <div>
      <Form>
        <Form.Group>
          <Form.Input
            type="file"
            label="Import File"
            id="form-input-file"
            onChange={importFile}
          />
        </Form.Group>
      </Form>
    </div>
  );

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <LightTooltip title="Import Impounds" placement="top-start">
          <Button color="teal" basic size="small">
            <ArrowUpward />
          </Button>
        </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Import Impounds" />
      <Modal.Content>{renderForm()}</Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default ImportImpounds;
