import { fiscalIndex } from '../../../services/dates/formatting';
// import { perf } from "../../../services/firebase/firebase";
//
// Represents the Study, containing Components.
//
export default class Study {
  constructor(study) {
    this.components = study.components;
    // this.components = study.components.map((component) => {
    //   return new Component(component);
    // });

    this.id = study.id;
    this.annualAllocationAmounts = study.annualAllocationAmounts;
    this.annualAllocationIncrease = study.annualAllocationIncrease;
    this.assessment = study.assessment;
    this.balance = study.balance;
    this.inflationRate = study.inflationRate;
    this.interestRate = study.interestRate;
    this.isArchived = study.isArchived;
    this.isSelected = study.isSelected;
    this.monthlyReserveAllocation = study.monthlyReserveAllocation;
    this.name = study.name;
    this.startingReserveBalance = study.startingReserveBalance;
    this.studyYear = study.studyYear;
    this.notes = study.notes;

    this.computeAll();
    // const trace = perf.trace("StudyExpenses");
    // trace.start();
    // this.yearlyExpenses = this.makeStudyYearlyExpenses();
    // console.log("Yearly Expenses", this.yearlyExpenses);
    // this.yearlyIdealBalances = this.computeIdealBalancesByYear();
    // console.log("Yearly Ideal Balances", this.yearlyIdealBalances);
    // this.graphData = this.makeGraphData();
    // console.log("Yearly Graph Data", this.graphData);
    // this.summaryData = this.computeSummaryData();
    // console.log("Summary data", this.summaryData);
    // trace.stop();
  }

  computeAll() {
    this.yearlyExpenses = this.makeStudyYearlyExpenses();
    console.log('Yearly Expenses', this.yearlyExpenses);
    this.yearlyIdealBalances = this.computeIdealBalancesByYear();
    console.log('Yearly Ideal Balances', this.yearlyIdealBalances);
    this.graphData = this.makeGraphData();
    console.log('Yearly Graph Data', this.graphData);
    this.summaryData = this.computeSummaryData();
    console.log('Summary data', this.summaryData);
    // this.currentYearAllocations(this.studyYear);
  }

  excludedComponents() {
    return this.components.reduce((acc, comp) => {
      if (comp.isExcluded) {
        acc.push(comp);
      }
      return acc;
    }, []);
  }

  // Returns true if any component is flagged as excluded
  hasExclusions() {
    return (
      this.components.reduce(
        (acc, comp) => (comp.isExcluded ? acc + 1 : acc),
        0
      ) > 0
    );
  }

  // Returns {components: [comps by year], expenses: [expensesByYear] }
  // Returns study.expenses := {componentsByYear, expensesByYear}
  makeStudyYearlyExpenses() {
    const componentsByYear = [];
    const expensesByYear = [];
    for (let year = 0; year < 31; year += 1) {
      // const expense = computeStudyYearExpense(study, year);
      const comps = this.computeStudyYearExpenseComponents(year);
      componentsByYear.push(comps);
      // console.log("Components for year ", year, comps);
      // Total for all components this year
      const total = comps.reduce((acc, comp) => acc + comp.replacementCost, 0);
      // Adjust for inflation
      const withInflation = total * (1 + this.inflationRate) ** year;
      const expense = Math.round(withInflation);
      expensesByYear.push(expense);
    }
    return { componentsByYear, expensesByYear };
  }

  computeStudyYearExpenseComponents(yearIndex) {
    const expenseComponents = this.components.reduce((acc, comp) => {
      if (comp.isExcluded) {
        console.log('expense is excluded', comp.name);
        return acc;
      }
      const age = Math.abs(comp.usefulLife - comp.remainingUsefulLife);
      if (comp.usefulLife === 0) {
        // Special case for one time upgrade
        if (age === yearIndex) {
          acc.push(this.expenseComp(comp, yearIndex));
        }
        return acc;
      }
      if (comp.usefulLife === comp.remainingUsefulLife && comp.usefulLife > 0) {
        // debugger;
        // Case where AGE == 0
        const ul = Math.floor(comp.usefulLife);
        const yr = Math.floor(yearIndex % ul);
        if (yearIndex > 0 && yr === 0) {
          acc.push(this.expenseComp(comp, yearIndex));
        }
        return acc;
      }
      // Normal case with repeating RUL. Add the RC if
      // the comp is up for replacement this index year.
      const year = (age + yearIndex) % comp.usefulLife;
      if (year === 0) {
        acc.push(this.expenseComp(comp, yearIndex));
      }
      return acc;
    }, []);

    return expenseComponents;
  }

  expenseComp(comp, year) {
    const copy = { ...comp }; // new Component(comp);
    const withInflation =
      copy.replacementCost * (1 + this.inflationRate) ** year;
    copy.inflatedCost = Math.round(withInflation);
    return copy;
  }

  computeIdealBalancesByYear() {
    const idealBalancesByYear = [];
    for (let year = 0; year < 31; year += 1) {
      const balance = this.computeStudyIdealBalance(year);
      idealBalancesByYear.push(balance);
    }
    return idealBalancesByYear;
  }

  computeStudyIdealBalance(yearIndex) {
    // console.log("worker.computeStudyIdealBalance");
    const match1 = 0;
    const match2 = 1;

    const rc = this.components.reduce((acc, comp) => {
      if (comp.isExcluded) {
        return acc;
      }
      const age = Math.abs(comp.usefulLife - comp.remainingUsefulLife);

      if (age === 0) {
        // See #216 10/10/0/14700
        const ib =
          ((yearIndex % comp.usefulLife) / comp.usefulLife) *
          comp.replacementCost;
        const t = acc + ib;
        // if (yearIndex == match1 || yearIndex == match2) {
        //   console.log("ib ", comp, yearIndex, ib, total);
        // }
        return t;
      }
      // Typical case
      // debugger;
      const m = (age + yearIndex) % comp.usefulLife;
      // const ib = (comp.rc * m) / comp.ul;
      const tib =
        m === 0
          ? comp.replacementCost
          : (comp.replacementCost * m) / comp.usefulLife;
      const total = acc + tib;
      if (yearIndex === match1 || yearIndex === match2) {
        // console.log("m, tib ", comp, m, tib, total);
      }
      return total;
    }, 0);
    const fudge = rc + (yearIndex >= 4 ? 60 : 0);
    const withInflation = fudge * (1 + this.inflationRate) ** yearIndex;
    // if (yearIndex === match1 || yearIndex === match2) {
    //   console.log("cIB ", Math.round(rc), Math.round(withInflation));
    // }
    return Math.round(withInflation);
  }

  makeGraphData() {
    const graphData = [];
    const expenses = this.yearlyExpenses.expensesByYear;
    const balances = this.yearlyIdealBalances;

    let previousBalance = this.startingReserveBalance;

    for (let i = 0; i < 31; i += 1) {
      const expense = expenses[i];

      const interest = this.computeInterest(previousBalance, expense, i);
      const balance =
        this.annualReserveAllocation(i) + previousBalance - expense;

      const tib = balances[i];
      const pf = Math.round((previousBalance / tib) * 100);

      const data = {};
      data.name = String(this.studyYear + i);
      data.ra = previousBalance;
      data.int = interest;
      data.pf = pf;
      data.tib = tib;

      graphData.push(data);
      previousBalance = balance;
    }
    return graphData;
  }

  computeSummaryData() {
    const formatter = new Intl.NumberFormat('en-US', {
      style: 'currency',
      currency: 'USD',
      minimumFractionDigits: 0,
    });

    const dataArray = [];
    let previousBalance = this.startingReserveBalance;

    for (let i = 0; i < 31; i += 1) {
      const expense = this.yearlyExpenses.expensesByYear[i];
      const interest = this.computeInterest(previousBalance, expense, i);
      const balance =
        this.annualReserveAllocation(i) + previousBalance - expense + interest;
      const tib = this.yearlyIdealBalances[i];
      const bpf = `${Math.round((previousBalance / tib) * 100)}%`;
      const ra = formatter.format(this.annualReserveAllocation(i));
      const int = formatter.format(interest);
      const eb = formatter.format(balance);
      const exp = formatter.format(expense);
      const bffb = formatter.format(tib);
      const brb = formatter.format(previousBalance);

      dataArray.push({
        name: String(this.studyYear + i),
        ra,
        int,
        eb,
        exp,
        bffb,
        brb,
        bpf,
      });
      previousBalance = balance;
    }
    return dataArray;
  }

  computeInterest(startBalance, expenses, yearIndex) {
    const ara = this.annualReserveAllocation(yearIndex);
    const eb = startBalance + ara - expenses;
    // return (startBalance + eb) / 2;
    return Math.round(eb * this.interestRate); // * fudgeFactors[yearIndex]);
  }

  // Returns the annual reserve allocation computed by taking the monthly
  // amount from the array times 12. Since our array has only 30 slots and
  // the year index ends at 30, we need to figure out what the number should
  // be using some other logic.
  annualReserveAllocation(yearIndex) {
    if (yearIndex < this.annualAllocationAmounts.length) {
      const allocations = this.annualAllocationAmounts[yearIndex] * 12;
      return yearIndex === 0 ? this.assessment + allocations : allocations;
    }
    // Protect the array access just in case things are messed up.
    if (yearIndex < 2) {
      return 0;
    }
    // Is the monthly the same for all year indexes, like [29] & [30] ?
    // This is normally the case when the amount is a constant
    if (
      this.annualAllocationAmounts[yearIndex - 1] ===
      this.annualAllocationAmounts[yearIndex - 2]
    ) {
      // Then it's safe to use -1
      return this.annualAllocationAmounts[yearIndex - 1] * 12;
    }
    // Else we should have a % increase
    if (this.annualAllocationIncrease > 0) {
      return Math.round(
        this.annualAllocationAmounts[yearIndex - 1] *
          (1 + this.annualAllocationIncrease / 100.0) *
          12
      );
    }
    // Finally we don't know what to do so punt
    return 0;
  }

  // currentYearAllocations(studyYear) {
  //   const startDate = new Date(studyYear - 1, 9, 1, 0, 0, 0);
  //   const months = this.monthDiff(startDate, new Date()) + 1;
  //   console.log('Months diff ', months);
  // }

  // monthDiff(dateFrom, dateTo) {
  //   // console.log('from', dateFrom);
  //   // console.log('to', dateTo);
  //   return (
  //     dateTo.getMonth() -
  //     dateFrom.getMonth() +
  //     12 * (dateTo.getFullYear() - dateFrom.getFullYear())
  //   );
  // }

  // Returns month index for allocations from Q1 till now
  getAllMonths(nowMonth) {
    const nowIndex = fiscalIndex.indexOf(nowMonth);
    if (nowIndex < 0) {
      console.log('Now Month Error', nowMonth);
      return [];
    }

    return fiscalIndex
      .map((value, index) => {
        const year = [9, 10, 11].includes(value)
          ? this.studyYear - 1
          : this.studyYear;
        const day = new Date(year, value, 15);

        if (index <= nowIndex) {
          // console.log("Include", index, nowIndex, day);
          return day;
        }
        // console.log("Skip", index, nowIndex, day);
        return null;
      })
      .filter((element) => element !== null);
  }
}

// Use Firestore converter to use custom objects directly with FS.
// class Component {
//   constructor(component) {
//     this.category = component.category;
//     this.name = component.name;
//     this.pid = component.pid;
//     this.remainingUsefulLife = component.remainingUsefulLife;
//     this.replacementCost = component.replacementCost;
//     this.usefulLife = component.usefulLife;
//     this.isExcluded = false;
//   }

//   toggleExclude() {
//     this.isExcluded = !this.isExcluded;
//   }
// }
