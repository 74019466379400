import React from 'react';
import { Button, Dimmer, Loader, Table } from 'semantic-ui-react';
import Can from '../../../../components/Can/Can';
import { UserContext } from '../../../../services/context/UserContext';
import ConfirmDelete from '../../../../components/Confirm/ConfirmDelete';
import ShowModal from '../../../../components/ShowModal/ShowModal';
import StudyComps from '../StudyComps/StudyComps';
import useModify from '../EditStudy/useModify';
import useStudies from '../useStudies';

const StudiesTable = ({ studies, user }) => {
  const { activateStudy, deleteStudy } = useModify(
    () => {},
    (error) => {
      console.log('Failure', error);
    }
  );

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const handleActivate = (event, data) => {
    activateStudy(data.id);
  };

  const deleteConfirmed = async (id) => {
    console.log('deleteConfirmed', id);
    deleteStudy(id);
  };

  const renderButtons = (study) => (
    <>
      <Button basic color="teal" id={study.id} onClick={handleActivate}>
        Activate
      </Button>
      <ConfirmDelete
        deleteId={study.id}
        name={study.name}
        deleteConfirmed={deleteConfirmed}
      />
    </>
  );

  // Tests role authorization, returning buttons
  const renderButtonsCan = (study) => (
    <Can
      role={user.role}
      perform="study:edit"
      data={{
        userId: user.id,
        ownerId: user.id,
      }}
      yes={() => renderButtons(study)}
    />
  );

  const renderNormalRow = (study) => (
    <Table.Row key={study.id}>
      <Table.Cell>{study.name}</Table.Cell>
      <Table.Cell>{study.studyYear}</Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(study.startingReserveBalance)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(study.monthlyReserveAllocation)}
      </Table.Cell>
      <Table.Cell>{study.notes}</Table.Cell>
      {/* <Table.Cell textAlign="center">{100 * study.inflationRate}%</Table.Cell>
      <Table.Cell textAlign="center">{100 * study.interestRate}%</Table.Cell> */}
      <Table.Cell textAlign="center">
        <Button.Group basic size="mini">
          <ShowModal
            modalTitle={`${study.name} Components`}
            buttonTitle="Components"
          >
            <StudyComps study={study} user={user} />
          </ShowModal>
          {renderButtonsCan(study)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  return (
    <>
      <Table celled compact striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={2}>Name</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Study Year
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Starting Balance
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Monthly Allocation
            </Table.HeaderCell>
            <Table.HeaderCell>Notes</Table.HeaderCell>
            {/* <Table.HeaderCell width={1} textAlign="center">
              Inflation %
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Interest %
            </Table.HeaderCell> */}
            <Table.HeaderCell width={2} textAlign="center">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {studies.map((study, index) => renderNormalRow(study, index))}
        </Table.Body>
      </Table>
    </>
  );
};

const Studies = () => {
  const { studies, loading, fetchError } = useStudies(true);

  if (fetchError) {
    return <div>Error loading payments.</div>;
  }

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div>
      <UserContext.Consumer>
        {({ user }) => <StudiesTable studies={studies} user={user} />}
      </UserContext.Consumer>
    </div>
  );
};

export default Studies;
