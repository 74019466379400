import React from 'react';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { digits, acceptFloat } from '../../../../services/number/formatting';

const EditForm = ({ fieldUpdated, supplemental }) => {
  const purchaseDate = (timestamp) => {
    fieldUpdated('date', timestamp);
  };

  return (
    <Form>
      <Form.Group>
        <Form.Input
          width={2}
          disabled
          label="Space"
          id="form-input-space"
          value={supplemental.space}
          onChange={(_, data) => {
            fieldUpdated('space', data.value);
          }}
        />
        <Form.Input label="Purchase Date">
          <DatePicker
            selected={supplemental.date}
            onChange={purchaseDate}
            popperPlacement="top-end"
          />
        </Form.Input>
        <Form.Input
          width={3}
          label="Tax Roll Year"
          id="form-input-ry"
          value={supplemental.rollYear}
          onChange={(_, data) => {
            fieldUpdated('rollYear', data.value);
          }}
        />
        <Form.Input
          width={3}
          label="Tax Rate"
          id="form-input-tr"
          value={supplemental.taxRate}
          onChange={(_, data) => {
            fieldUpdated('taxRate', acceptFloat(data.value));
          }}
        />
        <Form.Input
          width={3}
          label="Proration Factor"
          id="form-input-pf"
          value={supplemental.prorationFactor}
          onChange={(_, data) => {
            fieldUpdated('prorationFactor', acceptFloat(data.value));
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          width={3}
          label="Prev Land Value"
          id="form-input-pv"
          value={supplemental.prevLandValue}
          onChange={(_, data) => {
            fieldUpdated('prevLandValue', digits(data.value));
          }}
        />
        <Form.Input
          width={3}
          label="Prev Improvements"
          id="form-input-pi"
          value={supplemental.prevImprovements}
          onChange={(_, data) => {
            fieldUpdated('prevImprovements', digits(data.value));
          }}
        />
        <Form.Input
          width={3}
          label="New Land Value"
          id="form-input-cv"
          value={supplemental.newLandValue}
          onChange={(_, data) => {
            fieldUpdated('newLandValue', digits(data.value));
          }}
        />
        <Form.Input
          width={3}
          label="New Improvements"
          id="form-input-ci"
          value={supplemental.newImprovements}
          onChange={(_, data) => {
            fieldUpdated('newImprovements', digits(data.value));
          }}
        />
        <Form.Input
          width={3}
          label="Prorated Tax Bill"
          id="form-input-ptb"
          value={supplemental.proratedTaxBill}
          onChange={(_, data) => {
            fieldUpdated('proratedTaxBill', digits(data.value));
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default EditForm;
