import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import ConfirmDelete from '../../components/Confirm/ConfirmDelete';
import EditElectrical from './EditElectrical/EditElectrical';
import ElectricalFooter from './ElectricalFooter';
import useModify from './useModify';

const ElectricalTable = ({ park }) => {
  const { deletePedestal } = useModify((error) => {
    console.log('Failure', error);
  });

  const removePedestal = (spaceId) => {
    deletePedestal(spaceId);
  };

  const makeName = (space) => space.name;

  const renderButtons = (space) => (
    <Button.Group size="mini">
      <EditElectrical park={park} spaceNumber={space.number} />
      <ConfirmDelete
        deleteId={space.id}
        name={`Pedestal for coach #${space.number}`}
        deleteConfirmed={removePedestal}
      />
    </Button.Group>
  );

  const renderAddButton = () => (
    <>
      <Table.HeaderCell textAlign="center">
        <EditElectrical park={park} spaceNumber={0} />
      </Table.HeaderCell>
    </>
  );

  const pedestalFlag = (flag) => (flag ? 'YES' : 'NO');

  const pedestalValue = (value) => (value === 0 || !value ? '' : `${value}`);

  const renderSpaceRow = (space) => (
    <Table.Row key={space.id}>
      <Table.Cell />
      <Table.Cell textAlign="center">{space.number}</Table.Cell>
      <Table.Cell textAlign="left">{makeName(space)}</Table.Cell>
      <Table.Cell textAlign="center">
        {pedestalValue(space.pedestal?.main)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {pedestalValue(space.pedestal?.coach)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {pedestalValue(space.pedestal?.ac)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {pedestalValue(space.pedestal?.acMax)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {pedestalValue(space.pedestal?.aux1)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {pedestalValue(space.pedestal?.aux2)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {pedestalValue(space.pedestal?.aux3)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {pedestalFlag(space.pedestal?.isNew)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {pedestalFlag(space.pedestal?.isCited)}
      </Table.Cell>
      <Table.Cell textAlign="center">{space.pedestal?.notes}</Table.Cell>
      <Table.Cell textAlign="center">{renderButtons(space)}</Table.Cell>
    </Table.Row>
  );

  // const renderSpaces = () => park.spaces.map((space) => renderSpaceRow(space));
  const renderSpaces = () => {
    const spacesWithPedestal = park.spaces.reduce((acc, item) => {
      if (item.pedestal) {
        acc.push(item);
      }
      return acc;
    }, []);
    return spacesWithPedestal.map((space) => renderSpaceRow(space));
  };

  const renderTable = () => (
    <Table celled striped sortable>
      <Table.Header fullWidth>
        <Table.Row>
          {renderAddButton()}
          <Table.HeaderCell textAlign="center">Space</Table.HeaderCell>
          <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Main</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Coach</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">AC</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">AC Cur</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Aux 1</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Aux 2</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Aux 3</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Is New</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Citation</Table.HeaderCell>
          <Table.HeaderCell textAlign="left" width={4}>
            Notes
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>{renderSpaces()}</Table.Body>
    </Table>
  );

  return (
    <>
      {renderTable()}
      <ElectricalFooter park={park} />
    </>
  );
};

export default ElectricalTable;
