import { db } from '../../../../services/firebase/firebase';

const useModify = (success, failure) => {
  const _doModify = async (itemId, variables) => {
    db.collection('taxBills')
      .doc(itemId)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating item', error);
        failure(error.message);
      });
  };

  const archiveItem = (itemId) => {
    _doModify(itemId, { isArchive: true });
  };

  const activateItem = (itemId) => {
    _doModify(itemId, { isArchive: false });
  };

  const deleteItem = (itemId) => {
    db.collection('taxBills')
      .doc(itemId)
      .delete()
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error deleting item', error.message);
        failure(error.message);
      });
  };

  return {
    deleteItem,
    archiveItem,
    activateItem,
  };
};

export default useModify;
