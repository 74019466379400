import React, { useCallback } from 'react';
import { Form } from 'semantic-ui-react';
import ModalGallery from '../../../../../components/Image/ModalGallery';
import EditGallery from '../../../../../components/Image/EditGallery';
import { digits } from '../../../../../services/number/formatting';

const EditForm = ({ fieldUpdated, bid, imageUrls, uploadFile }) => {
  const maxSize = 1048576 * 3;

  const onDrop = useCallback(
    (acceptedFiles) => {
      uploadFile(acceptedFiles);
    },
    [uploadFile]
  );

  return (
    <Form>
      <Form.Group>
        <Form.Input
          width={4}
          label="Name"
          id="form-input-name"
          value={bid.name}
          onChange={(_, data) => {
            fieldUpdated('name', data.value);
          }}
        />
        <Form.Input
          width={2}
          fluid
          label="Amount"
          id="form-input-amount"
          value={bid.amount}
          onChange={(_, data) => {
            fieldUpdated('amount', digits(data.value));
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.TextArea
          width={16}
          label="Notes"
          id="form-input-notes"
          value={bid.notes}
          onChange={(_, data) => {
            fieldUpdated('notes', data.value);
          }}
        />
      </Form.Group>
      {imageUrls && (
        <EditGallery imageUrls={imageUrls} maxSize={maxSize} onDrop={onDrop} />
      )}
      {imageUrls && imageUrls.length > 0 ? (
        <ModalGallery imageUrls={imageUrls} header="Bid Images" />
      ) : null}
      <br />
    </Form>
  );
};

export default EditForm;
