import React, { useState, useEffect } from 'react';
import { Container, Dimmer, Divider, Header, Loader } from 'semantic-ui-react';
import Markdown from 'markdown-to-jsx';

const Privacy = () => {
  const [mdText, setMdText] = useState();
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    fetch('/Privacy-Policy.md')
      .then((response) => {
        if (response.ok) return response.text();
        // eslint-disable-next-line prefer-promise-reject-errors
        return Promise.reject("Didn't fetch text correctly");
      })
      .then((text) => {
        setMdText(text);
        setLoading(false);
      })
      .catch((error) => {
        console.error(error);
        setLoading(false);
      });
  }, []);

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div className="help-content">
      <Container text>
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>
            Paseo de Palomas Web Tools Privacy Policy
          </Divider>
        </Header>
        {mdText && <Markdown>{mdText}</Markdown>}
      </Container>
    </div>
  );
};

export default Privacy;
