import { useState, useEffect } from 'react';
import {
  parseLocaleNumber,
  parseLocaleFloat,
} from '../../../../services/number/formatting';
import { db } from '../../../../services/firebase/firebase';

const _shortDate = (date) => date.toLocaleDateString();

// Custom hook for CRUD Supplemental. Provide callbacks for
// success and failure notification.
const useSupplemental = (taxBill, itemOrNull, index, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();
  const [supplemental, setSupplemental] = useState();

  useEffect(() => {
    setSupplemental({
      space: itemOrNull.space || '',
      date: itemOrNull.date ? new Date(itemOrNull.date) : null,
      rollYear: itemOrNull.rollYear || '',
      taxRate: itemOrNull.taxRate || '',
      prevLandValue: itemOrNull.prevLandValue || '',
      prevImprovements: itemOrNull.prevImprovements || '',
      newLandValue: itemOrNull.newLandValue || '',
      newImprovements: itemOrNull.newImprovements || '',
      prorationFactor: itemOrNull.prorationFactor || '',
      proratedTaxBill: itemOrNull.proratedTaxBill || '',
    });
  }, [itemOrNull]);

  // Returns variables for edit
  const makeVariables = () => ({
    space: parseLocaleNumber(supplemental.space),
    date: _shortDate(supplemental.date),
    rollYear: supplemental.rollYear,
    taxRate: parseLocaleFloat(supplemental.taxRate),
    prevLandValue: parseLocaleFloat(supplemental.prevLandValue),
    prevImprovements: parseLocaleFloat(supplemental.prevImprovements),
    newLandValue: parseLocaleFloat(supplemental.newLandValue),
    newImprovements: parseLocaleFloat(supplemental.newImprovements),
    prorationFactor: parseLocaleFloat(supplemental.prorationFactor),
    proratedTaxBill: parseLocaleFloat(supplemental.proratedTaxBill),
  });

  const makeCreateVariables = () => makeVariables();

  const canCreate = () =>
    supplemental.space &&
    supplemental.date &&
    supplemental.rollYear &&
    supplemental.taxRate &&
    supplemental.prevLandValue &&
    supplemental.prevImprovements &&
    supplemental.newLandValue &&
    supplemental.newImprovements &&
    supplemental.prorationFactor &&
    supplemental.proratedTaxBill;

  const canUpdate = () => true;

  const doCreate = async () => {
    const vars = makeCreateVariables();
    console.log('create Supplemental vars ', vars);
    failure('Not implemented');
  };

  const update = async (variables) => {
    console.log('update Supplemental vars ', variables);

    taxBill.supplementals[index] = variables;
    // Updating an assessment requires updating the
    // array of assessments, best I can tell.
    const ref = db.collection('taxBills').doc(taxBill.id);
    ref
      .update({
        supplementals: taxBill.supplementals,
      })
      .then(function () {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.error('Error updating supplemental: ', error);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const doDelete = async (id) => {
    console.log('doDelete', id);
    failure('Not implemented');
  };

  const handleChange = (name, value) => {
    setSupplemental({ ...supplemental, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doDelete,
    supplemental,
  };
};

export default useSupplemental;
