import React from 'react';
import { Button, Icon, Modal } from 'semantic-ui-react';

const ModalActions = ({ onClose, onSave, disabled, cancelText }) => (
  <Modal.Actions>
    <Button
      basic
      color="teal"
      onClick={() => {
        onClose();
      }}
    >
      <Icon name="remove" /> {cancelText ? cancelText() : 'Close'}
    </Button>
    <Button
      disabled={disabled()}
      basic
      color="teal"
      onClick={() => {
        onSave();
      }}
    >
      <Icon name="checkmark" /> Save
    </Button>
  </Modal.Actions>
);

export default ModalActions;
