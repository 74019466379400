export const StatusOptions = [
  {
    key: 'ENABLED',
    text: 'ENABLED',
    value: 'ENABLED',
  },
  {
    key: 'ACTIVE',
    text: 'ACTIVE',
    value: 'ACTIVE',
  },
  {
    key: 'DISABLED',
    text: 'DISABLED',
    value: 'DISABLED',
  },
];

export const RoleOptions = [
  {
    key: 'RESIDENT',
    text: 'RESIDENT',
    value: 'RESIDENT',
  },
  {
    key: 'OFFICE',
    text: 'OFFICE',
    value: 'OFFICE',
  },
  {
    key: 'FOCUS',
    text: 'FOCUS',
    value: 'FOCUS',
  },
  {
    key: 'BOARD',
    text: 'BOARD',
    value: 'BOARD',
  },
  {
    key: 'ADMIN',
    text: 'ADMIN',
    value: 'ADMIN',
  },
];
