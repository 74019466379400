import { useState, useEffect } from 'react';
import Moment from 'moment';
import { db } from '../../../../services/firebase/firebase';

const useActivities = (studyId) => {
  const [activities, setActivities] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    try {
      const unsub = db
        .collection('studies')
        .doc(studyId)
        .collection('activities')
        .onSnapshot((snapshot) => {
          const act = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          const all = act.map((activity) => {
            if (activity.expenses) {
              activity.expenses = activity.expenses
                .map((item) => ({ ...item, date: item.date.toDate() }))
                .sort((a, b) => new Moment(a.date) - new Moment(b.date));
              // activity.expenses = sorted;
            }
            if (activity.allocations) {
              activity.allocations = activity.allocations
                .map((item) => ({ ...item, date: item.date.toDate() }))
                .sort((a, b) => new Moment(a.date) - new Moment(b.date));
              // activity.allocations = allocs;
            }
            return activity;
          });

          setLoading(false);
          console.log('All Activities', all);
          setActivities(all);
        });
      return () => {
        console.log('cleanup  activities');
        unsub();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [studyId]);

  return {
    fetchError,
    loading,
    activities,
  };
};

export default useActivities;
