import React from 'react';
import moment from 'moment';
import { Checkbox, Form, Table } from 'semantic-ui-react';

const PrintForm = ({ fieldUpdated, birthdays, options }) => {
  // console.log("PrintForm.form.props", props);

  const renderNormalRow = (person) => (
    <Table.Row key={person.name}>
      <Table.Cell textAlign="left">
        {moment(person.date).format('Do')}
      </Table.Cell>
      <Table.Cell textAlign="center">{person.name}</Table.Cell>
      <Table.Cell textAlign="right">{person.space}</Table.Cell>
    </Table.Row>
  );

  const renderHeader = () => (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell width={3} textAlign="left">
          Date
        </Table.HeaderCell>
        <Table.HeaderCell width={3} textAlign="center">
          Name
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="right" width={3}>
          Space
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  const renderBirthdays = () => (
    // <Table celled striped>
    <Table id="birthdaysTable">
      {options.showHeader && renderHeader()}
      <Table.Body>
        {birthdays.map((person) => renderNormalRow(person))}
      </Table.Body>
    </Table>
  );

  return (
    <>
      <Form>
        <Form.Group>
          <Form.Input
            width={3}
            label="Title"
            id="form-input-title"
            value={options.title}
            onChange={(_, data) => {
              fieldUpdated('title', data.value);
            }}
          />
          <Form.Input width={2} label="Include Header" id="form-input-sh">
            <Checkbox
              checked={options.showHeader}
              onChange={(_, data) => {
                fieldUpdated('showHeader', data.checked);
              }}
            />
          </Form.Input>
        </Form.Group>
      </Form>
      {renderBirthdays()}
    </>
  );
};

export default PrintForm;
