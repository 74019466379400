import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Checkbox, Segment, Table } from 'semantic-ui-react';
import ReassessmentsFooter from './ReassessmentsFooter';

const ReassessmentsTable = ({ park, taxBill }) => {
  const [showAll, setShowAll] = useState(false);
  const [showNegs, setShowNegs] = useState(false);

  const { model } = taxBill;

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const formatter0 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const differenceAmount = (assessment) =>
    model.taxDifference(assessment.coach);

  const differenceString = (assessment) =>
    formatter.format(differenceAmount(assessment));

  const assessmentHoeAmount = (coachNumber) => {
    const amount = model.assessmentHoeAmountFor(coachNumber);
    return amount === 0 ? '--' : formatter0.format(amount);
  };

  const reassessmentHoeAmount = (coachNumber) => {
    const amount = model.reassessmentHoeAmountFor(coachNumber);
    return amount === 0 ? '--' : formatter0.format(amount);
  };

  const renderCheckboxes = () => (
    <Segment.Group horizontal>
      <Segment>
        <Checkbox
          id="show-all"
          label="Show All Spaces"
          checked={showAll}
          onChange={() => {
            setShowAll(!showAll);
          }}
        />
      </Segment>
      <Segment>
        <Checkbox
          id="show-negs"
          label="Show Negative Refunds"
          checked={showNegs}
          onChange={() => {
            setShowNegs(!showNegs);
          }}
        />
      </Segment>
    </Segment.Group>
  );

  const renderUpdatedRow = (space) => {
    const assessment0 = model.assessmentFor(space.number);
    const assessment1 = model.reassessmentFor(space.number);
    const taxes0 = model.assessmentTaxesFor(space.number);
    const taxes1 = model.reassessmentTaxesFor(space.number);
    const diff = taxes0.annualTax - taxes1.annualTax;

    return (
      <Table.Row key="{space.number} + {space.name}">
        <Table.Cell textAlign="center">{space.number}</Table.Cell>
        <Table.Cell textAlign="left">{space.name}</Table.Cell>
        <Table.Cell textAlign="center">
          {assessmentHoeAmount(assessment0.coach)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {formatter0.format(taxes0.landImprovementsTotal)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {formatter.format(taxes0.annualTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {reassessmentHoeAmount(assessment1.coach)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {formatter0.format(taxes1.landImprovementsTotal)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {formatter.format(taxes1.annualTax)}
        </Table.Cell>
        <Table.Cell textAlign="center" negative={diff < 0}>
          {differenceString(assessment0)}
        </Table.Cell>
        <Table.Cell textAlign="center" />
      </Table.Row>
    );
  };

  const renderNormalRow = (assessment, index) => {
    const space = park.spaceFor(assessment.coach);

    return (
      <React.Fragment key={index}>{renderUpdatedRow(space)}</React.Fragment>
    );
  };

  const shouldShow = (assessment) => {
    const amount = differenceAmount(assessment);
    if (amount > 0) return true;
    if (amount < 0) return showNegs || showAll;
    return showAll;
  };

  const maybeRenderRow = (assessment, index) =>
    shouldShow(assessment) ? renderNormalRow(assessment, index) : null;

  return (
    <>
      {renderCheckboxes()}

      <Table celled>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="left">Space</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">HOE</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Assessed Value
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Previous Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">HOE</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Reassessed Value
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Updated Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Refund</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {model.assessments.map((assessment, index) =>
            maybeRenderRow(assessment, index)
          )}
        </Table.Body>
        <ReassessmentsFooter model={model} />
      </Table>
    </>
  );
};

ReassessmentsTable.propTypes = {
  park: PropTypes.object.isRequired,
  taxBill: PropTypes.object.isRequired,
};

export default ReassessmentsTable;
