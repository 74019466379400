import React from 'react';
import useSpaces from '../useSpaces';
import ExportDialer from './ExportDialer';

const makeResidentsVars = (space, group) => {
  const vars = {
    FirstName: space.name,
    LastName: '',
    EmailAddress: '',
    Phone: space.phone,
    Miscellaneous: space.address,
    ContactGroup: group,
  };
  return vars;
};

const Groups = [
  {
    name: 'La Paloma Odd',
    start: 1,
    end: 35,
    odd: 1,
  },
  {
    name: 'La Paloma Even',
    start: 1,
    end: 35,
    odd: 0,
  },
  {
    name: 'El Paseo',
    start: 36,
    end: 42,
    odd: 3,
  },
  {
    name: 'Palomar Real Odd',
    start: 43,
    end: 74,
    odd: 1,
  },
  {
    name: 'Palomar Real Even',
    start: 43,
    end: 74,
    odd: 0,
  },
  {
    name: 'La Paz Odd',
    start: 75,
    end: 106,
    odd: 1,
  },
  {
    name: 'La Paz Even',
    start: 75,
    end: 106,
    odd: 0,
  },
  {
    name: 'All Residents',
    start: 1,
    end: 106,
    odd: 3,
  },
  {
    name: 'Me',
    start: 48,
    end: 48,
    odd: 0,
  },
];

const oddEven = (flagged, value) => {
  switch (flagged) {
    case 0:
      return value % 2 === 0;
    case 1:
      return value % 2 !== 0;
    default:
      return true;
  }
};

const hasValidPhone = (phone) => phone && phone.toLowerCase() !== 'unlisted';

const makeGroups = (actives) =>
  Groups.map((group) => {
    const filtered = actives.filter(
      (active) =>
        hasValidPhone(active.phone) &&
        active.number >= group.start &&
        active.number <= group.end &&
        oddEven(group.odd, active.number)
    );
    const vars = filtered.map((value) => makeResidentsVars(value, group.name));
    return {
      name: group.name,
      vars,
    };
  });

const Exporter = () => {
  const { spaces } = useSpaces();

  if (!spaces) return null;

  const actives = spaces.filter((space) => !space.isArchive);

  const renderExport = (exportFile, title) => (
    <ExportDialer key={title} exportFile={exportFile} buttonTitle={title} />
  );

  const groups = makeGroups(actives);
  console.log('groups', groups);

  return (
    <span>{groups.map((group) => renderExport(group.vars, group.name))}</span>
  );
};

export default Exporter;
