//
// moment().format('MM-DD-YYYY'); // "08-13-2019"
//

export function format(aDate) {
  const dateToString = (d) =>
    `${`00${d.getMonth() + 1}`.slice(-2)}/${`00${d.getDate()}`.slice(
      -2
    )}/${d.getFullYear()}`;
  const myDate = new Date(Date.parse(aDate));
  // console.log(dateToString(myDate));
  return dateToString(myDate);
}

export const formatDateString = (dateString) => {
  const ds = dateString ? new Date(dateString).toLocaleDateString('en-US') : '';
  return ds;
};

export const formatDateTimeString = (dateString) => {
  const ds = dateString ? new Date(dateString).toLocaleString('en-US') : '';
  return ds;
};

export const monthDiff = (dateFrom, dateTo) =>
  dateTo.getMonth() -
  dateFrom.getMonth() +
  12 * (dateTo.getFullYear() - dateFrom.getFullYear());

export const compareDates = (dateA, dateB) => {
  const yearA = dateA.getFullYear();
  const yearB = dateB.getFullYear();
  const monA = dateA.getMonth();
  const monB = dateB.getMonth();
  if (yearA !== yearB) {
    return yearA > yearB ? 1 : -1;
  }
  return monA > monB ? 1 : -1;
  // console.log(dateA);
  // return dateA > dateB ? 1 : -1;
};

export const formatDateISO = (date) => {
  const d = new Date(date);
  let month = `${d.getMonth() + 1}`;
  let day = `${d.getDate()}`;
  const year = d.getFullYear();

  if (month.length < 2) month = `0${month}`;
  if (day.length < 2) day = `0${day}`;

  return [month, day, year].join('-');
};

export const compareByDay = (date1, date2) => {
  const day1 = date1.getDay();
  const day2 = date2.getDay();
  return day1 > day2 ? 1 : -1;
};

const compareQuarter = (date1, date2, month1, month2) => {
  if (month1 === month2) {
    return compareByDay(date1, date2);
  }
  return month1 - month2;
};

export const fiscalMonthCompare = (date1, date2) => {
  const month1 = date1.getMonth();
  const month2 = date2.getMonth();
  if (month1 >= 9 && month2 >= 9) {
    // Both in FQ1
    return compareQuarter(date1, date2, month1, month2);
  }
  if (month1 >= 9 && month2 <= 8) {
    // Month 1 in FQ1, month 2 FQ2-4
    return -1;
  }
  if (month1 <= 9 && month2 <= 8) {
    //  Month 1 in FQ 2-4, month 2 in FQ1
    return 1;
  }
  // Both in FQ2-4
  return compareQuarter(date1, date2, month1, month2);
};

export const monthNames = [
  'January',
  'February',
  'March',
  'April',
  'May',
  'June',
  'July',
  'August',
  'September',
  'October',
  'November',
  'December',
];

export const monthName = (index) => {
  if (index < monthNames.length) {
    return monthNames[index];
  }
  return 'Huh?';
};

export const fiscalIndex = [9, 10, 11, 0, 1, 2, 3, 4, 5, 6, 7, 8];
