import { useState, useEffect } from 'react';
import { db } from '../../../../services/firebase/firebase';
import { parseLocaleNumber } from '../../../../services/number/formatting';

// Custom hook for CRUD Assessment. Provide callbacks for
// success and failure notification.
const useAssessment = (taxBill, editItem, index, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();
  const [assessment, setAssessment] = useState();

  useEffect(() => {
    setAssessment({
      coach: editItem.coach,
      landValue: editItem.landValue || '',
      improvements: editItem.improvements || '',
      exemption: editItem.exemption || false,
      hoeAmount: editItem.hoeAmount || '7000',
      impound: '',
    });
  }, [editItem]);

  // Returns variables for edit
  const makeVariables = () => ({
    coach: assessment.coach,
    landValue: parseLocaleNumber(assessment.landValue),
    improvements: parseLocaleNumber(assessment.improvements),
    exemption: assessment.exemption,
    hoeAmount: assessment.exemption
      ? parseLocaleNumber(assessment.hoeAmount)
      : 0,
  });

  const makeCreateVariables = () => makeVariables();

  const canCreate = () =>
    assessment.coach &&
    assessment.landValue &&
    assessment.improvements &&
    assessment.hoeAmount;

  const canUpdate = () => true;

  const doCreate = async () => {
    const vars = makeCreateVariables();
    console.log('create Assessment vars ', vars);
    failure('Not implemented');
  };

  const update = async (variables) => {
    console.log('update Assessment vars ', variables, assessment);

    taxBill.assessments[index] = variables;
    // Updating an assessment requires updating the
    // array of assessments, best I can tell.
    const ref = db.collection('taxBills').doc(taxBill.id);
    ref
      .update({
        assessments: taxBill.assessments,
      })
      .then(function () {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.error('Error updating assessment: ', error);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const doDelete = async (id) => {
    console.log('doDelete', id);
    failure('Not implemented');
  };

  const handleChange = (name, value) => {
    setAssessment({ ...assessment, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doDelete,
    assessment,
  };
};

export default useAssessment;
