import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { digits } from '../../../../services/number/formatting';
import 'react-datepicker/dist/react-datepicker.css';

const EditForm = ({ fieldUpdated, listing, options }) => {
  const listingDate = (timestamp) => {
    fieldUpdated('listedAt', timestamp);
  };

  const closingDate = (timestamp) => {
    fieldUpdated('closedAt', timestamp);
  };

  return (
    <div>
      <Form>
        <Form.Group>
          <Form.Input
            width={2}
            fluid
            required
            label="Coach"
            id="form-input-number"
            value={listing.number}
            onChange={(_, data) => {
              fieldUpdated('number', data.value);
            }}
          />
          <Form.Input
            width={3}
            fluid
            required
            label="Owner"
            id="form-input-owner"
            value={listing.owner}
            onChange={(_, data) => {
              fieldUpdated('owner', data.value);
            }}
          />
          <Form.Input
            width={3}
            fluid
            required
            label="Agent"
            id="form-input-agent"
            value={listing.agent}
            onChange={(_, data) => {
              fieldUpdated('agent', data.value);
            }}
          />
          <Form.Input
            width={2}
            fluid
            required
            label="Asking Price"
            id="form-input-ip"
            value={listing.asking}
            onChange={(_, data) => {
              fieldUpdated('asking', digits(data.value));
            }}
          />
          <Form.Input
            width={2}
            // required
            label="Selling Price"
            id="form-input-sp"
            value={listing.selling}
            onChange={(_, data) => {
              fieldUpdated('selling', digits(data.value));
            }}
          />
        </Form.Group>
        <Form.Group>
          <Form.Input label="Listing Date">
            <DatePicker
              selected={listing.listedAt}
              onChange={listingDate}
              popperPlacement="top-end"
            />
          </Form.Input>
          <Form.Input label="Closing Date">
            <DatePicker
              selected={listing.closedAt}
              onChange={closingDate}
              popperPlacement="top-end"
            />
          </Form.Input>
          <Form.Input width={2} label="Status">
            <Dropdown
              basic
              selection
              options={options}
              value={listing.status}
              onChange={(_, data) => fieldUpdated('status', data.value)}
            />
          </Form.Input>
        </Form.Group>
        <Form.Group>
          <Form.TextArea
            width={16}
            label="Notes"
            id="form-input-notes"
            value={listing.notes}
            onChange={(_, data) => {
              fieldUpdated('notes', data.value);
            }}
          />
        </Form.Group>
      </Form>
    </div>
  );
};

export default EditForm;
