import React from 'react';
import { Button, Dimmer, Loader, Segment, Table } from 'semantic-ui-react';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import { UserContext } from '../../../services/context/UserContext';
import CreateUser from './EditUser/CreateUser';
import EditUser from './EditUser/EditUser';
import useUser from './EditUser/useUser';
import useUsers from './useUsers';

const UsersTable = ({ users, loggedInUser }) => {
  const { doDelete } = useUser({}, (status, result) => {
    console.log('completed', status, result);
  });

  const formattedDate = (date) => date.toLocaleDateString();

  const deleteConfirmed = (user) => {
    console.log('deleteConfirmed', user);
    doDelete(user);
  };

  const renderAddUser = () => <CreateUser user={{}} />;

  const renderButtons = (user) => (
    <>
      <EditUser user={user} />
      <ConfirmDelete
        deleteId={user}
        name={`User${user.email}`}
        deleteConfirmed={deleteConfirmed}
      />
    </>
  );

  const renderNormalRow = (user) => (
    <Table.Row key={user.id}>
      <Table.Cell />
      <Table.Cell textAlign="left">{user.email}</Table.Cell>
      <Table.Cell textAlign="center">{user.space}</Table.Cell>
      <Table.Cell textAlign="center">{user.status}</Table.Cell>
      <Table.Cell textAlign="center">{user.role}</Table.Cell>
      <Table.Cell textAlign="center">{formattedDate(user.updated)}</Table.Cell>
      <Table.Cell textAlign="left">{user.notes}</Table.Cell>
      <Table.Cell textAlign="center" width={2}>
        <Button.Group basic size="mini">
          {renderButtons(user)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  const renderTable = () => (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign="center">
              {renderAddUser()}
            </Table.HeaderCell>
            <Table.HeaderCell width={3} textAlign="left">
              Email
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Space</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Status</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Role</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Updated</Table.HeaderCell>
            <Table.HeaderCell width={8} textAlign="left">
              Notes
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{users.map((user) => renderNormalRow(user))}</Table.Body>
      </Table>
    </>
  );

  return <>{loggedInUser && renderTable()}</>;
};

const renderNormal = (users, user) => (
  <UsersTable users={users} loggedInUser={user} />
);

// Fetch Users and render Users table.
const Users = () => {
  const { users, error } = useUsers();

  if (error) {
    return (
      <div>
        <Segment textAlign="center" size="small" color="red">
          {`Error getting listings: ${error.message}`}
        </Segment>
      </div>
    );
  }

  if (!users) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div>
      <UserContext.Consumer>
        {({ user }) => renderNormal(users, user)}
      </UserContext.Consumer>
    </div>
  );
};

export default Users;
