import React, { useState } from 'react';
import ReactGA from 'react-ga';
import { BreakpointProvider } from 'react-socks';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import firebase from 'firebase/app';
import AppLayout from './components/AppLayout/AppLayout';
import Privacy from './components/Privacy/Privacy';
import Reserves from './containers/Reserves/Reserves';
import Budget from './containers/Budget/Budget';
import Home from './containers/Home/Home';
import Account from './containers/User/Account/Account';
import Register from './containers/User/Register/Register';
import Taxes from './containers/Taxes/Taxes';
import Parks from './containers/Parks/Parks';
import Office from './containers/Office/Office';
import Admin from './containers/Admin/Admin';
import { UserContext } from './services/context/UserContext';
import { getLoginItems } from './services/storage/Storage';
import { db } from './services/firebase/firebase';

function App() {
  const [authInfo, setAuthInfo] = useState(getLoginItems());
  const [error, setError] = useState();

  const initApp = () => {
    ReactGA.initialize('UA-149503053-1');
    console.log('>>>>>> GA initialized <<<<<<');
  };

  // Gets the logged in user record in order to have
  // the user's role, which dictates what user can do.
  const getUserInfo = (email) => {
    let user;

    db.collection('users')
      .where('email', '==', email)
      .get()
      .then((snapshot) => {
        snapshot.forEach((element) => {
          user = { id: element.id, ...element.data() };
        });
        setAuthInfo(user);
      })
      .catch((error) => {
        console.log('Error getting user ', error.message);
        setError(
          `${error.message} Please contact the manager to setup your account properly`
        );
      });
  };

  const observeAuthState = () => {
    firebase.auth().onAuthStateChanged(function (user) {
      // console.log("Auth state changed: ", user);
      if (user) {
        console.log('Current user', user);
        user.getIdTokenResult().then((idTokenResult) => {
          const { claims } = idTokenResult;
          console.log('Custom claims', claims);
          // const role = claims["role"];
          // const data = { id: user.uid, role: role };
          // handleLogin(data, user.uid);
          // setAuthInfo(data, user.uid);
        });

        getUserInfo(user.email);
      } else {
        console.log('User is signed out');
        setAuthInfo(null);
      }
    });
  };

  useState(() => {
    initApp();
    observeAuthState();
  }, []);

  return (
    <BreakpointProvider>
      <BrowserRouter>
        <UserContext.Provider value={{ user: authInfo }}>
          <AppLayout>
            <Switch>
              <Route
                exact
                path="/"
                render={() => (authInfo ? <Home /> : <Account error={error} />)}
              />
              <Route path="/account" component={Account} />
              <Route path="/register" component={Register} />
              <Route path="/reserves" component={Reserves} />
              <Route path="/finance" component={Budget} />
              <Route path="/taxes" component={Taxes} />
              <Route path="/park" component={Parks} />
              <Route path="/office" component={Office} />
              <Route path="/admin" component={Admin} />
              <Route path="/privacy" exact render={() => <Privacy />} />
            </Switch>
          </AppLayout>
        </UserContext.Provider>
      </BrowserRouter>
    </BreakpointProvider>
  );
}

export default App;
