import React, { useState } from 'react';
import { Button, Dimmer, Loader, Form, Grid, Segment } from 'semantic-ui-react';
import { db } from '../../../services/firebase/firebase';

const CreateEntityForm = () => {
  const [name, setName] = useState('');
  const [image, setImage] = useState('');
  const [address, setAddress] = useState('');
  const [description, setDescription] = useState('');
  // const [iPhoneStoreUrl, setIphoneStoreUrl] = useState("");
  // const [mobileAppName, setMobileAppName] = useState("");
  const [loading, setLoading] = useState(false);

  const makeVariables = () => ({
    name,
    image,
    address,
    description,
    mobileApp: {
      iPhoneStoreUrl:
        'https://apps.apple.com/us/app/paseo-de-app/id1503531038?mt=8',
      name: 'Paseo de App',
    },
  });

  const uploadFile = async (e) => {
    const { files } = e.target;
    const data = new FormData();
    data.append('file', files[0]);
    data.append('upload_preset', 'sickfits');

    const res = await fetch(
      'https://api.cloudinary.com/v1_1/wishinone/image/upload',
      {
        method: 'POST',
        body: data,
      }
    );
    setLoading(true);
    const file = await res.json();
    setLoading(false);
    // console.log("Uploaded file ", file.secure_url);
    setImage(file.secure_url);
  };

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Uploading image</Loader>
      </Dimmer>
    );
  }

  const create = () => {
    const variables = makeVariables();
    console.log('variables ', variables);
    db.collection('paseo-de-palomas')
      .doc('entity')
      .add(variables)
      .then(() => {
        console.log('Created entity');
      });
  };

  return (
    <div>
      <Grid
        textAlign="center"
        style={{ height: '100vh' }}
        verticalAlign="middle"
      >
        <Grid.Column style={{ maxWidth: 500 }}>
          <Form
            onSubmit={async (e) => {
              // Stop the form from submitting
              e.preventDefault();
              // call the mutation
              create();
            }}
          >
            <Segment stacked>
              <Form.Input
                // width={3}
                fluid
                label="Name"
                id="form-input-name"
                value={name}
                onChange={(_, data) => {
                  setName(data.value);
                }}
              />
              <Form.Input
                // width={2}
                fluid
                label="Address"
                id="form-input-addr"
                value={address}
                onChange={(_, data) => {
                  setAddress(data.value);
                }}
              />
              <Form.Input
                fluid
                label="Description"
                id="form-input-desc"
                value={description}
                onChange={(_, data) => {
                  setDescription(data.value);
                }}
              />
              <Button as="label" htmlFor="file" type="button">
                Select Image
              </Button>
              <input
                type="file"
                id="file"
                style={{ display: 'none' }}
                onChange={uploadFile}
              />
              <Button color="teal" type="submit">
                Submit
              </Button>
            </Segment>
          </Form>
        </Grid.Column>
      </Grid>
    </div>
  );
};

const CreateEntity = () => (
  <div>
    <CreateEntityForm />
  </div>
);

export default CreateEntity;
