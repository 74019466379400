import React, { useState } from 'react';
import { Table, Radio, Segment } from 'semantic-ui-react';
import colors from '../../../components/Styles/Colors';
import Assessments from './Assessments';

const TaxBillsTable = ({ park, taxBills }) => {
  const { selectedIndex } = useState(0);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const selectedChanged = () => {
    // handleSelectionChange(data.id);
  };

  const percent = (value) => (value ? `${value}%` : null);

  const currency = (value) => (value ? formatter.format(value) : null);

  const renderNormalRow = (taxBill, index) => {
    const { model } = taxBill;

    return (
      <Table.Row key={taxBill.id}>
        <Table.Cell textAlign="center">
          <Radio
            id={model.id}
            checked={selectedIndex === index}
            disabled={false}
            onChange={selectedChanged}
          />
        </Table.Cell>
        <Table.Cell textAlign="center">{model.year}</Table.Cell>
        <Table.Cell textAlign="center">
          {percent(model.assessedValueRate)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {percent(model.landImprovementsRate)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.perPropertyTotalTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.specialATax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.totalAssessedValue)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(model.totalReassessedValue)}
        </Table.Cell>
        <Table.Cell textAlign="left">{taxBill.notes}</Table.Cell>
        <Table.Cell textAlign="center" />
      </Table.Row>
    );
  };

  const renderTaxBillHeader = () => (
    <>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell textAlign="center">Selected</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Tax Year</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Assessed Value Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Land & Impr Rate
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Personal Property
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Special A Tax</Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            Total Assessed Value
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">
            New Assessed Value
          </Table.HeaderCell>
          <Table.HeaderCell width={5} textAlign="center">
            Notes
          </Table.HeaderCell>
          <Table.HeaderCell width={1} textAlign="center">
            Actions
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
    </>
  );

  const renderSelectedAssessments = () => {
    const color = colors[selectedIndex % colors.length];
    const index = selectedIndex < taxBills.length ? selectedIndex : 0;
    const taxBill = taxBills[index];

    if (!taxBill) {
      console.log('No tax bill', selectedIndex);
      return null;
    }

    if (!taxBill.assessments || !taxBill.supplementals) {
      return null;
    }

    return (
      <Segment color={color}>
        <Assessments taxBill={taxBill} showArchive={false} park={park} />
      </Segment>
    );
  };

  return (
    <>
      <Table celled striped>
        {renderTaxBillHeader()}
        <Table.Body>
          {taxBills.map((taxBill, index) => renderNormalRow(taxBill, index))}
        </Table.Body>
      </Table>
      {renderSelectedAssessments()}
    </>
  );
};

export default TaxBillsTable;
