import React from 'react';
import { UserContext } from '../../../services/context/UserContext';
import useProjects from './useProjects';
import ProjectsTable from './ProjectsTable';
import './Projects.scss';

const Projects = () => {
  const { user } = React.useContext(UserContext);
  const { error, projects } = useProjects(false);

  if (!user) {
    return <div>Loading..</div>;
  }

  if (error) {
    return <div>Error loading projects: {error}</div>;
  }

  return <ProjectsTable projects={projects} user={user} archive={false} />;
};

export default Projects;
