import React from 'react';
import SideBar from './SideBar/SideBar';
import AdminContent from './AdminContent/AdminContent';
import EnsureUser from '../../components/EnsureUser/EnsureUser';

const Admin = () => (
  <EnsureUser>
    <SideBar />
    <AdminContent />
  </EnsureUser>
);

export default Admin;
