import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import useSummary from './useSummary';

const BudgetSummary = ({ categories }) => {
  const { income, budgeted } = useSummary(categories);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const currency = (value) => {
    if (value) {
      return formatter.format(value);
    }
    return null;
  };

  const isNegative = () => income - budgeted < 0;

  // const renderDmfRow = () => (
  //   <Table.Row key="BudgetSummary:dmf">
  //     <Table.Cell />
  //     <Table.Cell textAlign="center">DMF</Table.Cell>
  //     <Table.Cell textAlign="center">{currency(dmfIncome)}</Table.Cell>
  //     <Table.Cell textAlign="center">{currency(dmfBudgeted)}</Table.Cell>
  //     <Table.Cell textAlign="center">
  //       {currency(dmfIncome - dmfBudgeted)}
  //     </Table.Cell>
  //     <Table.Cell textAlign="left">Move to DMF Account</Table.Cell>
  //   </Table.Row>
  // );

  const renderRegularRow = () => (
    <Table.Row key="BudgetSummary:regular">
      <Table.Cell />
      <Table.Cell textAlign="center">Operating</Table.Cell>
      <Table.Cell textAlign="center">{currency(income)}</Table.Cell>
      <Table.Cell textAlign="center">{currency(budgeted)}</Table.Cell>
      <Table.Cell textAlign="center" negative={isNegative()}>
        {currency(income - budgeted)}
      </Table.Cell>
      <Table.Cell textAlign="center" />
    </Table.Row>
  );

  const renderTable = () => (
    <Table celled striped>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell />
          <Table.HeaderCell width={2} textAlign="center">
            Budget Summary
          </Table.HeaderCell>
          <Table.HeaderCell width={2} textAlign="center">
            Income
          </Table.HeaderCell>
          <Table.HeaderCell width={2} textAlign="center">
            Expense
          </Table.HeaderCell>
          <Table.HeaderCell width={2} textAlign="center">
            Over/Under
          </Table.HeaderCell>
          <Table.HeaderCell textAlign="center">Notes</Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {renderRegularRow()}
        {/* {renderDmfRow()} */}
      </Table.Body>
    </Table>
  );

  return renderTable();
};

BudgetSummary.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default BudgetSummary;
