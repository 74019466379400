import React from 'react';
import Markdown from 'markdown-to-jsx';
import { Container, Dimmer, Divider, Header, Loader } from 'semantic-ui-react';
import useStorageText from '../../../components/Hooks/useStorageText';
import './Help.scss';

const Help = () => {
  const { text, error } = useStorageText('help/Reserves-Help.md');

  if (!text) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  if (error) {
    return <div>Error</div>;
  }

  return (
    <div className="help-content">
      <Container text>
        <Header as="h3" textAlign="center" block>
          <Divider horizontal>Reserves Help</Divider>
        </Header>
        <Markdown>{text}</Markdown>
      </Container>
    </div>
  );
};

export default Help;
