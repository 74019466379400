import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import { digits } from '../../../../services/number/formatting';

const EditForm = ({ fieldUpdated, assessment }) => (
  <Form>
    <Form.Group>
      <Form.Input
        disabled
        width={2}
        label="Coach"
        id="form-input-coach"
        value={assessment.coach}
        onChange={(_, data) => {
          fieldUpdated('coach', data.value);
        }}
      />
      <Form.Input
        width={2}
        label="Land Value"
        id="form-input-lv"
        value={assessment.landValue}
        onChange={(_, data) => {
          fieldUpdated('landValue', digits(data.value));
        }}
      />
      <Form.Input
        width={2}
        label="Improvements"
        id="form-input-imp"
        value={assessment.improvements}
        onChange={(_, data) => {
          fieldUpdated('improvements', digits(data.value));
        }}
      />
      <Form.Input label="Exemption" id="form-input-hoe">
        <Checkbox
          label="HOE"
          checked={assessment.exemption}
          onChange={(_, data) => {
            fieldUpdated('exemption', data.checked);
          }}
        />
      </Form.Input>
      {assessment.exemption && (
        <Form.Input
          width={2}
          label="Amount"
          id="form-input-hoe"
          value={assessment.hoeAmount}
          onChange={(_, data) => {
            fieldUpdated('hoeAmount', digits(data.value));
          }}
        />
      )}
    </Form.Group>
  </Form>
);

export default EditForm;
