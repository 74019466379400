import { useState } from 'react';
import { db } from '../../../../services/firebase/firebase';
import {
  parseLocaleNumber,
  parseLocaleFloat,
} from '../../../../services/number/formatting';
import useComponentSets from '../../ComponentSets/useComponentSets';

// Custom hook for CRUD Study. Provide optional callbacks for
// success and failure notification.
const useStudy = (studyOrNull, success, failure) => {
  const { sets, fetchError } = useComponentSets();
  const [busy, setBusy] = useState(false);

  const statusOptions = sets.map((set) => ({
    key: set.id,
    text: set.name,
    value: set.id,
  }));

  const [study, setStudy] = useState({
    comps: '',
    name: studyOrNull.name || '',
    notes: studyOrNull.notes || '',
    studyYear: studyOrNull.studyYear || '',
    startBalance: studyOrNull.startingReserveBalance || '',
    inflationRate: studyOrNull.inflationRate || '',
    interestRate: studyOrNull.interestRate || '',
    assessment: studyOrNull.assessment || '',
    annualIncrease: studyOrNull.annualAllocationIncrease || '',
    annualAmounts: studyOrNull.annualAllocationAmounts || Array(30).fill(''),
  });

  const convertAllocAmountsToInts = () =>
    study.annualAmounts.map((amount) => parseInt(amount));

  const makeVariables = () => ({
    notes: study.notes,
    name: study.name,
    isArchived: false,
    isSelected: false,
    studyYear: parseLocaleNumber(study.studyYear),
    startingReserveBalance: parseLocaleNumber(study.startBalance),
    monthlyReserveAllocation: parseLocaleNumber(study.annualAmounts[0]),
    inflationRate: parseLocaleFloat(study.inflationRate),
    interestRate: parseLocaleFloat(study.interestRate),
    assessment: parseLocaleNumber(study.assessment),
    annualAllocationIncrease: parseLocaleFloat(study.annualIncrease),
    annualAllocationAmounts: convertAllocAmountsToInts(),
    balance: {},
  });

  // Returns the selected component set of components with their
  // remainingUsefulLife and replacementCost values adjusted for
  // the difference between the study year and the component set
  // creation year.
  const adjustYear = (selectedSet) => {
    const { components } = selectedSet;
    const studyYearDiff = parseLocaleNumber(study.studyYear) - selectedSet.year;
    const inflationRate = parseLocaleFloat(study.inflationRate);

    return components.map((comp) => {
      const newRemLife = Math.max(0, comp.remainingUsefulLife - studyYearDiff);
      const newRepCost = Math.round(
        comp.replacementCost * (1 + inflationRate) ** studyYearDiff
      );
      return {
        ...comp,
        remainingUsefulLife: newRemLife,
        replacementCost: newRepCost,
      };
    });
  };

  const makeCreateVariables = () => {
    const selectedSet = sets.find((set) => set.id === study.comps);
    if (selectedSet) {
      const components = adjustYear(selectedSet);
      // Create with the current componet set
      return { components, ...makeVariables() };
    }
    // Create without components
    return makeVariables();
  };

  const canCreate = () =>
    study.name &&
    study.studyYear &&
    study.startBalance &&
    study.inflationRate &&
    study.interestRate &&
    !study.comps.isEmpty &&
    !study.annualAmounts.isEmpty;

  const canUpdate = () => true;

  const doCreate = async () => {
    const vars = makeCreateVariables();
    console.log('create Study vars ', vars);

    setBusy(true);

    db.collection('studies')
      .add(vars)
      .then(() => {
        setBusy(false);
        success();
      })
      .catch((error) => {
        console.log('Error creating Study', error);
        setBusy(false);
        failure(error.message);
      });
  };

  const doUpdate = async () => {
    const variables = makeVariables();
    console.log('update Study vars ', variables);

    db.collection('studies')
      .doc(studyOrNull.id)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating study', error);
        failure(error.message);
      });
  };

  const handleChange = (name, value) => {
    // console.log('handleChange', name, value);
    setStudy({ ...study, [name]: value });
  };

  return {
    busy,
    fetchError,
    statusOptions,
    handleChange,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    study,
  };
};

export default useStudy;
