import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';

const useDMFs = (archive = false) => {
  const [dmfs, setDmfs] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setLoading(false);

    try {
      const unsubscribe = db
        .collection('dmf')
        .where('archive', '==', archive)
        .onSnapshot((snapshot) => {
          const all = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));

          setDmfs(all);
          console.log('DMFs', all);
        });

      return () => {
        console.log('cleanup DMFs');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [archive]);

  return {
    fetchError,
    loading,
    dmfs,
  };
};

export default useDMFs;
