import React from 'react';
import SideBar from './SideBar/SideBar';
import BudgetContent from './BudgetContent/BudgetContent';
import EnsureUser from '../../components/EnsureUser/EnsureUser';

const Budget = () => (
  <EnsureUser>
    <SideBar />
    <BudgetContent />
  </EnsureUser>
);
export default Budget;
