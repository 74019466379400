import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const EditForm = ({ fieldUpdated, loan }) => (
  <Form>
    <Form.Group>
      <Form.Input
        required
        width={3}
        fluid
        label="Name"
        id="form-input-name"
        value={loan.name}
        onChange={(_, data) => {
          fieldUpdated('name', data.value);
        }}
      />
      <Form.Input
        required
        width={2}
        fluid
        label="Amount"
        id="form-input-amount"
        value={loan.amount}
        onChange={(_, data) => {
          fieldUpdated('amount', data.value);
        }}
      />
      <Form.Input
        required
        width={2}
        fluid
        label="Interest %"
        id="form-input-rate"
        value={loan.rate}
        onChange={(_, data) => {
          fieldUpdated('rate', data.value);
        }}
      />
      <Form.Input
        required
        width={2}
        fluid
        label="Term Years"
        id="form-input-term"
        value={loan.term}
        onChange={(_, data) => {
          fieldUpdated('term', data.value);
        }}
      />
    </Form.Group>
    <Form.Group>
      <Form.Input
        width={16}
        fluid
        label="Notes"
        id="form-input-note"
        value={loan.note}
        onChange={(_, data) => {
          fieldUpdated('note', data.value);
        }}
      />
    </Form.Group>
  </Form>
);

EditForm.propTypes = {
  loan: PropTypes.object.isRequired,
};

export default EditForm;
