import React, { useState } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import firebase from 'firebase/app';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import { statusOptions } from '../StatusOptions';
import useProject from './useProject';
import EditForm from './EditForm';
import 'firebase/storage';

const { uuid } = require('uuidv4');

const EditProject = ({ editing }) => {
  let noAutoClose = false;
  const [open, setOpen] = useState(false);
  const {
    project,
    doAddImageUrl,
    doUpdate,
    canUpdate,
    handleChange,
  } = useProject(
    editing,
    () => {
      setOpen(noAutoClose);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const uploadToFirebaseStorage = async (file) => {
    const fileName = `${uuid()}.jpeg`;
    const storage = firebase.storage();
    console.log('storageRef', storage);
    const storageRef = storage.ref();
    console.log('ref', storageRef);
    const imagesRef = storageRef.child(`images/${fileName}`);
    await imagesRef.put(file).then((snapshot) => {
      console.log('uploaded', snapshot, imagesRef.fullPath);
      imagesRef.getDownloadURL().then((value) => {
        console.log('downloadURL', value);
        noAutoClose = true;
        doAddImageUrl(value);
      });
    });
  };

  // Upload a single file TODO! multiple files
  const uploadFile = (acceptedFiles) => {
    // console.log("acceptedFiles", acceptedFiles);
    uploadToFirebaseStorage(acceptedFiles[0]);
  };

  const disabled = () => !canUpdate();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name="edit outline" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Project" />
      <Modal.Content>
        <EditForm
          options={statusOptions}
          project={project}
          fieldUpdated={handleChange}
          uploadFile={uploadFile}
          imageUrls={editing.imageUrls}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditProject;
