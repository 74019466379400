import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';

const useUsers = () => {
  const [users, setUsers] = useState([]);
  const [error, setError] = useState();

  useEffect(() => {
    try {
      const unsubscribe = db.collection('users').onSnapshot((snapshot) => {
        const all = snapshot.docs
          .map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }))
          .map((user) => {
            if (user.updated) {
              return { ...user, updated: user.updated.toDate() };
            }
            return user;
          })
          .sort((a, b) => (a.space > b.space ? 1 : -1));

        setUsers(all);
        console.log('Users', all);
      });
      return () => {
        console.log('cleanup  users');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setError(error.message);
    }
  }, []);

  return {
    error,
    users,
  };
};

export default useUsers;
