import React from 'react';
import { Form } from 'semantic-ui-react';
import { digits } from '../../../../../services/number/formatting';

const EditForm = ({ fieldUpdated, payment }) => (
  <Form>
    <Form.Group>
      <Form.Input
        width={4}
        label="Name"
        id="form-input-name"
        value={payment.name}
        onChange={(_, data) => {
          fieldUpdated('name', data.value);
        }}
      />
      <Form.Input
        width={2}
        fluid
        label="Amount"
        id="form-input-amount"
        value={payment.amount}
        onChange={(_, data) => {
          fieldUpdated('amount', digits(data.value));
        }}
      />
      <Form.Input
        width={4}
        label="Source"
        id="form-input-source"
        value={payment.source}
        onChange={(_, data) => {
          fieldUpdated('source', data.value);
        }}
      />
    </Form.Group>
    <Form.Group>
      <Form.TextArea
        width={16}
        label="Notes"
        id="form-input-notes"
        value={payment.notes}
        onChange={(_, data) => {
          fieldUpdated('notes', data.value);
        }}
      />
    </Form.Group>
  </Form>
);
export default EditForm;
