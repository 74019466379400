import React from 'react';
import { UserContext } from '../../services/context/UserContext';
import { ParkContext } from '../../services/context/ParkContext';
import Can from '../../components/Can/Can';
import ResidentsTable from './ResidentsTable';
import SpacesTable from './SpacesTable';

const renderSpacesTable = (spaces, user) => (
  <SpacesTable spaces={spaces} user={user} showArchive={false} />
);

const renderResidentsTable = (spaces) => <ResidentsTable spaces={spaces} />;

const Spaces = () => {
  const { user } = React.useContext(UserContext);
  const { park } = React.useContext(ParkContext);

  if (!user || !park) {
    return <div>Loading...</div>;
  }

  return (
    <Can
      role={user.role}
      perform="park:edit"
      yes={() => renderSpacesTable(park.spaces, user)}
      no={() => renderResidentsTable(park.spaces)}
    />
  );
};

export default Spaces;
