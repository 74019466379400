import React from 'react';
import jsPDF from 'jspdf';
import { Button } from 'semantic-ui-react';
import autoTable from 'jspdf-autotable';
import { PigeonBase64 } from './PigeonBase64';

const styles = {
  fillColor: 250, // 0=black, 255=white (grayscale)
  textColor: 60,
  lineColor: 20,
  lineWidth: 0.1,
  minCellHeight: 6,
  tableLineColor: 30,
  theme: 'grid',
};

const itemStyles = {
  fillColor: 250, // 0=black, 255=white (grayscale)
  textColor: 60,
  lineColor: 20,
  lineWidth: 0.1,
  minCellHeight: 12,
  tableLineColor: 30,
  theme: 'grid',
};

const headStyles = {
  fillColor: 240, // 0=black, 255=white (grayscale)
  textColor: 40,
  lineColor: 20,
  fontSize: 12,
  font: 'times',
  lineWidth: 0.1,
  minCellHeight: 15,
  tableLineColor: 30,
};

function simpleTable() {
  // eslint-disable-next-line new-cap
  const doc = new jsPDF();

  let finalY = doc.lastAutoTable.finalY || 10;
  // console.log("pw", doc.internal.pageSize.width);

  autoTable(doc, {
    startY: finalY,
    head: [
      [
        {
          content: '',
          colSpan: 3,
          styles: {
            halign: 'center',
            valign: 'middle',
            font: 'times',
            fontSize: 18,
          },
        },
      ],
    ],
    margin: { left: 2, right: 2 },
    headStyles,
    didDrawCell: (data) => {
      console.log('didDrawCell', data);
      // console.log(data.section, data.row.index, data.column.index);
      if (data.section === 'head' && data.row.index === 0) {
        doc.addImage(
          PigeonBase64,
          'JPEG',
          data.cell.x + 4,
          data.cell.y + 2.5,
          10,
          10
        );
        doc.text('Paseo de Palomas', 20, 20);
      }
    },
  });
  finalY = doc.lastAutoTable.finalY;

  autoTable(doc, {
    startY: finalY,
    head: [
      [
        {
          content: 'Name',
          styles: {
            halign: 'left',
            valign: 'middle',
            cellWidth: 50,
            minCellHeight: 6,
          },
        },
        {
          content: 'Address',
          styles: { halign: 'center', valign: 'middle', minCellHeight: 6 },
        },
        {
          content: 'Date',
          styles: {
            halign: 'center',
            valign: 'middle',
            cellWidth: 30,
            minCellHeight: 6,
          },
        },
      ],
    ],
    body: [
      [
        {
          content: '',
          styles: { minCellHeight: 12 },
        },
      ],
    ],
    margin: { left: 2, right: 2 },
    styles,
    headStyles,
  });
  finalY = doc.lastAutoTable.finalY;

  autoTable(doc, {
    startY: finalY,
    head: [
      [
        {
          content: 'Purchase Reason',
          styles: { halign: 'left', valign: 'middle', minCellHeight: 6 },
        },
      ],
    ],
    body: [
      [
        {
          content: '',
          styles: { minCellHeight: 12 },
        },
      ],
    ],
    margin: { left: 2, right: 2 },
    styles,
    headStyles,
  });
  finalY = doc.lastAutoTable.finalY;

  autoTable(doc, {
    startY: finalY,
    head: [
      [
        {
          content: 'Vendor',
          styles: {
            halign: 'left',
            valign: 'middle',
            cellWidth: 50,
            minCellHeight: 6,
          },
        },
        {
          content: 'Item',
          styles: { halign: 'center', valign: 'middle', minCellHeight: 6 },
        },
        {
          content: 'Amount',
          styles: {
            halign: 'center',
            valign: 'middle',
            cellWidth: 30,
            minCellHeight: 6,
          },
        },
      ],
    ],
    body: [[], [], [], [], [], [], [], [], [], [], [], []],
    margin: { left: 2, right: 2 },
    headStyles,
    styles: itemStyles,
  });
  finalY = doc.lastAutoTable.finalY;

  autoTable(doc, {
    startY: finalY,
    head: [
      [
        {
          content: 'Total',
          colSpan: 2,
          styles: { minCellHeight: 10, halign: 'right', valign: 'middle' },
        },
        {
          content: '',
          styles: { cellWidth: 30 },
        },
      ],
    ],
    body: [
      [
        {
          content: '',
          colSpan: 3,
          styles: {},
        },
      ],
    ],
    margin: { left: 2, right: 2 },
    styles,
    headStyles,
  });
  finalY = doc.lastAutoTable.finalY;

  autoTable(doc, {
    startY: finalY,
    head: [
      [
        {
          content: 'Submitted By',
          styles: { halign: 'left', valign: 'middle', minCellHeight: 6 },
        },
        {
          content: 'Approved By',
          styles: { halign: 'left', valign: 'middle', minCellHeight: 6 },
        },
        {
          content: 'Date',
          styles: {
            halign: 'center',
            valign: 'middle',
            cellWidth: 30,
            minCellHeight: 6,
          },
        },
      ],
    ],
    body: [
      [
        {
          content: '',
          styles: { minCellHeight: 12 },
        },
        {
          content: '',
          styles: { minCellHeight: 12 },
        },
        {
          content: '',
          styles: { minCellHeight: 12 },
        },
      ],
    ],
    margin: { left: 2, right: 2 },
    headStyles,
    styles,
  });
  return doc;
}

const doSimpleTable = () => {
  const doc = simpleTable();
  doc.autoPrint({ variant: 'non-conform' });
  doc.save('TestPrint.pdf');
};

const TestPrint = () => (
  <div>
    <Button
      floated="left"
      color="teal"
      basic
      size="small"
      style={{ margin: '6px 20px 10px' }}
      onClick={() => doSimpleTable()}
    >
      Download Reimburse Form
    </Button>
  </div>
);

export default TestPrint;
