import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Segment,
  Message,
} from 'semantic-ui-react';
import firebase from 'firebase/app';
import { db } from '../../../services/firebase/firebase';

const Register = ({ history }) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [confirm, setConfirm] = useState(null);
  const [passwordError, setPasswordError] = useState(false);
  const [errorMessage, setErrorMessage] = useState(null);

  const _updateUserWithDocId = async (docId) => {
    db.collection('users')
      .doc(docId)
      .update({ status: 'ACTIVE' })
      .catch((error) => {
        console.log('Error updating user ', error);
      });
  };

  const _updateUser = async (emailArg) => {
    db.collection('users')
      .where('email', '==', emailArg)
      .get()
      .then((querySnapshot) => {
        querySnapshot.forEach((snapshot) => {
          _updateUserWithDocId(snapshot.id);
        });
      })
      .catch((error) => {
        console.log('error', error);
      });
  };

  const _register = () => {
    firebase
      .auth()
      .createUserWithEmailAndPassword(email, password)
      .then(() => {
        _updateUser(email);
        history.push('/');
      })
      .catch(function (error) {
        const { code } = error;
        const { message } = error;
        console.log('error ', code, message);
        setErrorMessage(message);
      });
  };

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src="Pigeon.png" avatar />
          Register for access
        </Header>
        <Form
          size="large"
          onSubmit={async (e) => {
            e.preventDefault();
            setErrorMessage(null);

            if (confirm !== password) {
              setPasswordError(true);
              setErrorMessage('Passwords do not match.');
              return;
            }

            try {
              _register();
            } catch (err) {
              console.error('Caught in try/catch', err.message);
              setErrorMessage('Register error: ', err);
            }
          }}
        >
          <Segment stacked>
            {errorMessage ? (
              <Message
                visible={!!errorMessage}
                error
                header="Register Error"
                content={errorMessage}
              />
            ) : null}
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Email"
              onChange={(_, data) => {
                setEmail(data.value);
              }}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              error={passwordError}
              onChange={(_, data) => {
                setPassword(data.value);
                setPasswordError(false);
              }}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Confirm password"
              type="password"
              error={passwordError}
              onChange={(_, data) => {
                setConfirm(data.value);
                setPasswordError(false);
              }}
            />
            <Button
              color="teal"
              fluid
              size="large"
              type="submit"
              disabled={!email || !password || !confirm}
            >
              Register
            </Button>
            <Message>NOTE: You must pre-register with the manager</Message>
            <Message>
              Already registered? <a href="/account">Sign In</a>
            </Message>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

Register.propTypes = {
  history: PropTypes.object.isRequired,
};

export default withRouter(Register);
