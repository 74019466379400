import React from 'react';
import { UserContext } from '../../../services/context/UserContext';
import { ParkContext } from '../../../services/context/ParkContext';
import TaxBillsTable from './TaxBillsTable';
import Archives from './Archives';

const TaxBills = ({ taxBills, showArchive }) => {
  const { user } = React.useContext(UserContext);
  const { park } = React.useContext(ParkContext);

  if (!park || !user) {
    return null;
  }

  // console.log('TaxBills.taxBills', taxBills);
  // console.log('TaxBills.park', park);

  // CAUTION here - TaxesContent might send archived tax bills
  // or current tax bills, so we don't get the tax bills out of
  // the Park object. Maybe some redesign is needed here ? ?

  if (showArchive) {
    return (
      <Archives
        park={park}
        taxBills={taxBills}
        showArchive={showArchive}
        user={user}
      />
    );
  }

  return (
    <TaxBillsTable
      park={park}
      taxBills={taxBills}
      showArchive={showArchive}
      user={user}
    />
  );
};

export default TaxBills;
