import { useState, useEffect } from 'react';
import { parseLocaleNumber } from '../../../services/number/formatting';
import { db } from '../../../services/firebase/firebase';

// Custom hook for CRUD Space Pedestal. Provide callbacks for
// success and failure notification.
const useElectrical = (park, spaceNumber, success, failure) => {
  const [errorMessage, setErrorMessage] = useState(null);
  const [pedestal, setPedestal] = useState();

  useEffect(() => {
    if (spaceNumber > 0) {
      const space = park.spaceFor(spaceNumber);
      setPedestal({
        number: space.number || '',
        main: space.pedestal?.main || '',
        coach: space.pedestal?.coach || '',
        ac: space.pedestal?.ac || '',
        acMax: space.pedestal?.acMax || '',
        aux1: space.pedestal?.aux1 || '',
        aux2: space.pedestal?.aux2 || '',
        aux3: space.pedestal?.aux3 || '',
        notes: space.pedestal?.notes || '',
        isNew: space.pedestal?.isNew,
        isCited: space.pedestal?.isCited,
      });
    } else {
      setPedestal({
        number: '',
        main: '',
        coach: '',
        ac: '',
        acMax: '',
        aux1: '',
        aux2: '',
        aux3: '',
        notes: '',
        isNew: true,
        isCited: false,
      });
    }
  }, [park, spaceNumber]);

  // Returns variables for edit
  const makeVariables = () => {
    const vars = {
      main: parseLocaleNumber(pedestal.main),
      coach: parseLocaleNumber(pedestal.coach),
      ac: parseLocaleNumber(pedestal.ac),
      acMax: parseLocaleNumber(pedestal.acMax),
      aux1: parseLocaleNumber(pedestal.aux1),
      aux2: parseLocaleNumber(pedestal.aux2),
      aux3: parseLocaleNumber(pedestal.aux3),
      isCited: pedestal.isCited,
      isNew: pedestal.isNew,
      notes: pedestal.notes,
    };
    return vars;
  };

  const makeCreateVariables = () => makeVariables();

  const canCreate = () => pedestal.main && pedestal.coach;

  const canUpdate = () => true;

  const doCreate = async () => {
    const vars = makeCreateVariables();
    console.log('create Pedestal vars', vars);
  };

  const update = async (variables) => {
    console.log('update Pedestal vars ', variables, pedestal);

    // Be sure to use the right one here
    const space = park.spaceFor(parseLocaleNumber(pedestal.number));

    const ref = db.collection('spaces').doc(space.id);
    ref
      .update({
        pedestal: { ...variables },
        updated: Date(),
      })
      .then(function () {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.error('Error updating pedestal: ', error);
        failure(errorMessage);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const handleChange = (name, value) => {
    setPedestal({ ...pedestal, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    canUpdate,
    doUpdate,
    canCreate,
    doCreate,
    pedestal,
  };
};

export default useElectrical;
