import React from 'react';
import { Segment } from 'semantic-ui-react';
import ImportListings from './EditListing/ImportListings';
import ListingsTable from './ListingsTable';
import useListings from './useListings';
import { UserContext } from '../../../services/context/UserContext';

// Function component to query for active listings. Update state
// and render the table with the listings.
const Listings = () => {
  const { listings, fetchError } = useListings();

  if (fetchError) {
    return (
      <div>
        <Segment textAlign="center" size="small" color="red">
          {`Error getting listings: ${fetchError.message}`}
        </Segment>
      </div>
    );
  }

  if (!listings || listings.length === 0) {
    return (
      <div>
        <ImportListings />
      </div>
    );
  }

  return (
    <div>
      <UserContext.Consumer>
        {({ user }) => (
          <ListingsTable listings={listings} showArchive={false} user={user} />
        )}
      </UserContext.Consumer>
    </div>
  );
};

export default Listings;
