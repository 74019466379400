import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { withRouter } from 'react-router-dom';
import { Button, Table } from 'semantic-ui-react';
import Can from '../../../components/Can/Can';
import { db } from '../../../services/firebase/firebase';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import EditLineItem from './EditLineItem/EditLineItem';

const LineItem = (props) => {
  const [lineItem, setLineItem] = useState(props.lineItem);
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    setLineItem(props.lineItem);
  }, [props]);

  const currency = (value) => {
    if (value) {
      return formatter.format(value);
    }
    return null;
  };

  const deleteConfirmed = async (id) => {
    console.log('deleteConfirmed', id);
    db.collection('budgets')
      .doc(props.budget.id)
      .collection('lineItems')
      .doc(id)
      .delete()
      .catch((error) => {
        console.log('Error deleting LineItem', error);
      });
  };

  const renderButtons = () => (
    <Button.Group basic size="mini">
      <EditLineItem budgetId={props.budget.id} editItem={lineItem} />
      <ConfirmDelete
        deleteId={lineItem.id}
        name={lineItem.name}
        deleteConfirmed={deleteConfirmed}
      />
    </Button.Group>
  );

  const renderAllowedEditButtons = (user) => (
    <Can
      role={user.role}
      perform="budget:edit"
      data={{
        userId: user.id,
        ownerId: user.id,
      }}
      yes={() => renderButtons()}
    />
  );

  const renderTableRow = (user) => (
    <Table.Row key={lineItem.id}>
      <Table.Cell textAlign="left">{lineItem.category}</Table.Cell>
      <Table.Cell textAlign="center">{lineItem.cid}</Table.Cell>
      <Table.Cell textAlign="left">{lineItem.name}</Table.Cell>
      <Table.Cell textAlign="center">{currency(lineItem.amount)}</Table.Cell>
      <Table.Cell textAlign="center" style={{ color: '#999' }}>
        {currency(lineItem.prevYear)}
      </Table.Cell>
      <Table.Cell textAlign="left">{lineItem.notes}</Table.Cell>
      <Table.Cell textAlign="center">
        {renderAllowedEditButtons(user)}
      </Table.Cell>
    </Table.Row>
  );

  return renderTableRow(props.user);
};

LineItem.propTypes = {
  budget: PropTypes.object.isRequired,
  lineItem: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

export default withRouter(LineItem);
