import { useState } from 'react';
import { parseLocaleNumber } from '../../../../services/number/formatting';
import { db } from '../../../../services/firebase/firebase';
import TypeOptions from '../TypeOptions';

// Custom hook for CRUD LineItem. Provide optional callbacks for
// success and failure notification.
const useLineItem = (
  budgetId,
  itemOrNull,
  success = () => {},
  failure = () => {}
) => {
  const [errorMessage, setErrorMessage] = useState();

  const [lineItem, setLineItem] = useState({
    cid: itemOrNull.cid || '',
    // type: itemOrNull.type || "",
    name: itemOrNull.name || '',
    notes: itemOrNull.notes || '',
    amount: itemOrNull.amount || '',
    category: itemOrNull.category || '',
    type: itemOrNull.type || TypeOptions.INCOME,
  });

  const original = itemOrNull;

  // Returns variables for edit
  const makeVariables = () => ({
    cid: lineItem.cid,
    name: lineItem.name,
    notes: lineItem.notes,
    type: lineItem.type,
    category: lineItem.category,
    amount: parseLocaleNumber(lineItem.amount),
  });

  const makeCreateVariables = () => makeVariables();

  const canCreate = () => lineItem.cid && lineItem.name && lineItem.category;

  const canUpdate = () => true;

  const doCreate = async () => {
    const vars = makeCreateVariables();
    console.log('create LineItem vars ', vars);

    db.collection('budgets')
      .doc(budgetId)
      .collection('lineItems')
      .add(vars)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error creating LineItem', error);
        failure(error.message);
      });
  };

  const update = async (variables) => {
    console.log('update LineItem vars ', variables);

    db.collection('budgets')
      .doc(budgetId)
      .collection('lineItems')
      .doc(original.id)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating LineItem', error);
        setErrorMessage(error.message);
        failure(error.message);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const doSetSelected = (selected) => {
    update({ selected });
  };

  const doDelete = async (id) => {
    console.log('doDelete', id);

    db.collection('budgets')
      .doc(budgetId)
      .collection('lineItems')
      .doc(id)
      .delete()
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error deleting LineItem', error);
        setErrorMessage(error.message);
        failure(error.message);
      });
  };

  const handleChange = (name, value) => {
    setLineItem({ ...lineItem, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    doSetSelected,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doDelete,
    lineItem,
  };
};

export default useLineItem;
