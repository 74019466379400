import React, { Component } from 'react';
import { Menu, Divider } from 'semantic-ui-react';
import SideBarItem from '../../SideBar/SideBarItem/SideBarItem';
import SideBarHeader from '../../SideBar/SideBarHeader/SideBarHeader';
import SideBarFooter from '../../SideBar/SideBarFooter/SideBarFooter';
import './SideBar.scss';

class SideBar extends Component {
  render() {
    return (
      <Menu borderless vertical stackable fixed="left" className="side-nav">
        <SideBarItem path="/" label="Home" icon="home" />
        <Divider fitted />
        <SideBarHeader title="Finance" />
        <SideBarItem path="active" label="Active Budgets" icon="table" />
        <SideBarItem path="charts" label="Budget Charts" icon="chart pie" />
        <SideBarItem path="archived" label="Archived Budgets" icon="table" />
        <SideBarItem
          path="activeprojects"
          label="Active Projects"
          icon="clipboard outline"
        />
        <SideBarItem
          path="archivedprojects"
          label="Archived Projects"
          icon="clipboard outline"
        />
        <Divider fitted />
        <Divider fitted />
        <SideBarItem path="loans" label="Loan Calculator" icon="currency" />
        <SideBarItem
          path="archivedloans"
          label="Archived Loans"
          icon="currency"
        />
        <Divider fitted />
        <SideBarItem path="help" label="Help" icon="question circle outline" />
        <Divider />
        <SideBarFooter />
      </Menu>
    );
  }
}

export default SideBar;
