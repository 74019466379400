import { useState, useEffect } from 'react';
import { storage } from '../../services/firebase/firebase';

const useStorageText = (filePath) => {
  const [text, setText] = useState();
  const [error, setError] = useState();

  useEffect(() => {
    const gsReference = storage.refFromURL('gs://paseo-de-palomas.appspot.com');
    gsReference
      .child(filePath)
      .getDownloadURL()
      .then(function (url) {
        console.log('download url', url);
        fetch(url)
          .then((response) => {
            if (response.ok) return response.text();
            // eslint-disable-next-line prefer-promise-reject-errors
            return Promise.reject("Didn't fetch text correctly");
          })
          .then((response) => {
            setText(response);
          })
          .catch((error) => {
            console.error('Download error', error);
            setError(error);
          });
      })
      .catch(function (error) {
        console.log('error', error);
        setError(error);
      });
  }, [filePath]);

  return {
    error,
    text,
  };
};

export default useStorageText;
