import React, { Component } from 'react';
import { Menu, Divider } from 'semantic-ui-react';
import SideBarItem from '../../SideBar/SideBarItem/SideBarItem';
import SideBarHeader from '../../SideBar/SideBarHeader/SideBarHeader';
import SideBarFooter from '../../SideBar/SideBarFooter/SideBarFooter';
import './SideBar.scss';

class SideBar extends Component {
  render() {
    return (
      <Menu borderless vertical stackable fixed="left" className="side-nav">
        <SideBarItem path="/" label="Home" icon="home" />
        <Divider fitted />
        <SideBarHeader title="Reserves" />
        <SideBarItem path="studies" label="Active Studies" icon="chart line" />
        <SideBarItem path="activities" label="Study Activities" icon="table" />
        <SideBarItem path="components" label="Component Sets" icon="table" />
        <Divider />
        <SideBarItem path="archived" label="Archived Studies" icon="archive" />
        <Divider />
        <SideBarItem path="help" label="Help" icon="question circle outline" />
        {/* <SideBarItem
          path="feedback"
          label="Send feedback"
          icon="comment outline"
        /> */}
        <Divider />
        <SideBarFooter />
      </Menu>
    );
  }
}

export default SideBar;
