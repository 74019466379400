import TaxModel from './TaxModel';

//
// Represents the TaxBill, containing assessments and taxes.
//
export default class TaxBill {
  constructor(taxBill) {
    // console.log('Creating TaxBill with ', taxBill);
    this.id = taxBill.id;
    this.notes = taxBill.notes;
    this.year = taxBill.year;
    this.projected = taxBill.projected;
    this.projectedIncrease = taxBill.projectedIncrease;
    this.perPropertyTotalTax = taxBill.perPropertyTotalTax;
    this.totalAssessedValue = taxBill.totalAssessedValue;
    this.totalReassessedValue = taxBill.totalReassessedValue;
    this.assessedValueRate = taxBill.assessedValueRate;
    this.landImprovementsRate = taxBill.landImprovementsRate;
    this.exemptionAmount = taxBill.exemptionAmount;
    this.specialATax = taxBill.specialATax;
    this.assessments = taxBill.assessments;
    this.reassessments = taxBill.reassessments;
    this.supplementals = taxBill.supplementals;
    this.isArchive = taxBill.isArchive;

    // this._computeTaxes();

    this.model = new TaxModel(taxBill);
    // 252,725.58 prior year taxes
    // 240,110.04 currently calc'd total tax on reappraisals
    // 239,945.71 amount from county bill with refund checks
    // My calcs get close by changing the 5600 HOE to 7000 and
    // by changing 2 other HOE's from 0 to 7000.
    //     164.33 diff between
    //     164.46 refund check (equiv to 2 7000 tax amounts)
    //      65.80 refund check (equiv to the 5600 HOE amount)
    //   12,797.79 main refund check
    //   13,028.05 total of all 3 refund checks
    //   12,615.54 my calc'd refund sub'ting prev year from this year
    // This value probably isn't relevant, but not sure why...
    //   12,878.65 my calc just totalling all refund amounts
    // 252x - 239x = 12,779.87
    // 12x - 12x = 17.92

    // if (this.assessments && this.supplementals) {
    //   this.commonTaxes = this._computePerPropValues();
    //   this.assessmentTaxes = this._makeAssessmentTaxesMap(this.commonTaxes);
    //   this.allTaxes = this._computeAllTaxesMap(this.assessmentTaxes);
    //   this.expectedHighValues = this._expectedHighAssessment();

    //   console.log("commonTaxes", this.commonTaxes);
    //   console.log("assessmentTaxes", this.assessmentTaxes);
    //   console.log("allTaxes", this.allTaxes);
    //   console.log("expectedHighValues", this.expectedHighValues);
    // }
  }

  // hasReassessments = () => this.reassessments && this.reassessments.length > 0;
  hasReassessments = () => this.model.hasReassessments();

  // setSupplementals = (supps) => {
  //   this.supplementals = supps;
  //   // this._computeTaxes();
  // };

  // exemptionAmountFor = (coachNumber) => {
  //   const assessment = this.assessmentFor(coachNumber);
  //   if (!assessment) return 0;
  //   if (!assessment.exemption) return 0;
  //   return assessment.hoeAmount ? assessment.hoeAmount : this.exemptionAmount;
  // };

  // assessmentFor = (coachNumber) => this.model.assessmentFor(coachNumber);
  // this.assessments.find((assessment) => assessment.coach === coachNumber);

  // assessmentTaxesFor = (coachNumber) => this.model.assessmentTaxesFor(coachNumber);
  // this.assessmentTaxes.get(coachNumber);

  // projectedLandValue = () =>
  //   Math.trunc(this.expectedHighValues.landValue * 1.02);

  // projectedImprovements = () =>
  //   Math.trunc(this.expectedHighValues.improvements * 1.02);

  // projectedAssessedValue = () =>
  //   this.projectedLandValue() + this.projectedImprovements();

  // makeAssessmentsMap = () =>
  //   this.assessments.reduce(
  //     (acc, assessment) => acc.set(assessment.space, assessment),
  //     new Map()
  //   );

  // _computeTaxes = () => {
  //   if (this.assessments && this.supplementals) {
  //     this.commonTaxes = this._computePerPropValues();
  //     // this.assessmentTaxes = this._makeAssessmentTaxesMap(this.commonTaxes);
  //     // this.allTaxes = this._computeAllTaxesMap(this.assessmentTaxes);
  //     // this.expectedHighValues = this._expectedHighAssessment();

  //     // console.log("commonTaxes", this.commonTaxes);
  //     // console.log("assessmentTaxes", this.assessmentTaxes);
  //     // console.log("allTaxes", this.allTaxes);
  //     // console.log("expectedHighValues", this.expectedHighValues);
  //   }
  // };

  _getHoeAmount = (assessment) => {
    if (!assessment.exemption) {
      return 0;
    }
    if (assessment.hoeAmount) {
      return assessment.hoeAmount;
    }
    return this.exemptionAmount;
  };

  _computePerPropValues = () => {
    const { assessments } = this;
    const improveTotal = assessments.reduce(
      (acc, assessment) => acc + assessment.improvements,
      0
    );
    const landTotal = assessments.reduce(
      (acc, assessment) => acc + assessment.landValue,
      0
    );
    const assessTotal = landTotal + improveTotal;

    const exemptTotal = assessments.reduce(
      (acc, assessment) => acc + this._getHoeAmount(assessment),
      0
    );

    const length = assessments.length > 0 ? assessments.length : 1;
    const be = (this.totalAssessedValue - assessTotal) / length;
    const pp = this.perPropertyTotalTax / length;
    const sa = this.specialATax / length;
    return this.createCommonTaxes(
      landTotal,
      improveTotal,
      assessTotal,
      be,
      pp,
      sa,
      exemptTotal
    );
  };

  _computeSupplementalTaxes = (assessment) =>
    this.supplementals.reduce(
      (acc, supp) =>
        supp.space === assessment.coach ? acc + supp.proratedTaxBill : acc,
      0
    );

  _makeAssessmentTaxesMap = (commonTaxes, useSupplemental = false) =>
    this.assessments.reduce((acc, assessment) => {
      const hoe = this._getHoeAmount(assessment);
      const suppTax = this._computeSupplementalTaxes(assessment);
      const suppMonthly = suppTax / 12;
      const amountsWithSupp = this.computeTaxAmounts(
        commonTaxes,
        this.findLandValue(assessment, useSupplemental),
        this.findImprovements(assessment, useSupplemental),
        suppMonthly,
        suppTax,
        hoe
      );
      return acc.set(assessment.coach, amountsWithSupp);
    }, new Map());

  _computeAllTaxesMap = (taxes) => {
    // Total with annual tax
    const att = this.assessments.reduce((acc, assessment) => {
      const obj = taxes.get(assessment.coach);
      return obj ? acc + obj.annualTax : acc;
    }, 0);

    // Total with projected increases
    const atpt = this.assessments.reduce((acc, assessment) => {
      const obj = taxes.get(assessment.coach);
      return obj ? acc + obj.projectedMonthlyTax : acc;
    }, 0);

    // Total of all supplementals
    const tst = this.assessments.reduce((acc, assessment) => {
      const obj = taxes.get(assessment.coach);
      return obj ? acc + obj.supplementalTax : acc;
    }, 0);

    // Monthly total annual tax
    const attm = att / 12;

    // const attmp = (att * (1 + taxBill.projectedIncrease / 100)) / 12;
    return { att, attm, atpt, tst };
  };

  _expectedHighAssessment = () => {
    let highAssessment = { landValue: 0 };
    let index;
    for (index = 0; index < this.assessments.length; index += 1) {
      const assessment = this.assessments[index];
      if (assessment.landValue > highAssessment.landValue) {
        highAssessment = assessment;
      }
    }
    return highAssessment;
  };

  // Returns the assessed land value based on assessment. If the
  // assessment has supplememntal bill, use the larger manually
  // entered land value from the supplemental. Otherewse return
  // the assessment land value
  findLandValue = (assessment, useSupplemental) => {
    if (
      useSupplemental &&
      assessment.supplementals &&
      assessment.supplementals.length > 0
    ) {
      return assessment.supplementals[0].currentLandValue;
    }
    return assessment.landValue;
  };

  findImprovements = (assessment, useSupplemental) => {
    if (
      useSupplemental &&
      assessment.supplementals &&
      assessment.supplementals.length > 0
    ) {
      return assessment.supplementals[0].currentImprovements;
    }
    return assessment.improvements;
  };

  increasePercentFloat = () => 1 + this.projectedIncrease / 100;

  computeTaxAmounts = (
    commonTaxes,
    landValue,
    improvements,
    supplementalMonthly,
    supplementalTax,
    hoe
  ) => {
    const landImprovementsTotal = landValue + improvements;
    const landImprovementsPlus = landImprovementsTotal * 1.02;

    const assessedValueTax =
      this.assessedValueRate *
      (landImprovementsTotal +
        commonTaxes.perPropertyTax +
        commonTaxes.busEquipPerProperty -
        hoe);
    const landImprovementsTax =
      this.landImprovementsRate * landImprovementsTotal;
    const annualTax =
      assessedValueTax +
      landImprovementsTax +
      commonTaxes.perPropertySpecialTax;
    const monthlyTax = annualTax / 12;
    const projectedMonthlyTax = monthlyTax * this.increasePercentFloat();
    const projectedAnnualTax = annualTax * this.increasePercentFloat();

    return {
      landImprovementsTotal,
      landImprovementsPlus,
      landImprovementsTax,
      assessedValueTax,
      supplementalTax,
      supplementalMonthly,
      annualTax,
      monthlyTax,
      projectedAnnualTax,
      projectedMonthlyTax,
      landValue,
      improvements,
    };
  };

  // Returns the computed supplemental tax using the supplemental
  // tax bill data provided by SCC. Returns 0 if not applicable.
  // Returns the sum of all supplemental bills found.
  computeSupplementalTaxes = (assessment) => {
    const suppTax = assessment.supplementals.reduce((acc, supp) => {
      const previousAssessedValue =
        supp.previousLandValue + supp.previousImprovements;
      const currentAssessedValue =
        supp.currentLandValue + supp.currentImprovements;

      const tax =
        (currentAssessedValue - previousAssessedValue) *
        supp.assessedValueRate *
        supp.prorationFactor;
      return acc + tax;
    }, 0);
    return suppTax;
  };

  createCommonTaxes = (
    totalLandValue,
    totalImprovements,
    totalLandPlusImprovements,
    busEquipPerProperty,
    perPropertyTax,
    perPropertySpecialTax,
    totalExemptions
  ) => ({
    totalLandValue,
    totalImprovements,
    totalLandPlusImprovements,
    busEquipPerProperty,
    perPropertyTax,
    perPropertySpecialTax,
    totalExemptions,
  });
}
