import { useState } from 'react';
import firebase from 'firebase/app';
import { db } from '../../../../services/firebase/firebase';

// Custom hook for CRUD Project. Provide optional callbacks for
// success and failure notification.
// Passed in project argument might be {}
const useProject = (projectArg, success = () => {}, failure = () => {}) => {
  const [errorMessage, setErrorMessage] = useState();

  const [project, setProject] = useState({
    name: projectArg.name || '',
    notes: projectArg.notes || '',
    funding: projectArg.funding || '',
    status: projectArg.status || 'ACTIVE',
    archive: projectArg.archive || false,
    imageUrls: projectArg.imageUrls || [],
    date: projectArg.date ? projectArg.date : new Date(),
  });

  const makeVariables = () => ({ ...project });

  const makeCreateVariables = () => makeVariables();

  const canCreate = () => project.name && project.funding;

  const canUpdate = () => true;

  const doAddImageUrl = (urlString) => {
    console.log('add image', project.id, urlString);
    const vars = [urlString];

    db.collection('projects')
      .doc(projectArg.id)
      .update({ imageUrls: firebase.firestore.FieldValue.arrayUnion(...vars) })
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error adding image Url', error);
        failure(error.message);
      });
  };

  const doCreate = async () => {
    const vars = makeCreateVariables();
    console.log('create Project vars ', vars);

    db.collection('projects')
      .add(vars)
      .then((value) => {
        console.log('value', value.id);
        success();
      })
      .catch((error) => {
        console.log('error', error);
        failure(error.message);
      });
  };

  const update = async (variables) => {
    console.log('update Project vars ', variables);

    db.collection('projects')
      .doc(projectArg.id)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating project', error);
        setErrorMessage(error.message);
        failure(error.message);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const handleChange = (name, value) => {
    setProject({ ...project, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    doAddImageUrl,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    project,
  };
};

export default useProject;
