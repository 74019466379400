import { cloneElement } from 'react';
import { PERMISSIONS } from './permission-maps';
import { useGetRole } from './useGetRole';

const hasPermission = ({ permissions, scopes }) => {
  const scopesMap = {};
  scopes.forEach((scope) => {
    scopesMap[scope] = true;
  });

  return permissions.some((permission) => scopesMap[permission]);
};

export default function PermissionsGate({
  children,
  RenderError = () => <></>,
  errorProps = null,
  scopes = [],
}) {
  const { role } = useGetRole();
  const permissions = PERMISSIONS[role];

  // Sometimes role comes after initial render
  if (!permissions) return <RenderError />;

  const permissionGranted = hasPermission({ permissions, scopes });

  // No permission, no error props => default error page
  if (!permissionGranted && !errorProps) return <RenderError />;

  // No permission, have error props => children will handle props
  if (!permissionGranted && errorProps) {
    return cloneElement(children, { ...errorProps });
  }

  // Normal path when have permission
  return <>{children}</>;
}
