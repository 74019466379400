import Papa from 'papaparse';

// Custom hook for importing impounds from CSV files.
const useImportImpounds = (handleImpounds, failure) => {
  const _makeLowerCase = (string) =>
    string.charAt(0).toLowerCase() + string.slice(1);

  const _sortImpounds = (toSort) =>
    toSort.sort((a, b) => (a.coach > b.coach ? 1 : -1));

  const _importFile = async (file) => {
    console.log('importFile.File: ', file);
    if (!file) {
      failure('File not found');
    }

    Papa.parse(file, {
      header: true,
      complete(event) {
        console.log('Parsed impounds', event);
        const sorted = _sortImpounds(event.data);
        handleImpounds(sorted);
      },
      transformHeader(value) {
        return _makeLowerCase(value);
      },
      transform(value, name) {
        if (name === 'impounds') {
          return value.startsWith('-$')
            ? parseFloat(value.replace(['-$'], ''))
            : value;
        }
        if (name === 'coach') {
          return parseInt(value);
        }
        return value;
      },
    });
  };

  const importImpoundsFile = async (e) => {
    _importFile(e.target.files[0]);
  };

  return {
    importImpoundsFile,
  };
};

export default useImportImpounds;
