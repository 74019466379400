import React, { Component } from 'react';
import { Header } from 'semantic-ui-react';
import DaysOnMarket from './Charts/DaysOnMarket';
import SalesScatter from './Charts/SalesScatter';
import './Report.scss';

class Report extends Component {
  render() {
    return (
      <div className="report-wrapper">
        <Header as="h3" textAlign="center">
          Coach Sales Trends
        </Header>
        <SalesScatter />
        <DaysOnMarket />
      </div>
    );
  }
}

export default Report;
