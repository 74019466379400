import React, { useState } from 'react';
import { Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import PopupButton from '../../../../components/PopupButton/PopupButton';
import useImpounds from './useImpounds';
import EditForm from './EditForm';

const EditTaxBill = ({ space }) => {
  const [open, setOpen] = useState(false);
  const { impounds, doUpdate, canUpdate, handleChange } = useImpounds(
    space,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const disabled = () => !canUpdate();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <PopupButton
          id="edit-tax-bill"
          basic
          icon="edit outline"
          color="teal"
          title="Edit Impounds"
          onClick={() => {}}
        />
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Impounds" />
      <Modal.Content>
        <EditForm impounds={impounds} fieldUpdated={handleChange} />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditTaxBill;
