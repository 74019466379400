export const handleLogin = (user, token) => {
  console.log('handleLogin', user);
  localStorage.setItem('token', token);
  localStorage.setItem('user', JSON.stringify(user));
};

export const handleLogout = () => {
  localStorage.removeItem('token');
  localStorage.removeItem('user');
};

export const getLoginItems = () => {
  const items = {
    ...JSON.parse(localStorage.getItem('user')),
    token: localStorage.getItem('token'),
  };
  return items;
};

export const saveSelectedStudies = (selected) => {
  localStorage.setItem('selectedStudies', selected);
};

export const getSelectedStudies = () => localStorage.getItem('selectedStudies');

export const saveSelectedTaxBill = (taxBillId) => {
  localStorage.setItem('selectedTaxBillId', taxBillId);
};

export const getSelectedTaxBill = () =>
  localStorage.getItem('selectedTaxBillId');
