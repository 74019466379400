import React from 'react';
import PropTypes from 'prop-types';
import { Button, Segment, Table } from 'semantic-ui-react';
import PopupButton from '../../../components/PopupButton/PopupButton';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import { db } from '../../../services/firebase/firebase';
import EditListing from './EditListing/EditListing';
import CreateListing from './EditListing/CreateListing';
import { format } from '../../../services/dates/formatting';
import Can from '../../../components/Can/Can';

const ListingsTable = ({ listings, showArchive, user }) => {
  const deleteConfirmed = (id) => {
    console.log('deleteConfirmed', id);
    db.collection('listings')
      .doc(id)
      .delete()
      .catch((error) => {
        console.log('Error deleting listing', error);
      });
  };

  const archiveAction = (_, data) => {
    console.log('handleArchive', data.id);
    const archive = !showArchive;
    db.collection('listings')
      .doc(data.id)
      .update({ isArchive: archive })
      .then((result) => {
        console.log('Updated listing');
      })
      .catch((error) => {
        console.log('Error updating listing', error);
      });
  };

  const archiveButtonTitle = () =>
    showArchive ? 'Activate Listing' : 'Archive Listing';

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const currency = (value) => {
    if (value) {
      return formatter.format(value);
    }
    return null;
  };

  const renderButtons = (listing) => (
    <>
      <EditListing current={listing} />
      <ConfirmDelete
        deleteId={listing.id}
        name={`Listing for coach #${listing.number}`}
        deleteConfirmed={deleteConfirmed}
      />
      <PopupButton
        title={archiveButtonTitle()}
        icon="file archive outline"
        id={listing.id}
        onClick={archiveAction}
      />
    </>
  );

  const renderButtonsCan = (listing) => (
    <Can
      role={user.role}
      perform="listing:edit"
      yes={() => renderButtons(listing)}
    />
  );

  const renderCreateListing = () => !showArchive && <CreateListing />;

  const renderCreateListingCan = () => (
    <Can
      role={user.role}
      perform="listing:edit"
      yes={() => renderCreateListing()}
    />
  );

  const renderNormalRow = (listing) => {
    const listedAt = listing.listedAt ? format(listing.listedAt) : null;
    const closedAt = listing.closedAt ? format(listing.closedAt) : null;

    return (
      <Table.Row key={listing.id}>
        <Table.Cell />
        <Table.Cell textAlign="center">{listing.number}</Table.Cell>
        <Table.Cell textAlign="center">{listing.status}</Table.Cell>
        <Table.Cell>{listing.owner}</Table.Cell>
        <Table.Cell>{listing.agent}</Table.Cell>
        <Table.Cell textAlign="center">{currency(listing.asking)}</Table.Cell>
        <Table.Cell textAlign="center">{currency(listing.selling)}</Table.Cell>
        <Table.Cell textAlign="center">{listedAt}</Table.Cell>
        <Table.Cell textAlign="center">{closedAt}</Table.Cell>
        <Table.Cell>{listing.notes}</Table.Cell>
        <Table.Cell>
          <Button.Group basic size="mini">
            {renderButtonsCan(listing)}
          </Button.Group>
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderHeader = () => (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell width={1} textAlign="center">
          {renderCreateListingCan()}
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Coach
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Status
        </Table.HeaderCell>
        <Table.HeaderCell>Owner</Table.HeaderCell>
        <Table.HeaderCell>Agent</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Asking Price</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Selling Price</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Listing Date</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Closing Date</Table.HeaderCell>
        <Table.HeaderCell>Notes</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  return (
    <>
      <Segment color="teal">
        <Table celled striped>
          {renderHeader()}
          <Table.Body>
            {listings.map((listing, index) => renderNormalRow(listing, index))}
          </Table.Body>
        </Table>
      </Segment>
    </>
  );
};

ListingsTable.propTypes = {
  showArchive: PropTypes.bool.isRequired,
  listings: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
};

export default ListingsTable;
