import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import PopupButton from '../../../../components/PopupButton/PopupButton';
import ShowModal from '../../../../components/ShowModal/ShowModal';
import { UserContext } from '../../../../services/context/UserContext';
import { db } from '../../../../services/firebase/firebase';
import Can from '../../../../components/Can/Can';
import CategoryTable from '../Category/CategoryTable';
import useBudgets from '../useBudgets';

const ArchivedBudgetsTable = ({ user, budgets }) => {
  // NOTE: This is not working and Google doesn't recommend
  // deleting subcollections from the client. Just do it from
  // the console.
  //
  // const deleteConfirmed = async (id) => {
  //   console.log('deleteConfirmed', id);
  //   // Remove any associated Line Items
  //   db.collection('lineItems')
  //     .where('budget', '==', id)
  //     .get()
  //     .then((docs) => {
  //       docs.forEach((doc) => {
  //         db.collection('lineItems').doc(doc.id).delete();
  //       });
  //     });
  //   // Delete the budget...
  //   db.collection('budgets')
  //     .doc(id)
  //     .delete()
  //     .then(() => {
  //       console.log(`Deleted ${id}`);
  //     });
  // };

  const handleActivate = async (event, data) => {
    db.collection('budgets')
      .doc(data.id)
      .update({ archive: false })
      .then(() => {
        console.log(`Activated ${data.id}`);
      });
  };

  const renderButtons = (budget) => (
    <>
      {/* <ConfirmDelete
        deleteId={budget.id}
        name="Budget"
        deleteConfirmed={deleteConfirmed}
      /> */}
      <PopupButton
        title="Activate Study"
        icon="file archive outline"
        id={budget.id}
        onClick={handleActivate}
      />
    </>
  );

  const renderNormalRow = (budget) => (
    <Table.Row key={budget.id}>
      <Table.Cell textAlign="center">{budget.name}</Table.Cell>
      <Table.Cell textAlign="center">{budget.budgetYear}</Table.Cell>
      <Table.Cell textAlign="left">{budget.note}</Table.Cell>
      <Table.Cell textAlign="center">
        <Button.Group basic size="mini">
          <ShowModal buttonTitle="Line Items" modalTitle="Line Items">
            <CategoryTable budget={budget} user={user} />
          </ShowModal>
          <Can
            role={user.role}
            perform="budget:edit"
            data={{
              userId: user.id,
              ownerId: user.id,
            }}
            yes={() => renderButtons(budget)}
          />
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  return (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={2} textAlign="center">
              Name
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="left">
              Year
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Notes</Table.HeaderCell>
            <Table.HeaderCell width={3} textAlign="center">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>

        <Table.Body>
          {budgets &&
            budgets.map((budget, index) => renderNormalRow(budget, index))}
        </Table.Body>
      </Table>
    </>
  );
};

const Archived = () => {
  const { user } = React.useContext(UserContext);
  const { error, budgets } = useBudgets('ops', true);

  if (!user) {
    return <div>Loading..</div>;
  }

  if (error) {
    return <div>Error loading projects: {error}</div>;
  }

  return (
    <Can
      role={user.role}
      perform="finance/archived:visit"
      yes={() => (
        <div>
          {console.log('user', user)}
          <ArchivedBudgetsTable budgets={budgets} user={user} />
        </div>
      )}
      // no={() => <Redirect to="/" />}
    />
  );
};

export default Archived;
