import Papa from 'papaparse';

// Custom hook for importing frequents from CSV files.
// Provide callbacks for success and failure notification.
const useImport = (handleFrequents, failure) => {
  const _makeLowerCase = (string) =>
    string.charAt(0).toLowerCase() + string.slice(1);

  // const _sortBirthdays = (toSort) => {
  //   return toSort.sort((a, b) => {
  //     return a.name > b.name ? 1 : -1;
  //   });
  // };

  const importFrequentsFile = async (e) => {
    const file = e.target.files[0];
    console.log('importFrequentsFile.File: ', file);
    if (!file) {
      failure('File not found');
    }

    Papa.parse(file, {
      skipEmptyLines: 'greedy',
      header: true,
      complete(event) {
        console.log('Parsed frequent numbers', event);
        handleFrequents(event.data);
      },
      transformHeader(value) {
        return _makeLowerCase(value);
      },
      transform(value) {
        // if (name === "space") {
        //   return parseInt(value);
        // }
        // if (name === "date") {
        //   return new Date(value);
        // }
        return value;
      },
    });
  };

  return {
    importFrequentsFile,
  };
};

export default useImport;
