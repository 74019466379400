import { useState } from 'react';
import firebase from 'firebase/app';
import { db } from '../../../../services/firebase/firebase';

// Custom hook for CRUD Frequent. Provide optional callbacks for
// success and failure notification.
const useFrequent = (itemOrNull, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();

  const [frequent, setFrequent] = useState({
    category: itemOrNull.category || '',
    name: itemOrNull.name || '',
    phone: itemOrNull.phone || '',
    notes: itemOrNull.notes || '',
  });

  const [frequents, setFrequents] = useState([]);

  // Returns variables for edit
  const makeVariables = () => ({
    category: frequent.category,
    name: frequent.name,
    phone: frequent.phone,
    notes: frequent.notes,
  });

  // const makeCreateVariables = () => {
  //   return makeVariables();
  // };

  const canCreate = () => frequents.length > 0 || frequent.name;

  const canUpdate = () => true;

  const _writeBatch = async (batch) => {
    batch
      .commit()
      .then((res) => {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        failure(errorMessage);
      });
  };

  // Creates a batch of birthdays using the given birthdays array
  const _create = async (createList) => {
    const ref = db.collection('paseo-de-palomas').doc('frequentnumbers');

    const writeBatch = db.batch();

    createList.forEach((item) => {
      writeBatch.update(ref, {
        numbers: firebase.firestore.FieldValue.arrayUnion({ ...item }),
      });
    });

    _writeBatch(writeBatch);
  };

  const doCreate = async () => {
    _create(frequents.length > 0 ? frequents : [makeVariables()]);
  };

  const _update = async (variables) => {
    console.log('update frequent vars ', variables);

    const ref = db.collection('paseo-de-palomas').doc('frequentnumbers');

    const writeBatch = db.batch();
    writeBatch.update(ref, {
      persons: firebase.firestore.FieldValue.arrayRemove({ ...itemOrNull }),
    });
    writeBatch.update(ref, {
      persons: firebase.firestore.FieldValue.arrayUnion({ ...variables }),
    });
    _writeBatch(writeBatch);
  };

  const doUpdate = async () => {
    _update(makeVariables());
  };

  const doDelete = async (entry) => {
    console.log('doDelete', entry);

    const ref = db.collection('paseo-de-palomas').doc('frequentnumbers');

    const writeBatch = db.batch();
    writeBatch.update(ref, {
      persons: firebase.firestore.FieldValue.arrayRemove({ ...entry }),
    });
    _writeBatch(writeBatch);
  };

  const handleChange = (name, value) => {
    setFrequent({ ...frequent, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    setFrequents,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doDelete,
    frequent,
  };
};

export default useFrequent;
