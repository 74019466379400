import React, { useState } from 'react';
import { withRouter } from 'react-router-dom';
import {
  Button,
  Form,
  Grid,
  Header,
  Image,
  Message,
  Segment,
} from 'semantic-ui-react';
import firebase from 'firebase/app';
// import { db } from "../../../services/firebase/firebase";
// import { handleLogin } from "../../../services/storage/Storage";

const LoginForm = (props) => {
  const [email, setEmail] = useState(null);
  const [password, setPassword] = useState(null);
  const [errorMessage, setErrorMessage] = useState(null);
  const [errorHeader] = useState(null);

  // const _verifyUserInfo = (user) => {
  //   console.log("Looking up ", user.uid);

  //   db.collection("users")
  //     .doc(user.uid)
  //     .get()
  //     .then((doc) => {
  //       if (doc.exists) {
  //         const info = { id: doc.id, ...doc.data() };
  //         handleLogin(info, info.id);
  //         props.history.push("/");
  //       } else {
  //         setErrorMessage("User does not exist.");
  //       }
  //     })
  //     .catch((err) => {
  //       console.log("Error getting user after login", err);
  //       setErrorMessage(`Sign in error: ${err.message}`);
  //     });
  // };

  // Authenticate with Google Firebase
  const _authenticate = () => {
    firebase
      .auth()
      .signInWithEmailAndPassword(email, password)
      .then(() => {
        props.history.push('/');
      })
      .catch(function (error) {
        // Handle Errors here.
        const { code } = error;
        const { message } = error;
        console.log('error ', code, message);
        setErrorMessage('A Google sign in error occurred: ', message);
      });
  };

  return (
    <Grid textAlign="center" style={{ height: '100vh' }} verticalAlign="middle">
      <Grid.Column style={{ maxWidth: 450 }}>
        <Header as="h2" color="teal" textAlign="center">
          <Image src="Pigeon.png" avatar />
          Sign in to your account
        </Header>
        <Form
          error
          size="large"
          action="post"
          onSubmit={(e) => {
            e.preventDefault();
            _authenticate();
          }}
        >
          <Segment stacked>
            {errorMessage ? (
              <Message
                error
                header={errorHeader || 'Incorrect password'}
                content={errorMessage}
              />
            ) : null}
            <Form.Input
              fluid
              icon="user"
              iconPosition="left"
              placeholder="Email"
              onChange={(_, data) => {
                setEmail(data.value);
              }}
            />
            <Form.Input
              fluid
              icon="lock"
              iconPosition="left"
              placeholder="Password"
              type="password"
              onChange={(_, data) => {
                setPassword(data.value);
              }}
            />
            <Button
              color="teal"
              fluid
              size="large"
              type="submit"
              disabled={!email || !password}
            >
              Sign In
            </Button>
            <Message>
              New to us? <a href="/register">Register</a>
            </Message>
          </Segment>
        </Form>
      </Grid.Column>
    </Grid>
  );
};

export default withRouter(LoginForm);
