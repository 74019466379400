import React from 'react';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { UserContext } from '../../../services/context/UserContext';
import StudiesTable from './StudiesTable';
import useStudies from './useStudies';
import './Studies.scss';

const Studies = () => {
  const { studies, loading, fetchError } = useStudies(false);

  if (fetchError) {
    return (
      <div>
        <Segment textAlign="center" size="small" color="red">
          {`Error getting listings: ${fetchError.message}`}
        </Segment>
      </div>
    );
  }

  if (loading) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  return (
    <div>
      <UserContext.Consumer>
        {({ user }) => <StudiesTable studies={studies} user={user} />}
      </UserContext.Consumer>
    </div>
  );
};

export default Studies;
