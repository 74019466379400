import React from 'react';
import { Table } from 'semantic-ui-react';

const LineItemsTable = ({ children }) => (
  <Table celled compact striped stackable>
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell width={2} textAlign="left">
          Category
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          ID
        </Table.HeaderCell>
        <Table.HeaderCell width={3} textAlign="left">
          Name
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          This Year
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Prev Year
        </Table.HeaderCell>
        <Table.HeaderCell width={6} textAlign="left">
          Notes
        </Table.HeaderCell>
        <Table.HeaderCell width={1} textAlign="center">
          Actions
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
    {children}
  </Table>
);

export default LineItemsTable;
