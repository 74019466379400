import React, { Component } from 'react';
import { Menu, Divider } from 'semantic-ui-react';
import SideBarItem from '../../SideBar/SideBarItem/SideBarItem';
import SideBarHeader from '../../SideBar/SideBarHeader/SideBarHeader';
import SideBarFooter from '../../SideBar/SideBarFooter/SideBarFooter';
import './SideBar.scss';

class SideBar extends Component {
  render() {
    return (
      <Menu borderless vertical stackable fixed="left" className="side-nav">
        <SideBarItem path="/" label="Home" icon="home" />
        <Divider fitted />
        <SideBarHeader title="Residents" />
        <SideBarItem
          path="directory"
          label="Directory"
          icon="address book outline"
        />
        <SideBarItem path="listings" label="Coaches For Sale" icon="table" />
        <SideBarItem path="Electrical" label="Electrical" icon="table" />
        <SideBarItem
          path="report"
          label="Report"
          icon="file alternate outline"
        />
        <Divider />
        <SideBarHeader title="Archives" />
        <SideBarItem
          path="resarchives"
          label="Residents Archive"
          icon="archive"
        />
        <SideBarItem path="cfsarchives" label="Coach Sales" icon="archive" />
        <Divider />
        <SideBarItem path="help" label="Help" icon="question circle outline" />
        {/* <SideBarItem label="Send feedback" icon="comment outline" /> */}
        <Divider />
        <SideBarFooter />
      </Menu>
    );
  }
}

export default SideBar;
