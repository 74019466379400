import React from 'react';
import CsvDownloader from 'react-csv-downloader';

const Downloader = ({ icon, filename, columns, datas }) => {
  // console.log('About to download', datas);

  if (icon) {
    return (
      <div>
        <CsvDownloader
          meta
          filename={filename}
          extension=".csv"
          separator=","
          wrapColumnChar=""
          columns={columns}
          datas={datas}
        >
          {icon}
        </CsvDownloader>
      </div>
    );
  }
  return (
    <div>
      <CsvDownloader
        filename={filename}
        extension=".csv"
        separator=","
        // wrapColumnChar=""
        // columns={columns}
        datas={datas}
        // text={title}
      />
    </div>
  );
};

export default Downloader;
