import React, { useState } from 'react';
import Papa from 'papaparse';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dimmer, Header, Loader, Modal, Form } from 'semantic-ui-react';
import { db } from '../../../services/firebase/firebase';
import ModalActions from '../../../components/CreateModal/ModalActions';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const makeLowerCase = (string) =>
  string.charAt(0).toLowerCase() + string.slice(1);

const ImportSpaces = () => {
  const [loading] = useState(false);
  const [error] = useState(null);
  const [data, setData] = useState(null);
  const [open, setOpen] = useState(false);

  const importFile = async (e) => {
    const file = e.target.files[0];
    console.log('File: ', file);
    Papa.parse(file, {
      header: true,
      complete(event) {
        console.log('complete', event);
        // const filtered = applyFilters(event.data);
        // console.log("Filtered", filtered);
        setData(event.data);
      },
      transformHeader(value) {
        return makeLowerCase(value.replace(' ', ''));
      },
      transform(value) {
        return value.startsWith('$') ? value.replace(/\D/g, '') / 100 : value;
      },
    });
  };

  const makeSpaceVars = (parsed) => {
    const vars = {
      number: parsed.number ? parseInt(parsed.number) : 0,
      address: parsed.address,
      name: parsed.name,
      name2: parsed.name2,
      notes: parsed.notes,
      phone: parsed.phone,
      shareNumber: parsed.share,
      decal: parsed.decal,
      size: parsed.size,
      isArchive: false,
      isForSale: false,
      isVacant: false,
      isNewHome: false,
      purchaseDate: parsed.purchaseDate ? new Date(parsed.purchaseDate) : null,
      purchasePrice: parsed.purchasePrice
        ? parseFloat(parsed.purchasePrice)
        : 0,
    };
    return vars;
  };

  const createFromImport = () => {
    const batch = db.batch();
    let index;
    for (index = 0; index < data.length; index += 1) {
      const parsed = data[index];
      const vars = makeSpaceVars(parsed);
      const setRef = db.collection('spaces').doc();
      batch.set(setRef, vars);
    }
    batch
      .commit()
      .then(() => {
        console.log('batch done');
      })
      .catch((error) => {
        console.log(error);
      });
  };

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    createFromImport();
  };

  const disabled = () => false;

  const renderForm = () => (
    <div>
      <Form>
        <Form.Group>
          <Form.Input
            type="file"
            label="Import File"
            id="form-input-file"
            onChange={importFile}
          />
        </Form.Group>
      </Form>
    </div>
  );

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <LightTooltip title="Import Owners" placement="top-start">
          <Button
            floated="left"
            color="teal"
            basic
            size="small"
            style={{ margin: '6px 20px 10px' }}
          >
            Import
          </Button>
        </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Import Spaces" />
      <Modal.Content>
        {loading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {error && <p>Error: {error.message}</p>}
        {renderForm()}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default ImportSpaces;
