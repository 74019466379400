import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { digits } from '../../../../services/number/formatting';
import TypeOptions from '../TypeOptions';

const EditForm = ({ fieldUpdated, lineItem }) => (
  <Form>
    <Form.Group>
      <Form.Input
        width={3}
        label="Category"
        id="form-input-category"
        value={lineItem.category}
        onChange={(_, data) => {
          fieldUpdated('category', data.value);
        }}
      />
      <Form.Input
        width={2}
        label="ID"
        id="form-input-cid"
        value={lineItem.cid}
        onChange={(_, data) => {
          fieldUpdated('cid', data.value);
        }}
      />
      <Form.Input
        width={5}
        label="Name"
        id="form-input-name"
        value={lineItem.name}
        onChange={(_, data) => {
          fieldUpdated('name', data.value);
        }}
      />
      <Form.Input
        width={2}
        label="Amount"
        id="form-input-amount"
        value={lineItem.amount}
        onChange={(_, data) => {
          fieldUpdated('amount', digits(data.value));
        }}
      />
      <Form.Input width={2} label="Type">
        <Dropdown
          basic
          selection
          name="type"
          options={TypeOptions}
          value={lineItem.type}
          onChange={(_, data) => fieldUpdated(data.name, data.value)}
        />
      </Form.Input>
    </Form.Group>
    <Form.Group>
      <Form.Input
        width={16}
        label="Notes"
        id="form-input-note"
        value={lineItem.notes}
        onChange={(_, data) => {
          fieldUpdated('notes', data.value);
        }}
      />
    </Form.Group>
  </Form>
);

export default EditForm;
