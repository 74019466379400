import React from 'react';
import { ParkContext } from '../../../services/context/ParkContext';
import TaxBillsTable from './TaxBillsTable';

const Reassessments = ({ taxBills }) => {
  const { park } = React.useContext(ParkContext);

  if (!park) {
    return null;
  }

  if (!taxBills) {
    return null;
  }

  const withReassessments = taxBills.filter((tb) => tb.hasReassessments());
  console.log('withReassessments', withReassessments);

  return (
    <TaxBillsTable
      park={park}
      taxBills={withReassessments}
      showArchive={false}
    />
  );
};

export default Reassessments;
