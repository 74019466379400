import React from 'react';
import { Button, Table } from 'semantic-ui-react';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import Can from '../../../components/Can/Can';
import EditComponent from './EditComponent/EditComponent';
import CreateComponent from './EditComponent/CreateComponent';
import useModify from './useModify';
// import ExportComponents from './EditComponent/ExportComponents';

const ComponentsTable = ({ set, user }) => {
  const { deleteComponent } = useModify((error) => {
    console.log(error);
  });
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const deleteConfirmed = async (component) => {
    console.log('deleteConfirmed', component);
    deleteComponent(set.id, component);
  };

  const renderButtons = (component, index) => (
    <>
      <EditComponent set={set} editComp={component} index={index} />
      <ConfirmDelete
        deleteId={component}
        name={component.name}
        deleteConfirmed={deleteConfirmed}
      />
    </>
  );

  // Tests role authorization, returning buttons
  const renderButtonsCan = (component, index) => (
    <Can
      role={user.role}
      perform="component:edit"
      yes={() => renderButtons(component, index)}
      no={() => null}
    />
  );

  const renderCreate = () => (
    <>
      <CreateComponent set={set} />
      {/* <ExportComponents components={components} /> */}
    </>
  );

  // Tests role authorization, returning rendering
  const renderCreateCan = () => (
    <Can
      role={user.role}
      perform="component:create"
      yes={() => renderCreate()}
    />
  );

  const renderTableRow = (component, index) => (
    <Table.Row key={index}>
      <Table.Cell>{component.category}</Table.Cell>
      <Table.Cell>{component.name}</Table.Cell>
      <Table.Cell textAlign="center">{component.pid}</Table.Cell>
      <Table.Cell textAlign="center">{component.usefulLife}</Table.Cell>
      <Table.Cell textAlign="center">
        {component.remainingUsefulLife}
      </Table.Cell>
      <Table.Cell>{formatter.format(component.replacementCost)}</Table.Cell>
      <Table.Cell textAlign="center">
        <Button.Group basic size="mini" color="teal">
          {renderButtonsCan(component, index)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  return (
    <>
      {renderCreateCan()}
      <Table celled compact striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={2}>Category</Table.HeaderCell>
            <Table.HeaderCell width={4}>Name</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              ID
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              UL
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              RUL
            </Table.HeaderCell>
            <Table.HeaderCell width={1}>Repl Cost</Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {set.components.map((component, index) =>
            renderTableRow(component, index)
          )}
        </Table.Body>
      </Table>
    </>
  );
};

export default ComponentsTable;
