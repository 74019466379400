import React from 'react';
import { UserContext } from '../../../services/context/UserContext';
import { SCOPES } from '../../../components/Permissions/permission-maps';
import PermissionsGate from '../../../components/Permissions/PermissionsGate';
import BudgetsTable from './BudgetsTable';
import useBudgets from './useBudgets';

const Budgets = () => {
  const { user } = React.useContext(UserContext);
  const { error, budgets } = useBudgets('ops', false);

  if (!user) {
    return <div>Loading..</div>;
  }

  if (error) {
    return <div>Error loading budgets: {error}</div>;
  }

  return (
    <>
      <PermissionsGate
        errorProps={{ canCreate: false, canEdit: false }}
        scopes={[SCOPES.canCreate]}
      >
        <BudgetsTable budgets={budgets} user={user} />
      </PermissionsGate>
    </>
  );
};

export default Budgets;
