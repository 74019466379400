import React, { useState } from 'react';
import { Button, Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useBirthday from './useBirthday';
import useImport from './useImport';
import EditForm from './EditForm';

const CreateBirthday = () => {
  const [open, setOpen] = useState(false);
  const {
    person,
    doCreate,
    canCreate,
    setBirthdays,
    handleChange,
  } = useBirthday(
    {},
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const { importBirthdaysFile } = useImport(
    (parsedBirthdays) => {
      console.log('Imported birthdays');
      setBirthdays(parsedBirthdays);
    },
    (error) => {
      console.log('Error parsing import file', error);
    }
  );

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doCreate();
  };

  const disabled = () => !canCreate();

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button
          floated="left"
          color="teal"
          basic
          size="small"
          style={{ margin: '6px 20px 10px' }}
        >
          Create New Birthday
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create New Birthday" />
      <Modal.Content>
        <EditForm
          person={person}
          fieldUpdated={handleChange}
          importBirthdaysFile={importBirthdaysFile}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default CreateBirthday;
