import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const EditForm = ({ fieldUpdated, vars }) => (
  <Form>
    <Form.Group>
      <Form.Input
        width={3}
        fluid
        label="Name"
        id="form-input-name"
        value={vars.name}
        onChange={(_, data) => {
          fieldUpdated('name', data.value);
        }}
      />
      <Form.Input
        width={2}
        fluid
        label="Year"
        id="form-input-year"
        value={vars.year}
        onChange={(_, data) => {
          fieldUpdated('year', data.value);
        }}
      />
    </Form.Group>
    <Form.Group>
      <Form.Input
        width={15}
        fluid
        label="Notes"
        id="form-input-notes"
        value={vars.notes}
        onChange={(_, data) => {
          fieldUpdated('notes', data.value);
        }}
      />
    </Form.Group>
  </Form>
);

PropTypes.propTypes = {
  fieldUpdated: PropTypes.func.isRequired,
  vars: PropTypes.object.isRequired,
};

export default EditForm;
