import React from 'react';
import PropTypes from 'prop-types';
import NumberFormat from 'react-number-format';
import { Button, Table } from 'semantic-ui-react';
import Can from '../../../components/Can/Can';
import { UserContext } from '../../../services/context/UserContext';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import EditFrequent from './EditFrequents/EditFrequent';
import useFrequent from './EditFrequents/useFrequent';

const DirectoryTable = ({ categories }) => {
  const { user } = React.useContext(UserContext);
  const { doDelete } = useFrequent(
    {},
    () => {
      console.log('Deleted');
    },
    (error) => {
      console.log('Delete error', error);
    }
  );

  const deleteConfirmed = async (entry) => {
    console.log('deleteConfirmed', entry);
    doDelete(entry);
  };

  const renderButtons = (frequent) => (
    <>
      <Button.Group>
        <EditFrequent current={frequent} />
        <ConfirmDelete
          deleteId={frequent}
          name={frequent.name}
          deleteConfirmed={deleteConfirmed}
        />
      </Button.Group>
    </>
  );

  const renderButtonsCan = (frequent) => (
    <Can
      role={user.role}
      perform="office:admin"
      yes={() => renderButtons(frequent)}
      no={() => null}
    />
  );

  const renderFrequent = (frequent) => (
    <Table.Row key={frequent.phone}>
      <Table.Cell width={3} textAlign="left">
        {frequent.name}
      </Table.Cell>
      <Table.Cell width={3} textAlign="center">
        <NumberFormat
          format="(###) ###-####"
          displayType="text"
          value={frequent.phone}
        />
      </Table.Cell>
      <Table.Cell textAlign="left">{frequent.notes}</Table.Cell>
      <Table.Cell textAlign="center" width={2}>
        {user && renderButtonsCan(frequent)}
      </Table.Cell>
    </Table.Row>
  );

  const renderCategory = (category, index) => (
    <Table key={index} celled striped>
      <Table.Header fullWidth>
        <Table.Row>
          <Table.HeaderCell width={16} textAlign="left" colSpan={16}>
            {category[0].category}
          </Table.HeaderCell>
        </Table.Row>
      </Table.Header>
      <Table.Body>
        {category.map((frequent) => renderFrequent(frequent))}
      </Table.Body>
    </Table>
  );

  return (
    <React.Fragment key="frequents">
      {categories.map((category, index) => renderCategory(category, index))}
    </React.Fragment>
  );
};

DirectoryTable.propTypes = {
  categories: PropTypes.array.isRequired,
};

export default DirectoryTable;
