import React from 'react';
import { Image } from 'semantic-ui-react';
import moment from 'moment';
import './BallotImage.scss';

const format = (date, formatString) => {
  const s = moment(date).format(formatString);
  return s === 'Invalid date' ? '' : s;
};

const date1 = (date) => format(date, 'MMMM DD, YYYY');

// //const date2 = (date) => format(date, 'M/DD/YYYY');

const date3 = (date) => format(date, 'M/DD/YYYY');

class BallotImage extends React.Component {
  // const BallotImage = ({ content }) => (
  render() {
    const { content } = this.props;

    return content ? (
      <div className="ballot-container">
        <Image src="/Ballot-BoD-2021-v3.png" />
        <h2 className="date1">
          <span>{date1(content.date)}</span>
        </h2>
        {/* <h2 className="date2">
          <span>{date2(content.date)}</span>
        </h2> */}
        <h2 className="date3">
          <span>{date3(content.date3)}</span>
        </h2>
        <h2 className="line1">
          <span>{content.line1}</span>
        </h2>
        <h2 className="line2">
          <span>{content.line2}</span>
        </h2>
        <h2 className="line3">
          <span>{content.line3}</span>
        </h2>
        <h2 className="line4">
          <span>{content.line4}</span>
        </h2>
        <h2 className="line5">
          <span>{content.line5}</span>
        </h2>
        <h2 className="line6">
          <span>{content.line6}</span>
        </h2>
      </div>
    ) : null;
  }
}

export default BallotImage;
