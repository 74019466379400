import { useEffect } from 'react';
import { withRouter } from 'react-router-dom';
import { usePrevious } from './usePrevious';

const ScrollToTop = ({ location, children }) => {
  const previousLocation = usePrevious(location);

  useEffect(() => {
    if (location !== previousLocation && window) {
      window.scrollTo(0, 0);
    }
  }, [location, previousLocation]);

  return <>{children}</>;
};

export default withRouter(ScrollToTop);
