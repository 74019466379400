import React, { useState } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../../components/CreateModal/ModalActions';
import useAllocation from './useAllocation';
import AllocationForm from './AllocationForm';

const CreateAllocation = ({ study }) => {
  const [open, setOpen] = useState(false);
  const {
    allocation,
    doCreate,
    canCreate,
    handleChange,
    makeOptions,
    addAllToNow,
  } = useAllocation(
    {},
    study,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doCreate();
  };

  const disabled = () => !canCreate();

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button color="teal" basic icon size="small">
          <Icon name="plus" size="large" color="teal" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create an Allocation" />
      <Modal.Content>
        <AllocationForm
          allocation={allocation}
          fieldUpdated={handleChange}
          options={makeOptions()}
          addAll={addAllToNow}
        />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default CreateAllocation;
