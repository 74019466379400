import { useState } from 'react';
import { parseLocaleNumber } from '../../../../services/number/formatting';
import { db } from '../../../../services/firebase/firebase';

// Custom hook for CRUD Study. Provide optional callbacks for
// success and failure notification.
const useListing = (item, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();

  const [listing, setListing] = useState({
    id: item.id,
    number: item.number || '',
    owner: item.owner || '',
    agent: item.agent || '',
    notes: item.notes || '',
    asking: item.asking || '',
    selling: item.selling || '',
    listedAt: item.listedAt ? new Date(item.listedAt) : '',
    closedAt: item.closedAt ? new Date(item.closedAt) : '',
    status: item.status || 'ACTIVE',
    isArchive: item.isArchive || false,
  });

  // Returns variables for edit
  const makeVariables = () => ({
    number: parseLocaleNumber(listing.number),
    owner: listing.owner,
    agent: listing.agent,
    notes: listing.notes,
    asking: listing.asking ? parseLocaleNumber(listing.asking) : 0,
    selling: listing.selling ? parseLocaleNumber(listing.selling) : 0,
    listedAt: listing.listedAt ? new Date(listing.listedAt) : null,
    closedAt: listing.closedAt ? new Date(listing.closedAt) : null,
    status: listing.status,
    isArchive: listing.isArchive,
  });

  const statusOptions = [
    {
      key: 'ACTIVE',
      text: 'ACTIVE',
      value: 'ACTIVE',
    },
    {
      key: 'PENDING',
      text: 'PENDING',
      value: 'PENDING',
    },
    {
      key: 'CLOSED',
      text: 'CLOSED',
      value: 'CLOSED',
    },
  ];

  const canCreate = () =>
    listing.number && listing.owner && listing.agent && listing.asking;

  const canUpdate = () => true;

  const _create = async (variables) => {
    console.log('createListing vars ', variables);

    db.collection('listings')
      .add(variables)
      .then((result) => {
        success();
      })
      .catch((error) => {
        console.log('Error creating listing', error);
        setErrorMessage(error.message);
        failure(error.message);
      });
  };

  const doCreate = async () => {
    _create(makeVariables());
  };

  const _update = async (variables) => {
    console.log('UpdateListing vars ', variables);

    db.collection('listings')
      .doc(listing.id)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating listing', error);
        setErrorMessage(error.message);
        failure(error.message);
      });
  };

  const doUpdate = async () => {
    _update(makeVariables());
  };

  const doDelete = async (id) => {
    console.log('doDelete', id);
  };

  const handleChange = (name, value) => {
    console.log('handleChange', name, value);
    setListing({ ...listing, [name]: value });
  };

  return {
    statusOptions,
    errorMessage,
    handleChange,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doDelete,
    listing,
  };
};

export default useListing;
