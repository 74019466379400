import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';

const useListings = () => {
  const [listings, setListings] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    try {
      const unsubscribe = db
        .collection('listings')
        .where('isArchive', '==', false)
        .onSnapshot(
          (snapshot) => {
            const all = snapshot.docs
              .map((doc) => ({
                id: doc.id,
                ...doc.data(),
              }))
              .sort((a, b) => (a.number > b.number ? 1 : -1))
              .map((listing) => {
                let temp = listing;
                if (listing.listedAt) {
                  temp = { ...listing, listedAt: listing.listedAt.toDate() };
                }
                if (listing.closedAt) {
                  return { ...temp, closedAt: listing.closedAt.toDate() };
                }
                return temp;
              });

            setListings(all);
            console.log('listings', all);
          },
          (error) => {
            setFetchError(error);
          }
        );

      return () => {
        console.log('Cleanup listings');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error);
    }
  }, []);

  return {
    fetchError,
    listings,
  };
};

export default useListings;
