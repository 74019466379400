import React from 'react';
// import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const footerStyle = { fontWeight: '600' };

const totalBreakers = (spaces, size) =>
  spaces.reduce((acc, space) => {
    if (space.pedestal?.main === size) {
      return acc + 1;
    }
    return acc;
  }, 0);

const totalACs = (spaces) =>
  spaces.reduce((acc, space) => {
    if (space.pedestal?.ac > 0) {
      return acc + 1;
    }
    return acc;
  }, 0);

const totalAuxs = (spaces) =>
  spaces.reduce((acc, space) => {
    if (
      space.pedestal?.aux1 > 0 ||
      space.pedestal?.aux2 > 0 ||
      space.pedestal?.aux3 > 0
    ) {
      return acc + 1;
    }
    return acc;
  }, 0);

const totalUnknowns = (spaces) =>
  spaces.reduce((acc, space) => {
    if (space.pedestal?.main === 0 || !space.pedestal?.main) {
      return acc + 1;
    }
    return acc;
  }, 0);

const ElectricalFooter = ({ park }) => {
  const renderTotal = (label, count) => (
    <Table.Row>
      <Table.Cell colSpan="2" />
      <Table.Cell textAlign="right" width={4} style={footerStyle}>
        {label}
      </Table.Cell>
      <Table.Cell textAlign="center" style={footerStyle}>
        {count}
      </Table.Cell>
      <Table.Cell colSpan="2" />
    </Table.Row>
  );

  const renderRow = (spaces) => (
    <Table.Body>
      {renderTotal('Total 100 amp Breakers', totalBreakers(spaces, 100))}
      {renderTotal('Total 60 amp Breakers', totalBreakers(spaces, 60))}
      {renderTotal('Total 50 amp Breakers', totalBreakers(spaces, 50))}
      {renderTotal('Total 40 amp Breakers', totalBreakers(spaces, 40))}
      {renderTotal('Total with AC installed', totalACs(spaces))}
      {renderTotal('Total aux installed', totalAuxs(spaces))}
      {renderTotal('Total unknown mains', totalUnknowns(spaces))}
    </Table.Body>
  );

  const renderSpaces = () => {
    const spacesWithPedestal = park.spaces.reduce((acc, item) => {
      if (item.pedestal) {
        acc.push(item);
      }
      return acc;
    }, []);
    return renderRow(spacesWithPedestal);
  };

  return <Table>{renderSpaces()}</Table>;
};

// AssessmentsFooter.propTypes = {
//   taxes: PropTypes.object.isRequired,
// };

export default ElectricalFooter;
