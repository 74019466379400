import React, { useState } from 'react';
import { Button, Header, Segment, Modal } from 'semantic-ui-react';

const ShowModal = ({ buttonTitle, modalTitle, children }) => {
  const [open, setOpen] = useState(false);

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button floated="left" color="teal" basic size="small">
          {buttonTitle}
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Segment clearing>
        <Header as="h3" floated="left">
          {/* <Icon name="edit outline" /> */}
          {modalTitle}
        </Header>
        <Header as="h3" floated="right">
          <Button
            basic
            color="teal"
            onClick={() => {
              handleClose();
            }}
          >
            Close
          </Button>
        </Header>
      </Segment>

      <Modal.Content>{children}</Modal.Content>
      <Modal.Actions>
        <Button
          basic
          color="teal"
          onClick={() => {
            handleClose();
          }}
        >
          Close
        </Button>
      </Modal.Actions>
    </Modal>
  );
};

export default ShowModal;
