import React from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import { ParkContext } from '../../../services/context/ParkContext';
import { UserContext } from '../../../services/context/UserContext';
import AssessmentsFooter from './AssessmentsFooter';

const SingleAssessmentTable = ({ park, taxBill, assessments }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const formatter0 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
    maximumFractionDigits: 0,
  });

  const { model } = taxBill;

  const currency = (value) => formatter.format(value);

  const projectedString = (tb) => `${tb.projectedIncrease}%`;

  const hasSupplemental = (spaceNumber, supplementals) =>
    supplementals.find((supplemental) => supplemental.space === spaceNumber);

  const renderButtons = () => (
    <Button.Group size="mini">
      {/* <EditAssessment park={props.park} space={space} /> */}
    </Button.Group>
  );

  const renderNormalRow = (assessment) => {
    const space = park.spaceFor(assessment.coach);
    const assessmentTaxes = model.assessmentTaxesFor(assessment.coach);
    const isNewOwner =
      hasSupplemental(space.number, model.supplementals) != null;

    return (
      <Table.Row key={assessment.coach}>
        <Table.Cell textAlign="center">{space.number}</Table.Cell>
        <Table.Cell textAlign="left">{space.name}</Table.Cell>
        <Table.Cell textAlign="center">{isNewOwner ? 'Y' : ''}</Table.Cell>
        <Table.Cell textAlign="center">
          {assessment.exemption ? 'Y' : 'N'}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {formatter0.format(assessment.landValue)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {formatter0.format(assessment.improvements)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {formatter0.format(assessment.landValue + assessment.improvements)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {formatter.format(assessmentTaxes.annualTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(assessmentTaxes.monthlyTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {currency(assessmentTaxes.projectedMonthlyTax)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {renderButtons(space, assessment)}
        </Table.Cell>
      </Table.Row>
    );
  };

  return (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="left">Space</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width="1">
              New Owner
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">HOE</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Land Value</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Improvements</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Total Assessed Value
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Annual Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Monthly Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Projected ({projectedString(taxBill)})
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {assessments.map((assessment, index) =>
            renderNormalRow(assessment, index)
          )}
        </Table.Body>
        <AssessmentsFooter taxes={model.allAssessmentTaxesMap} />
      </Table>
    </>
  );
};

const SingleAssessment = ({ taxBill }) => {
  const { park } = React.useContext(ParkContext);
  const { user } = React.useContext(UserContext);

  console.log('Assessments.user...', user);
  if (!user) {
    return <div>Loading...</div>;
  }

  const assessments = taxBill.assessments.filter(
    (assessment) => assessment.coach === user.space
  );

  return (
    <>
      <div>
        <SingleAssessmentTable
          park={park}
          taxBill={taxBill}
          assessments={assessments}
          user={user}
        />
      </div>
    </>
  );
};

SingleAssessment.propTypes = {
  taxBill: PropTypes.object.isRequired,
};

export default SingleAssessment;
