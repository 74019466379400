import React, { useState } from 'react';
import { Button, Header, Icon, Modal } from 'semantic-ui-react';
import ModalActions from '../../../components/CreateModal/ModalActions';
import useElectrical from './useElectrical';
import EditForm from './EditForm';

const EditElectrical = ({ park, spaceNumber }) => {
  const [open, setOpen] = useState(false);
  const {
    pedestal,
    doUpdate,
    canUpdate,
    handleChange,
    errorMessage,
  } = useElectrical(
    park,
    spaceNumber,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const disabled = () => !canUpdate();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  const iconName = () => (spaceNumber > 0 ? 'edit outline' : 'plus');

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name={iconName()} size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Space Pedestal and Electrical Info" />
      <Modal.Content>
        <EditForm pedestal={pedestal} fieldUpdated={handleChange} />
        {errorMessage && <p>Error: {errorMessage}</p>}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditElectrical;
