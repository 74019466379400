import React, { useState, useEffect } from 'react';
import ReactGA from 'react-ga';
import { Breakpoint } from 'react-socks';
import { Link, withRouter } from 'react-router-dom';
import { Image, Menu } from 'semantic-ui-react';
import Can from '../Can/Can';
import './HeaderNav.scss';

const renderOfficeMenu = (activeItem) => (
  <Menu.Item
    header
    as={Link}
    to="/office/birthdays"
    name="office"
    active={activeItem === 'office'}
  >
    Office
  </Menu.Item>
);

const renderOfficeMenuCan = (user, activeItem) => (
  <Can
    role={user.role}
    perform="office/menu:view"
    data={{}}
    yes={() => renderOfficeMenu(activeItem)}
  />
);

const renderAdminMenu = (activeItem) => (
  <Menu.Item
    header
    as={Link}
    to="/admin/users"
    name="admin"
    active={activeItem === 'admin'}
  >
    Admin
  </Menu.Item>
);

const renderAdminMenuCan = (user, activeItem) => (
  <Can
    role={user.role}
    perform="admin/users:visit"
    data={{}}
    yes={() => renderAdminMenu(activeItem)}
  />
);

const renderAccountMenu = (activeItem) => (
  <Menu.Item
    header
    as={Link}
    to="/account"
    name="account"
    active={activeItem === 'account'}
  >
    Account
  </Menu.Item>
);

const renderReservesMenu = (activeItem) => (
  <Menu.Item
    header
    as={Link}
    to="/reserves/studies"
    name="reserves"
    active={activeItem === 'reserves'}
  >
    Reserves
  </Menu.Item>
);

const renderFinanceMenu = (activeItem) => (
  <Menu.Item
    header
    as={Link}
    to="/finance/active"
    name="finance"
    active={activeItem === 'finance'}
  >
    Finance
  </Menu.Item>
);

const renderTaxesMenu = (activeItem) => (
  <Menu.Item
    header
    as={Link}
    to="/taxes/taxbills"
    name="taxbills"
    active={activeItem === 'taxes'}
  >
    Taxes
  </Menu.Item>
);

const renderParkMenu = (activeItem) => (
  <Menu.Item
    header
    as={Link}
    to="/park/directory"
    name="park"
    active={activeItem === 'park'}
  >
    Park
  </Menu.Item>
);

const HeaderNav = ({ user, location }) => {
  const [activeItem, setActiveItem] = useState('/');

  useEffect(() => {
    const split = location.pathname.split('/');
    const active = split.length < 2 ? null : split[1];
    setActiveItem(active);
    ReactGA.pageview(location.pathname);
  }, [location, user]);

  return (
    <>
      <Breakpoint medium up>
        <Menu borderless className="top-menu" fixed="top">
          <Menu.Item
            size="huge"
            className="logo"
            header
            name="avatar"
            as={Link}
            // to="/"
            to={user ? '/' : '/account'}
          >
            {/* <Image src="http://via.placeholder.com/80x80" avatar /> */}
            {/* <Image style={{'fontize':42}} avatar src={'/xxx.jpg'}/> */}
            <Image
              src="/Pigeon.png"
              avatar
              circular
              style={{ fontSize: 16 }}
              // style={{ width: "30px", height: "30px" }}
            />
            Paseo de Palomas
          </Menu.Item>
          {user && (
            <Menu.Menu className="nav-container-links">
              {renderReservesMenu(activeItem)}
              {renderFinanceMenu(activeItem)}
              {renderTaxesMenu(activeItem)}
              {renderParkMenu(activeItem)}
              {renderOfficeMenuCan(user, activeItem)}
              {renderAdminMenuCan(user, activeItem)}
            </Menu.Menu>
          )}
          <Menu.Menu position="right" className="nav-container-right">
            {renderAccountMenu(activeItem)}
          </Menu.Menu>
        </Menu>
      </Breakpoint>

      <Breakpoint medium down>
        <Menu borderless className="top-menu" fixed="top">
          {user && renderReservesMenu(activeItem)}
          {user && renderFinanceMenu(activeItem)}
          {user && renderTaxesMenu(activeItem)}
          {user && renderParkMenu(activeItem)}
          <Menu.Menu className="nav-container-links">
            {renderAccountMenu(activeItem)}
          </Menu.Menu>
        </Menu>
      </Breakpoint>
    </>
  );
};

export default withRouter(HeaderNav);
