import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';
import { formatDateISO } from '../../../../services/dates/formatting';

const DetailsSeller = ({ values }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const currency = (value) => (value !== 0 ? formatter.format(value) : '');

  const renderNormalRow = () => {
    const { taxes, archive, space } = values;
    const {
      parkPaid,
      impounds,
      difference,
      monthlyImpounds,
      monthlyTaxes,
    } = taxes;

    return (
      <Table.Row key={space.id}>
        <Table.Cell textAlign="center">{space.number}</Table.Cell>
        <Table.Cell textAlign="left">{archive.name}</Table.Cell>
        <Table.Cell textAlign="center">
          {formatDateISO(space.purchaseDate)}
        </Table.Cell>
        <Table.Cell textAlign="center">{taxes.months}</Table.Cell>
        <Table.Cell textAlign="center">{currency(monthlyImpounds)}</Table.Cell>
        <Table.Cell textAlign="center">{currency(monthlyTaxes)}</Table.Cell>
        <Table.Cell textAlign="center">{currency(parkPaid)}</Table.Cell>
        <Table.Cell textAlign="center">{currency(impounds)}</Table.Cell>
        <Table.Cell textAlign="center" negative={difference < 0}>
          {currency(difference)}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderTable = () => (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="center">Number</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Months</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Impounds</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Taxes</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">SCC Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Collected</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Difference</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>{renderNormalRow()}</Table.Body>
      </Table>
    </>
  );

  return renderTable();
};

DetailsSeller.propTypes = {
  values: PropTypes.object.isRequired,
};

export default DetailsSeller;
