import TaxBill from '../Taxes/TaxBill';
import Space from '../Spaces/Space';

//
// Represents the Park, containing spaces and tax bills.
//
export default class Park {
  constructor(spaces, taxBills) {
    const current = spaces.filter((space) => !space.isArchive);
    this.spaces = current.map((space) => new Space(space));
    const archive = spaces.filter((space) => space.isArchive);
    this.archives = archive.map((space) => new Space(space));

    // Make a map of current spaces where key = space number
    this.mappedSpaces = this._makeSpacesMap();

    // Save list of all tax bills
    this._taxBills = taxBills.map((taxBill) => new TaxBill(taxBill));

    // Save list of active tax bills
    this.activeTaxBills = this._taxBills.filter(
      (taxBill) => !taxBill.isArchive
    );

    // Save list of archive tax bills
    this.archiveTaxBills = this._taxBills.filter(
      (taxBill) => taxBill.isArchive
    );
  }

  archiveSpaceFor = (coach) =>
    this.archives.find((space) => space.number === coach);

  taxBillFor = (index) =>
    index < this.activeTaxBills.length ? this.activeTaxBills[index] : null;

  spaceFor = (coachNumber) => this.mappedSpaces.get(coachNumber);

  _makeSpacesMap = () =>
    this.spaces.reduce((acc, space) => acc.set(space.number, space), new Map());
}
