class Space {
  constructor(space) {
    this.id = space.id;
    this.name = space.name;
    this.name2 = space.name2;
    this.number = space.number;
    this.address = space.address;
    this.phone = space.phone;
    this.decal = space.decal;
    this.size = space.size;
    this.notes = space.notes;
    this.isArchive = space.isArchive;
    this.isForSale = space.isForSale;
    this.isVacant = space.isVacant;
    this.isNewHome = space.isNewHome;
    this.shareNumber = space.shareNumber;
    this.purchasePrice = space.purchasePrice;
    this.purchaseDate = space.purchaseDate;
    this.pedestal = space.pedestal;
    this.impounds = space.impounds;
  }

  makeArchiveVars = () => ({
    isArchive: true,
    isForSale: false,
    isVacant: false,
    isNewHome: false,
    number: this.number,
    address: this.address,
    name: this.name,
    name2: this.name2,
    decal: this.decal,
    size: this.size,
    notes: this.notes,
    phone: this.phone,
    shareNumber: this.shareNumber,
    purchaseDate: this.purchaseDate,
    purchasePrice: this.purchasePrice,
    impounds: this.impounds,
  });
}

export default Space;
