import React, { useState } from 'react';
import { Button, Icon, Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../../components/CreateModal/ModalActions';
import usePayment from './usePayment';
import EditForm from './EditForm';

const CreatePayment = ({ project }) => {
  const [open, setOpen] = useState(false);
  const [errorMessage, setErrorMessage] = useState();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const { payment, doCreate, canCreate, handleChange } = usePayment(
    project.id,
    {},
    () => {
      handleClose();
    },
    (error) => {
      setErrorMessage(error);
    }
  );

  const disabled = () => !canCreate();

  const save = () => {
    doCreate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button
          floated="left"
          color="teal"
          basic
          icon
          size="small"
          // style={{ margin: "6px 20px 10px" }}
        >
          <Icon name="plus" size="large" color="teal" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Create New Payment" />
      <Modal.Content>
        <EditForm payment={payment} fieldUpdated={handleChange} />
        {errorMessage && <p style={{ color: 'red' }}>Error: {errorMessage}</p>}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default CreatePayment;
