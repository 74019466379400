import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Icon, Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useBirthday from './useBirthday';
import EditForm from './EditForm';

const EditBirthday = ({ current }) => {
  // console.log("EditLineItem.props", props);
  const [open, setOpen] = useState(false);
  const { person, doUpdate, canUpdate, handleChange } = useBirthday(
    current,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  const disabled = () => !canUpdate();

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name="edit outline" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Birthday" />
      <Modal.Content>
        <EditForm person={person} fieldUpdated={handleChange} />
        {/* {mutationLoading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {mutationError && <p>Error: {mutationError.message}</p>} */}
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

EditBirthday.propTypes = {
  current: PropTypes.object.isRequired,
};

export default EditBirthday;
