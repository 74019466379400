import React from 'react';
import Can from '../../../components/Can/Can';
import { ParkContext } from '../../../services/context/ParkContext';
import { UserContext } from '../../../services/context/UserContext';
import SingleSupplemental from './SingleSupplemental';
import SupplementalsTable from './SupplementalsTable';

const Supplementals = ({ taxBills, showArchive }) => {
  const { user } = React.useContext(UserContext);
  const { park } = React.useContext(ParkContext);

  if (!user || !park) {
    return null;
  }

  return (
    <div>
      <Can
        role={user.role}
        perform="park/taxes:visit"
        yes={() => (
          <SupplementalsTable
            park={park}
            taxBills={taxBills}
            showArchive={showArchive}
            user={user}
          />
        )}
        no={() => (
          <SingleSupplemental
            park={park}
            taxBills={taxBills}
            showArchive={showArchive}
            user={user}
          />
        )}
      />
    </div>
  );
};

export default Supplementals;
