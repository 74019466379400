import { db } from '../../services/firebase/firebase';

// Custom hook for delete Space Pedestal.
const useModify = (failure) => {
  const deletePedestal = async (spaceId) => {
    const ref = db.collection('spaces').doc(spaceId);
    ref
      .update({
        pedestal: null,
        updated: Date(),
      })
      .then(function () {})
      .catch((error) => {
        console.error('Error deleting pedestal: ', error);
        failure(error.message);
      });
  };

  return {
    deletePedestal,
  };
};

export default useModify;
