import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import DetailsModal from './Details/DetailsModal';
import DetailsSeller from './Details/DetailsSeller';
import { computeSellerTaxes, makeSellerFileData } from './Reconciler';
import ExportReconciliation from './ExportReconciliation';

const ReconcileSellers = ({ park, taxBill, bought }) => {
  const { model } = taxBill;
  const [allValues, setAllValues] = useState([]);

  useEffect(() => {
    const valuesMap = park.spaces
      .map((space) => {
        const archive = park.archiveSpaceFor(space.number);
        const year = space.purchaseDate?.getFullYear();
        const taxes = computeSellerTaxes(park, space);
        const value = {
          coach: space.number,
          archive,
          space,
          taxes,
        };
        if (bought === year) {
          return value;
        }
        return null;
      })
      .filter((value) => value);
    setAllValues(valuesMap);
  }, [park, taxBill, bought, model]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const currency = (value) => (value !== 0 ? formatter.format(value) : '');

  const renderButtons = (values) => (
    <Button.Group size="mini">
      <DetailsModal>
        <DetailsSeller values={values} />
      </DetailsModal>
    </Button.Group>
  );

  const renderNormalRow = (values) => {
    const { taxes, archive, space } = values;
    const {
      parkPaid,
      impounds,
      difference,
      monthlyImpounds,
      monthlyTaxes,
    } = taxes;

    return (
      <Table.Row key={space.id}>
        <Table.Cell textAlign="center">{space.number}</Table.Cell>
        <Table.Cell textAlign="left">{archive.name}</Table.Cell>
        <Table.Cell textAlign="left">{taxes.months}</Table.Cell>
        <Table.Cell textAlign="center">{currency(monthlyImpounds)}</Table.Cell>
        <Table.Cell textAlign="center">{currency(monthlyTaxes)}</Table.Cell>
        <Table.Cell textAlign="center">{currency(parkPaid)}</Table.Cell>
        <Table.Cell textAlign="center">{currency(impounds)}</Table.Cell>
        <Table.Cell textAlign="center" negative={difference < 0}>
          {currency(difference)}
        </Table.Cell>
        <Table.Cell textAlign="center">
          {model && renderButtons(values)}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderTable = () => (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              <ExportReconciliation
                exportFile={makeSellerFileData(allValues)}
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Months</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Impounds</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Taxes</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">SCC Tax</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Collected</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Difference</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {allValues.map((values) => renderNormalRow(values))}
        </Table.Body>
      </Table>
    </>
  );

  return renderTable();
};

ReconcileSellers.propTypes = {
  park: PropTypes.object.isRequired,
  taxBill: PropTypes.object.isRequired,
  bought: PropTypes.number.isRequired,
};

export default ReconcileSellers;
