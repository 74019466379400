import React from 'react';
import PropTypes from 'prop-types';
import { getMonthlyPayment } from 'mortgage';
import { Button, Segment, Table } from 'semantic-ui-react';
import PopupButton from '../../../components/PopupButton/PopupButton';
import ConfirmDelete from '../../../components/Confirm/ConfirmDelete';
import Can from '../../../components/Can/Can';
import CreateLoan from './EditLoan/CreateLoan';
import EditLoan from './EditLoan/EditLoan';
import useModify from './EditLoan/useModify';

const computedPayment = (loan) =>
  getMonthlyPayment(loan.amount, loan.rate, loan.term * 12);

const LoansTable = ({ user, loans, isArchive }) => {
  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const { toggleArchive, deleteLoan } = useModify(
    () => {},
    (error) => {
      console.log('Failure', error);
    }
  );

  const currency = (value) => {
    if (value) {
      return formatter.format(value);
    }
    return null;
  };

  const deleteConfirmed = async (id) => {
    console.log('deleteConfirmed', id);
    deleteLoan(id);
  };

  const findLoan = (loanId) => loans.find((loan) => loan.id === loanId);

  const handleArchive = async (event, data) => {
    toggleArchive(findLoan(data.id));
  };

  const renderAddLoanCan = () => (
    <Can role={user.role} perform="budget:create" yes={() => <CreateLoan />} />
  );

  const renderButtons = (loan) => (
    <>
      {!isArchive && <EditLoan toEdit={loan} />}
      <PopupButton
        title={loan.archive ? 'Activate Loan' : 'Archive Loan'}
        icon="file archive outline"
        id={loan.id}
        onClick={handleArchive}
      />
      <ConfirmDelete
        deleteId={loan.id}
        name={`Loan named ${loan.name}`}
        deleteConfirmed={deleteConfirmed}
      />
    </>
  );

  const renderButtonsCan = (loan) => (
    <Can
      role={user.role}
      perform="budget:edit"
      data={{
        userId: user.id,
        ownerId: user.id,
      }}
      yes={() => renderButtons(loan)}
    />
  );

  const formattedRate = (rate) => (rate * 100).toFixed(1);

  const renderNormalRow = (loan) => (
    <Table.Row key={loan.id}>
      <Table.Cell />
      <Table.Cell textAlign="left">{loan.name}</Table.Cell>
      <Table.Cell textAlign="center">{loan.amount}</Table.Cell>
      <Table.Cell textAlign="center">{formattedRate(loan.rate)}%</Table.Cell>
      <Table.Cell textAlign="center">{loan.term}</Table.Cell>
      <Table.Cell textAlign="center">
        {currency(computedPayment(loan))}
      </Table.Cell>
      <Table.Cell textAlign="left">{loan.note}</Table.Cell>
      <Table.Cell textAlign="center">
        <Button.Group basic size="mini">
          {renderButtonsCan(loan)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  const renderTable = () => (
    <>
      <Table celled striped stackable>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="center" width={1}>
              {!isArchive && renderAddLoanCan()}
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left" width={3}>
              Name
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              Amount
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              Interest
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              Term
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={2}>
              Payment
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={5}>
              Notes
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center" width={1}>
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {loans && loans.map((loan, index) => renderNormalRow(loan, index))}
        </Table.Body>
      </Table>
    </>
  );

  return (
    <>
      <Segment color="teal">{renderTable()}</Segment>
    </>
  );
};

LoansTable.propTypes = {
  loans: PropTypes.array.isRequired,
  user: PropTypes.object.isRequired,
  isArchive: PropTypes.bool.isRequired,
};

export default LoansTable;
