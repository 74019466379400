import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const footerStyle = { fontWeight: '600' };

const ReassessmentsFooter = ({ model }) => {
  const formatter2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const format2 = (value) => (value.isNaN ? '' : formatter2.format(value));

  const assessmentsTaxTotal = () => model.allAssessmentTaxesMap.att;

  const reassessmentsTaxTotal = () => model.allReassessmentTaxesMap.att;

  const renderHeaderRow = () => (
    <Table.Row>
      <Table.HeaderCell colSpan="5" />
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Totals
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Previous
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Reassessment
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Total Refunds
      </Table.HeaderCell>
      <Table.HeaderCell />
    </Table.Row>
  );

  const renderRow = () => (
    <Table.Row>
      <Table.HeaderCell colSpan="5" />
      <Table.HeaderCell textAlign="center">
        {model.refundsCount()}
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">
        {format2(assessmentsTaxTotal())}
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">
        {format2(reassessmentsTaxTotal())}
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">
        {format2(model.totalTaxRefunds())}
      </Table.HeaderCell>
      <Table.HeaderCell />
    </Table.Row>
  );

  return (
    <Table.Footer fullWidth>
      {renderHeaderRow()}
      {renderRow()}
    </Table.Footer>
  );
};

ReassessmentsFooter.propTypes = {
  model: PropTypes.object.isRequired,
};

export default ReassessmentsFooter;
