import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const footerStyle = { fontWeight: '600' };

const AssessmentsFooter = ({ taxes }) => {
  const formatter2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const renderHeaderRow = () => (
    <Table.Row>
      <Table.HeaderCell colSpan="6" />
      <Table.HeaderCell />
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Annual
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Monthly
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Projected
      </Table.HeaderCell>
      <Table.HeaderCell colSpan="1" />
    </Table.Row>
  );

  const renderRow = () => (
    <Table.Row>
      <Table.HeaderCell colSpan="6" />
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Totals
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">
        {!Number.isNaN(taxes.att) && formatter2.format(taxes.att)}
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">
        {!Number.isNaN(taxes.attm) && formatter2.format(taxes.attm)}
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center">
        {!Number.isNaN(taxes.atpt) && formatter2.format(taxes.atpt)}
      </Table.HeaderCell>
      <Table.HeaderCell colSpan="1" />
    </Table.Row>
  );

  return (
    <Table.Footer fullWidth>
      {renderRow()}
      {renderHeaderRow()}
    </Table.Footer>
  );
};

AssessmentsFooter.propTypes = {
  taxes: PropTypes.object.isRequired,
};

export default AssessmentsFooter;
