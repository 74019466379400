import React from 'react';
import { Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import { acceptFloat } from '../../../../services/number/formatting';

const EditForm = ({ impounds, fieldUpdated }) => {
  const date =
    typeof impounds.date !== 'string' ? new Date(impounds.date) : null;
  // console.log('EF.impounds', date);

  const dateChanged = (timestamp) => {
    fieldUpdated('date', timestamp);
  };

  return (
    <Form>
      <Form.Group>
        <Form.Input label="Start Impounds Date">
          <DatePicker
            selected={date}
            onChange={dateChanged}
            popperPlacement="top-end"
          />
        </Form.Input>
        <Form.Input
          width={3}
          label="Monthly Impounds"
          id="form-input-imp"
          value={impounds.amount}
          onChange={(_, data) => {
            fieldUpdated('amount', acceptFloat(data.value));
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default EditForm;
