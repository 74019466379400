// Models the property tax calculations

const getHoeAmount = (assessment) => {
  if (!assessment.exemption) {
    return 0;
  }
  if (assessment.hoeAmount) {
    return assessment.hoeAmount;
  }
  return 7000;
};

const perPropValues = (assessments, totalAssessed, taxBill) => {
  const improveTotal = assessments.reduce(
    (acc, assessment) => acc + assessment.improvements,
    0
  );
  const landTotal = assessments.reduce(
    (acc, assessment) => acc + assessment.landValue,
    0
  );
  const assessTotal = landTotal + improveTotal;

  const exemptTotal = assessments.reduce(
    (acc, assessment) => acc + getHoeAmount(assessment),
    0
  );

  const length = assessments.length > 0 ? assessments.length : 1;
  const be = (totalAssessed - assessTotal) / length;
  const pp = taxBill.perPropertyTotalTax / length;
  const sa = taxBill.specialATax / length;

  return {
    landTotal,
    improveTotal,
    assessTotal,
    exemptTotal,
    busEquipEach: be,
    perPropEach: pp,
    specialEach: sa,
  };
};

const computeSupplementalTaxes = (supplementals) =>
  supplementals.reduce((acc, supp) => acc + supp.proratedTaxBill, 0);

const findSupplementals = (supplementals, coachNumber) =>
  supplementals.filter((supp) => supp.space === coachNumber);

// Returns the newLandValue from the supplementals, returning the
// second array value for those with 2 supplemental bills.
const findSupplementalLandValue = (supplementals) =>
  supplementals.length > 0
    ? supplementals[supplementals.length - 1].newLandValue
    : 0;

const findSupplementalImprovements = (supplementals) =>
  supplementals.length > 0
    ? supplementals[supplementals.length - 1].newImprovements
    : 0;

// Returns the assessed land value based on assessment or supplementals
const findLandValue = (supplementals, assessment, useSupplemental) =>
  useSupplemental && supplementals.length > 0
    ? findSupplementalLandValue(supplementals)
    : assessment.landValue;

// Returns the assessed improvements based on assessment or supplementals
const findImprovements = (supplementals, assessment, useSupplemental) =>
  useSupplemental && supplementals.length > 0
    ? findSupplementalImprovements(supplementals)
    : assessment.improvements;

const increasePercentFloat = (projectedIncrease) => 1 + projectedIncrease / 100;

const computeTaxAmounts = (
  taxBill,
  perProp,
  landValue,
  improvements,
  supplementalMonthly,
  supplementalTax,
  hoe
) => {
  const landImprovementsTotal = landValue + improvements;
  const landImprovementsPlus = landImprovementsTotal * 1.02;

  const assessedValueTax =
    taxBill.assessedValueRate *
    (landImprovementsTotal + perProp.perPropEach + perProp.busEquipEach - hoe);
  const landImprovementsTax =
    taxBill.landImprovementsRate * landImprovementsTotal;
  const annualTax =
    assessedValueTax + landImprovementsTax + perProp.specialEach;
  const monthlyTax = annualTax / 12;
  const projectedMonthlyTax =
    monthlyTax * increasePercentFloat(taxBill.projectedIncrease);
  const projectedAnnualTax =
    annualTax * increasePercentFloat(taxBill.projectedIncrease);

  // console.log(
  //   'details',
  //   taxBill.year,
  //   assessedValueTax,
  //   landImprovementsTax,
  //   annualTax
  // );
  // console.log(
  //   'more',
  //   landImprovementsTotal + perProp.perPropEach + perProp.busEquipEach - hoe
  // );
  return {
    landImprovementsTotal,
    landImprovementsPlus,
    landImprovementsTax,
    assessedValueTax,
    supplementalTax,
    supplementalMonthly,
    annualTax,
    monthlyTax,
    projectedAnnualTax,
    projectedMonthlyTax,
    landValue,
    improvements,
  };
};

const makeAssessmentTaxesMap = (
  taxBill,
  assessments,
  supplementals,
  perProp,
  useSupplemental = false
) =>
  assessments.reduce((acc, assessment) => {
    const hoe = getHoeAmount(assessment);
    const supps = findSupplementals(supplementals, assessment.coachNumber);
    const suppTax = computeSupplementalTaxes(supps);
    const suppMonthly = suppTax / 12;
    const landValue = findLandValue(supps, assessment, useSupplemental);
    const improvements = findImprovements(supps, assessment, useSupplemental);
    const amountsWithSupp = computeTaxAmounts(
      taxBill,
      perProp,
      landValue,
      improvements,
      suppMonthly,
      suppTax,
      hoe
    );
    return acc.set(assessment.coach, amountsWithSupp);
  }, new Map());

const computeAllTaxesMap = (assessments, taxes) => {
  // Total with annual tax
  const att = assessments.reduce((acc, assessment) => {
    const obj = taxes.get(assessment.coach);
    return obj ? acc + obj.annualTax : acc;
  }, 0);

  // Total with projected increases
  const atpt = assessments.reduce((acc, assessment) => {
    const obj = taxes.get(assessment.coach);
    return obj ? acc + obj.projectedMonthlyTax : acc;
  }, 0);

  // Total of all supplementals
  const tst = assessments.reduce((acc, assessment) => {
    const obj = taxes.get(assessment.coach);
    return obj ? acc + obj.supplementalTax : acc;
  }, 0);

  // Monthly total annual tax
  const attm = att / 12;

  return { att, attm, atpt, tst };
};

// 252,725.58 prior year taxes
// 240,110.04 currently calc'd total tax on reappraisals
// 239,945.71 amount from county bill with refund checks
// My calcs get close by changing the 5600 HOE to 7000 and
// by changing 2 other HOE's from 0 to 7000.
//     164.33 diff between
//     164.46 refund check (equiv to 2 7000 tax amounts)
//      65.80 refund check (equiv to the 5600 HOE amount)
//   12,797.79 main refund check
//   13,028.05 total of all 3 refund checks
//   12,658.83 my calc'd refund sub'ting prev year from this year
// 252x - 239x = 12,779.87
// 12x - 12x = 121.04
// 13x - 12x = 369.22

export default class TaxModel {
  constructor(taxBill) {
    this.id = taxBill.id;
    this.notes = taxBill.notes;
    this.year = taxBill.year;
    this.projected = taxBill.projected;
    this.projectedIncrease = taxBill.projectedIncrease;
    this.perPropertyTotalTax = taxBill.perPropertyTotalTax;
    this.totalAssessedValue = taxBill.totalAssessedValue;
    this.totalReassessedValue = taxBill.totalReassessedValue;
    this.assessedValueRate = taxBill.assessedValueRate;
    this.landImprovementsRate = taxBill.landImprovementsRate;
    this.exemptionAmount = taxBill.exemptionAmount;
    this.specialATax = taxBill.specialATax;
    this.assessments = taxBill.assessments;
    this.reassessments = taxBill.reassessments;
    this.supplementals = taxBill.supplementals;
    this.impounds = taxBill.impounds;
    this.isArchive = taxBill.isArchive;

    this._computeAssessmentTaxes(taxBill);
    this._computeReassessmentTaxes(taxBill);
  }

  // TODO: Fix this to use assessmentTaxesMap
  impoundsFor = (coachNumber) => {
    if (!this.impounds) return 0;
    const impounds = this.impounds.find((item) => item.coach === coachNumber);
    return impounds ? impounds.impounds : 0;
  };

  // impoundsAt = (index) => {
  //   if (!this.impounds) return null;
  //   if (this.impounds.length <= index) return null;
  //   return this.impounds[index];
  // };

  difference = (coachNumber) => {
    const taxes = this.assessmentTaxesFor(coachNumber);
    if (!taxes) return 0;
    const amount = this.impoundsFor(coachNumber);
    return amount * 12 - taxes.monthlyTax * 12;
  };

  assessmentFor = (coachNumber) =>
    this.assessments.find((assessment) => assessment.coach === coachNumber);

  reassessmentFor = (coachNumber) =>
    this.reassessments.find((assessment) => assessment.coach === coachNumber);

  assessmentTaxesFor = (coachNumber) =>
    this.assessmentTaxesMap.get(coachNumber);

  reassessmentTaxesFor = (coachNumber) =>
    this.reassessmentTaxesMap.get(coachNumber);

  hoeAmountFor = (assessment) => {
    if (!assessment) return 0;
    if (!assessment.exemption) return 0;
    return assessment.hoeAmount ? assessment.hoeAmount : this.exemptionAmount;
  };

  assessmentHoeAmountFor = (coachNumber) =>
    this.hoeAmountFor(this.assessmentFor(coachNumber));

  reassessmentHoeAmountFor = (coachNumber) =>
    this.hoeAmountFor(this.reassessmentFor(coachNumber));

  taxDifference = (coachNumber) => {
    const assess = this.assessmentTaxesFor(coachNumber);
    const reassess = this.reassessmentTaxesFor(coachNumber);
    return assess.annualTax - reassess.annualTax;
  };

  hasTaxDifference = (coachNumber) => this.taxDifference(coachNumber) !== 0;

  hasReassessments = () => this.reassessments && this.reassessments.length > 0;

  totalTaxRefunds = () =>
    this.assessments.reduce((acc, assess) => {
      const diff = this.taxDifference(assess.coach);
      return diff > 0 ? acc + diff : acc;
    }, 0);

  refundsCount = () =>
    this.assessments.reduce((acc, assess) => {
      const diff = this.taxDifference(assess.coach);
      return diff > 0 ? acc + 1 : acc;
    }, 0);

  setAssessments = (assess) => {
    this.assessments = assess;
    this._computeAssessmentTaxes();
  };

  setSupplementals = (supps) => {
    this.supplementals = supps;
    this._computeReassessmentTaxes();
  };

  hasSupplementals = (spaceNumber) =>
    this.supplementals.find(
      (supplemental) => supplemental.space === spaceNumber
    );

  _computeAssessmentTaxes(taxBill) {
    if (taxBill.assessments && taxBill.supplementals) {
      this.perPropAssessments = perPropValues(
        taxBill.assessments,
        taxBill.totalAssessedValue,
        taxBill
      );
      this.assessmentTaxesMap = makeAssessmentTaxesMap(
        taxBill,
        taxBill.assessments,
        taxBill.supplementals,
        this.perPropAssessments,
        true
      );
      this.allAssessmentTaxesMap = computeAllTaxesMap(
        taxBill.assessments,
        this.assessmentTaxesMap
      );
    }
  }

  _computeReassessmentTaxes(taxBill) {
    if (taxBill.reassessments) {
      this.perPropReassessments = perPropValues(
        taxBill.reassessments,
        taxBill.totalReassessedValue,
        taxBill
      );
      this.reassessmentTaxesMap = makeAssessmentTaxesMap(
        taxBill,
        taxBill.reassessments,
        [],
        this.perPropReassessments
      );
      this.allReassessmentTaxesMap = computeAllTaxesMap(
        taxBill.reassessments,
        this.reassessmentTaxesMap
      );
    }
  }
}
