import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';
import { digits } from '../../../services/number/formatting';
import 'react-datepicker/dist/react-datepicker.css';

export const statusOptions = [
  {
    key: 'YES',
    text: 'YES',
    value: true,
  },
  {
    key: 'NO',
    text: 'NO',
    value: false,
  },
];

const EditForm = ({ fieldUpdated, pedestal }) => (
  <div>
    <Form>
      <Form.Group>
        <Form.Input
          width={2}
          fluid
          label="Space Number"
          id="form-input-number"
          value={pedestal.number}
          onChange={(_, data) => {
            fieldUpdated('number', digits(data.value));
          }}
        />
        <Form.Input
          width={2}
          fluid
          label="Main Breaker"
          id="form-input-main"
          value={pedestal.main}
          onChange={(_, data) => {
            fieldUpdated('main', digits(data.value));
          }}
        />
        <Form.Input
          width={2}
          label="Coach"
          id="form-input-coach"
          value={pedestal.coach}
          onChange={(_, data) => {
            fieldUpdated('coach', digits(data.value));
          }}
        />
        <Form.Input
          width={2}
          label="AC"
          id="form-input-ac"
          value={pedestal.ac}
          onChange={(_, data) => {
            fieldUpdated('ac', digits(data.value));
          }}
        />
        <Form.Input
          width={2}
          label="Aux - 1"
          id="form-input-aux1"
          value={pedestal.aux1}
          onChange={(_, data) => {
            fieldUpdated('aux1', digits(data.value));
          }}
        />
        <Form.Input
          width={2}
          label="Aux - 2"
          id="form-input-aux2"
          value={pedestal.aux2}
          onChange={(_, data) => {
            fieldUpdated('aux2', digits(data.value));
          }}
        />
        <Form.Input
          width={2}
          label="Aux - 3"
          id="form-input-aux3"
          value={pedestal.aux3}
          onChange={(_, data) => {
            fieldUpdated('aux3', digits(data.value));
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input label="New Pedestal">
          <Dropdown
            basic
            selection
            options={statusOptions}
            value={pedestal.isNew}
            onChange={(_, data) => fieldUpdated('isNew', data.value)}
          />
        </Form.Input>
        {pedestal.ac && (
          <Form.Input
            width={2}
            label="AC Max Current"
            id="form-input-acmax"
            value={pedestal.acMax}
            onChange={(_, data) => {
              fieldUpdated('acMax', digits(data.value));
            }}
          />
        )}
      </Form.Group>
      <Form.Group>
        <Form.Input label="Has Citation">
          <Dropdown
            basic
            selection
            options={statusOptions}
            value={pedestal.isCited}
            onChange={(_, data) => fieldUpdated('isCited', data.value)}
          />
        </Form.Input>
      </Form.Group>
      <Form.Group>
        <Form.TextArea
          width={16}
          label="Notes"
          id="form-input-notes"
          value={pedestal.notes}
          onChange={(_, data) => {
            fieldUpdated('notes', data.value);
          }}
        />
      </Form.Group>
    </Form>
  </div>
);

export default EditForm;
