import { db } from '../../../../services/firebase/firebase';

const useModify = (success, failure) => {
  const _doModify = async (studyId, variables) => {
    db.collection('studies')
      .doc(studyId)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating study', error);
        failure(error.message);
      });
  };

  const _doDelete = (studyId, collectionName) => {
    const ref = db
      .collection('studies')
      .doc(studyId)
      .collection(collectionName);
    const writeBatch = db.batch();

    ref.get().then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        writeBatch.delete(ref.doc(doc.id));
      });

      writeBatch
        .commit()
        .then((res) => {
          console.log('Deleted ', collectionName, res);
          success();
        })
        .catch((error) => {
          console.log('Error deleting ', collectionName, error);
          failure(error.message);
        });
    });
  };

  const archiveStudy = (studyId) => {
    _doModify(studyId, { isArchived: true });
  };

  const activateStudy = (studyId) => {
    _doModify(studyId, { isArchived: false });
  };

  const deleteStudy = (studyId) => {
    // Delete subcollections using batch first
    _doDelete(studyId, 'activities');
    // Then delete the project
    db.collection('studies')
      .doc(studyId)
      .delete()
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error deleting Study', error.message);
        failure(error.message);
      });
  };

  return {
    deleteStudy,
    archiveStudy,
    activateStudy,
  };
};

export default useModify;
