import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';

const useLoans = (archive) => {
  const [loans, setLoans] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setLoading(false);

    try {
      const unsubscribe = db
        .collection('loans')
        .where('archive', '==', archive)
        .onSnapshot((snapshot) => {
          const all = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setLoans(all);
          console.log('loans', all);
        });

      return () => {
        console.log('cleanup loans');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [archive]);

  return {
    fetchError,
    loading,
    loans,
  };
};

export default useLoans;
