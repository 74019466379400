import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';
import { digits } from '../../../../services/number/formatting';

// TODO: Default category

const EditForm = ({ fieldUpdated, component }) => (
  <Form>
    <Form.Group>
      {/* <Select
          label="Category"
          placeholder="Select Category"
          options={catOptions}
          onChange={(_, data) => {
            fieldUpdated("category", data.value);
          }}
        /> */}
      <Form.Input
        width={6}
        fluid
        label="Category"
        id="form-input-category"
        placeholder="Category"
        value={component.category}
        onChange={(_, data) => {
          fieldUpdated('category', data.value);
        }}
      />
      <Form.Input
        width={2}
        fluid
        label="ID"
        id="form-input-pid"
        placeholder="ID"
        value={component.pid}
        onChange={(_, data) => {
          fieldUpdated('pid', digits(data.value));
        }}
      />
      <Form.Input
        width={6}
        fluid
        label="Name"
        id="form-input-name"
        placeholder="Name"
        value={component.name}
        onChange={(_, data) => {
          fieldUpdated('name', data.value);
        }}
      />
      <Form.Input
        width={2}
        fluid
        label="UL"
        id="form-input-ul"
        placeholder="Useful life"
        value={component.ul}
        onChange={(_, data) => {
          fieldUpdated('ul', digits(data.value));
        }}
      />
      <Form.Input
        width={2}
        fluid
        label="Rem UL"
        id="form-input-rul"
        placeholder="Remaining useful life"
        value={component.rul}
        onChange={(_, data) => {
          fieldUpdated('rul', digits(data.value));
        }}
      />
      <Form.Input
        width={3}
        fluid
        label="Repl Cost"
        id="form-input-rc"
        placeholder="Repl cost"
        value={component.rc}
        onChange={(_, data) => {
          fieldUpdated('rc', digits(data.value));
        }}
      />
    </Form.Group>
  </Form>
);

PropTypes.propTypes = {
  fieldUpdated: PropTypes.func.isRequired,
};

export default EditForm;
