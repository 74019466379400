import React from 'react';
import {
  VictoryAxis,
  VictoryBar,
  VictoryChart,
  // VictoryLabel,
  // VictoryPie,
  VictoryTheme,
  VictoryTooltip,
} from 'victory';
import useLineItems from '../../LineItems/useLineItems';
import BudgetPie from './BudgetPie';

const computeName = (category) => category[0].category;

const computeTotal = (category) =>
  category.reduce(
    (acc, item) =>
      // acc += item.amount;
      acc + item.amount,
    0
  );

const makeNamesAxisX = (categories) =>
  categories
    .filter((category) => category[0].type !== 'INCOME')
    .map((category) => {
      const name = computeName(category);
      return name;
    });

const formatData = (categories) =>
  categories.map((category) => ({
    name: computeName(category),
    value: computeTotal(category),
  }));

const prepareData = (categories) =>
  categories
    .filter(
      (category) =>
        category[0].category !== 'Income' && category[0].category !== 'SMR'
    )
    .sort((a, b) => (computeTotal(a) > computeTotal(b) ? 1 : -1));

const ExpensesBar = ({ categories }) => {
  const ordered = prepareData(categories);
  if (ordered.length === 0) {
    return null;
  }
  const data = formatData(ordered);
  const xAxisNames = makeNamesAxisX(ordered);
  console.log('BudgetChart', data);

  return (
    <VictoryChart domainPadding={20} theme={VictoryTheme.material}>
      <VictoryAxis
        // tickValues specifies both the number of ticks and where
        // they are placed on the axis
        // tickValues={[1, 2, 3, 4]}
        tickFormat={xAxisNames}
        style={{
          axis: { stroke: '#756f6a' },
          axisLabel: { fontSize: 10, padding: 30 },
          ticks: { stroke: 'grey', size: 2 },
          tickLabels: {
            fontSize: 6,
            padding: 0,
            angle: 45,
            textAnchor: 'start',
          },
        }}
      />
      <VictoryAxis
        dependentAxis
        // tickFormat specifies how ticks should be displayed
        tickFormat={(x) => `$${x / 1000}k`}
        style={{
          axis: { stroke: '#756f6a' },
          axisLabel: { fontSize: 10, padding: 30 },
          ticks: { stroke: 'grey', size: 5 },
          tickLabels: { fontSize: 5, padding: 5 },
        }}
      />
      <VictoryBar
        data={data}
        x="name"
        y="value"
        barRatio={0.8}
        labels={(d) => {
          console.log('data', d.datum);
          return `${d.datum.value}`;
          // ("HELLO");
        }}
        style={{ data: { fill: '#31acc4' } }}
        labelComponent={
          <VictoryTooltip
            dy={0}
            style={{ fontSize: 5 }}
            flyoutStyle={{ stroke: '#31acc4', strokeWidth: 0.5 }}
            pointerLength={5}
          />
        }
      />
    </VictoryChart>
  );
};

// const ExpensesPie = (props) => {
//   const ordered = prepareData(props.categories);
//   if (ordered.length === 0) {
//     return null;
//   }
//   const data = formatData(ordered);
//   console.log("EP", data);

//   return (
//     // <VictoryChart domainPadding={20} theme={VictoryTheme.material}>
//     <VictoryPie
//       theme={VictoryTheme.material}
//       height={300}
//       data={data}
//       x="name"
//       y="value"
//       labelRadius={50}
//       // style={{ data: { fi/ll: "#31acc4" } }}
//       // labels={({ datum }) => datum.y}
//       labelComponent={
//         <VictoryLabel
//           angle={0}
//           textAnchor={"end"}
//           style={{ color: "red", fontSize: 4 }}
//         />
//       }

//       //   labelComponent={
//       //     <VictoryTooltip
//       //       dy={0}
//       //       style={{ fontSize: 5 }}
//       //       flyoutStyle={{ stroke: "#31acc4", strokeWidth: 0.5 }}
//       //       pointerLength={5}
//       //     />
//       //   }
//     />
//     // </VictoryChart>
//   );
// };

const BudgetChart = ({ budget }) => {
  const { categories } = useLineItems(budget.id);
  console.log('BudgetChart.categories', categories);

  if (!categories || categories.length === 0) {
    return null;
  }
  console.log('BudgetChart.categories', categories);
  return (
    <>
      <BudgetPie categories={categories} />
      {/* <ExpensesPie categories={categories} /> */}
      <ExpensesBar categories={categories} />;
    </>
  );
};

export default BudgetChart;
