import { useState, useEffect } from 'react';
import jsPDF from 'jspdf';
import 'jspdf-autotable';
import moment from 'moment';

const useGenPdf = (monthName, birthdays, success) => {
  const [options, setOptions] = useState();

  useEffect(() => {
    setOptions({
      title: '',
      showHeader: true,
      veryBasic: true,
    });
  }, []);

  const basicAttribute = () => (options.veryBasic ? 'very' : true);

  const generateFromHtml = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();
    document
      .getElementById('birthdaysTable')
      .setAttribute('basic', basicAttribute());
    doc.autoTable({ html: '#birthdaysTable' });
    doc.save(`Birthdays_${monthName}.pdf`);
  };

  const generatePDF = () => {
    // eslint-disable-next-line new-cap
    const doc = new jsPDF();

    const tableColumn = options.showHeader ? [['Name', 'Day', 'Space']] : null;
    const tableRows = [];

    birthdays.forEach((birthday) => {
      const dayData = [
        birthday.name,
        moment(birthday.date).format('MMMM D'),
        `${birthday.space}`,
      ];
      tableRows.push(dayData);
    });

    doc.autoTable({
      head: tableColumn,
      body: tableRows,
      margin: { top: 20 },
      theme: 'plain',
      // headStyles: {
      //   2: { halign: "center", fillColor: [0, 255, 0] },
      //   cellWidth: "auto",
      // },
      // columnStyles: {
      //   2: { halign: "center", fillColor: [0, 255, 0], cellWidth: "auto" },
      // },
    });
    doc.text(`${monthName} Birthdays`, 14, 15);
    doc.save(`Birthdays_${monthName}.pdf`);

    success();
  };

  const handleChange = (name, value) => {
    setOptions({ ...options, [name]: value });
  };

  return {
    handleChange,
    generateFromHtml,
    generatePDF,
    options,
  };
};

export default useGenPdf;
