import React from 'react';
import PropTypes from 'prop-types';
import { Button, Divider, Segment, Table } from 'semantic-ui-react';
import { formatDateString } from '../../../../services/dates/formatting';
import ConfirmDelete from '../../../../components/Confirm/ConfirmDelete';
import Can from '../../../../components/Can/Can';
import useExpense from './EditActivity/useExpense';
import CreateExpense from './EditActivity/CreateExpense';
import EditExpense from './EditActivity/EditExpense';
import ExpensesFooter from './ExpensesFooter';
import '../Studies.scss';

const formatter = new Intl.NumberFormat('en-US', {
  style: 'currency',
  currency: 'USD',
  minimumFractionDigits: 0,
});

const ExpensesTable = ({ study, activities, user }) => {
  const { doDelete } = useExpense(
    {},
    study,
    () => {},
    (error) => {
      console.log('Failure', error);
    }
  );

  const deleteConfirmed = async (expense) => {
    console.log('deleteConfirmed', expense);
    doDelete(expense);
  };

  const renderButtons = (expense) => (
    <>
      <EditExpense editExpense={expense} study={study} />
      <ConfirmDelete
        deleteId={expense}
        name={`Spending item ${expense.name}`}
        deleteConfirmed={deleteConfirmed}
      />
    </>
  );

  const renderButtonsCan = (expense) => (
    <Can
      role={user.role}
      perform="study:edit"
      yes={() => renderButtons(expense)}
    />
  );

  const renderAddExpense = () => <CreateExpense study={study} />;

  const renderActivityRow = (expense, index) => (
    <Table.Row key={index}>
      <Table.Cell textAlign="center" />
      <Table.Cell textAlign="left">{expense.name}</Table.Cell>
      <Table.Cell textAlign="center">{expense.pid}</Table.Cell>
      <Table.Cell textAlign="center">
        {formatDateString(expense.date)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(expense.amount)}
      </Table.Cell>
      <Table.Cell textAlign="center" />
      <Table.Cell textAlign="center">
        <Button.Group basic size="tiny">
          {renderButtonsCan(expense)}
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  const renderActivityHeader = () => (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell width={1} textAlign="center">
          {renderAddExpense()}
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="left" width={4}>
          Name
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={1}>
          ID
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Date</Table.HeaderCell>
        <Table.HeaderCell textAlign="center">Amount</Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={4} />
        <Table.HeaderCell textAlign="center" width={1}>
          Actions
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  const expenses = activities.find((act) => act.expenses);
  // console.log("ET", expenses);

  return (
    <>
      <Segment color="red">
        <Divider horizontal>Spending</Divider>
        <Table celled compact striped>
          {renderActivityHeader()}
          <Table.Body>
            {expenses &&
              expenses.expenses.map((expense, index) =>
                renderActivityRow(expense, index)
              )}
          </Table.Body>
          {expenses && <ExpensesFooter expenses={expenses} />}
        </Table>
      </Segment>
    </>
  );
};

ExpensesTable.propTypes = {
  study: PropTypes.object.isRequired,
  activities: PropTypes.array.isRequired,
};

export default ExpensesTable;
