import { useState } from 'react';
import Papa from 'papaparse';

// Custom hook for importing budget line items from CSV files.
// Provide optional callbacks for success and failure notification.
const useImports = (handleLineItems, failure) => {
  const [errorMessage, setErrorMessage] = useState();

  const _makeLowerCase = (string) =>
    string.charAt(0).toLowerCase() + string.slice(1);

  const _sortLineItems = (toSort) => toSort;
  // return toSort.sort((a, b) => {
  //   return a.coach > b.coach ? 1 : -1;
  // });
  const importLineItemsFile = async (e) => {
    const file = e.target.files[0];
    console.log('importLineItemsFile: ', file);
    if (!file) {
      setErrorMessage('File not found');
      failure('File not found');
    }

    Papa.parse(file, {
      header: true,
      complete(event) {
        console.log('Parsed line items', event);
        const sorted = _sortLineItems(event.data);
        handleLineItems(sorted);
      },
      transformHeader(value) {
        return _makeLowerCase(value);
      },
      transform(value, name) {
        const lower = name.toLowerCase();
        if (lower === 'amount' || lower === 'prevyear') {
          if (!value) return 0;
          return parseInt(value);
        }
        return value;
      },
    });
  };

  return {
    errorMessage,
    importLineItemsFile,
  };
};

export default useImports;
