import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Table } from 'semantic-ui-react';
import EditImpounds from './Edit/EditImpounds';
import ExportReconciliation from './ExportReconciliation';
import {
  computeTaxes,
  computeDifference,
  makeExportFileData,
} from './Reconciler';

const ReconcileBuyers = ({ park, taxBill, bought }) => {
  const { model } = taxBill;
  const [allValues, setAllValues] = useState([]);

  useEffect(() => {
    const valuesMap = park.spaces
      .map((space) => {
        const year = space.purchaseDate?.getFullYear();

        const taxes = computeTaxes(park, space, model);
        const difference = computeDifference(park, model, {
          coach: space.number,
          difference: taxes.difference,
        });
        const value = {
          coach: space.number,
          space,
          taxes,
          difference,
        };
        if (bought === model.year - 2 && (!year || year < model.year - 1)) {
          return value;
        }
        if (bought === year) {
          return value;
        }
        return null;
      })
      .filter((value) => value);
    setAllValues(valuesMap);
  }, [park, taxBill, bought, model]);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const currency = (value) => (value !== 0 ? formatter.format(value) : '');

  const renderButtons = (space) => (
    <Button.Group size="mini">
      <EditImpounds space={space} />
    </Button.Group>
  );

  const renderNormalRow = (values) => {
    const { space } = values;

    return (
      <Table.Row key={space.id}>
        <Table.Cell textAlign="center">{space.number}</Table.Cell>
        <Table.Cell textAlign="left">{space.name}</Table.Cell>
        <Table.Cell textAlign="center">
          {currency(space.impounds.amount)}
        </Table.Cell>
        <Table.Cell textAlign="center" />
        <Table.Cell textAlign="center" />
        <Table.Cell textAlign="center" />
        <Table.Cell textAlign="center" />
        <Table.Cell textAlign="center" />
        <Table.Cell textAlign="center">
          {model && renderButtons(space)}
        </Table.Cell>
      </Table.Row>
    );
  };

  const renderTable = () => (
    <>
      <Table celled striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell textAlign="center">
              <ExportReconciliation
                exportFile={makeExportFileData(allValues)}
              />
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="left">Name</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Impounds</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Taxes</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Supplemental</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Park Paid</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">
              Total Impounds
            </Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Difference</Table.HeaderCell>
            <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {allValues.map((values) => renderNormalRow(values))}
        </Table.Body>
      </Table>
    </>
  );

  return renderTable();
};

ReconcileBuyers.propTypes = {
  park: PropTypes.object.isRequired,
  taxBill: PropTypes.object.isRequired,
  bought: PropTypes.number.isRequired,
};

export default ReconcileBuyers;
