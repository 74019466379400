import { useState } from 'react';
import firebase from 'firebase/app';
import { parseLocaleNumber } from '../../../../services/number/formatting';
import { db } from '../../../../services/firebase/firebase';

// Custom hook for CRUD Birthday. Provide optional callbacks for
// success and failure notification.
const useBirthday = (itemOrNull, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();

  const [person, setPerson] = useState({
    name: itemOrNull.name || '',
    space: itemOrNull.space || '',
    date: itemOrNull.date ? itemOrNull.date : new Date(),
  });

  const [birthdays, setBirthdays] = useState([]);

  // Returns variables for edit
  const makeVariables = () => ({
    name: person.name,
    date: person.date,
    space: parseLocaleNumber(person.space),
  });

  // const makeCreateVariables = () => {
  //   return makeVariables();
  // };

  const canCreate = () =>
    birthdays.length > 0 || (person.name && person.date && person.space);

  const canUpdate = () => true;

  const _writeBatch = async (batch) => {
    batch
      .commit()
      .then((res) => {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        failure(errorMessage);
      });
  };

  // Creates a batch of birthdays using the given birthdays array
  const _create = async (createList) => {
    const ref = db.collection('paseo-de-palomas').doc('birthdays');

    const writeBatch = db.batch();

    createList.forEach((bday) => {
      writeBatch.update(ref, {
        persons: firebase.firestore.FieldValue.arrayUnion({ ...bday }),
      });
    });

    _writeBatch(writeBatch);
  };

  const doCreate = async () => {
    _create(birthdays.length > 0 ? birthdays : [makeVariables()]);
  };

  const _update = async (variables) => {
    console.log('update person vars ', variables);

    const ref = db.collection('paseo-de-palomas').doc('birthdays');

    const writeBatch = db.batch();
    writeBatch.update(ref, {
      persons: firebase.firestore.FieldValue.arrayRemove({ ...itemOrNull }),
    });
    writeBatch.update(ref, {
      persons: firebase.firestore.FieldValue.arrayUnion({ ...variables }),
    });
    _writeBatch(writeBatch);
  };

  const doUpdate = async () => {
    _update(makeVariables());
  };

  const doDelete = async (entry) => {
    console.log('doDelete', entry);

    const ref = db.collection('paseo-de-palomas').doc('birthdays');

    const writeBatch = db.batch();
    writeBatch.update(ref, {
      persons: firebase.firestore.FieldValue.arrayRemove({ ...entry }),
    });
    _writeBatch(writeBatch);
  };

  const handleChange = (name, value) => {
    console.log('name, value', name, value);
    setPerson({ ...person, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    setBirthdays,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doDelete,
    person,
  };
};

export default useBirthday;
