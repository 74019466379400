import { useState, useEffect } from 'react';
import { db } from '../../../../services/firebase/firebase';

const useBids = (projectId) => {
  const [bids, setBids] = useState([]);
  const [loading, setLoading] = useState(true);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    setLoading(false);

    try {
      const unsubscribe = db
        .collection('projects')
        .doc(projectId)
        .collection('bids')
        .orderBy('name', 'asc')
        .onSnapshot((snapshot) => {
          const all = snapshot.docs.map((doc) => ({
            id: doc.id,
            ...doc.data(),
          }));
          setBids(all);
          console.log('bids', all);
        });

      return () => {
        console.log('cleanup  bids');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, [projectId]);

  return {
    fetchError,
    loading,
    bids,
  };
};

export default useBids;
