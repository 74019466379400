import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'semantic-ui-react';

const footerStyle = { fontWeight: '600' };

const ReconcileFooter = ({ taxes, includeSupplementals = false }) => {
  const formatter2 = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 2,
  });

  const renderHeaderRow = () => (
    <Table.Row>
      <Table.HeaderCell colSpan="3" />
      <Table.HeaderCell />
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        {includeSupplementals ? `Supplementals` : ''}
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Park Paid
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Impounds
      </Table.HeaderCell>
      <Table.HeaderCell textAlign="center" style={footerStyle}>
        Refunds
      </Table.HeaderCell>
      <Table.HeaderCell colSpan="1" />
    </Table.Row>
  );

  const renderRow = () => (
    <Table.Row>
      <Table.Cell colSpan="3" />
      <Table.Cell textAlign="center" style={footerStyle}>
        Totals
      </Table.Cell>
      <Table.Cell textAlign="center">
        {includeSupplementals && formatter2.format(taxes.supplementals)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter2.format(taxes.parkPaid)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter2.format(taxes.impounds)}
      </Table.Cell>
      <Table.Cell textAlign="center">
        {formatter2.format(taxes.refunds)}
      </Table.Cell>
      <Table.Cell colSpan="1" />
    </Table.Row>
  );

  return (
    <Table.Footer fullWidth>
      {renderHeaderRow()}
      {renderRow()}
    </Table.Footer>
  );
};

ReconcileFooter.propTypes = {
  taxes: PropTypes.object.isRequired,
};

export default ReconcileFooter;
