import React from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles } from '@material-ui/core/styles';
import { Button, Dimmer, Header, Loader, Modal, Form } from 'semantic-ui-react';
import ModalActions from '../../../components/CreateModal/ModalActions';
import Downloader from '../../../components/Downloader/Downloader';
import useBirthdays from '../../Office/Birthdays/useBirthdays';
import { formatDateString } from '../../../services/dates/formatting';
import useSpaces from '../useSpaces';

const LightTooltip = withStyles((theme) => ({
  tooltip: {
    backgroundColor: theme.palette.common.white,
    color: 'rgba(0, 0, 0, 0.87)',
    boxShadow: theme.shadows[1],
    fontSize: 11,
  },
}))(Tooltip);

const columns = [
  'number',
  'address',
  'name',
  'name2',
  'notes',
  'name1Phone1',
  'shareName',
  'shareNumber',
  'decal',
  'size',
  'isArchive',
  'isForSale',
  'isVacant',
  'isNewHome',
  'purchaseDate',
  'purchasePrice',
];

const ExportSpaces = () => {
  const { spaces, spacesLoading, spacesError } = useSpaces();
  const { birthdays } = useBirthdays();
  // console.log('birthdays', birthdays);

  const [open, setOpen] = React.useState(false);
  const [fileName, setFileName] = React.useState();

  const findPossibles = (space) => {
    const possibles = birthdays
      .map((bd) => (bd.space === space.number ? bd : null))
      .filter((item) => !!item);
    return possibles;
  };

  const findBirthday1 = (possibles) =>
    possibles.length > 0 ? possibles[0].date : null;

  const findBirthday2 = (possibles) =>
    possibles.length > 1 ? possibles[1].date : null;

  const fixSize = (sizeString) =>
    sizeString ? sizeString.replace(',', ';') : sizeString;

  const makeSpaceVars = (space) => {
    // Find a birthday in the list if possible
    const possibles = findPossibles(space);
    // console.log('looking for ', space.name, space.name2, possibles);
    const birthday1 = findBirthday1(possibles);
    const birthday2 = findBirthday2(possibles);

    const vars = {
      number: space.number,
      address: space.address,
      name: space.name,
      name2: space.name2,
      birthday1,
      birthday2,
      notes: space.notes,
      name1Phone1: space.phone,
      shareName: space.name,
      shareNumber: space.shareNumber,
      decal: space.decal,
      size: fixSize(space.size),
      isArchive: space.isArchive,
      isForSale: space.isForSale,
      isVacant: space.isVacant,
      isNewHome: space.isNewHome,
      date: formatDateString(space.purchaseDate),
      price: space.purchasePrice,
    };
    return vars;
  };

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleFileName = (e) => {
    setFileName(e.target.value);
  };

  const exportFile = () => {
    if (spaces) {
      console.log('making export file', spaces);
      return spaces.map((space) => makeSpaceVars(space));
    }
    return [];
  };

  const disabled = () => false;

  const renderForm = () => (
    <div>
      <Form>
        <Form.Group>
          <Form.Input
            type="text"
            label="Export File Name"
            id="form-input-file"
            onChange={handleFileName}
          />
        </Form.Group>
      </Form>
      {fileName && spaces && (
        <Downloader
          title="Download Spaces"
          filename={fileName}
          columns={columns}
          datas={exportFile()}
        />
      )}
    </div>
  );

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <LightTooltip title="Export Owners" placement="top-start">
          <Button color="teal" basic style={{ margin: '6px 20px 10px' }}>
            Export
          </Button>
        </LightTooltip>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Import Spaces" />
      <Modal.Content>
        {spacesLoading && (
          <Dimmer inverted active>
            <Loader size="large">Loading</Loader>
          </Dimmer>
        )}
        {spacesError && <p>Error: {spacesError.message}</p>}
        {renderForm()}
      </Modal.Content>
      <ModalActions
        onClose={handleClose}
        onSave={exportFile}
        disabled={disabled}
      />
    </Modal>
  );
};

export default ExportSpaces;
