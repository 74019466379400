import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Privacy from '../../../components/Privacy/Privacy';
import ComponentSets from '../ComponentSets/ComponentSets';
import Archived from '../Studies/Archived/Archived';
import Activity from '../Studies/Activity/Activity';
import Studies from '../Studies/Studies';
import Help from '../Help/Help';
import './ReservesContent.scss';

class ReservesContent extends Component {
  render() {
    return (
      <div className="reserves-content">
        <Switch>
          <Route path="/reserves/studies" component={Studies} />
          <Route path="/reserves/archived" component={Archived} />
          <Route path="/reserves/activities" component={Activity} />
          <Route path="/reserves/components" component={ComponentSets} />
          <Route path="/reserves/privacy" component={Privacy} />
          <Route path="/reserves/help" component={Help} />
          <Route
            path="/reserves/feedback"
            render={() => <p>Feedback: under construction</p>}
          />
        </Switch>
      </div>
    );
  }
}

export default ReservesContent;
