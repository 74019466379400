import { useState } from 'react';
import { parseLocaleFloat } from '../../../../../services/number/formatting';
import { db } from '../../../../../services/firebase/firebase';

// Custom hook for CRUD Payment. Provide optional callbacks for
// success and failure notification.
const usePayment = (
  projectId,
  paymentOrNull,
  success = () => {},
  failure = () => {}
) => {
  const [payment, setPayment] = useState({
    name: paymentOrNull.name || '',
    amount: paymentOrNull.amount || '',
    notes: paymentOrNull.notes || '',
    source: paymentOrNull.source || '',
  });

  // Returns variables for edit or create
  const makeVariables = () => ({
    ...payment,
    amount: parseLocaleFloat(payment.amount),
  });

  const makeCreateVariables = () => makeVariables();

  const canCreate = () => payment.name && payment.amount;

  const canUpdate = () => true;

  const doCreate = async () => {
    const vars = makeCreateVariables();
    console.log('create Payment vars ', vars);

    db.collection('projects')
      .doc(projectId)
      .collection('payments')
      .add(vars)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error creating Payment', error);
        failure(error.message);
      });
  };

  const update = async (variables) => {
    console.log('update Payment vars ', variables);

    db.collection('projects')
      .doc(projectId)
      .collection('payments')
      .doc(paymentOrNull.id)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating payment', error);
        failure(error.message);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const handleChange = (name, value) => {
    setPayment({ ...payment, [name]: value });
  };

  return {
    handleChange,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    payment,
  };
};

export default usePayment;
