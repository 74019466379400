import React from 'react';
import DatePicker from 'react-datepicker';
import { Dropdown, Form } from 'semantic-ui-react';
import { digits } from '../../../../../services/number/formatting';
import 'react-datepicker/dist/react-datepicker.css';

const ExpenseForm = ({ expense, options, fieldUpdated, handleOption }) => {
  const optionUpdated = (name, value) => {
    handleOption(name, value, options);
  };

  const expenseDate = (timestamp) => {
    fieldUpdated('date', timestamp);
  };

  return (
    <Form>
      <Form.Group>
        <Form.Input width={5} label="Component">
          <Dropdown
            placeholder="Select Component"
            basic
            selection
            name="name"
            options={options}
            value={expense.name}
            onChange={(_, data) => optionUpdated(data.name, data.value)}
          />
        </Form.Input>
        <Form.Input
          width={2}
          fluid
          label="ID"
          id="form-input-id"
          value={expense.pid}
          onChange={(_, data) => {
            fieldUpdated('pid', data.value);
          }}
        />
        <Form.Input label="Date">
          <DatePicker
            selected={expense.date}
            onChange={expenseDate}
            popperPlacement="top-end"
          />
        </Form.Input>
        <Form.Input
          width={2}
          fluid
          label="Amount"
          id="form-input-amount"
          value={expense.amount}
          onChange={(_, data) => {
            fieldUpdated('amount', digits(data.value));
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default ExpenseForm;
