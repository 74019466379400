import { useState } from 'react';
import firebase from 'firebase/app';
import { parseLocaleNumber } from '../../../../services/number/formatting';
import { db } from '../../../../services/firebase/firebase';

// Custom hook for CRUD Component. Provide callbacks for
// success and failure notification.
const useComponent = (set, item, index, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();
  const [component, setComponent] = useState({
    pid: item.pid || '',
    name: item.name || '',
    ul: item.usefulLife || '',
    rul: item.remainingUsefulLife || '',
    rc: item.replacementCost || '',
    category: item.category || '',
  });

  // Returns variables for edit
  const makeVariables = () => ({
    pid: component.pid,
    name: component.name,
    remainingUsefulLife: parseLocaleNumber(component.rul),
    usefulLife: parseLocaleNumber(component.ul),
    replacementCost: parseLocaleNumber(component.rc),
    category: component.category,
  });

  const makeCreateVariables = () => makeVariables();

  const canCreate = () =>
    component.pid &&
    component.name &&
    component.category &&
    component.remainingUsefulLife &&
    component.usefulLife &&
    component.replacementCost;

  const canUpdate = () => true;

  const _writeBatch = async (batch) => {
    batch
      .commit()
      .then((res) => {
        success();
      })
      .catch((error) => {
        failure(error.message);
      });
  };

  const doCreate = async () => {
    const vars = makeCreateVariables();
    console.log('create Component vars ', vars);
    const ref = db.collection('componentSets').doc(set.id);

    const writeBatch = db.batch();
    writeBatch.update(ref, {
      components: firebase.firestore.FieldValue.arrayUnion({ ...vars }),
    });
    _writeBatch(writeBatch);
  };

  const update = async (variables) => {
    console.log('update Component vars ', variables, component);

    // Update component array at index
    set.components[index] = variables;
    // Update ComponentSet
    const ref = db.collection('componentSets').doc(set.id);
    ref
      .update({
        components: set.components,
      })
      .then(function () {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        console.error('Error updating component: ', error);
      });
  };

  const doUpdate = async () => {
    update(makeVariables());
  };

  const handleChange = (name, value) => {
    setComponent({ ...component, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    component,
  };
};

export default useComponent;
