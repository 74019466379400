import React from 'react';
import PropTypes from 'prop-types';
import { Table, Button, Divider, Header, Segment } from 'semantic-ui-react';
import Can from '../../../../components/Can/Can';
import { UserContext } from '../../../../services/context/UserContext';
import ConfirmDelete from '../../../../components/Confirm/ConfirmDelete';
import CreatePayment from './EditPayment/CreatePayment';
import EditPayment from './EditPayment/EditPayment';
import useModify from './EditPayment/useModify';
import usePayments from './usePayments';

const isArchived = (project) => project && project.status === 'ARCHIVED';

const PaymentsTable = ({ project, payments, user }) => {
  const { deletePayment } = useModify(
    project.id,
    () => {},
    (error) => {
      console.log('Delete payment failed', error);
    }
  );

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  const currency = (value) => (value ? formatter.format(value) : null);

  const deleteConfirmed = (payment) => {
    console.log('deleteConfirmed', payment);
    deletePayment(payment.id);
  };

  const renderPaymentButton = (proj) => <CreatePayment project={proj} />;

  const renderAddPayment = (proj) =>
    isArchived(proj) ? null : (
      <Can
        role={user.role}
        perform="job:edit"
        yes={() => renderPaymentButton(proj)}
      />
    );

  const renderButtons = (payment) =>
    isArchived(project) ? null : (
      <Button.Group floated="right" size="mini">
        <EditPayment project={project} editing={payment} />
        <ConfirmDelete
          deleteId={payment}
          name={`Payment to ${payment.name}`}
          deleteConfirmed={deleteConfirmed}
        />
      </Button.Group>
    );

  const renderButtonsCan = (payment) => (
    <Can
      role={user.role}
      perform="job:edit"
      yes={() => renderButtons(payment)}
    />
  );

  const renderNormalRow = (payment, index) => (
    <Table.Row key={index} width={16}>
      <Table.Cell colSpan={2} textAlign="center">
        {payment.name}
      </Table.Cell>
      <Table.Cell textAlign="center">{currency(payment.amount)}</Table.Cell>
      <Table.Cell textAlign="center">{payment.source}</Table.Cell>
      <Table.Cell textAlign="left">{payment.notes}</Table.Cell>
      <Table.Cell>{renderButtonsCan(payment)}</Table.Cell>
    </Table.Row>
  );

  return (
    <React.Fragment key={project.id}>
      <Segment color="red">
        <Header as="h4" textAlign="center" block>
          <Divider horizontal>{project.name} Payments</Divider>
        </Header>

        <Table celled striped>
          <Table.Header fullWidth>
            <Table.Row>
              <Table.HeaderCell width={1}>
                {renderAddPayment(project)}
              </Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                Name
              </Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                Amount
              </Table.HeaderCell>
              <Table.HeaderCell width={2} textAlign="center">
                Source
              </Table.HeaderCell>
              <Table.HeaderCell width={16} textAlign="left">
                Notes
              </Table.HeaderCell>
              <Table.HeaderCell textAlign="center">Actions</Table.HeaderCell>
            </Table.Row>
          </Table.Header>
          <Table.Body>
            {project &&
              payments &&
              payments.map((payment, index) => renderNormalRow(payment, index))}
          </Table.Body>
        </Table>
      </Segment>
    </React.Fragment>
  );
};

const Payments = ({ project }) => {
  const { payments, error } = usePayments(project.id);

  if (error) {
    return <p>Error: ${error.message}</p>;
  }

  return (
    <UserContext.Consumer>
      {({ user }) => (
        <PaymentsTable payments={payments} project={project} user={user} />
      )}
    </UserContext.Consumer>
  );
};

PaymentsTable.propTypes = {
  project: PropTypes.object.isRequired,
  user: PropTypes.object.isRequired,
};

Payments.propTypes = {
  project: PropTypes.object.isRequired,
};

export default Payments;
