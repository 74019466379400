import { useState, useEffect } from 'react';
import {
  getSelectedTaxBill,
  saveSelectedTaxBill,
} from '../../../services/storage/Storage';

// Returns selected TaxBill index or 0
const findSelectedIndex = (taxBillId, taxBills) => {
  if (!taxBillId) {
    return 0;
  }
  if (!taxBills) {
    return 0;
  }
  const found = taxBills.find((tb) => tb.id === taxBillId);
  return taxBills.indexOf(found);
};

const findArchiveIndex = (taxBills) => {
  if (!taxBills) {
    return 0;
  }
  return taxBills.length > 0 ? taxBills.length - 1 : 0;
};

// Hook that handles the selected TaxBill index
const useTaxBillSelector = (taxBills, showArchive = false) => {
  const [selectedIndex, setSelectedIndex] = useState();

  useEffect(() => {
    const index = showArchive
      ? findArchiveIndex(taxBills)
      : findSelectedIndex(getSelectedTaxBill(), taxBills);
    setSelectedIndex(index);
  }, [taxBills, showArchive]);

  const handleSelectionChange = (selectedId) => {
    setSelectedIndex(findSelectedIndex(selectedId, taxBills));
    saveSelectedTaxBill(selectedId);
  };

  return {
    selectedIndex,
    handleSelectionChange,
  };
};

export default useTaxBillSelector;
