const UNITS = ['K', 'M', 'B', 'T'];

// https://stackoverflow.com/a/28608086/2328833
export function getShortNumberString(number) {
  const shouldShowDecimalPlace = UNITS.some((element, index) => {
    const lowerBound = 1000 ** (index + 1);
    const upperBound = lowerBound + lowerBound * 10;
    return number > lowerBound && number < upperBound;
  });

  const digs = shouldShowDecimalPlace ? 1 : 0;
  for (let i = UNITS.length - 1; i >= 0; i -= 1) {
    const decimal = 1000 ** (i + 1);

    if (number >= decimal) {
      return (number / decimal).toFixed(digs) + UNITS[i];
    }
  }
  return number.toString();
}

export function parseLocaleNumber(stringNumber) {
  if (!stringNumber) return 0;
  if (stringNumber.replace === undefined) return stringNumber;
  const value = stringNumber.replace(/\D/g, '');
  return parseInt(value);
}

export function parseLocaleFloat(stringNumber) {
  if (!stringNumber) return 0;
  if (stringNumber.replace === undefined) return stringNumber;
  const value = stringNumber.replace('%', '');
  const value2 = value.replace('$', '');
  const value3 = value2.replace(',', '');
  return parseFloat(value3);
}

export const digits = (string) => string.replace(/\D/g, '');

export const acceptFloat = (value) =>
  value.replace(/[^0-9.]/g, '').replace(/(\..*)\./g, '$1');
/**
 * Check whether the value is a JavaScript number.
 *
 * First checks typeof, then self-equality to make sure it is
 * not NaN, then Number.isFinite() to check for Infinity.
 *
 * @param {*} value - The value to check
 * @return {boolean} Whether that value is a number
 */
export const isNumber = (value) =>
  // eslint-disable-next-line
  typeof value === "number" && value === value && Number.isFinite(value);

/**
 * First checks typeof, then self-equality to make sure it is
 * not NaN, then Number.isFinite() to check for Infinity.
 *
 * @param {*} value - The value to check
 * @return {boolean} Whether that value is a number
 */
export const isNumber2 = (value) => {
  // First: Check typeof and make sure it returns number
  // This code coerces neither booleans nor strings to numbers,
  // although it would be possible to do so if desired.
  if (typeof value !== 'number') {
    return false;
  }
  // Reference for typeof:
  // https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Operators/typeof
  // Second: Check for NaN, as NaN is a number to typeof.
  // NaN is the only JavaScript value that never equals itself.
  if (value !== Number(value)) {
    return false;
  }
  // Reference for NaN: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isNaN
  // Note isNaN() is a broken function, but checking for self-equality works as NaN !== NaN
  // Alternatively check for NaN using Number.isNaN(), an ES2015 feature that works how one would expect

  // Third: Check for Infinity and -Infinity.
  // Realistically we want finite numbers, or there was probably a division by 0 somehere.
  if (Number.isFinite(value) === false) {
    return false;
  }
  // Reference for Number.isFinite: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/isFinite
  return true;
};
