import React, { useState, useRef } from 'react';
import { Link } from 'react-router-dom';
import { Button, Header } from 'semantic-ui-react';
import ReactToPrint from 'react-to-print';
import BallotImage from './BallotImage';
import BallotForm from './BallotForm';

const Ballot = () => {
  const componentRef = useRef();
  const [content, setContent] = useState({
    line1: '',
    line2: '',
    line3: '',
    line4: '',
    line5: '',
    line6: '',
    date: '',
    date3: '',
  });

  // const generateRandomLetter = () => {
  //   const alphabet = 'abcdefghijklmnopqrstuvwxyz';
  //   return alphabet[Math.floor(Math.random() * alphabet.length)];
  // };

  const updateContent = (values) => {
    setContent({
      ...content,
      line1: values[0],
      line2: values[1],
      line3: values[2],
      line4: values[3],
      line5: values[4],
      line6: values[5],
    });
  };

  const getNames = () => [
    content.line1,
    content.line2,
    content.line3,
    content.line4,
    content.line5,
    content.line6,
  ];

  const randomizeNames = () => {
    const players = getNames();
    // Remove any empty elements
    const names = players.filter((name) => name.length > 0);

    names.sort(() => 0.5 - Math.random());
    while (names.length < 6) {
      names.push('');
    }
    console.log('rnd after push', names);
    updateContent(names);
  };

  const alphabetizeNames = () => {
    const players = getNames();

    // Remove any empty elements
    const names = players.filter((name) => name.length > 0);
    names.sort((a, b) => {
      const split1 = a.split(' ');
      const split2 = b.split(' ');
      // const s1 = split1.length > 1 ? split1[1] : '';
      // const s2 = split2.length > 1 ? split1[1] : '';
      // return s1.localeCompare(s2);
      return split1[1].localeCompare(split2[1]);
    });
    while (names.length < 6) {
      names.push('');
    }
    console.log('alp after push', names);
    updateContent(names);
  };

  const disabled = () => content.date.length === 0;

  return (
    <>
      <Header content="Create New Ballot" />
      <BallotForm
        content={content}
        fieldUpdated={(name, value) => {
          setContent({ ...content, [name]: value });
        }}
        randomize={randomizeNames}
        alphabetize={alphabetizeNames}
      />
      <ReactToPrint
        trigger={() => (
          <div>
            <Button
              disabled={disabled()}
              basic
              size="large"
              color="teal"
              as={Link}
              to="ballot"
            >
              Print Ballot
            </Button>
          </div>
        )}
        content={() => componentRef.current}
        removeAfterPrint={false}
      />

      <BallotImage ref={componentRef} content={content} />
      {/* <div style={{ display: "none" }}> */}
      {/* <BallotImage content={content} /> */}
      {/* </div> */}
    </>
  );
};

export default Ballot;
