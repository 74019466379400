import firebase from 'firebase/app';
import { db } from '../../../services/firebase/firebase';

const useModify = (failure) => {
  const _doModify = async (docId, variables) => {
    db.collection('componentSets')
      .doc(docId)
      .update(variables)
      .catch((error) => {
        console.log('Error updating Component Set', error);
        failure(error.message);
      });
  };

  const _doDelete = (docId) => {
    db.collection('componentSets')
      .doc(docId)
      .delete()
      .catch((error) => {
        console.log('Error deleting', error);
        failure(error.message);
      });
  };

  const setCurrentState = (docId, state) => {
    _doModify(docId, { isCurrent: state });
  };

  const deleteComponentSet = (docId) => {
    _doDelete(docId);
  };

  const _writeBatch = async (batch) => {
    batch
      .commit()
      .then((res) => {})
      .catch((error) => {
        failure(error.message);
      });
  };

  const deleteComponent = (setId, entry) => {
    console.log('Delete component ', setId, entry);

    const ref = db.collection('componentSets').doc(setId);

    const writeBatch = db.batch();
    writeBatch.update(ref, {
      components: firebase.firestore.FieldValue.arrayRemove({ ...entry }),
    });
    _writeBatch(writeBatch);
  };

  return {
    deleteComponent,
    deleteComponentSet,
    setCurrentState,
  };
};

export default useModify;
