import React from 'react';
import { Checkbox, Form } from 'semantic-ui-react';
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css';

const EditForm = ({ fieldUpdated, space }) => {
  const purchaseDate = (timestamp) => {
    fieldUpdated('purchaseDate', timestamp);
  };

  return (
    <Form>
      <Form.Group>
        <Form.Input
          width={2}
          label="Space"
          id="form-input-space"
          value={space.number}
          onChange={(_, data) => {
            fieldUpdated('number', data.value);
          }}
        />
        <Form.Input
          width={3}
          label="Address"
          id="form-input-address"
          value={space.address}
          onChange={(_, data) => {
            fieldUpdated('address', data.value);
          }}
        />
        <Form.Input
          width={3}
          label="Owner Name"
          id="form-input-name"
          value={space.name}
          onChange={(_, data) => {
            fieldUpdated('name', data.value);
          }}
        />
        <Form.Input
          width={3}
          label="Owner 2 Name"
          id="form-input-name2"
          value={space.name2}
          onChange={(_, data) => {
            fieldUpdated('name2', data.value);
          }}
        />
        <Form.Input
          width={3}
          label="Phone"
          id="form-input-phone"
          value={space.phone}
          onChange={(_, data) => {
            fieldUpdated('phone', data.value);
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          width={3}
          label="Size"
          id="form-input-size"
          value={space.size}
          onChange={(_, data) => {
            fieldUpdated('size', data.value);
          }}
        />
        <Form.Input
          width={2}
          label="Decal"
          id="form-input-decal"
          value={space.decal}
          onChange={(_, data) => {
            fieldUpdated('decal', data.value);
          }}
        />
        <Form.Input
          width={2}
          label="Share #"
          id="form-input-share"
          value={space.shareNumber}
          onChange={(_, data) => {
            fieldUpdated('shareNumber', data.value);
          }}
        />
      </Form.Group>
      <Form.Group>
        <Form.Input
          width={2}
          label="Purchase Price"
          id="form-input-pp"
          value={space.purchasePrice}
          onChange={(_, data) => {
            fieldUpdated('purchasePrice', data.value);
          }}
        />
        <Form.Input label="Purchase Date">
          <DatePicker
            selected={space.purchaseDate}
            onChange={purchaseDate}
            popperPlacement="top-end"
          />
        </Form.Input>
        <Form.Input width={2} label="Vacant" id="form-input-vacant">
          <Checkbox
            checked={space.isVacant}
            onChange={(_, data) => {
              fieldUpdated('isVacant', data.checked);
            }}
          />
        </Form.Input>
        <Form.Input width={2} label="For Sale" id="form-input-forSale">
          <Checkbox
            checked={space.isForSale}
            onChange={(_, data) => {
              fieldUpdated('isForSale', data.checked);
            }}
          />
        </Form.Input>
        <Form.Input width={2} label="New Home" id="form-input-newHome">
          <Checkbox
            checked={space.isNewHome}
            onChange={(_, data) => {
              fieldUpdated('isNewHome', data.checked);
            }}
          />
        </Form.Input>
      </Form.Group>
      <Form.Group>
        <Form.Input
          width={16}
          label="Notes"
          id="form-input-notes"
          value={space.notes}
          onChange={(_, data) => {
            fieldUpdated('notes', data.value);
          }}
        />
      </Form.Group>
    </Form>
  );
};

export default EditForm;
