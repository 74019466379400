import React from 'react';
import { Button, Divider, Header, Table, Segment } from 'semantic-ui-react';
import ShowModal from '../../../components/ShowModal/ShowModal';
import ExlusionsTable from './ExclusionsTable';
import ExpenseItems from './ExpenseItems';

const YearlySummaryTable = ({ study }) => {
  const renderNormalRow = (year, index) => (
    <Table.Row key={index}>
      <Table.Cell textAlign="center">{year.name}</Table.Cell>
      <Table.Cell textAlign="center">{year.bffb}</Table.Cell>
      <Table.Cell textAlign="center">{year.brb}</Table.Cell>
      <Table.Cell textAlign="center">{year.bpf}</Table.Cell>
      <Table.Cell textAlign="center">{year.ra}</Table.Cell>
      <Table.Cell textAlign="center">{year.int}</Table.Cell>
      <Table.Cell textAlign="center">{year.exp}</Table.Cell>
      <Table.Cell textAlign="center">{year.eb}</Table.Cell>
      <Table.Cell textAlign="center">
        <Button.Group basic size="mini">
          <ShowModal
            buttonTitle="Expense Items"
            modalTitle={`Expense Items for Year ${year.name}`}
          >
            <ExpenseItems
              study={study}
              index={index}
              items={study.yearlyExpenses.componentsByYear[index]}
            />
          </ShowModal>
        </Button.Group>
      </Table.Cell>
    </Table.Row>
  );

  return (
    <>
      <Table celled compact striped>
        <Table.Header fullWidth>
          <Table.Row>
            <Table.HeaderCell width={1} textAlign="center">
              Study Year
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Fully Funded Balance
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Starting Reserves Balance
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              % Funded
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Annual Allocation
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Annual Interest
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Expenses
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Ending Balance
            </Table.HeaderCell>
            <Table.HeaderCell width={1} textAlign="center">
              Actions
            </Table.HeaderCell>
          </Table.Row>
        </Table.Header>
        <Table.Body>
          {study.summaryData &&
            study.summaryData.map((year, index) =>
              renderNormalRow(year, index)
            )}
        </Table.Body>
      </Table>
    </>
  );
};

const YearlySummary = ({ study }) => (
  // console.log('has any exluded items?', study.hasExclusions());
  <>
    <Segment color="green">
      <Header as="h3" textAlign="center" block>
        <Divider horizontal>Yearly Summary for {study.name}</Divider>
      </Header>
      {study.hasExclusions() && <ExlusionsTable study={study} />}
      {/* <Segment.Group> */}

      <YearlySummaryTable study={study} />
    </Segment>
    {/* </Segment.Group> */}
  </>
);
export default YearlySummary;
