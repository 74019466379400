import React from 'react';
import HeaderNav from '../HeaderNav/HeaderNav';
import ScrollToTop from '../ScrollToTop/ScrollToTop';
import {
  UserContext,
  useUserContext,
} from '../../services/context/UserContext';
import './AppLayout.scss';

const AppLayout = ({ children }) => {
  const { user } = useUserContext(UserContext);

  return (
    <ScrollToTop>
      <div className="not-genre-list">
        <div className="app-layout">
          <HeaderNav user={user} />
          {children}
        </div>
      </div>
    </ScrollToTop>
  );
};

export default AppLayout;
