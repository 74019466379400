import { db } from '../../../../services/firebase/firebase';

const useModify = (success, failure) => {
  const _doModify = async (loanId, variables) => {
    db.collection('loans')
      .doc(loanId)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating loan', error);
        failure(error.message);
      });
  };

  const archiveLoan = (loanId) => {
    _doModify(loanId, { isArchived: true });
  };

  const activateLoan = (loanId) => {
    _doModify(loanId, { isArchived: false });
  };

  const toggleArchive = (loan) => {
    if (loan) {
      _doModify(loan.id, { archive: !loan.archive });
    }
  };

  const deleteLoan = (loanId) => {
    db.collection('loans')
      .doc(loanId)
      .delete()
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error deleting loan', error.message);
        failure(error.message);
      });
  };

  return {
    deleteLoan,
    archiveLoan,
    activateLoan,
    toggleArchive,
  };
};

export default useModify;
