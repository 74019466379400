import React from 'react';
import {
  // ResponsiveContainer,
  ScatterChart,
  CartesianGrid,
  XAxis,
  YAxis,
  Tooltip,
  Scatter,
} from 'recharts';
import moment from 'moment';
import {
  formatDateString,
  compareDates,
} from '../../../../services/dates/formatting';
import useArchives from '../../Listings/useArchives';

const monthYearDateString = (date) => {
  const newDate = new Date(date);
  // console.log('dates ', date, newDate);
  return `${newDate.getMonth()}/${newDate.getFullYear().toString().slice(-2)}`;
};

const listingDate = (listing) => {
  if (listing.closedAt) {
    return listing.closedAt;
  }
  if (listing.listedAt) {
    return listing.listedAt;
  }
  return null;
};

const daysOnMarket = (listing) => {
  if (listing.listedAt && listing.closedAt) {
    const start = moment(listing.listedAt);
    const end = moment(listing.closedAt);
    const days = moment.duration(end.diff(start)).asDays();
    return days.toFixed(0);
  }
  return 0;
};

const makeData = (listings) =>
  listings
    .map((listing) => {
      const dom = daysOnMarket(listing);
      const date = listingDate(listing);
      const dateString = monthYearDateString(date);
      // console.log('Use date', date);
      const price = listing.selling ? listing.selling : listing.asking;
      return {
        dateObj: new Date(date),
        dates: date.toString(),
        date: dateString,
        price,
        dom,
      };
    })
    .sort((a, b) => compareDates(a.dateObj, b.dateObj));

const SalesScatter = () => {
  const { archives } = useArchives();

  const formatter = new Intl.NumberFormat('en-US', {
    maximumSignificantDigits: 6,
    style: 'currency',
    currency: 'USD',
  });

  const formatValue = (value) => {
    if (typeof value === 'string') {
      return formatDateString(value);
    }
    return formatter.format(value);
  };

  const formatShortDate = (value) =>
    // console.log('formatShortDate', value);
    monthYearDateString(value);
  if (!archives) {
    return null;
  }

  const scatterData = makeData(archives);

  return (
    <ScatterChart
      width={1100}
      height={500}
      margin={{
        top: 20,
        right: 20,
        bottom: 20,
        left: 20,
      }}
    >
      <CartesianGrid />
      <XAxis
        type="category"
        dataKey="dates"
        name="date"
        tickFormatter={(value) => formatShortDate(value)}
      />
      <YAxis
        type="number"
        dataKey="price"
        name="price"
        tickFormatter={(value) => formatValue(value)}
        domain={[150000, 600000]}
        ticks={[100000, 200000, 300000, 400000, 500000, 600000]}
      />
      <Tooltip
        cursor={{ strokeDasharray: '4 4' }}
        formatter={(value) => formatValue(value)}
      />
      <Scatter name="Sales" data={scatterData} fill="#8884d8" />
    </ScatterChart>
  );
};

export default SalesScatter;
