import React, { useState } from 'react';
import { Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../../components/CreateModal/ModalActions';
import PopupButton from '../../../../../components/PopupButton/PopupButton';
import useAllocation from './useAllocation';
import AllocationForm from './AllocationForm';

const EditAllocation = ({ study, editAlloc }) => {
  const [open, setOpen] = useState(false);
  const { allocation, doUpdate, canUpdate, handleChange } = useAllocation(
    editAlloc,
    study,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const disabled = () => !canUpdate();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <PopupButton
          id="edit-allocation"
          basic
          icon="edit outline"
          color="teal"
          title="Edit Allocation"
          onClick={() => {}}
        />
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Allocation" />
      <Modal.Content>
        <AllocationForm allocation={allocation} fieldUpdated={handleChange} />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditAllocation;
