import { useState, useEffect } from 'react';
import { db } from '../../../services/firebase/firebase';

const sortCategories = (frequents) => {
  if (!frequents) return [];

  const cats = frequents.reduce((acc, item) => {
    const cat = item.category;
    if (!acc[cat]) {
      acc[cat] = [];
    }
    acc[cat].push(item);
    return acc;
  }, {});
  const values = Object.values(cats);
  const ordered = values.sort((a, b) => {
    const aCat = a[0].category;
    const bCat = b[0].category;
    return aCat > bCat ? 1 : -1;
  });
  return ordered;
};

const useBirthdays = () => {
  const [categories, setCategories] = useState([]);
  const [fetchError, setFetchError] = useState(null);

  useEffect(() => {
    try {
      const unsubscribe = db
        .collection('paseo-de-palomas')
        .doc('frequentnumbers')
        .onSnapshot((snapshot) => {
          if (snapshot.data() && snapshot.data().numbers) {
            const all = snapshot
              .data()
              .numbers.map((number) => ({ ...number }));
            const sorted = sortCategories(all);
            console.log('all fn', sorted);
            setCategories(sorted);
          }
        });

      return () => {
        console.log('cleanup frequentnumbers');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setFetchError(error.message);
    }
  }, []);

  return {
    fetchError,
    categories,
  };
};

export default useBirthdays;
