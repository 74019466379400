import { useState } from 'react';
import firebase from 'firebase/app';
import { parseLocaleFloat } from '../../../../../services/number/formatting';
import { db } from '../../../../../services/firebase/firebase';

// Custom hook for CRUD Bid. Provide optional callbacks for
// success and failure notification.
const useBid = (projectId, bidOrNull, success, failure) => {
  const [bid, setBid] = useState({
    isSelected: bidOrNull.isSelected || false,
    name: bidOrNull.name || '',
    amount: bidOrNull.amount || '',
    notes: bidOrNull.notes || '',
    imageUrls: bidOrNull.imageUrls || [],
  });

  // Returns variables for edit
  const makeVariables = () => ({
    name: bid.name,
    isSelected: bid.isSelected,
    amount: parseLocaleFloat(bid.amount),
    notes: bid.notes,
    imageUrls: bid.imageUrls,
  });

  const makeCreateVariables = () => makeVariables();

  const canCreate = () => bid.name && bid.amount;

  const canUpdate = () => true;

  const doAddImageUrl = (urlString) => {
    console.log('add image', projectId, urlString);
    const vars = [urlString];

    db.collection('projects')
      .doc(projectId)
      .collection('bids')
      .doc(bidOrNull.id)
      .update({ imageUrls: firebase.firestore.FieldValue.arrayUnion(...vars) })
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error adding image Url', error);
        failure(error.message);
      });
  };

  const doCreate = async () => {
    const vars = makeCreateVariables();
    console.log('create Bid vars ', vars);

    db.collection('projects')
      .doc(projectId)
      .collection('bids')
      .add(vars)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error creating Bid', error);
        failure(error.message);
      });
  };

  const _update = async (docId, variables) => {
    console.log('update Bid vars ', variables);

    db.collection('projects')
      .doc(projectId)
      .collection('bids')
      .doc(docId)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating bid', error);
        failure(error.message);
      });
  };

  const doUpdate = async () => {
    _update(bidOrNull.id, makeVariables());
  };

  const doDelete = async (id) => {
    console.log('doDelete', id);

    db.collection('projects')
      .doc(projectId)
      .collection('bids')
      .doc(id)
      .delete()
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error deleting bid', error);
        failure(error.message);
      });
  };

  const handleChange = (name, value) => {
    setBid({ ...bid, [name]: value });
  };

  return {
    handleChange,
    doAddImageUrl,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doDelete,
    bid,
  };
};

export default useBid;
