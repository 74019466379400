import { db } from '../../../../../services/firebase/firebase';

const useModify = (projectId, success, failure) => {
  const deleteBid = (bidId) => {
    console.log('delete bid', bidId);

    db.collection('projects')
      .doc(projectId)
      .collection('bids')
      .doc(bidId)
      .delete()
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error deleting bid', error);
        failure(error.message);
      });
  };

  const _update = async (bidId, variables) => {
    console.log('update Bid vars ', variables);

    db.collection('projects')
      .doc(projectId)
      .collection('bids')
      .doc(bidId)
      .update(variables)
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('Error updating bid', error);
        failure(error.message);
      });
  };

  const selectBid = (bidId) => {
    _update(bidId, { isSelected: true });
  };

  const unselectBid = (bidId) => {
    _update(bidId, { isSelected: false });
  };

  return {
    deleteBid,
    selectBid,
    unselectBid,
  };
};

export default useModify;
