import React, { Component } from 'react';
import { Route, Switch } from 'react-router-dom';
import Privacy from '../../../components/Privacy/Privacy';
import ImportSpaces from '../../Spaces/EditSpace/ImportSpaces';
import ExportSpaces from '../../Spaces/EditSpace/ExportSpaces';
import Exporter from '../../Spaces/EditSpace/Exporter';
import ImportImpounds from '../../Taxes/Reconcile/ImportImpounds';
import Users from '../Users/Users';
import './AdminContent.scss';

class AdminContent extends Component {
  render() {
    return (
      <div className="admin-content">
        <Switch>
          <Route path="/admin/exportdmc" component={Exporter} />
          <Route path="/admin/users" component={Users} />
          <Route path="/admin/importspaces" component={ImportSpaces} />
          <Route path="/admin/exportspaces" component={ExportSpaces} />
          <Route path="/admin/impounds" component={ImportImpounds} />
          <Route path="/admin/privacy" component={Privacy} />
        </Switch>
      </div>
    );
  }
}

export default AdminContent;
