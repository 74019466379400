import React from 'react';
import { Route, Switch } from 'react-router-dom';
import { Dimmer, Loader, Segment } from 'semantic-ui-react';
import { ParkContext } from '../../../services/context/ParkContext';
import Privacy from '../../../components/Privacy/Privacy';
import Spaces from '../../Spaces/Spaces';
import Archives from '../../Spaces/Archives';
import Report from '../../Coaches/Report/Report';
import Listings from '../../Coaches/Listings/Listings';
import CoachArchives from '../../Coaches/Listings/Archives';
import Electrical from '../../Electrical/Electrical';
import useSpaces from '../../Spaces/useSpaces';
import Park from '../Park';
import Help from '../Help/Help';
import './ParksContent.scss';

const ParksContent = () => {
  const { spaces, spacesError } = useSpaces();

  if (spacesError) {
    return (
      <div>
        <Segment textAlign="center" size="small" color="red">
          {`Error loading park spaces: ${spacesError}`}
        </Segment>
      </div>
    );
  }

  if (!spaces) {
    return (
      <Dimmer inverted active>
        <Loader size="large">Loading</Loader>
      </Dimmer>
    );
  }

  const park = new Park(spaces, []);

  return (
    <div className="parks-content">
      <ParkContext.Provider value={{ park }}>
        <Switch>
          <Route path="/park/directory" render={() => <Spaces />} />
          <Route
            path="/park/listings"
            render={() => <Listings showArchive={false} />}
          />
          <Route path="/park/electrical" component={Electrical} />
          <Route path="/park/report" component={Report} />
          <Route path="/park/resarchives" render={() => <Archives />} />
          <Route path="/park/cfsarchives" render={() => <CoachArchives />} />
          <Route path="/park/help" component={Help} />
          <Route path="/park/privacy" component={Privacy} />
        </Switch>
      </ParkContext.Provider>
    </div>
  );
};

export default ParksContent;
