import React, { useState } from 'react';
import { Button, Icon, Header, Modal } from 'semantic-ui-react';
import ModalActions from '../../../../components/CreateModal/ModalActions';
import useAssessment from './useAssessment';
import EditForm from './EditForm';

const EditAssessment = ({ taxBill, editAssess, index }) => {
  // console.log('EA', editAssess, taxBill);
  const [open, setOpen] = useState(false);
  const { assessment, doUpdate, canUpdate, handleChange } = useAssessment(
    taxBill,
    editAssess,
    index,
    () => {
      setOpen(false);
    },
    (error) => {
      console.log('Failure', error);
    }
  );

  const disabled = () => !canUpdate();

  // Modal Actions

  const handleOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const save = () => {
    doUpdate();
  };

  return (
    <Modal
      dimmer="inverted"
      trigger={
        <Button basic icon color="teal">
          <Icon name="edit outline" size="large" />
        </Button>
      }
      open={open}
      onOpen={handleOpen}
      onClose={handleClose}
    >
      <Header content="Edit Assessment" />
      <Modal.Content>
        <EditForm assessment={assessment} fieldUpdated={handleChange} />
      </Modal.Content>
      <ModalActions onClose={handleClose} onSave={save} disabled={disabled} />
    </Modal>
  );
};

export default EditAssessment;
