import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Divider, Segment, Table } from 'semantic-ui-react';
import Can from '../../../../components/Can/Can';
import { formatDateString } from '../../../../services/dates/formatting';
import EditBalance from './EditActivity/EditBalance';
import AllocationsTable from './AllocationsTable';
import ExpensesTable from './ExpensesTable';
import useActivities from './useActivities';

const SummaryTable = ({ study, user }) => {
  const { activities, loading, fetchError } = useActivities(study.id);

  const [totalAllocations, setTotalAllocations] = useState(0);
  const [totalExpenses, setTotalExpenses] = useState(0);

  const formatter = new Intl.NumberFormat('en-US', {
    style: 'currency',
    currency: 'USD',
    minimumFractionDigits: 0,
  });

  useEffect(() => {
    const allocations = activities.find((act) => act.allocations);
    if (allocations) {
      const total = allocations.allocations.reduce(
        (acc, alloc) => acc + alloc.amount,
        0
      );
      setTotalAllocations(total);
    } else {
      setTotalAllocations(0);
    }
    const expenses = activities.find((act) => act.expenses);
    if (expenses) {
      const total = expenses.expenses.reduce((acc, exp) => acc + exp.amount, 0);
      setTotalExpenses(total);
    } else {
      setTotalExpenses(0);
    }
  }, [activities]);

  const currency = (value) => {
    if (value) {
      return formatter.format(value);
    }
    return null;
  };

  const actualBalanceAmount = () =>
    study.balance ? currency(study.balance.amount) : 0;

  const actualBalanceDate = () =>
    study.balance ? formatDateString(study.balance.date) : '';

  const computeExpectedBalance = () =>
    study.startingReserveBalance + totalAllocations - totalExpenses;

  const renderButtons = () => (
    <>
      <EditBalance study={study} />
    </>
  );

  const renderButtonsCan = () => (
    <Can role={user.role} perform="study:edit" yes={() => renderButtons()} />
  );

  const renderSummaryData = () => (
    <Table.Row>
      <Table.Cell>{study.name}</Table.Cell>
      <Table.Cell textAlign="center">
        {formatter.format(study.startingReserveBalance)}
      </Table.Cell>
      <Table.Cell textAlign="center">{currency(totalAllocations)}</Table.Cell>
      <Table.Cell textAlign="center">{currency(totalExpenses)}</Table.Cell>
      <Table.Cell textAlign="center">
        {currency(computeExpectedBalance())}
      </Table.Cell>
      <Table.Cell textAlign="center">{actualBalanceAmount()}</Table.Cell>
      <Table.Cell textAlign="center">{actualBalanceDate()}</Table.Cell>
      <Table.Cell textAlign="center" />
      <Table.Cell>{renderButtonsCan()}</Table.Cell>
    </Table.Row>
  );

  const renderSummaryHeader = () => (
    <Table.Header fullWidth>
      <Table.Row>
        <Table.HeaderCell textAlign="left" width={3}>
          Name
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={2}>
          Starting Balance
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={2}>
          Allocations
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={2}>
          Spending
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={2}>
          Expected Balance
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={2}>
          Actual Balance
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={2}>
          Balance Date
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={2}>
          Delta
        </Table.HeaderCell>
        <Table.HeaderCell textAlign="center" width={2}>
          Actions
        </Table.HeaderCell>
      </Table.Row>
    </Table.Header>
  );

  if (loading) {
    return <div>Loading</div>;
  }

  if (fetchError) {
    return <div>fetchError</div>;
  }

  return (
    <>
      <Segment color="teal">
        <Divider horizontal>Summary</Divider>
        <Table celled compact striped>
          {renderSummaryHeader()}
          <Table.Body>{renderSummaryData()}</Table.Body>
        </Table>
        {activities && (
          <ExpensesTable study={study} activities={activities} user={user} />
        )}
        {activities && (
          <AllocationsTable study={study} activities={activities} user={user} />
        )}
      </Segment>
    </>
  );
};

SummaryTable.propTypes = {
  user: PropTypes.object.isRequired,
  study: PropTypes.object.isRequired,
};

export default SummaryTable;
