import { useState, useEffect } from 'react';
import { parseLocaleFloat } from '../../../../../services/number/formatting';
import { db } from '../../../../../services/firebase/firebase';

// Custom hook for update Reserve Actual Balance. Provide callbacks for
// success and failure notification.
const useSummary = (study, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();
  const [balance, setBalance] = useState({});

  useEffect(() => {
    setBalance({
      amount: study.balance ? study.balance.amount : '',
      date: study.balance ? new Date(study.balance.date) : new Date(),
    });
  }, [study]);

  // Returns variables for edit
  const makeVariables = () => ({
    balance: { date: balance.date, amount: parseLocaleFloat(balance.amount) },
  });

  const docRef = db.collection('studies').doc(study.id);

  const canUpdate = () => true;

  const _update = async (variables) => {
    console.log('update balance vars ', variables);

    docRef
      .update({ ...variables })
      .then(() => {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        failure(errorMessage);
      });
  };

  const doUpdate = async () => {
    _update(makeVariables());
  };

  const handleChange = (name, value) => {
    setBalance({ ...balance, [name]: value });
  };

  return {
    errorMessage,
    handleChange,
    canUpdate,
    doUpdate,
    balance,
  };
};

export default useSummary;
