/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Button, Image, Segment, Grid } from 'semantic-ui-react';
import { useDropzone } from 'react-dropzone';

const EditGallery = ({ onDrop, maxSize, imageUrls }) => {
  const {
    // isDragActive,
    getRootProps,
    getInputProps,
    // isDragReject,
    // acceptedFiles,
    // rejectedFiles
  } = useDropzone({
    onDrop: { onDrop },
    accept: 'image/*',
    minSize: 0,
    maxSize: { maxSize },
  });

  return (
    <Segment>
      <div {...getRootProps()}>
        <input {...getInputProps()} />
        <Grid columns={6} divided>
          {imageUrls.map((url, index) => (
            <Grid.Column key={index} verticalAlign="middle">
              <Image key={url} src={url} size="small" />
            </Grid.Column>
          ))}
          <Grid.Column verticalAlign="middle">
            <Button basic color="teal">
              Upload Image
            </Button>
          </Grid.Column>
        </Grid>
      </div>
    </Segment>
  );
};

export default EditGallery;
