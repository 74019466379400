function computeComponentIdealBalance(comp) {
  const age = comp.usefulLife - comp.remainingUsefulLife;
  if (age >= 0) {
    const balance = Math.round((comp.replacementCost * age) / comp.usefulLife);
    // if (isNaN(balance)) {
    // console.log("components.computeComponentIdealBalance age >=0 ", comp);
    // }
    return balance;
  }
  // useful life < remaining useful life => repl cost / remaining life + 1
  const bal = comp.replacementCost / (comp.remainingUsefulLife + 1);
  return Math.round(bal);

  // const interest = balance / Math.pow(1.005, project.remainingUsefulLife);
  // const inflation = balance / Math.pow(1.03, project.remainingUsefulLife);
  // console.log("db ", balance, interest, inflation);
  // console.log("ib ", balance + interest - inflation);

  // return Math.round(
  //   project.replacementCost -
  //     (project.replacementCost * project.remainingUsefulLife) /
  //       project.usefulLife
  // );
}

function computeTotalReplacementCost(comps) {
  // const filtered = comps.filter(comp => !comp.isExcluded);
  const total = comps.reduce(
    (acc, obj) => (obj.isExcluded ? acc : acc + obj.replacementCost),
    0
  );
  console.log('Total Repl Cost ', Math.round(total));
  return Math.round(total);
}

function computeTotalIdealBalance(comps) {
  // const filtered = comps.filter(comp => !comp.isExcluded);
  // console.log("components.computeTotalIdealBalance filtered", filtered);
  const total = Math.round(
    comps.reduce(
      (acc, val) =>
        val.isExcluded ? acc : acc + computeComponentIdealBalance(val),
      0
    )
  );
  console.log('Total Ideal Balance ', total);
  return total;
}

function makeIdealBalanceArray(comps) {
  const filtered = comps.filter((comp) => !comp.isExcluded);
  const total = computeTotalIdealBalance(comps);
  const ibs = filtered.map((comp) => {
    const ib = computeComponentIdealBalance(comp);
    const pib = (ib / total) * 100.0;
    const pibs = pib.toFixed(2);
    let sig;
    if (comp.usefulLife > comp.remainingUsefulLife) {
      sig = Math.round(comp.replacementCost / comp.usefulLife);
    } else {
      sig = Math.round(comp.replacementCost / (comp.remainingUsefulLife + 1));
    }
    return { id: comp.id, ib, pibs, sig };
  });
  // console.log("Ideal balances ", ibs);
  return ibs;
}

function printCompsInfo(comps, categoryName) {
  const filtered = comps.filter((comp) => comp.category === categoryName);
  console.log('For ', categoryName);
  computeTotalIdealBalance(filtered);
  computeTotalReplacementCost(filtered);
}

function computeStudyIdealBalance(study, yearIndex) {
  // const match1 = 3;
  // const match2 = 4;

  if (!study.components) {
    return 0;
  }
  // const filtered = study.components.filter(comp => !comp.isExcluded);
  const rc = study.components.reduce((acc, comp) => {
    if (comp.isExcluded) {
      return acc;
    }
    const age = Math.abs(comp.usefulLife - comp.remainingUsefulLife);

    if (age === 0) {
      // See #216 10/10/0/14700
      const ib =
        ((yearIndex % comp.usefulLife) / comp.usefulLife) *
        comp.replacementCost;
      const t = acc + ib;
      // if (yearIndex == match1 || yearIndex == match2) {
      //   console.log("ib ", comp, yearIndex, ib, total);
      // }
      return t;
    }
    // Typical case
    // debugger;
    const m = (age + yearIndex) % comp.usefulLife;
    // const ib = (comp.rc * m) / comp.ul;
    const tib =
      m === 0
        ? comp.replacementCost
        : (comp.replacementCost * m) / comp.usefulLife;
    const total = acc + tib;
    // if (yearIndex == match1 || yearIndex == match2) {
    //   console.log("m, tib ", comp, m, tib, total);
    // }
    return total;
  }, 0);
  // const fudge = rc + (yearIndex >= 4 ? 60 : 0);
  const withInflation = rc * (1 + study.inflationRate) ** yearIndex;
  // if (yearIndex == match1 || yearIndex == match2) {
  //   console.log("cIB ", Math.round(rc), Math.round(withInflation));
  // }
  return Math.round(withInflation);
}

function makeStudyIdealBalances(study, forYears) {
  const ibs = [];
  for (let year = 0; year < forYears; year += 1) {
    ibs.push(computeStudyIdealBalance(study, year));
  }
  return ibs;
}

function makeStudiesIdealBalanceArray(studies, forYears) {
  const iba = studies.map((study) => makeStudyIdealBalances(study, forYears));
  console.log('iba ', iba);
}

// Compute the expenses for components for given year index
function computeStudyYearExpense(study, yearIndex) {
  if (!study.components) {
    return 0;
  }
  // const filtered = study.components.filter(comp => !comp.isExcluded);
  const exp = study.components.reduce((acc, comp) => {
    if (comp.isExcluded) {
      return acc;
    }
    const age = Math.abs(comp.usefulLife - comp.remainingUsefulLife);
    if (comp.usefulLife === 0) {
      // Special case for one time upgrade
      if (age === yearIndex) {
        return acc + comp.replacementCost;
      }
      return acc + 0;
    }
    if (comp.usefulLife === comp.remainingUsefulLife && comp.usefulLife > 0) {
      // debugger;
      // Case where AGE == 0
      const ul = Math.floor(comp.usefulLife);
      const yr = Math.floor(yearIndex % ul);
      return acc + (yearIndex > 0 && yr === 0 ? comp.replacementCost : 0);
    }
    // Normal case with repeating RUL. Add the RC if
    // the comp is up for replacement this index year.
    const year = (age + yearIndex) % comp.usefulLife;
    const sum = acc + (year === 0 ? comp.replacementCost : 0);
    // if (comp.ul === comp.rul) {
    //   console.log("comp ", comp);
    //   debugger;
    // }
    // debugger;
    return sum;
  }, 0);
  const withInflation = exp * (1 + study.inflationRate) ** yearIndex;
  // console.log("expense withInflation ", withInflation, yearIndex);
  return Math.round(withInflation);
}

export { printCompsInfo };
export { makeStudiesIdealBalanceArray };
export { computeComponentIdealBalance };
export { makeIdealBalanceArray };
export { computeTotalIdealBalance };
export { computeTotalReplacementCost };
export { computeStudyIdealBalance };
export { computeStudyYearExpense };
