import React from 'react';
import { Form } from 'semantic-ui-react';
import { digits } from '../../../../services/number/formatting';

const EditForm = ({ fieldUpdated, studyComp }) => (
  <div>
    <Form>
      <Form.Group>
        <Form.Input
          width={6}
          fluid
          label="Name"
          id="form-input-name"
          placeholder="Name"
          value={studyComp.name}
          onChange={(_, data) => {
            fieldUpdated('name', data.value);
          }}
        />
        <Form.Input
          width={2}
          fluid
          label="ID"
          id="form-input-pid"
          placeholder="ID"
          value={studyComp.pid}
          onChange={(_, data) => {
            fieldUpdated('pid', digits(data.value));
          }}
        />
        <Form.Input
          width={2}
          fluid
          label="UL"
          id="form-input-ul"
          placeholder="Useful life"
          value={studyComp.ul}
          onChange={(_, data) => {
            fieldUpdated('ul', digits(data.value));
          }}
        />
        <Form.Input
          width={2}
          fluid
          label="Rem UL"
          id="form-input-rul"
          placeholder="Remaining useful life"
          value={studyComp.rul}
          onChange={(_, data) => {
            fieldUpdated('rul', digits(data.value));
          }}
        />
        <Form.Input
          width={2}
          fluid
          label="Repl Cost"
          id="form-input-rc"
          placeholder="Repl cost"
          value={studyComp.rc}
          onChange={(_, data) => {
            fieldUpdated('rc', digits(data.value));
          }}
        />
      </Form.Group>
    </Form>
  </div>
);

export default EditForm;
