import { useState } from 'react';
import firebase from 'firebase/app';
import { parseLocaleNumber } from '../../../../../services/number/formatting';
import { db } from '../../../../../services/firebase/firebase';

// Custom hook for CRUD Reserve Allocation Activity. Provide optional callbacks for
// success and failure notification.
const useAllocation = (itemOrNull, study, success, failure) => {
  const [errorMessage, setErrorMessage] = useState();
  const [allocation, setAllocation] = useState({
    date: itemOrNull.date ? itemOrNull.date : new Date(),
    amount: itemOrNull.amount || '',
  });

  // Returns variables for edit
  const makeVariables = () => ({
    ...allocation,
    amount: parseLocaleNumber(allocation.amount),
  });

  const docRef = db
    .collection('studies')
    .doc(study.id)
    .collection('activities')
    .doc('allocations');

  const canCreate = () => allocation.date && allocation.amount;

  const canUpdate = () => true;

  const _writeBatch = async (batch) => {
    batch
      .commit()
      .then((res) => {
        success();
      })
      .catch((error) => {
        setErrorMessage(error.message);
        failure(errorMessage);
      });
  };

  const _create = async (variables) => {
    console.log('variables', variables);
    const vars = [variables];

    db.collection('studies')
      .doc(study.id)
      .collection('activities')
      .doc('allocations')
      .set(
        { allocations: firebase.firestore.FieldValue.arrayUnion(...vars) },
        { merge: true }
      )
      .then(() => {
        success();
      })
      .catch((error) => {
        console.log('error', error);
        setErrorMessage(error.message);
        failure(errorMessage);
      });
  };

  const doCreate = async () => {
    _create(makeVariables());
  };

  const _update = async (variables) => {
    console.log('update allocations vars ', variables);
    console.log('Original', itemOrNull);

    const writeBatch = db.batch();

    writeBatch.update(docRef, {
      allocations: firebase.firestore.FieldValue.arrayRemove({ ...itemOrNull }),
    });
    writeBatch.update(docRef, {
      allocations: firebase.firestore.FieldValue.arrayUnion({ ...variables }),
    });
    _writeBatch(writeBatch);
  };

  const doUpdate = async () => {
    _update(makeVariables());
  };

  const doDelete = async (entry) => {
    console.log('doDelete', entry);

    const writeBatch = db.batch();

    writeBatch.update(docRef, {
      allocations: firebase.firestore.FieldValue.arrayRemove({ ...entry }),
    });
    _writeBatch(writeBatch);
  };

  const sumAllToNow = () => {
    const allocationAmount = study.annualAllocationAmounts[0];
    const nowMonth = new Date().getMonth();
    const months = study.getAllMonths(nowMonth);
    const sum = months.reduce((acc) => acc + allocationAmount, 0);
    return sum;
  };

  const addAllToNow = () => {
    const allocationAmount = study.annualAllocationAmounts[0];
    const nowMonth = new Date().getMonth();
    const months = study.getAllMonths(nowMonth);
    // const sum = sumAllToNow();
    // console.log("months", months, sum);
    const toAdd = months.map((month) => ({
      date: month,
      amount: allocationAmount,
    }));
    // console.log("toAdd", toAdd);

    const writeBatch = db.batch();
    toAdd.forEach((item) => {
      writeBatch.set(
        docRef,
        {
          allocations: firebase.firestore.FieldValue.arrayUnion({ ...item }),
        },
        { merge: true }
      );
    });
    _writeBatch(writeBatch);
  };

  // Returns month index for allocations from Q1 till now
  // const getAllMonths = (nowMonth) => {
  //   const nowIndex = fiscalIndex.indexOf(nowMonth);
  //   if (nowIndex < 0) {
  //     console.log("Now Month Error", nowMonth);
  //     return [];
  //   }

  //   return fiscalIndex
  //     .map((value, index) => {
  //       const year = [9, 10, 11].includes(value)
  //         ? study.studyYear - 1
  //         : study.studyYear;
  //       const day = new Date(year, value, 15);

  //       if (index <= nowIndex) {
  //         // console.log("Include", index, nowIndex, day);
  //         return day;
  //       } else {
  //         // console.log("Skip", index, nowIndex, day);
  //         return null;
  //       }
  //     })
  //     .filter((element) => element !== null);
  // };

  const handleChange = (name, value) => {
    setAllocation({ ...allocation, [name]: value });
  };

  const makeOptions = () => {
    const comps = study.yearlyExpenses.componentsByYear;
    return comps[0].map((comp) => ({
      text: comp.name,
      value: comp.name,
      key: comp.name,
      pid: comp.pid,
    }));
  };

  return {
    errorMessage,
    sumAllToNow,
    addAllToNow,
    handleChange,
    makeOptions,
    canUpdate,
    canCreate,
    doCreate,
    doUpdate,
    doDelete,
    allocation,
  };
};

export default useAllocation;
