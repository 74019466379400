import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'semantic-ui-react';

const EditForm = ({ fieldUpdated, budget, importFile }) => (
  <Form>
    <Form.Group>
      <Form.Input
        required
        width={3}
        fluid
        label="Name"
        id="form-input-name"
        value={budget.name}
        onChange={(_, data) => {
          fieldUpdated('name', data.value);
        }}
      />
      <Form.Input
        required
        width={2}
        fluid
        label="Year"
        id="form-input-year"
        value={budget.budgetYear}
        onChange={(_, data) => {
          fieldUpdated('budgetYear', data.value);
        }}
      />
      <Form.Input
        width={11}
        fluid
        label="Notes"
        id="form-input-note"
        value={budget.note}
        onChange={(_, data) => {
          fieldUpdated('note', data.value);
        }}
      />
    </Form.Group>
    {importFile && (
      <Form.Group>
        <Form.Input
          type="file"
          label="Import Line Items CSV File"
          id="form-input-file"
          onChange={importFile}
        />
      </Form.Group>
    )}
  </Form>
);

EditForm.propTypes = {
  budget: PropTypes.object.isRequired,
};

export default EditForm;
