import React from 'react';
import { Dropdown, Form } from 'semantic-ui-react';

const EditForm = ({ fieldUpdated, user, roleOptions, statusOptions }) => (
  // console.log("EditForm.props", props);

  // const updated = (name, value) => {
  //   props.fieldUpdated && props.fieldUpdated(name, value);
  // };

  <div>
    <Form>
      <Form.Group>
        <Form.Input
          width={4}
          fluid
          required
          label="Email"
          name="email"
          id="form-input-email"
          value={user.email}
          onChange={(_, data) => {
            fieldUpdated(data.name, data.value);
          }}
        />
        <Form.Input
          width={4}
          fluid
          required
          label="Space"
          name="space"
          id="form-input-space"
          value={user.space}
          onChange={(_, data) => {
            fieldUpdated(data.name, data.value);
          }}
        />
        <Form.Input width={3} label="Status">
          <Dropdown
            basic
            selection
            name="status"
            options={statusOptions}
            value={user.status}
            onChange={(_, data) => fieldUpdated(data.name, data.value)}
          />
        </Form.Input>
        <Form.Input width={2} label="Role">
          <Dropdown
            basic
            selection
            name="role"
            options={roleOptions}
            value={user.role}
            onChange={(_, data) => fieldUpdated(data.name, data.value)}
          />
        </Form.Input>
      </Form.Group>
    </Form>
  </div>
);
export default EditForm;
