import { useState } from 'react';
import { RoleOptions, StatusOptions } from './StatusOptions';
import { db } from '../../../../services/firebase/firebase';

export const COMPLETION_CODE = Object.freeze({
  SUCCESS: 'SUCCESS',
  FAILURE: 'FAILURE',
});

const useUser = (userOrNull, completed) => {
  const [errorMessage, setErrorMessage] = useState(null, (message) => {
    completed(COMPLETION_CODE.FAILURE, message);
  });
  const [user, setUser] = useState({
    email: userOrNull.email || '',
    space: userOrNull.space || '',
    status: userOrNull.status || StatusOptions[0].value,
    role: userOrNull.role || RoleOptions[0].value,
  });
  const original = userOrNull;

  const makeVariables = () => ({
    email: user.email,
    role: user.role,
    status: user.status,
    space: user.space ? parseInt(user.space) : 0,
    updated: new Date(),
  });

  const doCreate = async () => {
    const vars = makeVariables();
    console.log('create user vars ', vars);

    db.collection('users')
      .doc()
      .set({
        ...vars,
      })
      .then(() => {
        console.log('Created user');
        completed(COMPLETION_CODE.SUCCESS, user);
      })
      .catch((error) => {
        console.log('Error creating user', error);
        setErrorMessage(error.message);
      });
  };

  const doUpdate = async () => {
    const vars = makeVariables();
    console.log('update user vars ', vars);

    db.collection('users')
      .doc(original.id)
      .update({
        ...vars,
      })
      .then(() => {
        console.log('Updated user');
        completed(COMPLETION_CODE.SUCCESS, user);
      })
      .catch((error) => {
        console.log('Error updating user', error);
        setErrorMessage(error.message);
      });
  };

  const doDelete = async (userToDelete) => {
    console.log('doDelete', userToDelete);

    db.collection('users')
      .doc(userToDelete.id)
      .delete()
      .then(() => {
        console.log('Deleted user');
        completed(COMPLETION_CODE.SUCCESS, userToDelete);
      })
      .catch((error) => {
        console.log('Error deleting user', error);
        setErrorMessage(error.message);
      });
  };

  const handleChange = (name, value) => {
    setUser(() => ({
      ...user,
      [name]: value,
    }));
  };

  return {
    errorMessage,
    handleChange,
    doCreate,
    doUpdate,
    doDelete,
    user,
  };
};

export default useUser;
