import React from 'react';
import { UserContext } from '../../services/context/UserContext';
import { ParkContext } from '../../services/context/ParkContext';
import Can from '../../components/Can/Can';
import ElectricalTable from './ElectricalTable';

const renderElectricalTable = (park, user) => (
  <ElectricalTable park={park} user={user} showArchive={false} />
);

const Spaces = () => {
  const { user } = React.useContext(UserContext);
  const { park } = React.useContext(ParkContext);

  if (!user || !park) {
    return <div>Loading...</div>;
  }

  return (
    <Can
      role={user.role}
      perform="park:edit"
      yes={() => renderElectricalTable(park, user)}
      no={() => <p>Hello?</p>}
    />
  );
};

export default Spaces;
