import { db } from '../../../../services/firebase/firebase';

const useModify = (success, failure) => {
  const _doModify = (projectId, archiveState) => {
    db.collection('projects')
      .doc(projectId)
      .update({ archive: archiveState })
      .then(() => {
        success();
      })
      .catch((error) => {
        failure(error.message);
      });
  };

  const _doDelete = (projectId, collectionName) => {
    const ref = db
      .collection('projects')
      .doc(projectId)
      .collection(collectionName);
    const writeBatch = db.batch();

    ref.get().then((snapshot) => {
      snapshot.docs.forEach((doc) => {
        writeBatch.delete(ref.doc(doc.id));
      });

      writeBatch
        .commit()
        .then((res) => {
          console.log('Deleted ', collectionName, res);
          success();
        })
        .catch((error) => {
          console.log('Error deleting ', collectionName, error);
          failure(error.message);
        });
    });
  };

  const archiveProject = (projectId) => {
    _doModify(projectId, true);
  };

  const activateProject = (projectId) => {
    _doModify(projectId, false);
  };

  const deleteProject = (projectId) => {
    // Delete subcollections using batch first
    _doDelete(projectId, 'bids');
    _doDelete(projectId, 'payments');
    // Then delete the project
    db.collection('projects').doc(projectId).delete();
  };

  return {
    deleteProject,
    archiveProject,
    activateProject,
  };
};

export default useModify;
