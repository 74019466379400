import { useState, useEffect } from 'react';
import { db } from '../../services/firebase/firebase';

const useSpaces = () => {
  const [spaces, setSpaces] = useState();
  const [spacesLoading, setSpacesLoading] = useState(true);
  const [spacesError, setSpacesError] = useState(null);

  useEffect(() => {
    setSpacesLoading(false);

    try {
      const unsubscribe = db
        .collection('spaces')
        // .where("isArchive", "==", false)
        .onSnapshot((snapshot) => {
          const all = snapshot.docs
            .map((doc) => ({
              id: doc.id,
              ...doc.data(),
            }))
            .sort((a, b) => (a.number > b.number ? 1 : -1))
            .map((space) => {
              if (
                space.impounds &&
                space.impounds.date &&
                typeof space.impounds.date !== 'string'
              ) {
                return {
                  ...space,
                  impounds: {
                    coach: space.impounds.coach,
                    amount: space.impounds.amount,
                    date: space.impounds.date.toDate(),
                  },
                };
              }
              return space;
            })
            .map((space) => {
              if (space.purchaseDate) {
                return {
                  ...space,
                  purchaseDate: space.purchaseDate.toDate(),
                };
              }
              return space;
            });
          setSpaces(all);
        });

      return () => {
        console.log('cleanup spaces');
        unsubscribe();
      };
    } catch (error) {
      console.log('Error', error);
      setSpacesError(error.message);
    }
  }, []);

  return {
    spacesError,
    spacesLoading,
    spaces,
  };
};

export default useSpaces;
